import React from "react";
import "./AirisTour.scoped.css";
import TourBox from "../TourBox/TourBox";
import { useSelector } from "react-redux";
import VoiceAssistAnimation from "./VoiceAssistAnimation/VoiceAssistAnimation";
import AirisChatBox from "./AirisChat/AirisChatBox";
import AirisVideoCard from "./AirisVideoCard";

function AirisModal() {
  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);

  const subStep = tourStep - 6;

  const titles = ["VosynAssist", "Any Language", "Any Video", "Any Voice"];

  const tourTexts = [
    "VosynAssist is a chatbot that guides you through the VosynVerse, helping you discover what interests you.",
    "You're free to use any language of your choice to communicate.",
    "You can ask for video recommendations to find exactly what you're looking for.",
    "You can even use your voice if you don't feel like typing.",
  ];

  const chatText = [
    [
      {
        text: "What are the two best yoga types for building strength?",
        align: "right",
      },
      {
        text: "Sure! Here they are:\n\n1. Vinyas \n2. Ashtanga",
        align: "left",
      },
      null,
      null,
      { text: "" },
    ],
    [
      { text: "Do you know Arabic?", align: "right" },
      { text: "أجل، أستطيع", align: "left" },
      { text: "How about Spanish?", align: "right" },
      { text: "Sí, puedo", align: "left" },
      null,
      null,
      { text: "" },
    ],
  ];

  const tourContent = [
    <AirisChatBox conversations={[chatText[0]]} />,
    <AirisChatBox conversations={[chatText[1]]} />,
    <div>
      <AirisVideoCard
        label={"French speaking course"}
        divStyle={{ backgroundColor: "#46c4f5" }}
        index={3}
      />
      <AirisVideoCard
        label={"Interview with Tom Holland"}
        divStyle={{ backgroundColor: "#F2BEEE" }}
        index={2}
      />
      <AirisVideoCard
        label={"Top 10 Travel Destinations"}
        divStyle={{ backgroundColor: "#7595FA" }}
        index={1}
      />
    </div>,
    <VoiceAssistAnimation />,
  ];

  const currentChatText = subStep < chatText.length ? chatText[subStep] : [];

  return (
    <>
      <div className="highlighted" />
      <div className="airis-tour">
        <TourBox
          tourContent={tourContent[subStep]}
          voiceContent={subStep === 3 ? <VoiceAssistAnimation /> : null}
          AirisChat={subStep < 2}
          arrowDirection="arrow-right"
          title={titles[subStep]}
          tourText={tourTexts[subStep]}
          stage="3"
          progressBar={true}
          chatText={currentChatText}
          content={true}
        />
      </div>
    </>
  );
}

export default AirisModal;
