import React, { useRef, useState } from "react";
import {
  Card,
  CardActions,
  IconButton,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import Send from "@mui/icons-material/Send";

import "./UploadInteractions.scoped.css";
import { useNavigate } from "react-router-dom";
import { TRANSLATE_URL } from "../../constant/constantUrls";
import { useDispatch, useSelector } from "react-redux";
import RecordAudio from "../RecordAudio/RecordAudio";
import RecordVideo from "../RecordVideo/RecordVideo";
import { useAlert } from "../../context/AlertProvider";
import { setSelectedFile } from "../../reducers/languageSlice";

const UploadInteractions = () => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state?.userInfo?.userProfile);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [audioBlob, setAudioBlob] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAudioRecorded = (blob) => {
    setAudioBlob(blob);
  };

  const handleVideoRecorded = (blob) => {
    setVideoBlob(blob);
  };

  /**
   * A method to upload the media and ridirect to the translation page.
   *
   * @param {The event detail} e
   */
  const uploadMedia = async (e) => {
    e.preventDefault();

    const file = e.target.files;

    const fileType = file[0].type.split("/")[0];
    const fileName = file[0].name;

    const date = new Date();

    // This will need to be sent to the backend when we have the api endpoint.
    const mediaData = {
      dateUploaded: date.toUTCString(),
      user: profileDetails?.username,
      fileName: fileName,
    };

    dispatch(setSelectedFile(fileName));

    navigate(`${TRANSLATE_URL}/${fileType}/${fileName}`);
  };

  // Will be used next sprint
  const handleUploadMedia = async () => {
    setError("");
    setLoading(true);

    try {
      let response;
      if (audioBlob) {
        // Audio upload handled inside RecordAudio component
        showAlert("success", "Audio uploaded and translated successfully!");
        navigate(`/audio-translation/${response.id}`);
      } else if (videoBlob) {
        // Video upload handled inside RecordVideo component
        showAlert("success", "Video uploaded and translated successfully!");
        navigate(`/video-translation/${response.id}`);
      } else if (file) {
        response = await uploadMedia(file);
        const fileType = file.type.split("/")[0];
        showAlert("success", "File uploaded successfully!");
        navigate(`${TRANSLATE_URL}/${fileType}/${response.fileName}`);
      }
    } catch (err) {
      setError("Failed to upload media. Please try again.");
      showAlert("error", "Failed to upload media. Please try again.");
    } finally {
      setLoading(false);
      setAudioBlob(null);
      setVideoBlob(null);
      setFile(null);
    }
  };

  return (
    <Card className="actions-card-container" style={{ boxShadow: 'none' }}>
      <CardActions className="actions-container">
        {/* Start recording video directly when icon is clicked */}
        <RecordVideo
          onRecorded={handleVideoRecorded}
          triggerStartRecording={true}
        >
          {(startRecording) => (
            <IconButton
              className="icon-color"
              aria-label="record-video"
              onClick={startRecording}
            >
              <VideocamOutlinedIcon />
            </IconButton>
          )}
        </RecordVideo>

        {/* Start recording audio directly when icon is clicked */}
        <RecordAudio
          onRecorded={handleAudioRecorded}
          triggerStartRecording={true}
        >
          {(startRecording) => (
            <IconButton
              className="icon-color"
              aria-label="record-audio"
              onClick={startRecording}
            >
              <MicNoneOutlinedIcon />
            </IconButton>
          )}
        </RecordAudio>

        <IconButton
          className="icon-color"
          onClick={() => fileInputRef.current.click()}
          aria-label="upload-media"
        >
          <AddOutlinedIcon />
          <input
            type="file"
            ref={fileInputRef}
            onChange={uploadMedia}
            className="hidden-input"
          />
        </IconButton>
      </CardActions>

      {/* Display error message if any */}
      {error && <Typography color="error">{error}</Typography>}
    </Card>
  );
};

export default UploadInteractions;
