import { Box, Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./OnboardingInstructions.scoped.css";

const OnboardingInstructions = ({ onboardingStep, handleGoBack }) => {
  return (
    <Box className="instruction-panel">
      <Box className="back-button-container">
        {onboardingStep > 0 && (
          <Button
            className="back-button"
            variant="text"
            onClick={handleGoBack}
            disableRipple
          >
            <ArrowBackIosNewIcon />
            Back
          </Button>
        )}
      </Box>
      <Typography className="instruction-body" variant="body1" gutterBottom>
        Answer a few quick questions so we can show you the right content in
        your favourite language!
      </Typography>
    </Box>
  );
};

export default OnboardingInstructions;
