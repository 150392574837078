import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    globeView: true
}

const globeBannerSlice = createSlice({
    name: 'globeBanner',
    initialState,
    reducers: {
        setGlobeView(state){
            state.globeView = !state.globeView;
        }
    }
})

export const { setGlobeView } = globeBannerSlice.actions;
export default globeBannerSlice.reducer;
