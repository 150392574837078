import React, { useState } from "react";
import {
  Box,
  Container,
  Card,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./SettingsPage.scoped.css";
import ParentalControlSettings from "../../components/ParentalControlSettings/ParentalControlSettings";
import SettingsPrivacyData from "../../components/SettingsPrivacyData/SettingsPrivacyData";
import SettingsNotification from "../../components/SettingsNotifications/SettingsNotifications";
import SettingPlayback from "../../components/SettingPlayback/SettingPlayback";
import ThirdPartyAccounts from "../../components/SettingsLinkThirdParty/ThirdPartyAccounts";


const SettingsPageNavigation = () => {
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [settings] = useState([
    {
      primaryText: "Playback settings",
      secondaryText: "Set autoplay and audio, video quality",
      component: <SettingPlayback onBack={() => setSelectedSetting(null)} />,
    },
    {
      primaryText: "Download settings",
      secondaryText: "Set autoplay and audio, video quality",
      //   component: <ParentalControlSettings onBack={() => setSelectedSetting(null)} />,
    },
    {
      primaryText: "Parental control",
      secondaryText: "Set autoplay and audio, video quality",
      component: (
        <ParentalControlSettings onBack={() => setSelectedSetting(null)} />
      ),
    },
    {
      primaryText: "Notification settings",
      secondaryText: "Set autoplay and audio, video quality",
      component: (
        <SettingsNotification onBack={() => setSelectedSetting(null)} />
      ),
    },
    {
      primaryText: "Link 3rd party",
      secondaryText: "Set autoplay and audio, video quality",
        component: <ThirdPartyAccounts onBack={() => setSelectedSetting(null)} />,
    },
    {
      primaryText: "Data and privacy settings",
      secondaryText: "Set autoplay and audio, video quality",
      component: (
        <SettingsPrivacyData onBack={() => setSelectedSetting(null)} />
      ),
    },
    {
      primaryText: "Subtitle preference",
      secondaryText: "Manage notifications preferences",
      //   component: <ParentalControlSettings onBack={() => setSelectedSetting(null)} />,
    },
    {
      primaryText: "View history and ratings",
      secondaryText: "Manage notifications preferences",
      //   component: <ParentalControlSettings onBack={() => setSelectedSetting(null)} />,
    },
  ]);
  return (
    <>
      {selectedSetting ? (
        selectedSetting
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          minHeight="100vh"
          bgcolor="#f5f5f5"
        >
          <Container className="settings-container">
            <Typography variant="h2" gutterBottom>
              Settings
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Playback, download, notification, link 3rd party, subtitle, and
              more.
            </Typography>
            <Card style={{ padding: "16px", marginTop: "16px" }}>
              <List>
                {settings.map((setting) => (
                  <ListItem
                    button
                    key={setting.id}
                    onClick={() => setSelectedSetting(setting.component)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                        cursor: "pointer",
                      },
                    }}
                    aria-label={`Select ${setting.primaryText}`}
                  >
                    <ListItemIcon>
                      <Box
                        className="icon"
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#e0e0e0",
                          borderRadius: "50%",
                        }}
                      ></Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={setting.primaryText}
                      secondary={setting.secondaryText}
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                      secondaryTypographyProps={{ color: "textSecondary" }}
                    />
                    <ArrowForwardIcon />
                  </ListItem>
                ))}
              </List>
            </Card>
          </Container>
        </Box>
      )}
    </>
  );
};
export default SettingsPageNavigation;
