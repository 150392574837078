import { useDispatch } from "react-redux";
import testSubs from "./sample_captions/stop_sugar_subtitles_en.vtt";
import "./Video.scoped.css";
import { setDuration } from "../../reducers/videoPlayerSlice.js";

const Video = ({
  videoRef,
  handleTimeUpdate,
  handleVideoEnded,
  togglePlayPause,
  dashVideoObject,
  handlePlay,
}) => {
  const dispatch = useDispatch();

  return (
    <video
      ref={videoRef}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={() => {
        if (videoRef.current) {
          dispatch(setDuration(videoRef.current.duration || 0));
        }
      }}
      onClick={togglePlayPause}
      onEnded={handleVideoEnded}
      onPlay={handlePlay}
    >
      <source
        src={dashVideoObject ? dashVideoObject.file_stream_cdn_url : ""}
        type="application/dash+xml"
      />
      <track
        label="English"
        kind="subtitles"
        srcLang="en"
        src={testSubs}
        default
      />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
