import PropTypes from "prop-types";
import "./Background.scoped.css";

const Background = ({ children, style }) => {
  return (
    <div className="container" style={style}>
      <div className="v1" />
      <div className="v2" />
      <div className="v3" />
      <div className="v4" />
      <div className="v5" />
      {children}
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};

export default Background;
