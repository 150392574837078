/**
 * This file contains the implementation of the YouTube video card component.
 * It represents a card displaying a YouTube video.
 */

import { useState, useEffect } from "react";
import { fetchYoutubeMetadata } from "../../../../../api/airis";

import "../../ResultsBlock.scoped.css";
import "./AirisVideos.scoped.css";

function YTVideoCard({ ytURL, ytRedirectText }) {
  const [youtubeMetadata, setYoutubeMetadata] = useState(undefined);

  //Effect to fecth information(metadata) about different YouTube videos
  useEffect(() => {
    async function getYoutubeMetadata(url) {
      let res = await fetchYoutubeMetadata(url);
      setYoutubeMetadata(res);
    }

    getYoutubeMetadata(ytURL);
  }, [ytURL]);

  // Function providing the thumbnail image associated with a given YouTube video URL.
  function getYouTubeThumbnail(url) {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const videoID = match && match[7].length === 11 ? match[7] : "";
    const thumbnailURL = `https://img.youtube.com/vi/${videoID}/0.jpg`;

    return thumbnailURL;
  }

  return (
    <div className="ai-response-container video-response-container">
      <div className="va-video-thumbnail">
        <img
          src={getYouTubeThumbnail(ytURL)}
          alt="youtube video thumbnail"
          className="va-thumbnail-image"
        />
        <img
          src={process.env.PUBLIC_URL + `/assets/Airis/image/yt_icon_rgb.png`}
          alt="youtube icon"
          className="youtube-icon"
        />
      </div>
      <div className="va-video-title">{youtubeMetadata?.data?.title}</div>
      <a
        className="third-party-link"
        href={ytURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {ytRedirectText}
      </a>
    </div>
  );
}

export default YTVideoCard;
