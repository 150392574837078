import React from "react";
import { Box, Card, Typography, IconButton } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./VideoCard.scoped.css";

const truncateText = (text, charLimit = 50) => {
  if (text.length > charLimit) {
    return text.substring(0, charLimit) + "...";
  }
  return text;
};

function VideoCard({ videoData, size }) {
  const { title_en, view_count, released_date, thumbnail_url } = videoData;

  return (
    <Card className={`videocard-container ${size}`}>
      <Box
        className="video-thumbnail"
        style={{ backgroundImage: `url(${thumbnail_url})` }}
      >
        {/* Play Icon Overlay */}
        <IconButton className="play-icon">
          <YouTubeIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </Box>

      <Box className="text-container">
        <Typography className="video-title">
          {truncateText(title_en)}
        </Typography>

        <Box className="video-info">
          <Typography
            variant="caption"
            sx={{ marginRight: "1vw" }}
          >{`${view_count} views`}</Typography>
          <Typography variant="caption">{released_date}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default VideoCard;
