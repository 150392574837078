import React, { useState } from "react";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageSelectorMenu from "../../LanguageSelectorMenu/LanguageSelectorMenu";
import useClickOutside from "../../../hooks/useClickOutside";
import "./OnboardingCompletion.scoped.css";

const OnboardingCompletion = ({ handleSubmitOnboarding }) => {
  const [currentLanguage, setCurrentLanguage] = useState("Select language");
  const languageSelectorComponent = useClickOutside();

  const showLanguageSelector = () => {
    languageSelectorComponent.setIsVisible(true);
  };

  const hideLanguageSelector = () => {
    languageSelectorComponent.setIsVisible(false);
  };

  return (
    <Box className="onboarding-body">
      <Box className="onboarding-body-container">
        <Box className="stepper-header">
          <Typography className="stepper-title" variant="h5" gutterBottom>
            You're a{" "}
            <Typography
              component="span"
              className="stepper-title title-highlight"
            >
              legend{" "}
            </Typography>
            for completing that! 🎉
          </Typography>
        </Box>
        <Box className="stepper-subheading">
          <Typography variant="body1" gutterBottom>
            Now, let’s make VosynVerse speak your language.
            <br />
            Pick your browsing language below and let the fun begin!
          </Typography>
        </Box>
        <Box
          className="language-selector-container"
          ref={languageSelectorComponent.ref}
        >
          {languageSelectorComponent.isVisible ? (
            <LanguageSelectorMenu
              hideLanguageSelector={hideLanguageSelector}
              setCurrentLanguage={setCurrentLanguage}
              currentLanguage={currentLanguage}
            />
          ) : (
            <ButtonBase
              className="language-selector-button-container"
              onClick={showLanguageSelector}
              disableRipple
            >
              <Typography
                className="selector-button"
                variant="button"
                gutterBottom
                sx={{
                  display: "block",
                  color:
                    currentLanguage === "Select language" ? "gray" : "black",
                }}
              >
                {currentLanguage}
              </Typography>
              <KeyboardArrowDownIcon className="arrow-icon" />
            </ButtonBase>
          )}
          <Box className="enter-button-container">
            <Button
              className="enter-button"
              variant="contained"
              onClick={handleSubmitOnboarding}
              disableElevation
            >
              Enter VosynVerse
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingCompletion;
