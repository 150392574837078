import "./AudioPlayerTranscript.scoped.css";
import React, { useState, useEffect } from "react";
import { Button, Box, Typography, useMediaQuery } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import { useTheme } from "@emotion/react";
import AudioPlayerTextPanel from "../../../components/AudioPlayer/AudioPlayerTextPanel/AudioPlayerTextPanel";
import { loadAudioFile } from "../../../utils/fetchTrack";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[600], // Use theme's neutral color
  color: theme.palette.common.white, // Use theme's common white color
  borderRadius: "3rem",
  "&:hover": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.8),
  },
  "&:disabled": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.3),
    color: theme.palette.common.white,
  },
}));

const AudioPlayerTranscript = ({ fileUrl }) => {
  const theme = useTheme();
  const [showCompare, setShowCompare] = useState(false);
  const isMobileView = useMediaQuery(theme.breakpoints.down("tablet"));

  const [pageControls, setPageControls] = useState({
    sideBarOpen: false,
    isWideScreen: false,
    isFullScreen: false,
    textPanelOpen: true,
    textPanelType: "transcript", // "transcript" | "captions"
    translationLanguage: null,
  });

  const pageFunctions = {
    toggleTranscriptMode: function () {
      setShowCompare(false); // Set showCompare to false when switching to Transcript mode
      setPageControls({
        ...pageControls,
        textPanelOpen: true,
        textPanelType: "transcript",
        translationLanguage: null,
        sideBarOpen: false,
        wideScreen: true,
      });
    },
    toggleCompareMode: function () {
      setShowCompare(true); // Set showCompare to true when switching to Compare mode
      setPageControls({
        ...pageControls,
        textPanelOpen: true,
        textPanelType: "transcript",
        translationLanguage: pageControls.translationLanguage ?? "es",
        sideBarOpen: false,
        wideScreen: true,
      });
    },
  };

  // Toggle between modes based on current state
  const handleToggle = () => {
    if (showCompare) {
      pageFunctions.toggleTranscriptMode(); // Switch to Transcript mode if currently in Compare mode
    } else {
      pageFunctions.toggleCompareMode(); // Switch to Compare mode if currently in Transcript mode
    }
  };

  // get audio
  const [audioFile, setAudioFile] = useState({
    isLoaded: false,
    audio: null,
    transcript: null,
    nativeLanguage: null,
    availableLanguages: [],
  });

  useEffect(() => {
    loadAudioFile()
      .then((data) => {
        setAudioFile(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [audioControls, setAudioControls] = useState(null);

  // transcript control
  const [activeTextLineIndex, setActiveTextLineIndex] = useState(0);

  const updateActiveTextLineIndex = () => {
    let timePointsArr = Object.keys(audioFile.transcript);
    let formattedTime = audioControls
      ? convertSecondsToTime(audioControls.currentTime)
      : "00:00";

    if (timePointsArr.includes(formattedTime)) {
      setActiveTextLineIndex(timePointsArr.indexOf(formattedTime));
    }
  };

  useEffect(() => {
    if (audioFile.isLoaded) {
      updateActiveTextLineIndex();
    }
  }, [audioControls ? audioControls.currentTime : null]);

  // helper functions
  const convertSecondsToTime = (seconds) => {
    if (Math.floor(seconds) <= 0) {
      return "00:00";
    } else {
      let mins = Math.floor(seconds / 60);
      mins =
        mins === 0 ? "00" : mins < 10 ? "0" + mins.toString() : mins.toString();

      seconds = Math.floor(seconds % 60);
      seconds =
        seconds === 0
          ? "00"
          : seconds < 10
            ? "0" + seconds.toString()
            : seconds.toString();

      return mins + ":" + seconds;
    }
  };

  return (
    <Box
      component="section"
      bgcolor="var(--mui-palette-neutral-800)"
      borderRadius="16px"
      p="1rem"
      position="relative" // Make the container relative for absolute positioning of the button
      sx={{ mb: "16px" }}
    >
      {/* Compare Button positioned at the top-right corner */}
      <Box position="absolute" top="1rem" right="1rem">
        <CustomButton
          variant="contained"
          startIcon={
            showCompare ? (
              <FormatQuoteOutlinedIcon />
            ) : (
              <FormatQuoteOutlinedIcon />
            )
          } // Change icon based on state
          onClick={handleToggle} // Toggle between modes on click
          data-testid="compareBtn"
        >
          {showCompare ? "Compare" : "Compare"}{" "}
          {/* Change button label based on state */}
        </CustomButton>
      </Box>

      {/* Content Display When Not Comparing */}
      {!showCompare && (
        <Box m={2}>
          <Box mb={2}>
            <Box className="transcript-panel custom-scrollbar" component="div">
              <AudioPlayerTextPanel
                open={pageControls.textPanelOpen}
                textPanelType={pageControls.textPanelType}
                audioFile={audioFile}
                pageControls={pageControls}
                activeTextLineIndex={activeTextLineIndex}
              />
            </Box>
          </Box>
        </Box>
      )}

      {/* Conditional Compare Section */}
      {showCompare && (
        <Box>
          <Typography variant="body1" component="div">
            <Box className="transcript-panel custom-scrollbar">
              <AudioPlayerTextPanel
                open={pageControls.textPanelOpen}
                textPanelType={pageControls.textPanelType}
                audioFile={audioFile}
                pageControls={pageControls}
                activeTextLineIndex={activeTextLineIndex}
              />
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AudioPlayerTranscript;
