import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageControl from "../../pages/UploadTranslationPages/UploadTextPage/PageControl/PageControl";
import data from "../../data/dummyUploadText2.json";
import { setToast } from "../../reducers/snackbarSlice";
import { setTextObject } from "../../reducers/textObjectSlice";
import { logout } from "../../reducers/authSlice";
import "./TextContainer.css"; // Import the CSS file

const fetchAndHandleTextDetails = async (dispatch, navigate) => {
  try {
    dispatch(
      setTextObject({
        textUrlPdf: data.textUrls.pdf,
        textUrlWord: data.textUrls.word,
      })
    );
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

const TextContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState("EN");
  const [pageLength, setPageLength] = useState();
  const [paginatedContent, setPaginatedContent] = useState([]);
  const [fontSize, setFontSize] = useState({
    title: 30,
    summary: 12,
    paragraph: 16,
    subtitle: 20,
  });
  
  const charLimitPage1 = 1395;
  const charLimitOtherPages = 4091;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRefs = useRef([]);

  const contentData = data.translations[language] || data;
  const content = contentData.translatedContent || data.content;
  const title = contentData.translatedTitle || data.title;
  const subtitle = contentData.translatedSubtitle || data.subtitle;
  const featuredImage = data.featuredImage;

  useEffect(() => {
    fetchAndHandleTextDetails(dispatch, navigate);
  }, [dispatch, navigate]);

  useEffect(() => {
    const pages = [];
    let contentToSplit = content;

    if (contentToSplit.length > charLimitPage1) {
      pages.push(contentToSplit.slice(0, charLimitPage1));
      contentToSplit = contentToSplit.slice(charLimitPage1);
    } else {
      pages.push(contentToSplit);
      contentToSplit = "";
    }

    while (contentToSplit.length > 0) {
      pages.push(contentToSplit.slice(0, charLimitOtherPages));
      contentToSplit = contentToSplit.slice(charLimitOtherPages);
    }

    setPaginatedContent(pages);
    setPageLength(pages.length);
  }, [content]);

  const handleNextPage = () => {
    if (currentPage < paginatedContent.length) {
      setCurrentPage(currentPage + 1);
      pageRefs.current[currentPage].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      pageRefs.current[currentPage - 2].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Container className="upload-text-page-main-container">
        <Box className="box-padding">
          <PageControl
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageRefs={pageRefs}
            pageLength={pageLength}
            setFontSize={setFontSize}
            fontSize={fontSize}
          />

          {paginatedContent.map((pageContent, index) => (
            <Box
              key={index}
              ref={(el) => (pageRefs.current[index] = el)}
              className="page-box"
            >
              {index === 0 && (
                <Box className="title-box">
                  <Typography
                    variant="h1"
                    style={{ fontSize: `${fontSize.title}px` }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontSize: `${fontSize.subtitle}px` }}
                  >
                    {subtitle}
                  </Typography>
                  <Box className="image-box">
                    <img
                      src={featuredImage}
                      alt="Featured"
                      className="featured-image"
                    />
                  </Box>
                </Box>
              )}

              <Typography
                variant="body1"
                style={{ fontSize: `${fontSize.paragraph}px` }}
                className="content-text"
              >
                {pageContent}
              </Typography>

              <Box className="page-number-box">
                <Typography variant="caption"> {index + 1}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default TextContainer;