import React, { useState, useEffect } from "react";
//import { getAllMyPlaylists } from '../../api/playlists';
import VideoPlaylistCard from "./VideoPlaylistCard";
import { Box ,Typography,IconButton} from "@mui/material";
import playlist from "../../data/playlist.json";
import "./VideoPlaylistCardList.scoped.css";
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import CloseIcon from '@mui/icons-material/Close'

const VideoPlaylistCardList = () => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /* useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await getAllMyPlaylists(1);  
        setPlaylists(response?.data?.data);
        console.log(response?.data?.data);
      } catch (error) {
        console.error("Error fetching playlists:", error);
        setError(error); 
      } finally {
        setLoading(false);
      }
    };
    fetchPlaylists();  
  }, []);  

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return <div>Error loading playlists: {error.message || "Unknown error"}</div>;  
  }

  if (!loading && playlists?.length === 0) {
    return <div>No playlists found.</div>; 
  }

  return (
    <Box>
      {playlists?.map((playlist) => (
         <Box key={playlist?.id} mb={3} mt={2}>
            <VideoPlaylistCard
            imageUrl={playlist?.content_overview?.thumbnail_url}     
            title={playlist?.name}           
            description={playlist?.description}  
            />
        </Box>
      ))}
    </Box>
  );
;*/
  // useEffect(() => {
  //     const fetchPlaylists = async () => {
  //       try {
  //         const response = await fetch('../../data/playlist.json');
  //         const data = await response.json();
  //     };
  //     fetchPlaylists();
  //   }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return (
  //     <div>Error loading playlists: {error.message || "Unknown error"}</div>
  //   );
  // }

  // if (!loading && playlists?.length === 0) {
  //   return <div>No playlists found.</div>;
  // }

  return (
    <Box className="video-playlist-card-list">
       <Box 
        className="top-box" 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2, 
          p: 2, // Add padding to the top box
        }}
      >
        <Box> {/* Wrap the Typography components in a Box */}
          <Typography variant="h6" sx={{ color: '#656565', fontWeight: 'bold' }}>Playlists</Typography>
          <Typography variant="body2" sx={{ color: '#656565' }}>18 Videos</Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <IconButton size="small" sx={{ color: '#656565', mr: 1 }}> 
            <MoreVertIcon fontSize="small" /> 
          </IconButton>
          <IconButton size="small" sx={{ color: '#656565' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {playlist?.map((playlist) => (
        <Box key={playlist?.id} mb={3} mt={2}>
          <VideoPlaylistCard
            imageUrl={playlist.thumbnail}
            title={playlist?.name}
            description={
              playlist?.videos?.[0]?.description || "No description available"
            }
          />
        </Box>
      ))}
    </Box>
  );
};

export default VideoPlaylistCardList;