import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import { Box } from "@mui/material";
import "./Comment.scoped.css";

const handleCommentClick = () => {
  // Implement what happens when the "Comment" button is clicked
};

const CommentButton = () => {
  return (
    <Box className="comment-button-container">
      <ToolTip content={"Comment"}>
        <Box
          className="comment-button"
          onClick={handleCommentClick}
          sx={{ cursor: "pointer" }}
        >
          <MuiIcon name="CommentOutlined" />
        </Box>
      </ToolTip>
    </Box>
  );
};

export default CommentButton;
