import React, { useState, useEffect } from "react";
import "./HistoryPage.scoped.css";
import { useSearchParams, useNavigate } from "react-router-dom";
import VideoCards from "../../components/VideoCards/VideoCards";
import HistorySidebar from "../../components/VideoCards/HistorySideBar/HistorySideBar";
import CardTitle from "../../components/VideoCards/CardTitle/CardTitle";
import useLoadMore from "../../hooks/useLoadMore";
import { setToast } from "../../reducers/snackbarSlice";
import { useDispatch } from "react-redux";
import { fetchVideosList } from "../../api/video";
import { logout } from "../../reducers/authSlice";

const HistoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [showHistorySidebar, setShowHistorySidebar] = useState(true);
  const [searchParams] = useSearchParams();
  const [paramObj, setParamObj] = useState({});

  useEffect(() => {
    // Convert searchParams to an object
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setParamObj(params);
  }, [searchParams]);

  useEffect(() => {
    handleLoadMore();
  }, []);

  const fetchVideos = async () => {
    const requestData = {
      sort_by: "view_count", // To get trending videos
      limit: 15,
      page,
    };
    setPage((prevPage) => prevPage + 1);

    try {
      const res = await fetchVideosList(requestData);
      if (res?.status === 200) {
        return res.data;
      }
      return [];
    } catch (err) {
      if (err?.response?.status === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error occurred. Please try again later.",
            type: "red",
          })
        );
      }
      return [];
    }
  };

  const { data, handleLoadMore, hasMore, isLoading, deleteVideo } = useLoadMore(
    [],
    fetchVideos
  );

  const toggleSidebar = () => {
    setShowHistorySidebar((prevState) => !prevState);
  };

  const removeVideo = (id) => {
    deleteVideo(id);
  };

  return (
    <div className="history-body-container">
      <div className="history-breadcrumb-container">
        <h1 className="title">Watch History</h1>
        <div
          className="history-filter-container-toggle-icon"
          onClick={toggleSidebar}
        >
          <i className="fa-solid fa-filter"></i>
        </div>
      </div>
      <div
        className={`history-content-container ${
          !showHistorySidebar ? "history-content-container-full-width" : ""
        }`}
      >
        <div className="history-cards">
          <CardTitle>Today</CardTitle>
          <VideoCards
            videos={data}
            cardType="history"
            loadMore={handleLoadMore}
            hasMore={hasMore}
            isLoading={isLoading}
            onDelete={removeVideo}
          />
        </div>
        <div
          className={`history-filter-container ${
            !showHistorySidebar ? "history-filter-container-hide" : ""
          }`}
        >
          <HistorySidebar />
        </div>
      </div>
    </div>
  );
};

export default HistoryPage;
