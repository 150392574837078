import React from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import "./WatchPartyChatBoxSettingModal.scoped.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const WatchPartyChatBoxSettingModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="watch-party-chat-box-setting-modal-title"
    >
      <Box className="settings-modal-container">
        <Box className="modal-header">
          <Typography
            className="modal-title"
            id="watch-party-chat-box-setting-modal-title"
            variant="h6"
            component="h2"
            align="center"
            color="black"
          >
            SETTINGS
          </Typography>
          <IconButton
            className="close-icon"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="modal-body">
          <Box className="modal-sub-heading">
            <Typography>USER PREFERENCES</Typography>
          </Box>
          <Box className="modal-settings-list">
            <Box className="menu-item">
              <Typography>chat filters</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Box>
            <Box className="menu-item">
              <Typography>set font style</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Box>
            <Box className="menu-item">
              <Typography>set font size</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Box>
            <Box className="menu-item">
              <Typography>chat appearance</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Box>
            <Box className="menu-item">
              <Typography>UI language settings</Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default WatchPartyChatBoxSettingModal;
