import { useMemo } from "react";
import ServiceNav from "../ServiceNav/ServiceNav";
import ServiceHeader from "../ServiceHeader/ServiceHeader";
import { useParams } from "react-router-dom";
import useQueryParam from "../../../hooks/useQueryParam";
import "./ServiceCoursera.scoped.css";
import ServiceCourseraMyLearning from "./ServiceCourseraMyLearning/ServiceCourseraMyLearning";
import ServiceCards from "../ServiceCards/ServiceCards";

const ServiceCoursera = ({ data }) => {
  const categories = useMemo(() => ["Home", "My Learning", "Categories"], []);
  const { serviceName } = useParams();
  const { getAllParams } = useQueryParam();
  const selectedCategory = useMemo(
    () => getAllParams().category || categories[0],
    [categories, getAllParams]
  );

  return (
    <>
      <ServiceNav
        categories={categories}
        selectedCategory={selectedCategory}
        serviceName={serviceName}
      />
      {selectedCategory === "My Learning" ? (
        <ServiceCourseraMyLearning />
      ) : (
        <div>
          <ServiceHeader serviceName={serviceName} />
          <h3 className="service-page-subtitle">Most Popular Certifications</h3>
          <ServiceCards
            serviceItems={data}
            serviceName="coursera"
            cardType="courseServiceCard"
          />
          <h3 className="service-page-subtitle">Recently Viewed</h3>
          <ServiceCards
            serviceItems={data}
            serviceName="coursera"
            cardType="courseServiceCard"
          />
        </div>
      )}
    </>
  );
};

export default ServiceCoursera;
