import "./AudioPlayerTranscript.scoped.css";
import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useAlert } from "../../../../context/AlertProvider";
import { downloadFile } from "../../../../utils/fileActions";
import WatchPartyModal from "../../../../components/WatchPartyModal/WatchPartyModal";
import ShareModal from "../../../../components/ShareModal/ShareModal";
import { useTheme } from "@emotion/react";
import AudioPlayerTextPanel from "../../../../components/AudioPlayer/AudioPlayerTextPanel/AudioPlayerTextPanel";
import { loadAudioFile } from "../../../../utils/fetchTrack";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[600], // Use theme's neutral color
  color: theme.palette.common.white, // Use theme's common white color
  borderRadius: "3rem",
  "&:hover": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.8),
  },
  "&:disabled": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.3),
    color: theme.palette.common.white,
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.neutral[600],
  },
}));


const CustomMenuButton = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.neutral[600], 
    color: theme.palette.common.white,
    "&:hover": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.8),
  },
  }));

  const CustomMenu = styled(Menu)(({ theme }) => ({
    "ul": {
        backgroundColor: theme.palette.neutral[600],
  },
  }));

const AudioPlayerTranscript = ({ fileUrl }) => {
  const theme = useTheme();
  const [showCompare, setShowCompare] = useState(false);
  const [openWatchParty, setOpenWatchParty] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const { showAlert } = useAlert();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const isMobileView = useMediaQuery(theme.breakpoints.down("tablet"));

  const handleDownloadClick = () => {
    downloadFile(fileUrl);
    setAnchorEl(null);
  };

  const handleBookmarkClick = () => {
    showAlert("success", "Media file has been bookmarked successfully!");
    setAnchorEl(null);
  };

  const handleCompareClick = () => {
    setShowCompare(!showCompare);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [pageControls, setPageControls] = useState({
    sideBarOpen: false,
    isWideScreen: false,
    isFullScreen: false,
    textPanelOpen: true,
    textPanelType: "transcript", // "transcript" | "captions"
    translationLanguage: null,
  });
  const pageFunctions = {
    toggleTranscriptMode: function () {
      setShowCompare(!showCompare);
      if (!pageControls.textPanelOpen && !pageControls.translationLanguage) {
        setPageControls({
          ...pageControls,
          textPanelOpen: true,
          textPanelType: "transcript",
          translationLanguage: null,
          sideBarOpen: false,
          wideScreen: true,
        });
      } else {
        setPageControls({
          ...pageControls,
          textPanelOpen: true,
          textPanelType: "transcript",
          translationLanguage: null,
          sideBarOpen: false,
          wideScreen: true,
        });
      }
    },
    toggleCompareMode: function () {
      setShowCompare(!showCompare);
      if (pageControls.textPanelOpen && pageControls.translationLanguage) {
        setPageControls({
          ...pageControls,
          textPanelOpen: false,
          textPanelType: "none",
          sideBarOpen: true,
          wideScreen: false,
        });
      } else {
        setPageControls({
          ...pageControls,
          textPanelOpen: true,
          textPanelType: "transcript",
          translationLanguage: pageControls.translationLanguage ?? "es",
          sideBarOpen: false,
          wideScreen: true,
        });
      }
    },
  };

  // get audio
  const [audioFile, setAudioFile] = useState({
    isLoaded: false,
    audio: null,
    transcript: null,
    nativeLanguage: null,
    availableLanguages: [],
  });
  useEffect(() => {
    let response = loadAudioFile()
      .then((data) => {
        setAudioFile(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [audioControls, setAudioControls] = useState(null);

  // transcript control
  const [activeTextLineIndex, setActiveTextLineIndex] = useState(0);
  const updateActiveTextLineIndex = () => {
    let timePointsArr = Object.keys(audioFile.transcript);
    let formattedTime = audioControls
      ? convertSecondsToTime(audioControls.currentTime)
      : "00:00";
    if (timePointsArr.includes(formattedTime)) {
      setActiveTextLineIndex(timePointsArr.indexOf(formattedTime));
    }
  };
  useEffect(() => {
    if (audioFile.isLoaded) {
      updateActiveTextLineIndex();
    }
  }, [audioControls ? audioControls.currentTime : null]);

  // helper functions
  const convertSecondsToTime = (seconds) => {
    // converts seconds to time in format mm:ss
    if (Math.floor(seconds) <= 0) {
      return "00:00";
    } else {
      let mins = Math.floor(seconds / 60);
      mins =
        mins === 0 ? "00" : mins < 10 ? "0" + mins.toString() : mins.toString();
      seconds = Math.floor(seconds % 60);
      seconds =
        seconds === 0
          ? "00"
          : seconds < 10
            ? "0" + seconds.toString()
            : seconds.toString();
      return mins + ":" + seconds;
    }
  };

  return (
    <Box
      component="section"
      bgcolor="var(--mui-palette-neutral-800)"
      borderRadius="16px"
      p="1rem"
    >
      <Box
        data-testid="menu-1"
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mb="1rem"
      >
        <Box
          component="div"
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          gap="0.5rem"
          my="1rem"
        >
          <CustomButton
            // onClick={() => setShowCompare(false)}
            onClick={() => pageFunctions.toggleTranscriptMode()}
            variant="contained"
            startIcon={<FormatAlignLeftIcon />}
            disabled={!showCompare}
          >
            Transcript
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<FormatQuoteOutlinedIcon />}
            onClick={() => pageFunctions.toggleCompareMode()}
            // onClick={handleCompareClick}
            disabled={showCompare}
            data-testid="compareBtn"
          >
            Compare
          </CustomButton>
        </Box>
        <Box component="section" display="flex" gap="0.5rem" my="1rem">
          {isSmallerScreen ? (
            <>
              <CustomIconButton onClick={handleMenuOpen}>
                <MoreVertOutlinedIcon sx={{ color: "neutral.25" }} />
              </CustomIconButton>
              <CustomMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <CustomMenuButton
                  onClick={() => {
                    setOpenWatchParty(true);
                    setAnchorEl(null);
                  }}
                >
                  <GroupsOutlinedIcon style={{ marginRight: "1rem" }} /> Watch
                  Party
                </CustomMenuButton>
                <CustomMenuButton onClick={handleBookmarkClick}>
                  <BookmarkAddOutlinedIcon style={{ marginRight: "1rem" }} />
                  Bookmark
                </CustomMenuButton>
                <CustomMenuButton onClick={handleDownloadClick}>
                  <FileDownloadOutlinedIcon style={{ marginRight: "1rem" }} />
                  Download
                </CustomMenuButton>
                <CustomMenuButton
                  onClick={() => {
                    setOpenShare(true);
                    setAnchorEl(null);
                  }}
                >
                  <ShareOutlinedIcon style={{ marginRight: "1rem" }} />
                  Share
                </CustomMenuButton>
              </CustomMenu>
            </>
          ) : (
            <>
              <CustomButton
                variant="contained"
                startIcon={<GroupsOutlinedIcon />}
                onClick={() => {
                  setOpenWatchParty(true);
                  setAnchorEl(null);
                }}
              >
                Watch Party
              </CustomButton>

              <CustomButton
                variant="contained"
                startIcon={<BookmarkAddOutlinedIcon />}
                onClick={handleBookmarkClick}
              >
                Bookmark
              </CustomButton>
              <CustomButton
                variant="contained"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={handleDownloadClick}
              >
                Download
              </CustomButton>
              <CustomButton
                variant="contained"
                startIcon={<ShareOutlinedIcon />}
                onClick={() => setOpenShare(true)}
              >
                Share
              </CustomButton>
            </>
          )}
          <WatchPartyModal
            isOpen={openWatchParty}
            onClose={() => setOpenWatchParty(false)}
          />
          <ShareModal isOpen={openShare} onClose={() => setOpenShare(false)} />
        </Box>
      </Box>

      {/* Content Display When Not Comparing */}
      {!showCompare && (
        <Box m={2}>
          <Box mb={2}>
            <Box className="transcript-panel" component="div">
              <AudioPlayerTextPanel
                open={pageControls.textPanelOpen}
                textPanelType={pageControls.textPanelType}
                audioFile={audioFile}
                pageControls={pageControls}
                activeTextLineIndex={activeTextLineIndex}
              />
            </Box>
          </Box>
        </Box>
      )}

      {/* Conditional Compare Section */}
      {showCompare && (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} mr={2}>
              <Typography variant="body1" component="div">
                {/* <Transcript transcriptJson={originalTranscript} /> */}
                <Box className="transcript-panel">
                  <AudioPlayerTextPanel
                    open={pageControls.textPanelOpen}
                    textPanelType={pageControls.textPanelType}
                    audioFile={audioFile}
                    pageControls={pageControls}
                    activeTextLineIndex={activeTextLineIndex}
                  />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AudioPlayerTranscript;
