import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlaying,
  setHasEnded,
  setCurrentTime,
} from "../reducers/videoPlayerSlice";
const useMediaPlayerControls = (audioRef, playing, hovering) => {
  const dispatch = useDispatch();
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [showTimeline, setShowTimeline] = useState(true);
  const mouseMoveTimer = useRef(null);
  const currentTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);

  // Shows controls and hides them after 2 seconds of inactivity
  const showControls = () => {
    clearTimeout(mouseMoveTimer.current);
    setIsCursorVisible(true);
    setShowTimeline(true);
    mouseMoveTimer.current = setTimeout(() => {
      if (!hovering) {
        setIsCursorVisible(false);
        setShowTimeline(false);
      }
    }, 2000);
  };

  // Toggles play and pause for the audio
  const togglePlayPause = () => {
    const audio = audioRef.current;

    dispatch(setHasEnded(false));

    if (audio.paused) {
      audio.play();
      dispatch(setPlaying(true));
      showControls();
    } else {
      audio.pause();
      dispatch(setPlaying(false));
      setShowTimeline(false);
      setTimeout(() => {
        setShowTimeline(true);
      }, 0);
    }
  };

  // Handles mouse movement to show controls if the audio is playing
  const handleMouseMove = () => {
    clearTimeout(mouseMoveTimer.current);
    if (playing) {
      showControls();
    }
  };

  // Update video time by `e` seconds
  const handleTimeUpdate = (e) => {
    console.log("e", e);

    // console.log("INSIDE HANDLETIME UPDATE");
    const audio = audioRef.current;
    if (!audio) return;

    dispatch(setCurrentTime(audio.currentTime));
    if (typeof e === "number") {
      audio.currentTime += e;
      dispatch(setCurrentTime(audio.currentTime));
      console.log("currentTime", currentTime);
    }

    // if (captionsEnabled) {
    //   const subtitle = subtitles.find(
    //     (subtitle) =>
    //       video.currentTime >= subtitle.start &&
    //       video.currentTime <= subtitle.end
    //   );
    //   if (subtitle) {
    //     setCurrentSubtitle(subtitle.text);
    //   } else {
    //     setCurrentSubtitle("");
    //   }
    // }
  };

  //   Update current time in Redux
  const handleTimeChange = () => {
    if (audioRef.current) {
      dispatch(setCurrentTime(audioRef.current.currentTime));
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener("timeupdate", handleTimeChange);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", handleTimeChange);
      }
    };
  }, [audioRef]);

  return {
    isCursorVisible,
    showTimeline,
    togglePlayPause,
    handleMouseMove,
    showControls,
    setShowTimeline,
    handleTimeUpdate,
  };
};

export default useMediaPlayerControls;
