import Slider from "react-slick";
import "./ServiceHeader.css";
import { mockData } from "../../../data/service-data-coursera.js";
import { useEffect, useState } from "react";
import CourseraBanner from "../ServiceBanner/CourseraBanner";

const SliderDots = (dots) => (
  <div>
    {dots.map((dot, index) => (
      <div key={index} className="slider-dots__list-item">
        {dot}
      </div>
    ))}
  </div>
);

const CourseraHeader = () => {
  const [slides, setSlides] = useState([]);
  const bannerSliderOptions = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: SliderDots,
  };

  useEffect(() => {
    setSlides(mockData);
  }, []);

  return (
    <div className="coursera-header">
      <Slider {...bannerSliderOptions}>
        {slides.slice(0, 3).map((slide) => (
          <CourseraBanner {...slide} key={slide + Math.random()} />
        ))}
      </Slider>
    </div>
  );
};

export default CourseraHeader;
