import { useEffect, memo } from "react";
import { motion, useAnimate } from "framer-motion";

import { EASING_SINE_INOUT, getBlurStyle } from "./airis-blob-utils";
import "./AirisBlob.scoped.css";

const AirisBlobBlur = ({ size, animationState, animationEnabled }) => {
  // Initializing animation
  const [blurScope, animateBlur] = useAnimate();

  // Effect hook to handle animation changes
  useEffect(() => {
    let animation = undefined;
    // Define animation parameters based on animation state
    if (animationEnabled) {
      animation = animateBlur(
        blurScope.current,
        { opacity: animationState === 1 ? [0.3, 0.5] : [0.3, 0.7] },
        {
          duration: animationState === 1 ? 2 : 3,
          ease: EASING_SINE_INOUT,
          repeat: Infinity,
          repeatType: "reverse",
        }
      );
    }

    return () => {
      if (animation !== undefined) {
        animation.stop();
      }
    };
  }, [animationState, animationEnabled, blurScope, animateBlur]);

  return (
    <div
      className={`${
        size === -1
          ? "va-blob-unsized-layer-wrapper"
          : "va-blob-layer-wrapper"
      }`}
    >
      {/* SVG element for rendering the blob */}
      <svg
        width={size === -1 ? "100%" : size}
        height={size === -1 ? "100%" : size}
        viewBox="0 0 144 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter2_f_347_5)">
          {/* Motion path for animating the blob */}
          <motion.path
            ref={blurScope}
            style={animationEnabled ? undefined : getBlurStyle(animationState)}
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.9294 41.1669C79.1364 36.0429 96.4964 36.8839 105.4644 49.9919S110.7774 81.4969 101.3634 94.4189S76.5364 116.1339 61.8634 109.8329S46.3234 86.2679 47.0014 71.0759S53.1374 45.4199 64.9294 41.1669Z"
            fill={
              animationState === 2
                ? "url(#paint1_radial_347_5_state2)"
                : animationState === 1
                  ? "url(#paint1_radial_347_5_state1)"
                  : "url(#paint1_radial_347_5)"
            }
          />
        </g>
        {/* Definitions for filters and gradients */}
        <defs>
          <filter
            id="filter2_f_347_5"
            x="0"
            y="0"
            width="144"
            height="144"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="16.0649"
              result="effect1_foregroundBlur_347_5"
            />
          </filter>
          {/* Radial gradients for different states */}
          <radialGradient
            id="paint1_radial_347_5"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(78.6405 74.0474) rotate(-163.39) scale(41.1564 42.9559)"
          >
            <stop offset="0.2" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#eb00cf" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_347_5_state1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(78.6405 74.0474) rotate(-163.39) scale(41.1564 42.9559)"
          >
            <stop offset="0.2" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#45e8ff" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_347_5_state2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(78.6405 74.0474) rotate(-163.39) scale(41.1564 42.9559)"
          >
            <stop offset="0.2" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#222222" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export default memo(AirisBlobBlur);
