// src/pages/Featured.js
// rename it as videopage, once the existing is removed
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import CarouselComponent from "../../Carousel/CarouselComponent";
import VideoThumbnail from "../../VideoCards/VideoThumbnail/VideoThumbnail";
import Filter from "../../Filter/Filters";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useLoadMore from "../../../hooks/useLoadMore";
import { fetchVideosList } from "../../../api/video";
import { logout } from "../../../reducers/authSlice";
import { setToast } from "../../../reducers/snackbarSlice";
import InfiniteScroll from "react-infinite-scroll-component";

const videoTestData = [
  {
    id: "1",
    title: "Big Buck Bunny",
    thumbnail_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Big_Buck_Bunny_thumbnail_vlc.png/1200px-Big_Buck_Bunny_thumbnail_vlc.png",
    duration: "8:18",
    upload_time: "May 9, 2011",
    views: "24,969,123",
    author: "Vlc Media Player",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    description:
      "Big Buck Bunny tells the story of a giant rabbit with a heart bigger than himself. When one sunny day three rodents rudely harass him, something snaps... and the rabbit ain't no bunny anymore! In the typical cartoon tradition he prepares the nasty rodents a comical revenge.",
    subscriber: "25254545 Subscribers",
    isLive: true,
  },
  {
    id: "2",
    title: "The first Blender Open Movie from 2006",
    thumbnail_url: "https://i.ytimg.com/vi_webp/gWw23EYM9VM/maxresdefault.webp",
    duration: "12:18",
    upload_time: "May 9, 2011",
    views: "24,969,123",
    author: "Blender Inc.",
    video_url:
      "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    description:
      "Song : Raja Raja Kareja Mein Samaja\nAlbum : Raja Kareja Mein Samaja\nArtist : Radhe Shyam Rasia\nSinger : Radhe Shyam Rasia\nMusic Director : Sohan Lal, Dinesh Kumar\nLyricist : Vinay Bihari, Shailesh Sagar, Parmeshwar Premi\nMusic Label : T-Series",
    subscriber: "25254545 Subscribers",
    isLive: true,
  },
];

const VideoTab = () => {
  const videosBreakpoint = 4;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  useEffect(() => {
    handleLoadMore();
  }, []);

  const fetchVideos = async () => {
    // Get trending videos based on the country.
    let data = {
      sort_by: "view_count",
      //  upload_country: 'us', // To do: to be updated with the user preferred region from the store.
      limit: 15,
      page,
    };
    setPage((p) => p + 1);
    try {
      let res = await fetchVideosList(data);
      if (res?.status === 200) {
        return res.data;
      }
      return [];
    } catch (err) {
      if (err?.response?.status === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error ocurred. Please try again later",
            type: "red",
          })
        );
      }
      return [];
    }
  };

  const { data, handleLoadMore, hasMore, isLoading } = useLoadMore(
    [],
    fetchVideos
  );

  /*
   * Main Video display component is divided into 2 Main sections
   * 1. Carousel + Side Thumbnails
   * 2. Bottom Thumbnails
   */

  return (
    <>
      <Filter />
      <Box sx={{ flexGrow: 1, px: "3vw", py: "5vh" }}>
        <Grid id={`main-layout-grid`} container spacing={8}>
          {/*
           * Grid container for the carousel and the Side Thumbnails
           */}
          <Grid
            id={"carousel-thumbnaili-grid"}
            container
            spacing={8}
            columns={5}
          >
            {/*
             * Grid item - Carousel
             */}
            <Grid item size={3}>
              <CarouselComponent />
            </Grid>

            {/*
             * Grid item - 4 side thumbnails
             */}
            <Grid container columns={2} spacing={8} size={2}>
              {/*
               * Displaying only the first 9 videos on the right side
               */}

              {/* Dummy data just for test purpose ---- REMOVE THIS LATER ↓*/}
              {videoTestData.map((video, index) => (
                <Grid
                  item
                  id={`video-${index}`}
                  key={index}
                  size={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minheight: "100%",
                  }}
                >
                  <VideoThumbnail
                    key={`video-${video.id}`}
                    id={`thumbnail-${video.id}`}
                    data={video}
                    cardLayout="vertical" // Can be 'horizontal' or 'vertical'
                    cardType="video" // Can be 'video', 'playlist', 'history', etc.
                    featured={false}
                  />
                </Grid>
              ))}
              {/* Dummy data just for test purpose ---- REMOVE THIS LATER ↑*/}

              {/*--- ↓ Please change back this here ↓---- */}
              {/* {data.slice(0, videosBreakpoint).map((video, index) => (
                <Grid
                  item
                  id={`video-${index}`}
                  key={index}
                  size={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minheight: "100%",
                  }}
                >
                  <VideoThumbnail
                    key={`video-${video.id}`}
                    id={`thumbnail-${video.id}`}
                    data={video}
                    cardLayout="vertical" // Can be 'horizontal' or 'vertical'
                    cardType="video" // Can be 'video', 'playlist', 'history', etc.
                    featured={false}
                  />
                </Grid>
              ))} */}
              {/*--- ↑  Please change back here ↑ ---- */}
            </Grid>
          </Grid>

          {/*
           * Grid container for the Bottom Thumbnails
           */}
          <Grid
            container
            id={`bottom-thumbnail-grid`}
            data-testid="bottom-thumbnail-grid"
            spacing={8}
            columns={5}
          >
            {/*
             * Displaying thhe rest of the videos at the bottom
             */}

            {data.slice(videosBreakpoint).map((video, index) => (
              <Grid id={`video-${index}`} key={index} size={1}>
                <InfiniteScroll
                  style={{ overflow: "visible" }}
                  className="videocards-infinite-scroll"
                  dataLength={data.length}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={
                    <div className="videocards-spinner-loadmore">
                      <i
                        className="fas fa-spinner fa-spin"
                        aria-hidden="true"
                      />
                    </div>
                  }
                  scrollThreshold="90%"
                >
                  <VideoThumbnail
                    key={`video-${video.id}`}
                    id={`thumbnail-${video.id}`}
                    data={video}
                    cardLayout="vertical" // Can be 'horizontal' or 'vertical'
                    cardType="video" // Can be 'video', 'playlist', 'history', etc.
                    featured={false}
                  />
                </InfiniteScroll>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VideoTab;
