import { Box, Button, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import "../TextPageActionButtonPanel/TextPageActionButtonPanel.scoped.css";
import DownloadModal from "../DownloadModal/DownloadModal";
import ShareModal from "../../../../components/ShareModal/ShareModal";

function TextPageActionButtonPanel({ setTransLang }) {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  // Handle button clicks for Bookmark, Download, Rate and Share buttons
  const handleButtonClick = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  // Handle close event for the Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box className="textPageActionButtonDiv">
      <Button
        variant="contained"
        className="TextPageActionButton"
        size="small"
        startIcon={<BookmarkBorderOutlinedIcon />}
        onClick={() => handleButtonClick("Bookmarked successfully!")}
      >
        Bookmark
      </Button>
      <DownloadModal />
      <Button
        variant="contained"
        size="small"
        className="TextPageActionButton"
        startIcon={<ShareOutlinedIcon />}
        onClick={() => setOpenShare(true)}
      >
        Share
      </Button>

      {/* <Button
        variant="contained"
        className="TextPageActionButton"
        startIcon={<StarBorderOutlinedIcon />}
        onClick={() => handleButtonClick("Rated successfully!")}
      >
        Rate
      </Button>
      <Button
        variant="contained"
        className="TextPageActionButton"
        startIcon={<MenuOutlinedIcon />}
        onClick={setTransLang}
      >
        Compare
      </Button> */}
      {/* Snackbar for toasts */}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "20%" }}>
          {message}
        </Alert>
      </Snackbar>
      <ShareModal isOpen={openShare} onClose={() => setOpenShare(false)} />
    </Box>
  );
}

export default TextPageActionButtonPanel;
