import React, { useState } from "react";
import RegionSelector from "../RegionSelector/RegionSelector";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HistoryIcon from "@mui/icons-material/History";
import PlaylistPlayRoundedIcon from "@mui/icons-material/PlaylistPlayRounded";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  HISTORY_PAGE_URL,
  PLAYLIST_PAGE_URL,
  ACCOUNT_PAGE_URL,
  SETTING_PAGE_URL,
} from "../../constant/constantUrls";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import Settings from "../Settings/Settings";
import UserProfile from "../UserProfile/UserProfile";
import "./Navbar.scoped.css";

export default function PrimarySearchAppBar({ setSearchInputRef }) {
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const navigate = useNavigate();


  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };

  const toggleDropdown = () => {
    setIsUserProfileOpen(!isUserProfileOpen);
  };

  return (
    <Box className="flex-grow">
      <AppBar className="app-bar">
        <Toolbar>
          <Typography
            variant="h5"
            noWrap
            component="div"
            className="vosyn-logo-container"
          >
            <img
              className="vosyn-logo"
              src={process.env.PUBLIC_URL + "/assets/vosyn_logo_long.png"}
              alt="Vosyn Logo"
              onClick={() => navigate("/CONTENT-PLATFORM/home")}
            />
          </Typography>

          <Box className="flex-grow" />

          <RegionSelector setSearchInputRef={setSearchInputRef} />

          <Box className="flex-grow" />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton className="icon-button">
              <GroupOutlinedIcon className="icon-style" />
            </IconButton>

            <IconButton
              className="icon-button"
              onClick={() => {
                navigate(HISTORY_PAGE_URL);
              }}
            >
              <HistoryIcon className="icon-style" />
            </IconButton>

            <IconButton
              onClick={() => {
                navigate(PLAYLIST_PAGE_URL);
              }}
              className="icon-button"
            >
              <PlaylistPlayRoundedIcon className="icon-style" />
            </IconButton>

            <IconButton
              className="icon-button"
              onClick={() => {
                navigate(SETTING_PAGE_URL);
              }}
            >
              <SettingsOutlinedIcon className="icon-style" />
            </IconButton>

            {openSettingsModal ? (
              <Settings
                open={openSettingsModal}
                handleClose={handleCloseSettingsModal}
              />
            ) : null}

            <IconButton
              onClick={() => {
                navigate(ACCOUNT_PAGE_URL);
              }}
              className="icon-button"
            >
              <PermIdentityOutlinedIcon className="icon-style" />
            </IconButton>

            {/* Dropdown will be shown here */}
            {isUserProfileOpen && (
              <UserProfile setIsUserProfileOpen={setIsUserProfileOpen} />
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
