import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import languages from "../../../../data/language.json";
import {
  incrementStage,
  updateLanguage,
  updateFormData,
} from "../../../../reducers/userPrefReducer";
import "./ProfileLanguage.scoped.css";
import useUpdateUserProfile from "../../../../hooks/useUpdateUserProfile";

const ProfileLanguage = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.userPref.formData);
  const [searchTerm, setSearchTerm] = useState(
    formData.preferred_language || ""
  );
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [isValidLanguage, setIsValidLanguage] = useState(true);
  const langref = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState("100%");

  useEffect(() => {
    setFilteredLanguages(languages);

    function setWidth() {
      if (langref.current) {
        setDropdownWidth(`${langref.current.offsetWidth}px`);
      }
    }
    setWidth();
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);

  useEffect(() => {
    setSearchTerm(formData.preferred_language || "");
  }, [formData]);

  const handleLanguageChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = languages.filter((lang) =>
      lang.display_name.toLowerCase().startsWith(term.toLowerCase())
    );
    setFilteredLanguages(filtered);
    if (!term) {
      setFilteredLanguages(languages);
    }
    setIsValidLanguage(true);
  };

  const handleLanguageSelection = (selectedLanguage) => {
    setSearchTerm(selectedLanguage.display_name);
    setFilteredLanguages([]);
    setIsValidLanguage(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedLanguage = searchTerm.trim().toLowerCase();
    if (
      !selectedLanguage ||
      !languages.find(
        (lan) => lan.display_name.toLowerCase() === selectedLanguage
      )
    ) {
      setIsValidLanguage(false);
      return;
    }
    setIsValidLanguage(true);
    const lang = languages.find(
      (lan) => lan.display_name.toLowerCase() === selectedLanguage
    );
    if (lang?.value) {
      dispatch(updateLanguage(lang?.value));
    }
    dispatch(updateFormData({ preferred_language: selectedLanguage }));
    dispatch(incrementStage());
  };

  return (
    <form id="ob-profile-form" onSubmit={handleSubmit}>
      <label>
        <div ref={langref}>
          <input
            type="text"
            placeholder={"Please select your language"}
            value={searchTerm}
            onChange={handleLanguageChange}
            className={`inputs ${isValidLanguage ? "" : "inputError"} ${
              searchTerm ? "selectedLanguage" : ""
            }`}
            required
          />
          {searchTerm && (
            <ul className="dropdownMenu" style={{ width: dropdownWidth }}>
              {filteredLanguages.map((lang, index) => (
                <li key={index} onClick={() => handleLanguageSelection(lang)}>
                  {lang.display_name}
                </li>
              ))}
            </ul>
          )}
          <p className="fieldError">
            &nbsp;{!isValidLanguage && "Please select a valid language"}
          </p>
        </div>
      </label>
    </form>
  );
};

export default ProfileLanguage;
