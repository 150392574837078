import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../pages/VideoPage/VideoPage.scoped.css";
import {
  setCommentIndex,
  setComments,
  setDisplayComments,
  setNewComment,
  setReplyComment,
  setShowCommentReplyField,
} from "../../reducers/videoCommentSlice";
import CommentForm from "./VideoCommentSubcomponents/CommentForm/CommentForm";
import CommentList from "./VideoCommentSubcomponents/CommentList/CommentList";
import store from "../../store";
import { Box, Typography } from "@mui/material";

const fetchAndHandleAllComments = () => {
  let allComments = [];

  for (let i = 0; i < 10; i++) {
    allComments.push({
      id: i,
      name: `User ${i + 1}`,
      image: "https://www.w3schools.com/howto/img_avatar.png",
      comment: `This is comment ${i + 1}`,
      date: "2 days ago",
      likes: 0,
      replies: [],
    });
  }
  store.dispatch(setComments(allComments));
  const indexVal = store.getState().videoComment.commentIndex;
  store.dispatch(setDisplayComments(allComments.slice(indexVal, indexVal + 5)));
  store.dispatch(setCommentIndex(indexVal + 5));
};

function VideoComment() {
  const dispatch = useDispatch();
  const comments = useSelector(({ videoComment }) => videoComment.allComments);
  const newComment = useSelector(({ videoComment }) => videoComment.newComment);
  const displayComments = useSelector(
    ({ videoComment }) => videoComment.displayComments
  );
  const commentIndex = useSelector(
    ({ videoComment }) => videoComment.commentIndex
  );
  const showCommentReplyField = useSelector(
    ({ videoComment }) => videoComment.showCommentReplyField
  );
  const replyComment = useSelector(
    ({ videoComment }) => videoComment.replyComment
  );

  //intializes the comments with predefined set of comments
  useEffect(() => {
    fetchAndHandleAllComments();
  }, []);

  //view more comments
  const showMoreComments = () => {
    dispatch(
      setDisplayComments([...comments.slice(commentIndex, commentIndex + 5)])
    );
    dispatch(setCommentIndex(commentIndex + 5));
  };

  //based on the value entered update the state newComment
  const handleNewComment = (event) => {
    dispatch(setNewComment(event.target.value));
  };

  const getCommentPostedTime = () => {
    const currentDate = new Date();
    //calculates time difference
    const timeDifference = (currentDate - new Date()) / 1000;
    if (timeDifference < 60) {
      return `${Math.floor(timeDifference)} seconds ago`;
    } else if (timeDifference < 3600) {
      return `${Math.floor(timeDifference / 60)} minutes ago`;
    } else if (timeDifference < 86400) {
      return `${Math.floor(timeDifference / 3600)} hours ago`;
    } else {
      return `${Math.floor(timeDifference / 86400)} days ago`;
    }
  };

  //adding new comments when the users submits
  const handleSubmitNewComment = () => {
    //remove unnecessary whitespace
    if (newComment.trim() !== "") {
      const commentPostedTime = getCommentPostedTime();

      const updatedComment = {
        id: comments ? comments.length : 0, // Use comments length as id
        name: `User${comments ? comments.length + 1 : ""}`,
        image: "https://www.w3schools.com/howto/img_avatar.png",
        comment: newComment,
        date: commentPostedTime,
        likes: 0,
        replies: [], // Initialize an empty array for replies
      };

      const copiedComments = [...comments];
      copiedComments.unshift(updatedComment);
      dispatch(setComments(copiedComments));
      dispatch(setDisplayComments(copiedComments));
      dispatch(setNewComment("")); //reset
    }
  };

  //updating number of likes
  const handleNewCommentLike = (commentIndex) => {
    const updatedComments = [...comments]; //append - creating a copy
    const updatedElement = { ...updatedComments[commentIndex] };
    updatedElement.likes += 1;
    updatedComments[commentIndex] = updatedElement;
    dispatch(setComments(updatedComments));
    dispatch(setDisplayComments(updatedComments));
  };

  //cancel the tasks
  const handleCancelComment = () => {
    dispatch(setNewComment(""));
  };

  // visibility state of reply comment
  const handleCommentReply = (commentId) => {
    dispatch(setShowCommentReplyField, {
      id: commentId,
      value: !showCommentReplyField[commentId],
    });
  };

  //reply submit
  //commentId - to identify the comment for which the reply visibility is toggle
  const handleCommentReplySubmit = (commentId) => {
    const submittedReply = replyComment.trim(); //remove white spaces
    if (submittedReply !== "") {
      //not empty
      const updatedComments = [...comments]; //creating a copy
      const commentIndex = updatedComments.findIndex(
        (comment) => comment.id === commentId
      );
      //find index of the comment whose id matches the commentId
      updatedComments[commentIndex].replies.push(submittedReply); //pushes the submittedReply to replies array
      dispatch(setComments(updatedComments));
      dispatch(setReplyComment("")); //reset
      dispatch(setShowCommentReplyField, {
        id: commentId,
        value: false,
      }); //hide the reply field associated with the commentId
    }
  };

  return (
    <Box className="video-comments">
      <Typography className="video-comments-title">
        {comments ? comments.length : 0} Comments
      </Typography>
      <CommentForm
        newComment={newComment}
        handleNewComment={handleNewComment}
        handleSubmitNewComment={handleSubmitNewComment}
        handleCancelComment={handleCancelComment}
      />
      {/* comments section */}
      <CommentList
        handleNewCommentLike={handleNewCommentLike}
        handleCommentReply={handleCommentReply}
        handleCommentReplySubmit={handleCommentReplySubmit}
      />
      {comments && displayComments.length < comments.length && (
        <Typography
          component="span"
          className="show-more-button"
          onClick={showMoreComments}
        >
          Show More
        </Typography>
      )}
    </Box>
  );
}

export default VideoComment;
