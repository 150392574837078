import { useState } from 'react';

const useLoadMore = (initialData, fetchData, inDocument=true) => {
  const [data, setData] = useState(initialData);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, seIsLoading] = useState(false)

  const handleLoadMore = async (newList= false) => {
    // if (!hasMore) return;
    try {
      seIsLoading(true)
      let newData = await fetchData(newList);
      if(inDocument) {
        newData = newData.map(({document}) => document)
      }
      if (newData.length === 0) {
        setHasMore(false);
      }
      if(!inDocument) {
        console.log('useLoadMore', newData)

      }
      newList ? setData(newData) : setData(prevData => [...prevData, ...newData]);
    } catch (error) {
      console.error('Error loading more data:', error);
    } finally {
      seIsLoading(false);
    }
  };

  const deleteVideo = (id) => {
    setData(data.filter(video => video.id !== id))
  }

  return { data, handleLoadMore, hasMore, isLoading, deleteVideo };
};

export default useLoadMore;