export const PLATFORM_OPTIONS = [
  "All",
  "Netflix",
  "Youtube",
  "Amazon Prime",
  "Disney+",
];
export const CATEGORY_OPTIONS = [
  "All",
  "Movies",
  "TV Series",
  "Animation",
  "Shorts",
];
export const GENRE_OPTIONS = [
  "All",
  "Action",
  "Documentary",
  "Sports",
  "Comedy",
];
