import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Box,
} from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack"; // Importing the music icon

const AudioCard = ({
  image,
  title,
  description,
  hoverEff = true,
  id = 0,
  onClick,
}) => {
  return (
    <Box onClick={onClick}>
      <Card
        data-testid={id}
        sx={{
          width: "100%",
          borderRadius: 2,
          background: "transparent",
          boxShadow: 0,
          transition: "transform 0.2s",
          "&:hover": {
            transform: hoverEff ? "scale(1.05)" : "",
            zIndex: 1,
          },
          position: "relative",
        }}
      >
        <CardActionArea sx={{ height: "100%" }}>
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              image={image || "https://via.placeholder.com/140"}
              alt={title}
              sx={{ objectFit: "cover" }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 8,
                right: 8,
                backgroundColor: "#585858",
                padding: "8px",
                borderRadius: 2,
              }}
            >
              <AudiotrackIcon sx={{ color: "#fff" }} />
            </Box>
          </Box>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                padding: "3px",
                borderRadius: 2,
                marginBottom: "3px",
                color: "white",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                padding: "2px",
                borderRadius: 2,
                color: "white",
              }}
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default AudioCard;
