import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRecording: false,
  recordedBlob: null,
  recordingType: "audio", // 'audio' or 'video'
  hasPermissions: {
    audio: false,
    video: false,
  },
};

const recordingSlice = createSlice({
  name: "recording",
  initialState,
  reducers: {
    setRecording(state, action) {
      state.isRecording = action.payload;
    },
    setRecordedBlob(state, action) {
      state.recordedBlob = action.payload;
    },
    setRecordingType(state, action) {
      state.recordingType = action.payload;
    },
    setPermissions(state, action) {
      state.hasPermissions = action.payload;
    },
  },
});

export const {
  setRecording,
  setRecordedBlob,
  setRecordingType,
  setPermissions,
} = recordingSlice.actions;
export default recordingSlice.reducer;
