import { useEffect, useState } from "react";
import allVideos from "../../data/72_videos_get_all.json";
import { fetchVideosList } from "../../api/video";
import "./RegionSelector.scoped.css";

const SearchDropDown = ({
  isSearch,
  setIsSearching,
  searchRef,
  onSubmit,
  searchTerm,
  searchResult = true,
}) => {
  const results = allVideos.results;
  const [dropdownList, setDropdownList] = useState([]);

  function normalizeString(str) {
    // Convert numbers to words
    str = str.replace(/(\d+(\.\d+)?)/g, function (match, p1) {
      return p1.replace(".", ""); // Remove dots from numbers
    });

    return str.toLowerCase().replace(/[^\w\s]/g, ""); // Remove punctuation and convert to lowercase
  }

  // handle user search for a title or keywords
  const handleSearch = (keyword) => {
    onSubmit(keyword);
    setIsSearching(false);
  };

  useEffect(() => {
    findDropdownList();
  }, [searchTerm]);

  const findTitles = (items) => {
    if (items) {
      return items.map((item) => item?.document?.titles?.[0]);
    }
  };

  const findDropdownList = async () => {
    const normalizedSearchTerm = normalizeString(searchTerm);
    console.log("normalizedSearchTerm", normalizedSearchTerm);
    let data = {
      sort_by: "view_count",
      limit: 5,
      page: 1,
      query: normalizedSearchTerm,
    };
    try {
      let res = await fetchVideosList(data);
      if (res?.status === 200) {
        setDropdownList(findTitles(res?.data));
      }
      return [];
    } catch (err) {
      return [];
    }
  };

  useEffect(() => {
    findDropdownList();
  }, [searchTerm]);

  function searchFilter(searchTerm) {
    // Normalize search term
    const normalizedSearchTerm = normalizeString(searchTerm);

    // Filter the list based on normalized search term
    return results.filter((item) => {
      const normalizedTitle = normalizeString(item.titles[0].title_text);
      return normalizedTitle.includes(normalizedSearchTerm);
    });
  }

  return (
    <div
      onAnimationEnd={() => {
        if (!isSearch) setIsSearching(false);
      }}
      className="search-container"
      ref={searchRef}
    >
      <div className={"search-container-title"}>
        {searchResult &&
          (dropdownList.length != 0 ? (
            <span>Search Results</span>
          ) : (
            <span>Sorry, there are no matching results</span>
          ))}
        <i
          className="fa-solid fa-xmark"
          onClick={() => setIsSearching(false)}
        />
      </div>
      <div className="search-results">
        {dropdownList.length !== 0 &&
          dropdownList.map((result, index) => {
            if (result) {
              const trimmedTitle = result.trim();
              const displayTitle =
                trimmedTitle.length > 35
                  ? trimmedTitle.slice(0, 35) + "..."
                  : trimmedTitle;

              return (
                <div
                  className="search-result"
                  key={index}
                  onClick={() => {
                    handleSearch(trimmedTitle);
                  }}
                >
                  <i className="fa-solid fa-magnifying-glass" />
                  <h3>{displayTitle}</h3>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default SearchDropDown;
