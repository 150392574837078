import { useRef, useEffect } from "react";


/**
 * 
 * custom React hook  that helps you determine whether the component is being rendered for the first time
 */
export default function useFirstRender() {
  //This firstRender variable is set to true initially.
  const firstRender = useRef(true);

  // This effect runs after the component has been rendered for the first time
  useEffect(() => {
    firstRender.current = false;

    // Cleanup function will be called when the component unmounts.
    return () => {
      firstRender.current = true;
    };
  }, []);

  return firstRender.current;
}
