import React, { useState, useEffect } from "react";
import "./VideoPlayerNotification.scoped.css";

const VideoPlayerNotification = () => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const delay = 5000; //5s delay

  //Displays notification for 5s
  useEffect(() => {
    const showTimer = startShowTimer();
    return () => clearTimeout(showTimer);
  }, []);

  const startShowTimer = () => {
    const showTimer = setTimeout(() => {
      showNotificationAfterDelay();
    }, delay);

    return showTimer;
  };

  const showNotificationAfterDelay = () => {
    setNotificationVisible(true);

    const hideTimer = setTimeout(() => {
      setNotificationVisible(false);
    }, delay);

    return hideTimer;
  };

  return (
    <div>
      {notificationVisible && (
        <div className="notification">Click here for further context</div>
      )}
    </div>
  );
};

export default VideoPlayerNotification;
