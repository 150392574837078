import React, { useState, useEffect } from "react";
import VosynImage from '../imgs/vosyn-logo.svg';
import NetflixLogo from '../imgs/netflix-logo.png';
import AmazonPrimeLogo from '../imgs/amazon-prime-logo.png';
import DisneyLogo from '../imgs/disney-plus-logo.png';
import YoutubeLogo from '../imgs/youtube-logo.png';
import NetflixBgImage from '../imgs/netflix-bg.jpeg';
import AmazonPrimeBgImage from '../imgs/amazon-prime-bg.jpeg';
import DefaultBgImage from '../imgs/default-bg.jpeg';
import './ServiceHeader.css'
import CourseraHeader from "./CourseraHeader";

const ServiceHeader = ({serviceName}) => {
  const [logo, setLogo] = useState(VosynImage);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLogo(NetflixLogo);
      setAnimationStarted(true);
    }, 1000);

    const timer2 = setTimeout(() => {
      setLogo(VosynImage);
      setAnimationStarted(false);
    }, 10500); 

    const timer3 = setTimeout(() => {
      setLogo(NetflixLogo);
      setAnimationStarted(false);
    }, 11500); 
    
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return (
    <>
      { serviceName === "netflix" && (
        <div className="logo-container">
          <img className={`logo ${animationStarted ? 'animate' : ''}`} src={logo} alt="netflix-Logo" />
          <img className="background-image" src={NetflixBgImage} alt="Overlay Image" />
        </div>
      )}
      { serviceName === "amazon-prime" && (
          <div className="logo-container">
            <img className={`logo`} src={AmazonPrimeLogo} alt="amazon-prime-logo" />
            <img className="background-image" src={DefaultBgImage} alt="amazon-prime-background-image" />
          </div>
      )}

      { serviceName === "disney" && (
          <div className="logo-container">
            <img className={`logo`} src={DisneyLogo} alt="disney-logo" />
            <img className="background-image" src={DefaultBgImage} alt="disney-background-image" />
          </div>
      )}
      { serviceName === "youtube" && (
          <div className="logo-container">
            <img className={`logo`} src={YoutubeLogo} alt="youtube-logo" />
            <img className="background-image" src={DefaultBgImage} alt="youtube-background-image" />
          </div>
      )}
      { serviceName === "coursera" && <CourseraHeader /> }
    </>
  );
};

export default ServiceHeader;