import * as React from "react";

import Modal from "@mui/material/Modal";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "../TextPageActionButtonPanel/TextPageActionButtonPanel.scoped.css";
import { Button, Typography, Box } from "@mui/material";
import "../DownloadModal/DownloadModal.scoped.css";
import { downloadFile } from "../../../../utils/fileActions";
import { useSelector } from "react-redux";

function ChildModal({ setOpenModal }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };
  const handleDownloadClick = (url) => {
    downloadFile(url);
  };
  const state = useSelector((state) => {
    return state.textObject;
  });

  return (
    <React.Fragment>
      <Button onClick={handleOpen} variant="contained">
        Save
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="modal-div modal-div-second-pop-up">
          <Box className="modal-header-div">
            <FileDownloadOutlinedIcon className="download-icon" />
            <Typography className="heading-download-pop">want to save changes to your document ?</Typography>
          </Box>
          <Box className="downloadModal-btn-div">
            <Box className="downloadModal-save-btn-div">
              <Button
                variant="contained"
                onClick={() => {
                  handleDownloadClick(state.textUrlWord);
                }}
              >
                Word file
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleDownloadClick(state.textUrlPdf);
                }}
              >
                PDF file
              </Button>
              <Button variant="contained">Image file</Button>
              <Button variant="contained">Sound file</Button>
            </Box>
            <Box className="downloadModal-cancel-button-div">
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function DownloadModal() {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="TextPageActionButton"
        startIcon={<FileDownloadOutlinedIcon />}
        size="small"
        onClick={handleOpen}
      >
        Download
      </Button>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modal-div modal-div-first-pop-up">
          <Box className="modal-header-div">
            <FileDownloadOutlinedIcon className="download-icon" />
            <Typography className="heading-download-pop">want to download the file ?</Typography>
          </Box>
          <Box className="downloadModal-btn-div">
            <Box className="downloadModal-save-btn-div">
              <ChildModal
                setOpenModal={setOpenModal}
              />
              <Button variant="contained" onClick={handleClose}>Do not save</Button>
            </Box>
            <Box className="downloadModal-cancel-button-div">
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
