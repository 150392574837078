import AirisBlobBottom from "./AirisBlobBottom";
import AirisBlobMiddle from "./AirisBlobMiddle";
import AirisBlobTop from "./AirisBlobTop";
import AirisBlobBlur from "./AirisBlobBlur";

import "./AirisBlob.scoped.css";

/**
 * A component that renders the VosynAssist blob. For more information on the implementation/usage details, please refer to Notion page documentation under "Frontend/VosynAssist Squad".
 *
 * @param {string} size - The size of the blob in px. The blob is square in shape (width = height) and there is a gap at the edges to accommodate the animation. If this value is unset (or === -1), the size of the blob will fill up the element it is contained in.
 * @param {number} roughness - The roughness of the top blob layer interpolation. Increasing this value will make the top layer smoother, but it will also increase the time it takes for the component to mount.
 * @param {number} glowSpread - The spread size (in px) of the glow. Set it to 0 to have no glow effects at all.
 * @param {number} animationState - The animation state of the VosynAssist blob. The value of 0 represents the default animation, 1 represents a "processing user input" animation, and 2 is an error state.
 * @param {number} initialAnimationState - The initial animation state for the VosynAssist blob. If this value is different than animationState, then on mount, the VosynAssist blob will animate from initialAnimationState to animationState. If unset (or === -1), the initial animation state is set to be the same as animationState.
 * @param {boolean} animationEnabled - A boolean value to enable or disable the entire blob animation.
 * @param {string} className - A class to apply to the div that wraps this component.
 */
const AirisBlob = ({
  size = -1,
  roughness = 10,
  glowSpread = 10,
  animationState = 0,
  initialAnimationState = -1,
  animationEnabled = true,
  className = "",
}) => {
  return (
    <div
      className={`${className} ${
        size === -1 ? "va-blob-unsized-wrapper" : "va-blob-wrapper"
      }`}
    >
      <AirisBlobBottom
        size={size}
        glowSpread={glowSpread}
        animationState={animationState}
        initialAnimationState={initialAnimationState}
        animationEnabled={animationEnabled}
      />
      <AirisBlobMiddle
        size={size}
        glowSpread={glowSpread}
        animationState={animationState}
        initialAnimationState={initialAnimationState}
        animationEnabled={animationEnabled}
      />
      <AirisBlobTop
        size={size}
        roughness={roughness}
        animationState={animationState}
        initialAnimationState={initialAnimationState}
        animationEnabled={animationEnabled}
      />
      <AirisBlobBlur
        size={size}
        animationState={animationState}
        animationEnabled={animationEnabled}
      />
    </div>
  );
};

export default AirisBlob;
