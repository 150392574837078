import React from "react";
import { useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import "../../../../pages/VideoPage/VideoPage.scoped.css";

const CommentForm = ({
  handleNewComment,
  handleSubmitNewComment,
  handleCancelComment,
}) => {
  const newComment = useSelector(({ videoComment }) => videoComment.newComment);

  // TODO: once the overall theme object is defined, change it into TextField and use the theme hook to control the color
  return (
    <Box className="comment">
      <Box className="comment-avtar-wrapper">
        <Box
          className="comment-avtar"
          component="img"
          alt="user avatar"
          src="/assets/avatar.svg"
        />
      </Box>
      <input
        type="text"
        className="comment-form-input"
        placeholder="Add comment"
        value={newComment}
        onChange={handleNewComment}
      />
      {/*<button className="button btn-blue" onClick={handleSubmitNewComment}>*/}
      {/*  Comment*/}
      {/*</button>*/}
      {/*<button className="button" onClick={handleCancelComment}>*/}
      {/*  Cancel*/}
      {/*</button>*/}
    </Box>
  );
};

export default CommentForm;
