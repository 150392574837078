import { Box, CardMedia } from "@mui/material";
import styles from "./Avatar.module.css";

function Avatar({ variant, type }) {
  return variant === "playlist" && type === "private" ? (
    <Box className={styles.playlistAvatar}>
      <CardMedia
        component="img"
        image={process.env.PUBLIC_URL + `/assets/avatar.svg`}
        alt="user avatar"
      />
    </Box>
  ) : variant === "playlist" && type === "collaborative" ? (
    <Box>{/* show list of collaborative avatars from data */}</Box>
  ) : null;
}

export default Avatar;
