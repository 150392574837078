/**
 * This component represents the message box along with the user's profile picture.
 * It's the user contribution to the chatbox
 */

import React from "react";
import i18next from "i18next";

import "./UserMessage.scoped.css";

function UserMessage({ message }) {
  // Retrieving the translated username text using the message's language.
  const fixedT = i18next.getFixedT(message.language, "airis");
  const userHeaderText = fixedT("airis.username");

  return (
    <div className="user-message-container">
      <span>{userHeaderText}</span>
      <div className="user-message-and-profile-container">
        <div className="user-message" dir={message.languageType}>
          {message.userInputText}
        </div>
        <div className="user-profile-pic">
          <img
            src={
              process.env.PUBLIC_URL + `/assets/general/image/profile-pic.jpg`
            }
            alt="user profile"
            className="user-profile-image"
          />
        </div>
      </div>
    </div>
  );
}

export default UserMessage;
