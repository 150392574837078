import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import "./WatchPartyChatBox.scoped.css";
import WatchPartyChatBoxSettingModal from "../WatchPartyChatBoxSettingModal/WatchPartyChatBoxSettingModal";

const WatchPartyChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [username, setUsername] = useState("You");
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const messageListRef = useRef(null);

  const handleSendMessage = (e) => {
    if (e.key === "Enter" && newMessage.trim() !== "") {
      const newMessageObject = {
        id: messages.length + 1,
        type: "message",
        senderName: username,
        message: newMessage,
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessageObject]);
      setNewMessage("");
    }
  };

  const handleUserJoin = () => {
    const joinMessage = {
      id: messages.length + 1,
      type: "notification",
      message: "user1234 has joined the chat",
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages((prevMessages) => [...prevMessages, joinMessage]);
  };

  const handleWatchPartyRaid = () => {
    const raidMessage = {
      id: messages.length + 1,
      type: "raid",
      message: "streamer567 has raided with 12 viewers!",
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages((prevMessages) => [...prevMessages, raidMessage]);
  };

  const handleOtherUserMessage = () => {
    const otherUserMessage = {
      id: messages.length + 1,
      type: "message",
      senderName: "Anonymous",
      message: "Hello everyone!",
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages((prevMessages) => [...prevMessages, otherUserMessage]);
  };

  const handleOpenSettings = () => {
    setIsSettingsModalOpen(true);
  };

  const handleCloseSettings = () => {
    setIsSettingsModalOpen(false);
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <Box className="watch-party-chatbox-container">
        <Paper className="watch-party-chatbox-header">
          <Typography className="watch-party-chatbox-title">
            LIVE CHAT
          </Typography>
        </Paper>

        <Box className="watch-party-chatbox-messages" ref={messageListRef}>
          {messages.map((message) => (
            <Box key={message.id} className="watch-party-chatbox-message">
              {message.type === "message" ? (
                <>
                  <Typography
                    className={`watch-party-chatbox-sender ${message.senderName === "You" ? "watch-party-chatbox-sender-right" : "watch-party-chatbox-sender-left"}`}
                  >
                    {message.senderName}:
                  </Typography>
                  <Typography className="watch-party-chatbox-text">
                    {message.message}
                  </Typography>
                </>
              ) : message.type === "notification" ? (
                <Typography className="watch-party-chatbox-notification">
                  {message.message}
                </Typography>
              ) : message.type === "raid" ? (
                <Typography className="watch-party-chatbox-raid-notification">
                  {message.message}
                </Typography>
              ) : null}
            </Box>
          ))}
        </Box>

        <IconButton
          className="chatbox-settings"
          disableRipple
          aria-label="Open chat box settings"
          onClick={handleOpenSettings}
        >
          <SettingsOutlinedIcon />
        </IconButton>
        <TextField
          autoComplete="off"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleSendMessage}
          placeholder="Type a Message..."
          className="watch-party-chatbox-input"
          sx={{
            "& .MuiInputBase-input::placeholder": {
              color: "black",
              opacity: 1,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.45)",
                borderRadius: "12px",
              },
            },
          }}
        />
      </Box>
      <Box className="watch-party-chatbox-actions">
        <Button
          className="watch-party-chatbox-user-join"
          variant="contained"
          onClick={handleUserJoin}
        >
          Simulate User Join
        </Button>
        <Button
          className="watch-party-chatbox-other-message"
          variant="contained"
          onClick={handleOtherUserMessage}
        >
          Simulate Other User Message
        </Button>
        <Button
          className="watch-party-chatbox-party-raid"
          variant="contained"
          onClick={handleWatchPartyRaid}
        >
          Simulate Watch Party Raid
        </Button>
      </Box>
      <WatchPartyChatBoxSettingModal
        open={isSettingsModalOpen}
        handleClose={handleCloseSettings}
      />
    </>
  );
};

export default WatchPartyChatBox;
