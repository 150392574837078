// Function to fetch videos data
import staticVideos from "../constant/staticVideos.json"; // To be removed when we get API
export const fetchVideosPageData = async () => {
  try {
    // const response = await fetch(""); // This Will Replace with API instead
    // const data = await response.json();
    return staticVideos;
  } catch (err) {
    console.error("Error fetching video list:", err);
    throw err;
  }
};
