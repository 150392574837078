import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FaCar,
  FaFilm,
  FaGraduationCap,
  FaMusic,
  FaNewspaper,
  FaPlane,
  FaPuzzlePiece,
  FaTableTennisPaddleBall,
  FaUtensils,
} from "react-icons/fa6";
import { FaHeartbeat, FaTshirt } from "react-icons/fa";
import {
  incrementStage,
  updateInterests,
} from "../../../../reducers/userPrefReducer";
import "./TopicsButtons.scoped.css";

const TopicsButtons = () => {
  const dispatch = useDispatch();

  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "All", checked: false },
    { id: 2, label: "Automative", icon: <FaCar />, checked: false },
    { id: 3, label: "Fashion & Beauty", icon: <FaTshirt />, checked: false },
    { id: 4, label: "Food & Cooking", icon: <FaUtensils />, checked: false },
    { id: 5, label: "Gaming", icon: <FaPuzzlePiece />, checked: false },
    { id: 6, label: "Health & Wealth", icon: <FaHeartbeat />, checked: false },
    { id: 7, label: "Learning", icon: <FaGraduationCap />, checked: false },
    { id: 8, label: "Music", icon: <FaMusic />, checked: false },
    { id: 9, label: "News", icon: <FaNewspaper />, checked: false },
    { id: 10, label: "Series", icon: <FaFilm />, checked: false },
    {
      id: 11,
      label: "Sports",
      icon: <FaTableTennisPaddleBall />,
      checked: false,
    },
    { id: 12, label: "Travel", icon: <FaPlane />, checked: false },
  ]);

  const handleCheckboxChange = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) => {
        if (checkbox.id === id) {
          return { ...checkbox, checked: !checkbox.checked };
        } else if (id === 1) {
          return { ...checkbox, checked: !prevCheckboxes[0].checked };
        } else return checkbox;
      })
    );
  };

  useEffect(() => {
    const checkedInterests = checkboxes
      .filter((box) => box.checked)
      .map((checked) => checked.label);
    dispatch(updateInterests(checkedInterests));
  }, [checkboxes]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(incrementStage());
  };

  return (
    <form
      id="ob-profile-form"
      className="checkboxes-container"
      onSubmit={handleSubmit}
    >
      {checkboxes.map((checkbox) => (
        <div
          key={checkbox.id}
          className={`topic-button ${checkbox.checked && "selected"}`}
          onClick={() => handleCheckboxChange(checkbox.id)}
        >
          {checkbox.icon}
          {checkbox.label}
        </div>
      ))}
    </form>
  );
};

export default TopicsButtons;
