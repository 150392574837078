import axios from "axios";
import { VIDEO_LIST_URL, VIDEO_DETAIL_URL } from "../constant/constantUrls";

const axiosInstance = axios.create();

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetches a list of videos with query parameters
export const fetchVideosList = async (data) => {
  try {
    const queryParams = new URLSearchParams(data).toString();
    const videoListUrl = `${VIDEO_LIST_URL}?${queryParams}`;
    const res = await axiosInstance.get(videoListUrl);
    return res;
  } catch (err) {
    console.error("Error fetching video list:", err);
    throw err;
  }
};

// Fetches details for a specific video by ID
export const fetchVideoDetails = async (videoId) => {
  try {
    const videoDetailUrl = `${VIDEO_DETAIL_URL}${videoId}`;
    const res = await axiosInstance.get(videoDetailUrl);
    return res;
  } catch (err) {
    console.error("Error fetching video details:", err);
    throw err;
  }
};
