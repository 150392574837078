import ServiceSlider from "../ServiceSlider/ServiceSlider"
import ServiceHeader from "../ServiceHeader/ServiceHeader";
import { useParams } from "react-router-dom";
import './ServiceDisney.scoped.css';

const movieData = [
    {
      title: "Movie 1",
      imageUrl: "https://placehold.co/600x400",
      // Other movie information
    },
    {
      title: "Movie 2",
      imageUrl: "https://placehold.co/600x400",
      // Other movie information
    },
];

const ServiceDisney = () => {

    const { serviceName } = useParams()
    
    return (
        <>
            <div>
                <ServiceHeader serviceName={serviceName} />
                <h3 className="service-page-subtitle ">Recommended</h3>
                <ServiceSlider items={movieData} slidesToShow="4"  ServiceSliderType="recommended-slider" serviceName={serviceName} />
                <h3 className="service-page-subtitle">Most Popular</h3>
                <ServiceSlider items={movieData} slidesToShow="4" serviceName={serviceName} />
                <h3 className="service-page-subtitle">New Releases</h3>
                <ServiceSlider items={movieData} slidesToShow="4" serviceName={serviceName} />
            </div>
        </>
    )
}

export default ServiceDisney