import "./AudioPlayerTextPanel.scoped.css";
import { Box, Typography, IconButton, TextField } from "@mui/material";
import { useState, useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const TranscriptTextPanel = ({
  open,
  textPanelType,
  audioFile,
  pageControls,
  translationLanguage,
  activeTextLineIndex,
  disposition,
}) => {
  const effectiveTranslationLanguage = useMemo(
    () => translationLanguage ?? pageControls.translationLanguage,
    [translationLanguage, pageControls.translationLanguage]
  );

  const [openInputBoxIndex, setOpenInputBoxIndex] = useState(null);

  const handleFlagClick = (index) => {
    setOpenInputBoxIndex(openInputBoxIndex === index ? null : index);
  };

  const renderTextLine = (nativeText, translatedText) => (
    <Box className="text">
      <Typography className="text">{nativeText}</Typography>
      {effectiveTranslationLanguage !== audioFile.nativeLanguage && (
        <Typography className="text">{translatedText}</Typography>
      )}
    </Box>
  );

  return (
    <Box
      className="text-panel"
      data-textpanelopen={open}
      data-textpaneltype={textPanelType}
      data-disposition={disposition}
    >
      <Box className="transcript-scroll">
        {audioFile.transcript &&
          Object.entries(audioFile.transcript).map((textTimeLine, index) => {
            const [timepoint, textContent] = textTimeLine;
            const nativeText = textContent[audioFile.nativeLanguage];
            const translatedText = textContent[effectiveTranslationLanguage];

            return (
              <Box
                key={index}
                className="line"
                data-highlighted={index === activeTextLineIndex}
              >
                {/* Flag icon to toggle input box */}
                <IconButton
                  onClick={() => handleFlagClick(index)}
                  aria-label="Flag text"
                >
                  <FlagIcon />
                </IconButton>

                {/* Timepoint */}
                <Box className="timepoint">{timepoint}</Box>

                {/* Native and translated text */}
                {renderTextLine(nativeText, translatedText)}

                {/* Input box and buttons that appear when flag is clicked */}
                {openInputBoxIndex === index && (
                  <Box className="input-section">
                    <Typography variant="body2">
                      Suggested Translation
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Type here"
                      size="small"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 2,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ccc",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "8px",
                        },
                      }}
                    />
                    <IconButton className="right-button">
                      <CheckIcon />
                    </IconButton>
                    <IconButton className="wrong-button">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            );
          })}
      </Box>

      {/* Captions section */}
      <Box className="captions">
        {audioFile.transcript &&
          Object.entries(audioFile.transcript).map((textTimeLine, index) => {
            const textLines = textTimeLine[1];
            return (
              <Box
                key={index}
                className="textLineBox"
                data-hidden={
                  index < activeTextLineIndex
                    ? "above"
                    : index > activeTextLineIndex
                      ? "below"
                      : false
                }
              >
                {/* Caption display */}
                <Typography className="caption" sx={{ color: "gray" }}>
                  {textLines[audioFile.nativeLanguage]}
                </Typography>
                {effectiveTranslationLanguage !== audioFile.nativeLanguage && (
                  <Typography className="caption">
                    {textLines[effectiveTranslationLanguage]}
                  </Typography>
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default TranscriptTextPanel;
