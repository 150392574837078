import React, { useState, useEffect } from "react";
import {
  Switch,
  Typography,
  Box,
  Divider,
  Link,
  IconButton,
} from "@mui/material";
import MuiIcon from "../../utils/MuiIcon.js";
import "./SettingsNotifications.scoped.css";
import { getUserSettings, updateSettings } from "../../api/settings.js";

const SettingsNotification = ({ onBack }) => {
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);
  const [settings, setSettings] = useState({
    enable_notifications: false,
    new_content_alert: false,
    personalized_recommendation: false,
    subscription_updates: false,
    replies_to_my_comment: false,
    mentions: false,
  });

  const displayKeys = [
    "new_content_alert",
    "personalized_recommendation",
    "subscription_updates",
    "replies_to_my_comment",
    "mentions",
  ];

  const formatKey = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase()); 
  };

  // Fetch notification settings from the backend
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getUserSettings();
        console.log("Notification settings:", response);
        if(response) setSettings(response);    
        if(response.enable_notifications) setIsNotificationsEnabled(true);
      } catch (error) {
        console.error("Error fetching notification settings:", error);
      }
    };

    fetchSettings();
  }, []);

  // Handle toggle for "Enable Notifications" switch
  const handleToggle = async (event) => {

    const { name, checked } = event.target;

    console.log(name, checked)
    const updatedSettings = { ...settings, [name]: checked };

    if(name === "enable_notifications") setIsNotificationsEnabled(checked);

    setSettings(updatedSettings);
    try {
      const response = await updateSettings(updatedSettings);
      console.log("Updated settings response:", response);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <Box className="settings-big-container">
      {/* Back Button */}
      <IconButton onClick={onBack} className="back-button">
        <MuiIcon name="ArrowBackIos" className="back-button-icon" />
        Back
      </IconButton>

      <Box className="settings-container">
        <Typography variant="h5" className="settings-title">
          Notification Settings
        </Typography>
        <Typography variant="body2" className="settings-subtitle">
          Manage how and when you receive notifications
        </Typography>

        <Box className="settings-section inline-setting">
          <Box className="settings-option">
            <Switch
              name="enable_notifications"
              checked={settings.enable_notifications}
              onChange={handleToggle}
              className="parental-control-switch"
              inputProps={{ "aria-label": "Enable Notifications" }}
            />
            <Typography variant="body1" className="options">
              Enable Notifications
            </Typography>
          </Box>
          <Typography variant="body2" className="option-description">
            All notifications are disabled
          </Typography>
        </Box>

        <Divider />

        <Box
          disabled={!isNotificationsEnabled}
          className={`restrictions-input ${
            !isNotificationsEnabled ? "disabled" : ""
          }`}
        >
          {displayKeys.map((key) => (
            <Box key={key} className="settings-section">
              <Box className="settings-option">
                <Switch
                  name={key}
                  disabled={!isNotificationsEnabled}
                  checked={settings[key]}
                  onChange={handleToggle}
                  inputProps={{ "aria-label": key }}
                />
                <Typography variant="body1"> {formatKey(key)}</Typography>
              </Box>
              <Typography variant="body2" className="option-description">
                {`Manage notifications for ${formatKey(key).toLowerCase()}`}
              </Typography>
            </Box>
          ))}
        </Box>
        <Divider />

        <Box className="settings-section menu">
          <Box className="settings-option-x">
            <Typography variant="body1">Email Notifications</Typography>
            <div className="arrow">&gt;</div>
          </Box>
          <Typography variant="body2" className="option-description-x">
            Receive notifications via email for important updates
          </Typography>
        </Box>

        <Box className="settings-section">
          <Box className="settings-option-x">
            <Typography variant="body1">Desktop Notifications</Typography>
            <div className="arrow">&gt;</div>
          </Box>
          <Typography variant="body2" className="option-description-x space">
            Display pop-up notifications on your desktop
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsNotification;