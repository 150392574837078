import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import MuiIcon from "../../utils/MuiIcon"; // Assuming you have an icon utility
import "./OfflineViewers.scoped.css"; // Your custom styles
import data from "../../data/dummyUploadText.json"; // Your data file

const OfflineViewers = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const accordionStyles = {
    backgroundColor: "transparent",
    color: "#9d9595",
    boxShadow: "none",
  };

  return (
    <Box className="offline-viewers-section">
      <Accordion
        expanded={isAccordionOpen}
        onChange={handleAccordionToggle}
        sx={accordionStyles}
      >
        <AccordionSummary
          expandIcon={<MuiIcon name="OpenInFull" className="expand-icon" />}
          aria-controls="offline-viewers-content"
          id="offline-viewers-header"
        >
          <Typography className="accordion-summary-text">Offline Viewers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="viewer-items">
            <Box className="viewer-item">
              <img
                src="https://media.istockphoto.com/id/1316134499/photo/a-concept-image-of-a-magnifying-glass-on-blue-background-with-a-word-example-zoom-inside-the.jpg?s=1024x1024&w=is&k=20&c=b5kopTcDYozVMCDGVIDd4BUDI2zgxhSzKXPC2YBBIKc="
                alt="Viewer 1"
                className="viewer-image"
              />
              <Typography className="viewer-title">{data[0].title}</Typography>
            </Box>
            <Box className="viewer-item">
              <img
                src="https://media.istockphoto.com/id/1316134499/photo/a-concept-image-of-a-magnifying-glass-on-blue-background-with-a-word-example-zoom-inside-the.jpg?s=1024x1024&w=is&k=20&c=b5kopTcDYozVMCDGVIDd4BUDI2zgxhSzKXPC2YBBIKc="
                alt="Viewer 2"
                className="viewer-image"
              />
              <Typography className="viewer-title">{data[1].title}</Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OfflineViewers;
