import { getAllMyPlaylists } from "../api/playlists";
import store from "../store";
import { setLoggedIn } from "../reducers/authSlice";
import { LOGIN_URL } from "../constant/constantUrls";

export const fetchAllPlayList = async (page, setPage, navigate) => {
  if (setPage) {
    setPage((page) => page + 1);
  }
  try {
    let res = await getAllMyPlaylists(page);
    if (res?.status === 200) {
      return res?.data?.data;
    }
    return [];
  } catch (err) {
    let statusCode = err?.response?.status;
    if (statusCode === 401) {
      localStorage.clear();
      store.dispatch(setLoggedIn(false));
      navigate(LOGIN_URL);
    }
  }
  return [];
};
