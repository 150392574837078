import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { MicNoneOutlined, Stop, Close } from "@mui/icons-material";
import { setRecording } from "../../reducers/recordingSlice";
import { uploadAudio } from "../../api/recording";
import { useAlert } from "../../context/AlertProvider";
import "./RecordAudio.scoped.css";

const RecordAudio = ({ onRecorded, children }) => {
  const dispatch = useDispatch();
  const { isRecording } = useSelector((state) => state.recording);
  const { showAlert } = useAlert();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = async (e) => {
        const audioBlob = new Blob([e.data], { type: "audio/wav" });
        try {
          const response = await uploadAudio(audioBlob);
          onRecorded(response);
          showAlert("success", "Audio uploaded and translated successfully!");
        } catch (err) {
          showAlert("error", "Failed to upload audio.");
        }
      };

      recorder.start();
      dispatch(setRecording(true));
      setOpen(true);
    } catch (err) {
      setError("Microphone access denied.");
      showAlert("error", "Microphone access denied.");
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      dispatch(setRecording(false));
      setOpen(false);
    }
  };

  return (
    <>
      {children(handleStartRecording)}

      {/* Modal for recording */}
      <Dialog
        open={open}
        onClose={handleStopRecording}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            position: "relative",
            overflow: "visible",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <Box className="voice-input-container">
            <IconButton
              onClick={handleStopRecording}
              sx={{ alignSelf: "flex-end", color:"white" }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Box className="voice-input-indicator">
              <Box className="voice-line" />
              <MicNoneOutlined sx={{ fontSize: "2rem", margin: "0 1rem" }} />
              <Box className="voice-line" />
            </Box>
            <Box className="status-container">
              <Typography className="voice-input-status">
                Listening
              </Typography>
              <Box className="dots"></Box>
            </Box>
            <Box className="voice-bg-blur" />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordAudio;
