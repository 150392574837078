import { getAudio } from '../api/audio';

// fetches test audio file from spotify and returns transcript line by line, native language and available languages
export const loadAudioFile = async () => {
    // let previewUrl = await getAudio(); // (500 requests per month) - the below url is what the api call will retrieve
    let previewUrl = "https://p.scdn.co/mp3-preview/b2064057c1d5e811103e04ec682e52d2d3e5759e?cid=d8a5ed958d274c2e8ee717e6a4b0971d";
    return {
      isLoaded: true,
      audio: previewUrl,
      transcript: {
        "00:00": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:01": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:03": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:05": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:07": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:09": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:11": {
          en: "Rumor has it (rumor)",
          es: "El rumor dice (rumor)",
        },
        "00:24": {
          en: "All of these words whispered in my ear",
          es: "Todas estas palabras susurradas en mi oído",
        },
      },
      nativeLanguage: "en",
      availableLanguages: [
        {
          name: "English",
          isocode: "en",
        },
        {
          name: "Español",
          isocode: "es",
        },
      ],
    };
  };
