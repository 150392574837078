import AutoDubbingButton from "./ActionControl/AutodubbingButton";
import CaptionButton from "./ActionControl/CaptionButton";
import CommentButton from "./ActionControl/Comment";
import PlaybackSpeed from "./ActionControl/PlaybackSpeed";
import PlayPauseButton from "./ActionControl/PlayPauseButton";
import RewindForwardButtons from "./ActionControl/RewindForwardButtons";
import Settings from "./ActionControl/Settings";
import VolumeSlider from "./ActionControl/VolumeSlider";
import "./ControlPanel.scoped.css";
import FullScreenButton from "./ActionControl/FullScreenButton";
import LikeDislikeButton from "./ActionControl/LikeDislike";

const ControlPanelAudio = ({
  togglePlayPause,
  mediaRef,
  dashVideoObject,
  showControls,
  mouseMoveTimer,
}) => {
  return (
    <div className="media-player-control">
      <div className="play-time-volume">
        {/* PlayPause Button */}
        <PlayPauseButton togglePlayPause={togglePlayPause} />

        {/* <div className="seek-controls"> */}
        {/* Rewind and Forward Buttons */}
        <RewindForwardButtons mediaRef={mediaRef} />

        {/* Volume Slider */}
        <VolumeSlider mediaRef={mediaRef} />
      </div>
      {/* {/* Languages section */}
      <div className="langauge-setting-fullscreen">
        {/* <div className="like-comment-container">
          <LikeDislikeButton />
          <CommentButton />
        </div> */}

        <AutoDubbingButton
          mediaRef={mediaRef}
          dashVideoObject={dashVideoObject}
        />

        <CaptionButton showControls={showControls} />

        <PlaybackSpeed
          mediaRef={mediaRef}
          mouseMoveTimer={mouseMoveTimer}
          showControls={showControls}
        />

        <Settings />

        <FullScreenButton
          mediaRef={mediaRef}
          mouseMoveTimer={mouseMoveTimer}
          showControls={showControls}
        />
      </div>
    </div>
  );
};

export default ControlPanelAudio;
