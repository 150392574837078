import { useRef } from "react";
import { setHovering } from "../../../reducers/videoPlayerSlice";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import { useDispatch, useSelector } from "react-redux";
import { setCaptionsEnabled } from "../../../reducers/videoPlayerSlice";

const CaptionButton = ({ showControls }) => {
  const dispatch = useDispatch();
  const captionsEnabled = useSelector(
    ({ videoPlayer }) => videoPlayer.captionsEnabled
  );

  const mouseMoveTimer = useRef(null);

  const handleCaptionsToggle = () => {
    dispatch(setCaptionsEnabled(!captionsEnabled));
  };
  return (
    <div
      onMouseEnter={() => {
        clearTimeout(mouseMoveTimer.current);
        dispatch(setHovering(true));
      }}
      onMouseLeave={() => {
        dispatch(setHovering(false));
        showControls();
      }}
    >
      <ToolTip content={captionsEnabled ? "Captions On" : "Captions Off"}>
        <MuiIcon
          name={captionsEnabled ? "ClosedCaption" : "ClosedCaptionOffOutlined"}
          onClick={handleCaptionsToggle}
          style={{ cursor: "pointer" }}
          id="controls-btn-captions"
        />
      </ToolTip>
    </div>
  );
};

export default CaptionButton;
