import PropTypes from "prop-types";
import "./Centerbox.scoped.css";
import AirisBlob from "../Airis/AirisBlob/AirisBlob";

const CenterBox = ({ children, airisText, maxWidth, style }) => {
  return (
    <div className="box" style={{ ...style, maxWidth: maxWidth }}>
      <div className="airisHeader">
        <div className="airisGlow">
          <AirisBlob glowSpread={30} />
        </div>
        <p className="airisText">{airisText}</p>
      </div>
      {children}
    </div>
  );
};

CenterBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  airisText: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
};

export default CenterBox;
