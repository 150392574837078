// Constant Definitions
export const EASING_SINE_INOUT = [0.445, 0.05, 0.55, 0.95];
const EASING_CUBIC_INOUT = [0.645, 0.045, 0.355, 1.0];
const EASING_QUAD_INOUT = [0.455, 0.03, 0.515, 0.955];
const STATE_TRANSISTION_DURATION = 0.5;

// Array of SVG paths (blob shapes) for the top layer of the VosynAssist blob (used for SVG path morphing animation)
export const topLayerPathStrings = [
  "M66.61204,25.5302C87.70556,24.3546,110.6718,31.8549,117.5198,51.6804C124.6258,72.2549,112.1818,93.3034,94.43216,106.0715C76.73966,118.7985,52.51984,124.1865,35.32054,110.8105C19.39094,98.4224,23.79174,75.3862,30.71484,56.5183C36.71284,40.1715,49.10274,26.506,66.61204,25.5302Z",
  "M68.25097,29.77564C88.66245,32.69235,104.87,45.45842,115.5671,63.53663C126.5793,82.14751,113.6938,90.3392,102.2789,99.18728C88.69435,109.7171,63.03762,121.558,45.33209,109.8453C31.2239,100.5124,19.1507,67.08742,26.42283,48.91024C32.06461,34.80821,50.71635,27.27002,68.25097,29.77564Z",
  "M79.28878,22.52416C91.32528,22.56586,105.8901,31.11011,115.0871,64.18832C120.8799,85.02285,113.9976,103.5908,99.59698,113.6444C82.39808,125.6517,56.3211,123.5286,40.39506,109.4918C27.53686,98.15885,24.96747,88.36653,28.02868,69.02945C31.2918,48.41701,61.57618,22.46278,79.28878,22.52416Z",
  "M60.6393,28.57708C81.17577,26.74379,112.3901,32.68296,119.5871,53.76117C126.5747,74.22596,117.8229,97.78501,98.30627,109.1382C79.68637,119.9697,58.74576,116.6746,41.71674,105.0018C23.9482,92.82207,17.73725,71.80308,25.00938,53.6259C30.65117,39.52387,42.99672,30.15204,60.6393,28.57708Z",
  "M71.16053,26.71233C92.44596,26.93574,106.1154,37.75754,113.3124,58.83575C120.3,79.30054,123.8494,94.59915,102.9081,110.4864C86.19726,123.1641,62.11591,116.7293,46.18987,102.6925C33.33167,91.35957,20.04616,70.20088,27.31829,52.0237C32.96008,37.92167,44.07876,26.42808,71.16053,26.71233Z",
  "M66.34248,23.17087C87.00398,19.83758,103.8653,40.41216,111.0623,61.49037C118.0499,81.95516,118.2939,104.3552,103.8933,114.4087C86.69438,126.416,60.97095,121.4645,45.04491,107.4277C32.18671,96.09477,26.4776,74.9139,28.78944,62.54596C32.1296,44.67669,48.85584,25.99196,66.34248,23.17087Z",
  "M67.39518,24.50685C88.05666,21.17356,107.1392,37.18963,114.3363,58.26784C121.3238,78.73263,106.3237,99.24874,91.92306,109.3024C74.72412,121.3096,51.09431,124.6856,37.48886,111.3265C25.07062,99.13308,25.91101,70.8704,33.18314,52.69322C38.82493,38.59119,49.90854,27.32794,67.39518,24.50685Z",
  "M57.55701,27.17733C78.03909,22.87716,109.7153,26.04319,118.4404,52.50742C126.3395,76.46621,108.9122,96.88109,94.51169,106.9347C77.31269,118.942,52.67155,125.0304,33.95247,107.3872C23.03543,97.09754,19.46389,72.22219,29.50551,52.44689C35.42913,40.78131,42.01492,30.44036,57.55701,27.17733Z",
  "M76.51248,23.15355C98.42398,19.32026,108.7905,36.72021,115.9876,57.79842C122.9751,78.26321,110.6735,98.00165,96.19938,107.9491C78.00048,120.4563,58.30891,128.6627,42.22231,112.0213C30.43828,99.83083,21.32123,75.72421,28.59336,57.54703C34.23516,43.445,59.06475,26.20594,76.51248,23.15355Z",
  "M63.27261,28.03361C83.93408,24.70032,112.9828,31.70218,119.9241,53.68584C126.8975,75.77083,108.5139,97.73109,94.11328,107.7847C76.91438,119.792,56.09871,120.0486,39.58601,106.7068C21.22781,91.87387,19.19381,73.98296,26.46591,55.80578C32.10771,41.70375,45.78601,30.8547,63.27261,28.03361Z",
  "M74.56933,26.56585C95.58436,25.00033,110.9044,37.22046,117.6014,50.79867C127.167,70.19284,113.7584,103.4373,102.274,111.6334C81.13256,126.7215,59.44413,109.2787,47.58396,100.8987C33.58591,91.00812,17.68872,70.44413,24.96085,52.26695C30.60264,38.16492,56.90554,27.88172,74.56933,26.56585Z",
  "M72.36747,26.19604C89.65397,24.23775,114.956,29.18951,119.5463,50.78914C124.015,71.81632,106.959,98.53965,96.05837,109.5933C81.33007,124.5283,68.04367,118.0047,52.56477,105.312C39.49287,94.59294,17.25437,71.93964,25.51637,54.19049C34.40817,35.08846,54.76737,28.18987,72.36747,26.19604Z",
];

// Using Fisher-Yates Shuffle
export const shuffleArray = (inputArray) => {
  let outputArray = [...inputArray];

  for (let i = inputArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = outputArray[i];
    outputArray[i] = outputArray[j];
    outputArray[j] = tmp;
  }

  // Push first path to the end for animation looping
  const firstPath = outputArray[0];
  outputArray.push(firstPath);

  return outputArray;
};

export const generateRandomValue = (lowerBound, upperBound) => {
  if (upperBound > lowerBound) {
    return Math.random() * (upperBound - lowerBound) + lowerBound;
  } else {
    return undefined;
  }
};

// Generate random transformation values for the top AIRIS layer
export const generateTopTransformations = (animationState) => {
  const morphType = Math.random();
  let newXSkew = 0;
  let newYSkew = 0;
  let newXScale = generateRandomValue(0.9, 0.95);
  let newYScale = generateRandomValue(0.9, 0.95);
  let newXOrigin = generateRandomValue(0.4, 0.6);
  let newYOrigin = generateRandomValue(0.4, 0.6);
  let newRotation = generateRandomValue(-5, 5);

  if (morphType < 0.33) {
    // Transforms for a "O" shaped layer
    newXSkew = generateRandomValue(-1, 1);
    newYSkew = generateRandomValue(-1, 1);
  } else if (morphType < 0.66) {
    // Transforms for a "/" shaped layer
    const skewSum = generateRandomValue(-3, -1);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  } else {
    // Transforms for a "\" shaped layer
    const skewSum = generateRandomValue(1, 3);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  }

  // Handle different animation states
  if (animationState === 1) {
    newXScale += 0.1;
    newYScale += 0.1;
    newXOrigin = 0.5;
    newYOrigin = 0.5;
    newRotation = 0;
  } else if (animationState === 2) {
    newXSkew = generateRandomValue(-9, -7);
    newYSkew = generateRandomValue(-9, -7);
    newXScale = generateRandomValue(0.85, 0.95);
    newYScale = generateRandomValue(0.85, 0.95);
    newXOrigin = 0.5;
    newYOrigin = 0.5;
    newRotation = generateRandomValue(170, 190);
  }

  return {
    skewX: newXSkew,
    skewY: newYSkew,
    scaleX: newXScale,
    scaleY: newYScale,
    originX: newXOrigin,
    originY: newYOrigin,
    rotate: newRotation,
  };
};

// Get transformation properties for the top AIRIS layer based on current animation state
export const getTopTransformationProperties = (
  animationState,
  duringStateTransition,
  animationEnabled
) => {
  let animationDuration = 2.1;
  let easingFunction = EASING_SINE_INOUT;
  let animationType = "tween";
  let springBounce = undefined;

  if (animationState === 1) {
    animationDuration = 1.6;
  } else if (animationState === 2) {
    animationDuration = 5;
    easingFunction = EASING_CUBIC_INOUT;
  }

  // Speed up transition to new state
  if (duringStateTransition) {
    animationDuration = STATE_TRANSISTION_DURATION;
    easingFunction = "easeInOut";
  }
  // Use spring animation upon transition to "error" state
  if (duringStateTransition && animationState === 2) {
    animationDuration = undefined;
    animationType = "spring";
    springBounce = 0.8;
  }
  // Skip transition animation if animation is paused
  if (!animationEnabled) {
    animationDuration = 0;
  }

  // Handle transformation properties that dont change between two different generated transforms
  let originAnimationDuration = animationDuration;
  let rotateAnimationDuration = animationDuration;
  if (!duringStateTransition && animationState === 1) {
    originAnimationDuration = 0;
    rotateAnimationDuration = 0;
  }
  if (!duringStateTransition && animationState === 2) {
    originAnimationDuration = 0;
  }
  const partialProperties = {
    ease: easingFunction,
    type: animationType,
    bounce: springBounce,
  };
  let staticAnimationProperties = {
    originX: { duration: originAnimationDuration, ...partialProperties },
    originY: { duration: originAnimationDuration, ...partialProperties },
    rotate: { duration: rotateAnimationDuration, ...partialProperties },
  };

  return {
    ...{
      duration: animationDuration,
      ease: easingFunction,
      type: animationType,
      bounce: springBounce,
    },
    ...staticAnimationProperties,
  };
};

// Generate random transformation values for the middle AIRIS layer
export const generateMiddleTransformations = (animationState) => {
  const morphType = Math.random();
  let newXSkew = 0;
  let newYSkew = 0;
  let newXScale = generateRandomValue(0.95, 1.0);
  let newYScale = generateRandomValue(0.95, 1.0);
  let newRotation = generateRandomValue(-15, 15);

  if (morphType < 0.4) {
    // Transforms for a "O" shaped layer
    newXSkew = generateRandomValue(-2, 2);
    newYSkew = generateRandomValue(-2, 2);
  } else if (morphType < 0.7) {
    // Transforms for a "/" shaped layer
    const skewSum = generateRandomValue(-4, -2);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  } else {
    // Transforms for a "\" shaped layer
    const skewSum = generateRandomValue(2, 4);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  }

  // Handle different animation states
  if (animationState === 1) {
    newXScale -= 0.08;
    newYScale -= 0.08;
  } else if (animationState === 2) {
    newXScale = generateRandomValue(0.65, 0.75);
    newYScale = generateRandomValue(0.9, 1.0);
    newRotation = generateRandomValue(-50, -40);
  }

  return {
    skewX: newXSkew,
    skewY: newYSkew,
    scaleX: newXScale,
    scaleY: newYScale,
    rotate: newRotation,
  };
};

// Get transformation properties for the middle AIRIS layer based on current animation state
export const getMiddleTransformationProperties = (
  animationState,
  duringStateTransition,
  animationEnabled
) => {
  let animationDuration = 2.5;
  let easingFunction = EASING_SINE_INOUT;
  let animationType = "tween";
  let springBounce = undefined;

  if (animationState === 1) {
    animationDuration = 1.2;
  } else if (animationState === 2) {
    animationDuration = 3;
    easingFunction = EASING_QUAD_INOUT;
  }

  // Speed up transition to new state
  if (duringStateTransition) {
    animationDuration = STATE_TRANSISTION_DURATION;
    easingFunction = "easeInOut";
  }
  // Use spring animation upon transition to "error" state
  if (duringStateTransition && animationState === 2) {
    animationDuration = undefined;
    animationType = "spring";
    springBounce = 0.8;
  }
  // Skip transition animation if animation is paused
  if (!animationEnabled) {
    animationDuration = 0;
  }

  return {
    duration: animationDuration,
    ease: easingFunction,
    type: animationType,
    bounce: springBounce,
  };
};

// Generate random transformation values for the bottom AIRIS layer
export const generateBottomTransformations = (animationState) => {
  const morphType = Math.random();
  let newXSkew = 0;
  let newYSkew = 0;
  let newXScale = generateRandomValue(0.95, 1.0);
  let newYScale = generateRandomValue(0.95, 1.0);
  let newXOrigin = generateRandomValue(0.35, 0.65);
  let newYOrigin = generateRandomValue(0.35, 0.65);
  let newRotation = generateRandomValue(-5, 5);

  if (morphType < 0.3) {
    // Transforms for a "/" shaped layer
    const skewSum = generateRandomValue(-5, -2);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  } else if (morphType < 0.6) {
    // Transforms for a "\" shaped layer
    const skewSum = generateRandomValue(2, 5);

    newXSkew = skewSum * Math.random();
    newYSkew = skewSum - newXSkew;
  } else if (morphType < 0.8) {
    // Transforms for a "-" shaped layer
    newXSkew = generateRandomValue(-2, 2);
    newYSkew = generateRandomValue(-2, 2);

    newXScale = generateRandomValue(1.0, 1.05);
    newYScale = generateRandomValue(0.85, 0.9);
  } else {
    // Transforms for a "|" shaped layer
    newXSkew = generateRandomValue(-2, 2);
    newYSkew = generateRandomValue(-2, 2);

    newXScale = generateRandomValue(0.85, 0.9);
    newYScale = generateRandomValue(1.0, 1.05);
  }

  // Handle different animation states
  if (animationState === 1) {
    newXScale -= 0.12;
    newYScale -= 0.12;
    newXOrigin = 0.5;
    newYOrigin = 0.5;
  } else if (animationState === 2) {
    newXScale = generateRandomValue(0.65, 0.75);
    newYScale = generateRandomValue(0.9, 0.95);
    newRotation = generateRandomValue(-95, -85);
    newXOrigin = 0.5;
    newYOrigin = 0.5;
  }

  return {
    skewX: newXSkew,
    skewY: newYSkew,
    scaleX: newXScale,
    scaleY: newYScale,
    originX: newXOrigin,
    originY: newYOrigin,
    rotate: newRotation,
  };
};

// Get transformation properties for the bottom AIRIS layer based on current animation state
export const getBottomTransformationProperties = (
  animationState,
  duringStateTransition,
  animationEnabled
) => {
  let animationDuration = 5;
  let easingFunction = EASING_SINE_INOUT;
  let animationType = "tween";
  let springBounce = undefined;

  if (animationState === 1) {
    animationDuration = 2.5;
  } else if (animationState === 2) {
    animationDuration = 4;
    easingFunction = EASING_QUAD_INOUT;
  }

  // Speed up transition to new state
  if (duringStateTransition) {
    animationDuration = STATE_TRANSISTION_DURATION;
    easingFunction = "easeInOut";
  }
  // Use spring animation upon transition to "error" state
  if (duringStateTransition && animationState === 2) {
    animationDuration = undefined;
    animationType = "spring";
    springBounce = 0.8;
  }
  // Skip transition animation if animation is paused
  if (!animationEnabled) {
    animationDuration = 0;
  }

  // Handle transformation properties that dont change between two different generated transforms
  let originAnimationDuration = animationDuration;
  if (
    !duringStateTransition &&
    (animationState === 1 || animationState === 2)
  ) {
    originAnimationDuration = 0;
  }
  const partialProperties = {
    ease: easingFunction,
    type: animationType,
    bounce: springBounce,
  };
  let staticAnimationProperties = {
    originX: { duration: originAnimationDuration, ...partialProperties },
    originY: { duration: originAnimationDuration, ...partialProperties },
  };

  return {
    ...{
      duration: animationDuration,
      ease: easingFunction,
      type: animationType,
      bounce: springBounce,
    },
    ...staticAnimationProperties,
  };
};

// Get animation values/properties for bottom layer glowing animation based on animation state
export const getGlowAnimation = (
  animationState,
  duringStateTransition,
  glowSpread,
  animationEnabled
) => {
  let animationDuration = 2;
  let boxShadowAnimationDuration = undefined;
  let easingFunction = EASING_SINE_INOUT;
  let opacityKeyframes = [1.0, 0.5];
  let boxShadowAnimation = `0px 0px ${glowSpread}px 0px #ff60eb`;

  if (animationState === 1) {
    animationDuration = 4;
    boxShadowAnimationDuration = 2;
    opacityKeyframes = [1.0, 0.7];
    boxShadowAnimation = [
      `0px 0px ${glowSpread * 1.5}px 0px #527af9`,
      `0px 0px ${glowSpread * 1.5}px 0px #45e8ff`,
    ];
  } else if (animationState === 2) {
    animationDuration = 3;
    easingFunction = EASING_CUBIC_INOUT;
    opacityKeyframes = [1.0, 0.3];
    boxShadowAnimation = `0px 0px ${glowSpread * 1.25}px 0px #df0200`;
  }

  // Speed up transition to new state
  if (duringStateTransition) {
    animationDuration = STATE_TRANSISTION_DURATION;
    easingFunction = "easeInOut";

    if (animationState === 1) {
      boxShadowAnimation = `0px 0px ${glowSpread * 1.5}px 0px #527af9`;
    }
  }
  // Skip transition animation if animation is paused
  if (!animationEnabled) {
    animationDuration = 0;
  }

  let animate = {
    opacity: opacityKeyframes,
    boxShadow: boxShadowAnimation,
  };
  let animateProperties = {
    opacity: {
      duration: animationDuration,
      ease: easingFunction,
      repeat: Infinity,
      repeatType: "reverse",
    },
    boxShadow: {
      duration: animationDuration,
      ease: easingFunction,
    },
  };

  // Special case where box shadow property is animated with keyframes
  if (!duringStateTransition && animationState === 1) {
    animateProperties = {
      ...animateProperties,
      boxShadow: {
        duration: boxShadowAnimationDuration,
        ease: EASING_SINE_INOUT,
        repeat: Infinity,
        repeatType: "reverse",
      },
    };
  }

  // Handle state transition animation
  if (duringStateTransition) {
    animate = { ...animate, opacity: 1.0 };
    animateProperties = {
      opacity: { duration: animationDuration, ease: easingFunction },
      boxShadow: { duration: animationDuration, ease: easingFunction },
    };
  }

  return [animate, animateProperties];
};

// When animation is disabled, functions to get transformation properties that are animated
export const getBlurStyle = (animationState) => {
  let opacityValue = 0.5;

  if (animationState === 1) {
    opacityValue = 0.4;
  }

  return { opacity: opacityValue };
};

export const getGlowStyle = (animationState, glowSpread) => {
  let opacityValue = 0.75;
  let boxShadowValue = `0px 0px ${glowSpread}px 0px #ff60eb`;

  if (animationState === 1) {
    opacityValue = 0.85;
    boxShadowValue = `0px 0px ${glowSpread * 1.5}px 0px #527af9`;
  } else if (animationState === 2) {
    opacityValue = 0.65;
    boxShadowValue = `0px 0px ${glowSpread * 1.5}px 0px #e91501`;
  }

  return { opacity: opacityValue, boxShadow: boxShadowValue };
};
