import { useEffect, useState, memo } from "react";
import { motion, useAnimate } from "framer-motion";

import {
  generateMiddleTransformations,
  getMiddleTransformationProperties,
} from "./airis-blob-utils";
import useFirstRender from "./useFirstRender";
import "./AirisBlob.scoped.css";

const AirisBlobMiddle = ({
  size,
  glowSpread,
  animationState,
  initialAnimationState,
  animationEnabled,
}) => {
  const [layerScope, animateLayer] = useAnimate();

  const isFirstRender = useFirstRender();
  const [duringStateTransition, setDuringStateTransition] = useState(
    initialAnimationState !== -1 && initialAnimationState !== animationState
  );
  const [transformations, setTransformations] = useState(
    generateMiddleTransformations(animationState)
  );

  // Re-generate transformations if animation state has changed
  useEffect(() => {
    // Do not re-generate transformations on mount, only during state changes
    if (!isFirstRender) {
      setTransformations(generateMiddleTransformations(animationState));
      setDuringStateTransition(true);
    }
    // eslint-disable-next-line
  }, [animationState]);

  useEffect(() => {
    let animation = undefined;
    if (animationEnabled || duringStateTransition) {
      animation = animateLayer(layerScope.current, transformations, {
        ...getMiddleTransformationProperties(
          animationState,
          duringStateTransition,
          animationEnabled
        ),
        onComplete: () => {
          // After transform animation completes, generate a new set of transforms to animate to
          setTransformations(generateMiddleTransformations(animationState));
          setDuringStateTransition(false);
        },
      });
    }

    return () => {
      if (animation !== undefined) {
        animation.stop();
      }
    };
    // eslint-disable-next-line
  }, [
    animationEnabled,
    transformations,
    duringStateTransition,
    layerScope,
    animateLayer,
  ]);

  function transformOrder({ skewX, skewY, scaleX, scaleY, rotate }) {
    return `rotate(${rotate}) scaleX(${scaleX}) scaleY(${scaleY}) skewX(${skewX}) skewY(${skewY})`;
  }

  return (
    <motion.div
      ref={layerScope}
      transformTemplate={transformOrder}
      initial={generateMiddleTransformations(
        initialAnimationState === -1 ? animationState : initialAnimationState
      )}
      style={{
        ...{
          width: size === -1 ? "100%" : `${size}px`,
          height: size === -1 ? "100%" : `${size}px`,
        },
        ...(animationEnabled || duringStateTransition
          ? {}
          : generateMiddleTransformations(animationState)),
      }}
      className="va-blob-middle-layer-wrapper"
    >
      <svg
        width={size === -1 ? "100%" : size}
        height={size === -1 ? "100%" : size}
        viewBox="0 0 144 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="va-blob-svg-container"
      >
        {/* Layer fill */}
        <g
          filter={
            animationState === 2
              ? "url(#filter1_i_347_5_state2)"
              : "url(#filter1_i_347_5)"
          }
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d={
              animationState === 2
                ? "M71.0219 21.3214C89.3368 20.7239 106.3034 30.9216 116.5365 51.7357C126.6158 72.2368 127.9796 100.4411 105.5498 115.0208C90.1476 125.0326 54.0872 126.1703 35.8557 112.9702C17.9208 99.9849 16.9091 74.29 26.5391 52.7226C34.2201 35.5201 51.6406 21.9536 71.0219 21.3214Z"
                : "M69.8974 22.0224C88.2123 21.4249 107.1236 30.2084 117.3567 51.0225C127.4360 71.5236 121.0571 99.6975 98.6273 114.2772C83.225 124.2890 60.6536 125.5214 42.4221 112.3213C24.4873 99.336 16.4918 73.5767 26.1217 52.0094C33.8027 34.8069 50.5161 22.6546 69.8974 22.0224Z"
            }
            fill={animationState === 2 ? "#431d1b" : "#222222"}
          />
        </g>
        {/* Layer stroke */}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d={
            animationState === 2
              ? "M71.0219 21.3214C89.3368 20.7239 106.3034 30.9216 116.5365 51.7357C126.6158 72.2368 127.9796 100.4411 105.5498 115.0208C90.1476 125.0326 54.0872 126.1703 35.8557 112.9702C17.9208 99.9849 16.9091 74.29 26.5391 52.7226C34.2201 35.5201 51.6406 21.9536 71.0219 21.3214Z"
              : "M69.8974 22.0224C88.2123 21.4249 107.1236 30.2084 117.3567 51.0225C127.4360 71.5236 121.0571 99.6975 98.6273 114.2772C83.225 124.2890 60.6536 125.5214 42.4221 112.3213C24.4873 99.336 16.4918 73.5767 26.1217 52.0094C33.8027 34.8069 50.5161 22.6546 69.8974 22.0224Z"
          }
          stroke={animationState === 2 ? "#3e1311" : "#7595fa"}
          stroke-width="0.642597"
        />
        <defs>
          <filter
            id="filter1_i_347_5"
            x="15"
            y="15"
            width="128"
            height="128"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.57039" />
            <feGaussianBlur stdDeviation="16.0649" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.321569 0 0 0 0 0.478431 0 0 0 0 0.976471 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_347_5"
            />
          </filter>
          <filter
            id="filter1_i_347_5_state2"
            x="15"
            y="15"
            width="128"
            height="128"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.57039" />
            <feGaussianBlur stdDeviation="12" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.76 0 0 0 0 0.045 0 0 0 0 0.005 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_347_5_state2"
            />
          </filter>
        </defs>
      </svg>
      {/* Glow effect */}
      {animationState <= 1 && (
        <div
          className="va-blob-middle-layer-glow"
          style={{
            boxShadow: `0px 0px ${glowSpread / 3}px 0px ${
              animationState === 2 ? "#441918" : "#527af9"
            }`,
          }}
        ></div>
      )}
    </motion.div>
  );
};

export default memo(AirisBlobMiddle);
