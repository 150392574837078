// Custom React hook for managing chat history

import { useDispatch } from "react-redux";
import i18next from "i18next";

import {
  addLoadingChatItem,
  updateLoadingChatItem,
} from "../reducers/airisChatHistorySlice";

export const useAddToChatHistory = () => {
  const dispatch = useDispatch();

  const addLoadingMessage = (
    messageID,
    language,
    searchDirection,
    userInputText
  ) => {
    // Retrieve translated text based on th eprovided language
    const fixedT = i18next.getFixedT(language, "airis");
    const transitionText = fixedT("airis.transition");

    // Dispatching an action to add a loading message to the chat history
    dispatch(
      addLoadingChatItem({
        messageID: messageID,
        language: language,
        searchDirection: searchDirection,
        userInputText: userInputText,
        languageSwitchText: transitionText,
      })
    );
  };

  // Function to update a loading message in the chat history
  const updateLoadingMessage = (
    messageID,
    response = "",
    errorResponse = false,
    videoResponse = false,
    thirdParty = false
  ) => {
    // Dispatching an action to update a loading message in the chat history
    dispatch(
      updateLoadingChatItem({
        messageID: messageID,
        response: response,
        errorResponse: errorResponse,
        videoResponse: videoResponse,
        thirdParty: thirdParty,
      })
    );
  };

  return { addLoadingMessage, updateLoadingMessage };
};
