import React, { useState } from "react";
import { LANGUAGES_URL } from "../../constant/constantUrls";
import "./AvailableLanguages.scoped.css";

const AvailableLanguages = ({
  languageList,
  addToPinnedLanguages,
  selectedTrackIndex,
  handleAudioChange,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Fetches languages and filters them based on the search string
  const getLanguages = async (searchString) => {
    if (!searchString) return;

    try {
      const response = await fetch(LANGUAGES_URL);
      if (!response.ok) {
        throw new Error(`Error Status: ${response.status}`);
      }

      const data = await response.json();
      const result = data
        .map((item) => item["English"])
        .filter(
          (language) =>
            language &&
            language.toLowerCase().includes(searchString.toLowerCase())
        );

      setSearchResults(result);
    } catch (error) {
      console.error("Error fetching the data", error);
    }
  };

  // Updates search input and makes a call to fetch languages
  const handleChange = (input) => {
    setSearchInput(input);
    getLanguages(input);
  };

  // Initiates the audio track change when a language is selected
  const handleLanguageClick = (langObj) => {
    const index = langObj.index - 1;

    //Skip translation if the selected language is already active
    if (index === selectedTrackIndex) {
      return;
    }

    handleAudioChange(langObj);
  };

  return (
    <section className="available-languages-header">
      <div className="pinned-languages default-languages">
        {languageList.length > 0
          ? languageList.map((languageObj) => (
              <div key={languageObj.index} className="pinned-item">
                <span
                  className={`language-hover-color ${
                    selectedTrackIndex === languageObj.index - 1 ? "select" : ""
                  }`}
                  onClick={() => handleLanguageClick(languageObj)}
                >
                  {languageObj.lang}
                </span>
              </div>
            ))
          : "No track available"}
      </div>
      <div className="pinned-languages search-languages">
        {searchResults.map((language, index) => {
          return (
            <div key={index} className="pinned-item">
              <span
                className="search-result-languages"
                onClick={(e) => {
                  e.stopPropagation();
                  addToPinnedLanguages(language);
                }}
              >
                {language}
              </span>
            </div>
          );
        })}
      </div>
      <div className="input-group">
        <span className="input-group-addon">
          <i className="fa-solid fa-magnifying-glass"></i>
        </span>
        <input
          type="search"
          className="search-bar"
          placeholder="Search any language"
          aria-label="Search"
          value={searchInput}
          key="key"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </section>
  );
};

export default AvailableLanguages;
