import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./VideoPlaylistCard.scoped.css"; 


const VideoPlaylistCard = ({ imageUrl, title, description }) => {
  const [hovered, setHovered] = useState(false);

  return (

    <Card   
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        borderRadius: "15px",
        border: "1px transparent var(--mui-palette-neutral-600)",
        backgroundColor: "transparent",
        cursor: "pointer",
        transition: "0.5s",
        ":hover": {
          transform: "scale(1.01)",
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
    
    
       <Box sx={{ p: 2 }}>
        <CardMedia
         component="img"
         image={imageUrl}
         alt={title}
         sx={{
           width: '141.87px', 
           height: '80px',
           borderRadius: "10px",
           objectFit: "cover",
         }}
       />
     </Box>
     <Box sx={{ 
        flexGrow: 1, 
        p: 2, 
        display: 'flex', 
        alignItems: 'flex-start', 
        flexDirection: 'column'  
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          width: '100%' 
        }}>
          <Typography variant="h6" sx={{ color: '#656565', fontWeight: 'bold' }}> 
            {title} 
          </Typography>
          <IconButton size="small" sx={{ color: '#656565' }}> 
            <MoreVertIcon fontSize="small" />
            
          </IconButton>
        </Box> 
        <Typography variant="body2" sx={{ color: '#656565' }}> 
          2019 1h 45m
        </Typography>
      </Box>
    
      
    </Card>
  );
};

export default VideoPlaylistCard;
