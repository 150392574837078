
import React from 'react';
import { Container, Typography, Box, Button, Divider ,IconButton } from '@mui/material';
import './ThirdPartyAccounts.scoped.css'; 
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiIcon from "../../utils/MuiIcon.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ThirdPartyAccounts = ({ onBack }) => {
  return (

      <Box  bgcolor="#f7fafc" minHeight="100vh">
      <IconButton onClick={onBack} className="back-button">
        <MuiIcon name="ArrowBackIos" className="back-button-icon"/>
        Back
      </IconButton>
    <Container className="container">
      {/* Section Title */}
      <Typography variant="h4" className="title" >Link 3rd Party Accounts</Typography>
      <Typography variant="body1" className="description"> VosynVerse supports vast language compatibility
         and supports multi-language for many features. You have the flexibility to explore and consume
          content in the language of your choice.
         </Typography>

      {/* Linked Accounts Section */}
      <Box className="section">
        <Typography variant="h6" className="section-title">Linked accounts</Typography>
        <Box className="linked-accounts">
          <Box className="account">
            <div className="icon spotify"></div>
            <Typography variant="h6" className="edit-button">Edit</Typography>
          </Box>
          <Box className="account">
            <div className="icon netflix"></div>
            <Typography variant="h6" className="edit-button">Edit</Typography>
          </Box>
          <Box className="account">
            <div className="icon prime-video"></div>
            <Typography variant="h6" className="edit-button">Edit</Typography>
          </Box>
          <Box className="account">
            <div className="icon youtube"></div>
            <Typography variant="h6" className="edit-button">Edit</Typography>
          </Box>
            <Box className="account">
            <AddCircleOutlineIcon fontSize="large"  className="icon add-more"/>
            <Typography variant="h6" className="edit-button-add">Add More Accounts</Typography>
          </Box>
        </Box>
      </Box>

        {/* Divider */}
        <Box className="div">
          <Divider className="divider" />
          </Box>

      {/* Add More Accounts Section */}
      <Box className="section">
        <Typography variant="h6" className="section-title">Add more accounts</Typography>
        <Typography className="note">
          *Don’t see a platform in our system? 
          <a href="#" className="send-message">Send a message to our system</a>
        </Typography>
        <Box className="add-accounts">
          <Box className="account">
            <div className="icon crunchyroll"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
          <Box className="account">
            <div className="icon hulu"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
          <Box className="account">
            <div className="icon hulu"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
          <Box className="account">
            <div className="icon hulu"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
          <Box className="account">
            <div className="icon hulu"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
          <Box className="account">
            <div className="icon hulu"></div>
            <Typography variant="h6" className="link-button">Link</Typography>
          </Box>
        </Box>
      </Box>
      </Container>
      </Box>
  );
};

export default ThirdPartyAccounts;
