import './ServiceBanner.scoped.css'
import Dropdown from "../../Dropdown/Dropdown";
import {useRef, useState} from "react";


const ServiceBanner = ({data, selectedSeason, onSeasonSelect}) => {
    const seasonToggleRef = useRef()
    const [dropDownOpen, setDropDownOpen] = useState(false)

    const handleDropDownOpen = (open) => () => setDropDownOpen(open);

    return (
        <div className="service-banner">
            <img src={data.bannerImg} alt={data.title} />
            <div>
                <div className="service-banner__title">
                    <h1>{data.title}</h1>
                    {data.seasons && (
                        <div>
                            <button
                                className="service-banner__season-select"
                                onClick={handleDropDownOpen(!dropDownOpen)}
                                ref={seasonToggleRef}
                            >
                                {selectedSeason?.value || 1} Season
                            </button>
                            <Dropdown
                                anchorRef={seasonToggleRef}
                                list={data.seasons}
                                handleDropDownOpen={handleDropDownOpen}
                                handleSelection={onSeasonSelect}
                                type="Season"
                                isOpen={dropDownOpen}
                            />
                        </div>
                    )}
                </div>
                <span>{data.description}</span>
            </div>
        </div>
    )
}

export default ServiceBanner