// src/api/recording.js
import axios from "axios";

const API_BASE_URL = "https://your-backend-api.com"; // Replace with your backend API URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const uploadAudio = async (audioBlob) => {
  const formData = new FormData();
  formData.append("file", audioBlob, "audio.wav");

  try {
    const response = await api.post("/upload/audio", formData);
    return response.data; // Expected response should contain the URL or ID for the translation page
  } catch (error) {
    console.error("Failed to upload audio:", error);
    throw error;
  }
};

export const uploadVideo = async (videoBlob) => {
  const formData = new FormData();
  formData.append("file", videoBlob, "video.mp4");

  try {
    const response = await api.post("/upload/video", formData);
    return response.data; // Expected response should contain the URL or ID for the translation page
  } catch (error) {
    console.error("Failed to upload video:", error);
    throw error;
  }
};
