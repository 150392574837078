import Tabs from "../../../Tabs/Tabs";
import "./Coursera.scoped.css";

const CourseraTabs = () => {
  const tabs = [
    { title: "User Experience (UX)", link: "/ux" },
    { title: "Prototype", link: "/prototype" },
    { title: "Wireframe", link: "/wireframe" },
    { title: "User Experience Design (UXD)", link: "/uxd" },
    { title: "Usability Testing", link: "/usability-testing" },
    { title: "UX Research", link: "/ux-research" },
    { title: "Figma", link: "/figma" },
    { title: "Mockup", link: "/mockup" },
  ];

  return (
    <div className="coursera-tabs">
      <h2>Skills you’ll gain</h2>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default CourseraTabs;
