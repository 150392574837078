import React, { useState } from "react";
import Background from "../../components/Background/Background";
import CenterBox from "../../components/CenterBox/CenterBox";
import "./ForgetPasswordPage.scoped.css";
import { userResetPassword } from "../../api/auth";
import { useDispatch } from "react-redux";
import { setToast } from "../../reducers/snackbarSlice";
import { Link } from "react-router-dom";

const ForgetPasswordPage = ({ onFormSubmit = () => {} }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailFieldError, setEmailFieldError] = useState();
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmailFieldError("");
    setEmail(e.target.value);
    setEmailError(""); // Reset email error message when email changes
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email validation logic
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    console.log("Email submitted:", email);
    resetPassword();
  };

  const resetPassword = async () => {
    try {
      setIsLoading(true);
      let res = await userResetPassword({ email: email });
      setIsLoading(false);
      if (res?.status === 200) {
        dispatch(
          setToast({
            showSnackBar: true,
            message:
              "A verification email has been sent. Please check your inbox",
            type: "green",
          })
        );
      }
    } catch (err) {
      setIsLoading(false);
      let statusCode = err?.response?.status;
      if (statusCode && statusCode === 400) {
        setEmailFieldError("No user found with this email");
      } else if (statusCode && statusCode === 429) {
        dispatch(
          setToast({
            showSnackBar: true,
            message: err?.response?.data.detail,
            type: "red",
          })
        );
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Please try again later",
            type: "red",
          })
        );
      }
    }
  };

  return (
    <Background>
      <CenterBox
        airisText="Forgot your password? Don't worry! Just enter your email address."
        maxWidth={516}
        style={{
          paddingTop: "72px",
          paddingBottom: "10px",
          paddingLeft: "53px",
          paddingRight: "53px",
        }}
      >
        <form className="form" onSubmit={handleSubmit}>
          <label htmlFor="email" className="label">
            Email Address:
          </label>
          <input
            type="email"
            id="email-input"
            placeholder="name@domain.com"
            value={email}
            onChange={handleEmailChange}
            className={`emailInput ${
              (emailError || emailFieldError) && "error"
            }`}
          />

          {emailError && <p className="fieldError">{emailError}</p>}
          {emailFieldError && <p className="fieldError">{emailFieldError}</p>}

          <button type="submit" disabled={isLoading} className="submitButton">
            Send Reset Link
          </button>
          <p className="backToLogin">
            <Link to="/CONTENT-PLATFORM/auth/login">Back to Login</Link>;
          </p>
        </form>
      </CenterBox>
    </Background>
  );
};

export default ForgetPasswordPage;
