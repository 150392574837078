import React, { useEffect, useState } from "react";
import "./Loader.css";

export const Loader = ({ className, onTimeout }) => {
  const vLeftLine = `${process.env.PUBLIC_URL}/assets/Loader-img/V-LeftLine.png`;
  const vConnector = `${process.env.PUBLIC_URL}/assets/Loader-img/V-Connector.png`;
  const vRightLine = `${process.env.PUBLIC_URL}/assets/Loader-img/V-RightLine.png`;

  const [showSpinner, setShowSpinner] = useState(false);
  const show = "";

  useEffect(() => {
    if (show !== "") {
      setShowSpinner(true);
      const timer = setTimeout(() => {
        setShowSpinner(false);
        if (onTimeout) {
          onTimeout(); // Call the callback function after timeout
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [show, onTimeout]);

  return (
    <>
      {showSpinner && (
        <div className="mainDiv">
          <div className={className}>
            <img
              className="vLeftLine"
              src={vLeftLine}
              width={60}
              alt="Left segment of Vosyn logo"
            />
            <img
              className="vConnector"
              src={vConnector}
              width={60}
              alt="Connector segment of Vosyn logo"
            />
            <img
              className="vRightLine"
              src={vRightLine}
              width={60}
              alt="Right segment of Vosyn logo"
            />
          </div>
        </div>
      )}
    </>
  );
};
