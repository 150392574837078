import React, { useState, useEffect } from "react";
import AirisBlob from "../../components/Airis/AirisBlob/AirisBlob";

import "./POCAiris.css";

const POCAiris = ({ onFinish }) => {
  const [page, setPage] = useState(0);
  const [animation, setAnimation] = useState(false);

  const handleNext = (x) => {
    if (x === 1) {
      // if (document.querySelector(".poc-airis"))
      //   document.querySelector(".poc-airis").className = "poc-airis animate";
      // if (document.querySelector(".typewriter"))
      //   document.querySelector(".typewriter").className =
      //     "typewriter animate-3";
      setAnimation(!animation);
      setTimeout(() => {
        setPage(page + 1);
      }, 1000);
    } else if (x === 2) {
      // document.querySelector(".poc-airis-2").className =
      //   "poc-airis-2 animate-2";
      // document.querySelector(".poc-airis-2-main").className =
      //   "poc-airis-2-main animate-3";
      setAnimation(!animation);
      setTimeout(() => {
        setPage(page + 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (page === 2) {
      setTimeout(() => {
        onFinish();
      }, 2000);
    }
  }, [page]);

  if (page === 0)
    return (
      <div className="poc-airis-container" onClick={() => handleNext(1)}>
        {/* May look off, replaced old Blob component (Blob still on AIRIS demo branch + old Blob styles in POCAiris.css) */}
        <AirisBlob size={300} roughness={3} glowSpread={30} />

        <div class="typewriter">
          <h1>Hi! I'm AIRIS</h1>
        </div>
      </div>
    );
  else if (page === 1)
    return (
      <div
        className="poc-airis-container-2"
        onClick={(e) => {
          let el = document.querySelector(".airis-2-span");
          if (e.target !== el && !el.contains(e.target)) {
            el.className = "airis-2-span glow";
          }
        }}
      >
        <div>
          {/* May look off, replaced old Blob component (Blob still on AIRIS demo branch + old Blob styles in POCAiris.css) */}
          <AirisBlob
            size={1300}
            roughness={1}
            glowSpread={120}
            animationEnabled={false}
          />
        </div>
        <div className="poc-airis-2-main">
          <div>
            <div class="typewriter">
              <h1>All types of content all over the world</h1>
            </div>
            <div className="airis-2-content">
              <h1>What videos are you looking for?</h1>
              <span onClick={() => handleNext(2)} className="airis-2-span">
                <input type="text" placeholder="Find anything..." />
                <i class="fa-solid fa-paper-plane"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  else if (page === 2)
    return (
      <div className="poc-airis-container" onClick={() => handleNext(1)}>
        {/* May look off, replaced old Blob component (Blob still on AIRIS demo branch + old Blob styles in POCAiris.css) */}
        <AirisBlob size={300} roughness={3} glowSpread={30} />
        <div>
          <h1>Finding the right one...</h1>
        </div>
      </div>
    );
};

export default POCAiris;
