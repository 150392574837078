import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHovering } from "../../../reducers/videoPlayerSlice";
import "./TimeLine.scoped.css";

const TimeLine = ({ mediaRef, showControls }) => {
  const dispatch = useDispatch();
  const currentTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);
  const duration = useSelector(({ videoPlayer }) => videoPlayer.duration);

  const timelineRef = useRef(null);
  const mouseMoveTimer = useRef(null);

  // Format seconds into "HH:MM:SS" or "MM:SS"
  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";

    const [hours, minutes, seconds] = [
      Math.floor(time / 3600),
      Math.floor((time % 3600) / 60),
      Math.floor(time % 60),
    ].map((unit) => String(unit).padStart(2, "0"));

    return hours !== "00"
      ? `${hours}:${minutes}:${seconds}`
      : `${minutes}:${seconds}`;
  };

  const handleTimelineClick = (e) => {
    const timeline = timelineRef.current;

    if (!timeline) {
      console.error("Error: Timeline element not found.");
      return;
    }

    const rect = timeline.getBoundingClientRect();

    // Calculate the percentage of the click relative to the timeline
    const percent = (e.clientX - rect.left) / rect.width;
    let newTime = percent * duration;

    // Ensure newTime is within valid bounds
    newTime = Math.min(Math.max(newTime, 0), duration);

    // Update the currentTime of the media element
    if (mediaRef.current && mediaRef.current.currentTime !== undefined) {
      mediaRef.current.currentTime = newTime;
    }
  };

  return (
    <div
      className="timeline-container"
      ref={timelineRef}
      onClick={handleTimelineClick}
      onMouseEnter={() => {
        clearTimeout(mouseMoveTimer.current);
        dispatch(setHovering(true));
      }}
      onMouseLeave={() => {
        dispatch(setHovering(false));
        showControls();
      }}
    >
      <div className="timeline">
        <div
          className="progress"
          style={{ width: `${(currentTime / duration) * 100}%` }}
        ></div>
      </div>
      <div className="time-display">{formatTime(currentTime)}</div>
    </div>
  );
};

export default TimeLine;
