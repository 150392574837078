import React from 'react'
import './Banner.scoped.css'

const Banner = () => {
  return (
    <div className="banner">
          <div className="banner-info">
            <div className="banner-info-text">

              <div className="banner-live-event">
                <i className="fa fa-circle text-danger"> </i>
                <span>&nbsp; LIVE EVENT</span>
              </div>
             
              

              <h1>Blade Runner (2017)</h1>
              <p>20 people waiting...</p>
              <p>Scheduled for 04/20/2024 | 12:00 pm</p>
              <button>SET REMINDER <i class="fa-regular fa-bell"></i></button>
            </div>
        </div>
    </div>
  )
}

export default Banner