import React from "react";
import "./VideoContainer.scoped.css";

/**
 * Component that wraps the video player and other content.
 */
const VideoContainer = ({ children, isCollapsed, isPortrait, isVisible }) => (
  <div
    className={`video-container ${isCollapsed ? "collapsed" : ""} ${
      isPortrait ? "portrait" : ""
    } ${!isVisible ? "invisible" : ""}`}
  >
    {children}
  </div>
);

export default VideoContainer;
