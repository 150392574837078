import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAirisDisplayed: false,
}

const airisSidebarSlice = createSlice({
    name: 'toggleSidebarSlice',
    initialState,
    reducers: {
        toggleAirisSidebar: (state) => {
            state.isAirisDisplayed = !state.isAirisDisplayed
        },
        showAirisSidebar: (state) => {
            state.isAirisDisplayed = true
        },
        hideAirisSidebar: (state) => {
            state.isAirisDisplayed = false
        }
    },
})

export const {
    toggleAirisSidebar,
    showAirisSidebar,
    hideAirisSidebar,
} = airisSidebarSlice.actions

export default airisSidebarSlice.reducer