import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import airisEnglish from "../constant/translations/english/airis.json";
import airisArabic from "../constant/translations/arabic/airis.json";
import airisFrench from "../constant/translations/french/airis.json";
import airisSpanish from "../constant/translations/spanish/airis.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      airis: airisEnglish,
    },
    ar: {
      airis: airisArabic,
    },
    fr: {
      airis: airisFrench,
    },
    es: {
      airis: airisSpanish,
    },
  },
  fallbackLng: "en",
});

export default i18n;
