// src/components/NavBar/NavBar.js
import React, { useEffect, useState } from "react";
// import "./NavigationMenu.css"; // Ensure your CSS file is set up
import "./NavigationMenu.scoped.css";
import useQueryParam from "../../hooks/useQueryParam";
import { Box, Button } from "@mui/material";

const NavigationMenu = () => {
  const { updateQueryParam, getAllParams } = useQueryParam();
  const params = getAllParams();
  const [selectedTab, setSelectedTab] = useState(params.tab || "featured");

  // when the user clicks on a tab, it should direct to that tab
  const handleSetSelectedTab = (tabName) => {
    updateQueryParam("tab", tabName.toLowerCase());
    setSelectedTab(tabName);
  };

  useEffect(() => {
    if (!params.tab) {
      updateQueryParam("tab", "featured");
      setSelectedTab("featured");
    }
  }, [params, updateQueryParam]);

  return (
    <Box className="container">
      <Box
        className="navbar"
        display="flex"
        justifyContent="space-between"
        sx={{ overflowX: "scroll" }}
      >
        <Box className="navbar-list">
          <Button
            className={`nav-item-btn ${selectedTab === "video" ? "active" : ""}`}
            onClick={() => handleSetSelectedTab("video")}
          >
            Video
          </Button>
          <Button
            className={`nav-item-btn ${selectedTab === "audio" ? "active" : ""}`}
            onClick={() => handleSetSelectedTab("audio")}
          >
            Audio
          </Button>
          <Button
            className={`nav-item-btn ${selectedTab === "featured" ? "active" : ""}`}
            onClick={() => handleSetSelectedTab("featured")}
          >
            Featured
          </Button>
          <Button
            className={`nav-item-btn ${selectedTab === "text" ? "active" : ""}`}
            onClick={() => handleSetSelectedTab("text")}
          >
            Text
          </Button>
          <Button
            className={`nav-item-btn ${selectedTab === "my channel" ? "active" : ""}`}
            onClick={() => handleSetSelectedTab("my channel")}
          >
            My Channel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationMenu;
