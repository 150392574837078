import React, { useEffect, useState } from 'react';
import './VideoCard.scoped.css';
import formatViewCount from "../../utils/formatViewCount";
import search from "./img/search.png"

const VideoCardEarth = ({pinClass, videoThumbnail, videoTitle, videoData, isDropdownOpen, onDropdownToggle}) => {
 const [selectedOption, setSelectedOption] = useState(null);
 const [selectedOptions, setSelectedOptions] = useState([]);
 const options = ['Cool Cars', 'Tech Innovations'];

 const handleSelect = (event, option) => {
  event.stopPropagation(); // Stop event propagation
  setSelectedOption(option);
  if (selectedOptions.includes(option)) {
     setSelectedOptions(selectedOptions.filter(opt => opt !== option));
  } else {
     setSelectedOptions([...selectedOptions, option]);
  }
};

 const CustomCheckbox = ({ label, checked, onChange }) => (
    <div className="custom-checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <label>{label}</label>
    </div>
 );

 function truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "..";
    } else {
      return title;
    }
 }

 return (
    <div className={pinClass} style={{position: 'relative'}}>
      <button className="close-button" >&times;</button>

      <div className="thumbnail-container">
        <img className="thumbnail-image" src={videoThumbnail ? videoThumbnail : "../assets/Unavailable.png"} alt={videoTitle} />
        <div className="overlay-text">2:21</div>
        <div className="play-button"><img className="PlayButton" src={'../assets/PlayButton.png'} alt="" /></div>
      </div>

      <div className="info">
        <img className="channel-img" src={videoData?.channelImg ? videoData?.channelImg : '../assets/ProfileUnavailable.png'} alt={videoData?.channelName}/>
        <div className="title">
          {videoData?.titles?.[0] ? truncateTitle(videoData.titles[0], 50) : 'Video Unavailable or Deleted'}
          <div className="description" > 
            <div className="channelname" >Vosyn </div>

            <div className="viewcount" >
              {videoData?.view_count ? formatViewCount(videoData.view_count) + ' views' : ''} | 2 months ago 
            </div>     
          </div>
        </div>

        <button className="follow-button" onClick={(event) => onDropdownToggle(event)}>
 <img className="playlist-img" src={'../assets/AddtoPlaylist.png'} alt={videoData?.channelName}/>
</button>
      </div>  

      {isDropdownOpen && 
        <div className="dropdown-menu">
          <div className="dropdown-heading" >Save To...
          <button className="close-dropdown-button" onClick={(event) => onDropdownToggle(event)}>
          <img className="close-dropdown" src={'../assets/PlaylistCloseButton.png'}/>
      </button> </div>
          <div className="search-container">
            <input className="search" type="text" placeholder="Search" />
            <img src={search} className="search-icon" />
          </div>
          {options.map((option, index) => (
    <CustomCheckbox
      key={index}
      label={option}
      onChange={(event) => handleSelect(event, option)} // Pass the event object here
    />
))}

          <button className="dropdown-end" >+ New Playlist</button>
        </div>
      }
    </div>
 )
}

export default VideoCardEarth;