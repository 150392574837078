import React from "react";
import { useSelector } from "react-redux";
import ReplyForm from "../ReplyForm/ReplyForm";
import ReplyList from "../ReplyList/ReplyList";
import { Box, Typography } from "@mui/material";
import "../../../../pages/VideoPage/VideoPage.scoped.css";

const Comment = ({
  comment,
  commentIndex,
  handleNewCommentLike,
  handleCommentReply,
  handleCommentReplySubmit,
}) => {
  const showCommentReplyField = useSelector(
    ({ videoComment }) => videoComment.showCommentReplyField
  );
  return (
    <Box key={comment.id} className="comments">
      <Box className="comment-item-avtar-wrapper">
        <Box
          className="comment-item-avtar"
          component="img"
          src={comment.image}
        />
      </Box>
      {/*<img src={comment.image} />*/}
      <Box className="column">
        <Box className="comment-title">
          <Box
            component="img"
            className="comment-title-country"
            src="/assets/flags/br.png"
          />
          <Typography component="h1" className="comment-title-user-name">
            @ {comment.name}
          </Typography>
          {/*<h2>{comment.date}</h2>*/}
        </Box>
        <Typography className="comment-content" component="p">
          {comment.comment}
        </Typography>
        {/*<div className="row no-flex-content">*/}
        {/*  <span*/}
        {/*    className="like"*/}
        {/*    onClick={() => handleNewCommentLike(commentIndex)}*/}
        {/*  >*/}
        {/*    <i className="fa-solid fa-thumbs-up"></i>*/}
        {/*    {comment.likes}*/}
        {/*  </span>*/}
        {/*  <span onClick={() => handleCommentReply(comment.id)}>*/}
        {/*    {showCommentReplyField[comment.id] ? "Cancel" : "Reply"}*/}
        {/*  </span>*/}
        {/*  <ReplyForm*/}
        {/*    comment={comment}*/}
        {/*    handleCommentReplySubmit={handleCommentReplySubmit}*/}
        {/*  />*/}

        {/*  /!* replies section *!/*/}
        {/*  /!* > greater than zero *!/*/}
        {/*  <ReplyList comment={comment} />*/}
        {/*</div>*/}
      </Box>
    </Box>
  );
};

export default Comment;
