import React, { useState, useEffect, useRef, useMemo } from "react";
import "./LanguageSelectorMenu.scoped.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ButtonBase from "@mui/material/ButtonBase";
import languages from "../../data/language.json";
import useDebounce from "../../hooks/useDebounce";

export default function LanguageSelectorMenu({
  hideLanguageSelector,
  setCurrentLanguage,
  currentLanguage,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const textFieldRef = useRef(null);

  const handleLanguageSelection = (language) => {
    setSelectedLanguage(language.display_name);
    setCurrentLanguage(language.display_name);
    hideLanguageSelector();
  };

  const filteredLanguages = useMemo(() => {
    return languages.filter((language) =>
      language.display_name
        .toLowerCase()
        .includes(debouncedSearchQuery.toLowerCase())
    );
  }, [debouncedSearchQuery]);

  // Focus on search input upon showing language selector menu
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  return (
    <>
      <Box className="language-selector-container">
        <Box className="language-list-container">
          <Box className="langauage-list-container-top">
            <Box className="list-container-top-header">
              <Typography
                className="selector-button"
                variant="button"
                gutterBottom
                sx={{ display: "block" }}
              >
                Select language
              </Typography>
              <KeyboardArrowUpIcon className="arrow-icon" />
            </Box>
            <Box className="searchbar">
              <TextField
                id="input-with-icon-textfield"
                variant="outlined"
                className="language-textfield"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                inputRef={textFieldRef}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      border: "none",
                    },
                    height: 36,
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "6px 12px",
                  },
                }}
              />
            </Box>
          </Box>
          <Box className="language-list-container-bottom">
            <Box className="list-container">
              <List className="language-list">
                {filteredLanguages.map((language) => (
                  <ButtonBase
                    key={language.value}
                    className={`list-item ${
                      selectedLanguage === language.display_name
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handleLanguageSelection(language)}
                    disableRipple
                  >
                    <ListItem button>
                      <ListItemText primary={language.display_name} />
                    </ListItem>
                  </ButtonBase>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
