import React from "react";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import "./PlayPauseButton.scoped.css";
import { useSelector } from "react-redux";

const PlayPauseButton = ({ togglePlayPause }) => {
  const playing = useSelector(({ videoPlayer }) => videoPlayer.playing);

  return (
    <div className="playback-pause-btn-control" onClick={togglePlayPause}>
      {playing ? (
        <ToolTip content="Pause">
          <MuiIcon name="Pause" />
        </ToolTip>
      ) : (
        <ToolTip content="Play">
          <MuiIcon name="PlayArrowOutlined" />
        </ToolTip>
      )}
    </div>
  );
};
export default PlayPauseButton;
