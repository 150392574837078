import React, { useEffect, useState } from "react";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import VideoCards from "../../components/VideoCards/VideoCards";
import POCAiris from "./POCAiris";
import data from "../../data/videos.json";
import axios from "axios";
import { useDeviceType } from "../../Utils";
import newVideoData from "../../data/videos-new.json";
import "./POC.css";

const POCPage = () => {
  const [showCards, setShowCards] = useState(true);
  const [video, setVideo] = useState(data[3]);
  const [videos, setVideos] = useState(data.slice(0, 3));
  const [introFinished, setIntroFinished] = useState(false);
  const isMobile = useDeviceType();

  const handleIconClick = () => {
    setShowCards(!showCards);
  };

  useEffect(() => {
    if (sessionStorage.introFinished) setIntroFinished(true);
  }, [sessionStorage]);

  const [newVideos, setNewVideos] = useState([]);

  useEffect(() => {
    const initialVideos = newVideoData.results.slice(0, 10);
    setNewVideos(initialVideos);
  }, [newVideoData]);

  const handleLoadMore = () => {
    const nextVideos = newVideoData.results.slice(
      newVideos.length,
      newVideos.length + 10
    ); // Load 8 more items
    setNewVideos((prevVideos) => [...prevVideos, ...nextVideos]);
  };

  const cards = (
    <div className="poc-cards">
      <h1>Your Curated Playlist: 5 videos</h1>
      <h2>Show me popular videos I would like!</h2>
      <span>
        <VideoCards
          className="poc-card"
          videos={videos}
          setVideos={(e) => setVideos(e)}
          setVideo={(e) => setVideo(e)}
          video={video}
          loadMore={handleLoadMore}
        />
      </span>
    </div>
  );

  if (!introFinished)
    return (
      <POCAiris
        onFinish={() => {
          sessionStorage.setItem("introFinished", true);
        }}
      />
    );

  return (
    <div className="poc-container">
      <div className="poc-main">
        <div className="poc-header">
          {!isMobile && (
            <i class="fa-solid fa-list" onClick={handleIconClick}></i>
          )}

          <img
            src={process.env.PUBLIC_URL + "/assets/vosyn_logo_long.png"}
            alt="Vosyn Logo"
            onClick={() =>
              (window.location.href = process.env.PUBLIC_URL + "/home")
            }
          />
        </div>
        {showCards && !isMobile && cards}
      </div>

      <div className={`poc-player ${!showCards ? "full" : "half"}`}>
        <VideoPlayer video={video} key={video.videoId} />
      </div>
      {isMobile && cards}
    </div>
  );
};

export default POCPage;
