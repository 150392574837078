import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Typography, Box, Divider, styled } from "@mui/material";
import Flags from 'react-world-flags'; // Import all flags
import './AccountLanguageSettings.scoped.css';
import { updateAccountLanguage, getAccountLanguage } from "../../api/account.js";
import { Widgets } from "@mui/icons-material";

// Language data with associated country codes
const languages = [
  { label: "German", code: "de" },
  { label: "Japanese", code: "jp" },
  { label: "English", code: "us" },
  { label: "French", code: "fr" },
];

const getFlagComponent = (code, className) => {
  return code ? <Flags className={`flags-select ${className}`} code={code} /> : null;
};


const AccountLanguageSettingsPage = () => {

  const [allLanguages, setAllLanguages] = useState({
    display_language: "de",
    subtitle_language: "jp",
    dubbing_language: "fr",
    prefered_secondary_language: "de",
  });


  useEffect(() => {
    const fetchData = async () => {
      const data = await getAccountLanguage();
      console.log(data);
      if(!data) return;
      setAllLanguages(data);
    };

    fetchData();
  }, []);

  const handleLanguageChange = (settingType) => async (event) => {
    const newValue = event.target.value;
    const languageCode = languages.find((language) => language.label === newValue).code.toLowerCase();
   

    const updatedAccountLanguages = { ...allLanguages, [settingType]: languageCode };
    setAllLanguages(updatedAccountLanguages);

    await updateAccountLanguage(updatedAccountLanguages);
  };


  const StyledSelect = styled(Select)(({ theme }) => ({
    height: '30%',
    marginLeft: '70%',
    padding: 0,
    backgroundColor: '#f2f2f0',
    border: 'none',
  }));

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 800,
        width: '10px',
      },
    },
  };

  return (
    <Box className="language-settings-container">
      <Typography variant="h1" className="language-title" aria-label="Language">Language</Typography>
      <Typography variant="subtitle1" className="language-subtitle" aria-label="Language settings">Language settings</Typography>

      <Box className="language-setting-section">
        {/* Display Language Setting */}
        <Box className="setting inline-setting">
          <Box className="text-area">
            <Typography variant="h6" className="language-label">Display language</Typography>
            <Typography variant="subtitle1" className="language-description">
              The text you can see on VosynVerse platform
            </Typography>
          </Box>
          <FormControl className="language-select">
            <StyledSelect
              value={languages.find(lang => lang.code === allLanguages.display_language).label} 
              inputProps={{ 'aria-label': 'Display language select' }}
              onChange={handleLanguageChange('display_language')}
              MenuProps={menuProps}
              renderValue={(value) => {
                const selectedLanguage = languages.find(lang => lang.label === value);
                return (
                  <>
                    {getFlagComponent(selectedLanguage?.code, 'selected-option')} 
                  </>
                );
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.label} value={lang.label}>
                     {getFlagComponent(lang.code, 'select-options')} 
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>

        {/* Subtitle Language Setting */}
        <Box className="setting inline-setting">
          <Box className="text-area">
            <Typography variant="h6" className="language-label">Subtitle language</Typography>
            <Typography variant="subtitle1" className="language-description">
              The written text translating spoken words in a video
            </Typography>
          </Box>
          <FormControl className="language-select">
            <StyledSelect
              value={languages.find(lang => lang.code === allLanguages.subtitle_language).label}
              inputProps={{ 'aria-label': 'Subtitle language select' }}
              onChange={handleLanguageChange( 'subtitle_language')}
              MenuProps={menuProps}
              renderValue={(value) => {
                const selectedLanguage = languages.find(lang => lang.label === value);
                return (
                  <>
                    {getFlagComponent(selectedLanguage?.code, 'selected-option')} 
                  </>
                );
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.label} value={lang.label}>
                     {getFlagComponent(lang.code, 'select-options')} 
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>

        {/* Dubbing Language Setting */}
        <Box className="setting inline-setting">
          <Box className="text-area">
            <Typography variant="h6" className="language-label">Dubbing language</Typography>
            <Typography variant="subtitle1" className="language-description">
              The language used to replace original speech in films or shows
            </Typography>
          </Box>
          <FormControl className="language-select">
            <StyledSelect
              value={languages.find(lang => lang.code === allLanguages.dubbing_language).label}
              inputProps={{ 'aria-label': 'Dubbing language select' }}
              onChange={handleLanguageChange('dubbing_language')}
              MenuProps={menuProps}
              renderValue={(value) => {
                const selectedLanguage = languages.find(lang => lang.label === value);
                return (
                  <>
                    {getFlagComponent(selectedLanguage?.code, 'selected-option')} 
                  </>
                );
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.label} value={lang.label}>
                     {getFlagComponent(lang.code, 'select-options')} 
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>

        <Divider className="divider" />

        {/* Secondary Language Setting */}
        <Box className="setting inline-setting">
          <Box className="text-area">
            <Typography variant="h6" className="language-label">
              Set your preferred secondary languages.
            </Typography>
            <Typography variant="subtitle1" className="language-description">
              brief intro of the section
            </Typography>
          </Box>
          <FormControl className="language-select">
            <StyledSelect
              value={languages.find(lang => lang.code === allLanguages.prefered_secondary_language).label}
              inputProps={{ 'aria-label': 'Secondary language select' }}
              onChange={handleLanguageChange( 'prefered_secondary_language')}
              MenuProps={menuProps}
              renderValue={(value) => {
                const selectedLanguage = languages.find(lang => lang.label === value);
                return (
                  <>
                    {getFlagComponent(selectedLanguage?.code, 'selected-option')} 
                  </>
                );
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.label} value={lang.label}>
                     {getFlagComponent(lang.code, 'select-options')} 
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountLanguageSettingsPage;
