import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audioURL: "",
  title: "",
  id: 1,
  description: ""
};

const audioObjectSlice = createSlice({
  name: "audioObject",
  initialState,
  reducers: {
    setAudioObject: (state, action) => {
      return {
        ...state,
        audioURL: action.payload.audioURL,
        title: action.payload.title,
        id: action.payload.id,
        description: action.payload.description,
      };
    },
  },
});

export const { setAudioObject } = audioObjectSlice.actions;
export default audioObjectSlice.reducer;
