import axios from "axios";

const airis_chat = `${process.env.REACT_APP_AIRIS_URL}/chat`;
const language_detect = `${process.env.REACT_APP_LANGUAGE_DETECTION_URL}/predict`;

export const airisChat = async (data) => {
  try {
    let res = await axios.post(airis_chat, { input: data });
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchYoutubeMetadata = async (url) => {
  try {
    let res = await axios.get(
      `https://noembed.com/embed?dataType=json&url=${url}`
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const languageDetection = async (text) => {
  try {
    let res = await axios.post(language_detect, { text: text });
    return res;
  } catch (err) {
    throw err;
  }
};
