import axios from "axios";
import {
  USER_PROFILE,
  PROFILE_UPDATE_URL,
  CHANGE_PASSWORD,
} from "../constant/constantUrls";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const userProfile = async () => {
  try {
    const res = await axiosInstance.get(USER_PROFILE);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userProfileUpdate = async (data) => {
  try {
    const res = await axiosInstance.put(PROFILE_UPDATE_URL, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userPasswordUpdate = async (data) => {
  try {
    const res = await axiosInstance.post(CHANGE_PASSWORD, data);
    return res;
  } catch (err) {
    throw err;
  }
};
