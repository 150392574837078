import { useEffect, useRef, useState } from "react";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import { Slider, Menu, MenuItem, IconButton } from "@mui/material";
import "./VolumeSlider.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import { setVolume } from "../../../reducers/videoPlayerSlice";

const VolumeSlider = ({ mediaRef }) => {
  const dispatch = useDispatch();
  const prevVolumeRef = useRef(1);

  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const volume = useSelector(({ videoPlayer }) => videoPlayer.volume);

  const volumeContainerRef = useRef(null);
  const sliderTimeout = useRef(null);

  const iconStyle = {
    cursor: "pointer",
  };

  const paperPropsStyle = {
    backgroundColor: "darkgrey",
    borderRadius: "30px",
    padding: "10px",
    width: "50px",
    zIndex: 9999,
    position: "absolute",
    left: "auto",
    right: "0",
    top: "-50px",
    bottom: "auto",
    marginTop: "-40px",
  };

  const sliderStyle = {
    height: 100,
    "& .MuiSlider-thumb": {
      backgroundColor: "lightgrey",
    },
    "& .MuiSlider-track": {
      backgroundColor: "lightgrey",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "white",
    },
  };

  const iconButtonStyle = {
    backgroundColor: "lightgrey",
    padding: "8px",
    borderRadius: "50%",
  };

  // Update volume based on the slider value
  const handleVolumeChange = (e, newVolume) => {
    // const newVolume = e.target.value;
    dispatch(setVolume(newVolume));
    mediaRef.current.volume = newVolume;

    // Clear any existing timeout when user is interacting with the slider
    if (sliderTimeout.current) {
      clearTimeout(sliderTimeout.current);
    }

    // Hide the slider after 2 seconds of inactivity
    sliderTimeout.current = setTimeout(() => {
      setShowVolumeSlider(false);
    }, 2000);
  };

  const handleVolumeSliderVisibility = () => {
    setShowVolumeSlider(true);

    if (sliderTimeout.current) {
      clearTimeout(sliderTimeout.current);
    }
    sliderTimeout.current = setTimeout(() => {
      setShowVolumeSlider(false);
    }, 2000);
  };

  const handleMenuClose = () => {
    sliderTimeout.current = setTimeout(() => {
      setShowVolumeSlider(false);
    }, 2000);
  };
  // Set volume to 0 if currently unmuted, restore previous volume if muted
  const toggleMuteUnmute = () => {
    const currentVolume = mediaRef.current.volume;

    if (currentVolume !== 0) {
      prevVolumeRef.current = currentVolume;
      mediaRef.current.volume = 0;
    } else {
      mediaRef.current.volume = prevVolumeRef.current;
    }
    dispatch(setVolume(mediaRef.current.volume));
  };

  const toggleVolume = () => {
    showVolumeSlider ? toggleMuteUnmute() : setShowVolumeSlider(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`); // Log the pressed key
      const activeElement = document.activeElement;
      const video = mediaRef.current;

      // Ignore key events if the focus is on an input field (excluding range inputs)
      if (
        activeElement.tagName.toLowerCase() === "input" &&
        activeElement.type !== "range"
      ) {
        return;
      }

      if (!video) return;

      switch (event.key) {
        case "m": // Mute/unmute
          event.preventDefault(); // Prevent default behavior
          toggleMuteUnmute?.();
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleMuteUnmute]);

  return (
    <div className="volume-container" ref={volumeContainerRef}>
      {volume === 0 ? (
        <ToolTip content="mute">
          <MuiIcon
            name="VolumeOff"
            onMouseOver={handleVolumeSliderVisibility}
            onClick={toggleVolume}
            style={iconStyle}
          />
        </ToolTip>
      ) : (
        <ToolTip content="volume">
          <MuiIcon
            name="VolumeUp"
            onMouseOver={handleVolumeSliderVisibility}
            onClick={toggleVolume}
            style={iconStyle}
          />
        </ToolTip>
      )}

      {/* Volume Menu */}
      {showVolumeSlider && (
        <Menu
          anchorEl={volumeContainerRef.current}
          container={isFullScreen ? volumeContainerRef.current : document.body}
          open={Boolean(showVolumeSlider)}
          onClose={() => setShowVolumeSlider(false)}
          PaperProps={{ sx: paperPropsStyle }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          disableScrollLock
        >
          <MenuItem
            disableGutters
            sx={{ justifyContent: "center" }}
            onMouseEnter={() => clearTimeout(sliderTimeout.current)}
            onMouseLeave={handleMenuClose}
          >
            <Slider
              orientation="vertical"
              value={volume}
              min={0}
              max={1}
              step={0.05}
              onChange={handleVolumeChange}
              sx={sliderStyle}
            />
          </MenuItem>

          <MenuItem
            disableGutters
            sx={{ justifyContent: "center" }}
            onMouseEnter={() => clearTimeout(sliderTimeout.current)}
            onMouseLeave={handleMenuClose}
          >
            <IconButton onClick={toggleMuteUnmute} sx={iconButtonStyle}>
              <MuiIcon name={volume === 0 ? "VolumeOff" : "VolumeUp"} />
            </IconButton>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default VolumeSlider;
