import React from "react";
import { FaCircleCheck, FaRegCircle } from "react-icons/fa6";
import "./ProfileSetupYN.scoped.css";
import { useDispatch } from "react-redux";
import { skipToStage } from "../../../../reducers/userPrefReducer";

const PofileSetupYN = ({ profileCompletion, numberOfCompleted }) => {
  const dispatch = useDispatch();

  return (
    <div className="box">
      <div className="box-content">
        <div className="box-header">
          <div className="box-title">
            <h3>{"Profile setup"}</h3>
            <p>Please provide some details to get to know you better.</p>
          </div>
          <div className="progress-header">
            <p>{numberOfCompleted} / 5 tasks completed</p>
            <div className="progress-container">
              <div className="progress-step done" />
              <div
                className={`progress-step ${
                  profileCompletion.language && "done"
                }`}
              />
              <div
                className={`progress-step ${
                  profileCompletion.location && "done"
                }`}
              />
              <div
                className={`progress-step ${
                  profileCompletion.gender && "done"
                }`}
              />
              <div className="progress-step" />
            </div>
          </div>
        </div>
        <div className="progress-checklist">
          <div className="list-step">
            <FaCircleCheck color="#008A00" size="24px" />
            <p className="item-crossed">Creating an account</p>
          </div>
          <div className="list-step">
            {profileCompletion.language ? (
              <FaCircleCheck color="#008A00" size="24px" />
            ) : (
              <FaRegCircle size="24px" />
            )}
            <p className={profileCompletion.language && "item-crossed"}>
              Language
            </p>
          </div>
          <div className="list-step">
            {profileCompletion.location ? (
              <FaCircleCheck color="#008A00" size="24px" />
            ) : (
              <FaRegCircle size="24px" />
            )}
            <p className={profileCompletion.location && "item-crossed"}>
              Location
            </p>
          </div>
          <div className="list-step">
            {profileCompletion.gender ? (
              <FaCircleCheck color="#008A00" size="24px" />
            ) : (
              <FaRegCircle size="24px" />
            )}
            <p className={profileCompletion.gender && "item-crossed"}>
              Birthdate & Gender
            </p>
          </div>
          <div className="list-step">
            <FaRegCircle size="24px" />
            <p>Topics</p>
          </div>
        </div>
      </div>
      <div className="footer-buttons">
        <button
          className="save-button"
          onClick={() => {
            dispatch(skipToStage(16));
          }}
        >
          Not now
        </button>
        <button
          className="continue-button"
          onClick={() => {
            dispatch(skipToStage(numberOfCompleted + 11));
          }}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default PofileSetupYN;
