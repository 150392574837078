import {
  Box,
  Typography,
  FormControl,
  Input,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "../PageControl/PageControl.scoped.css";
import { useState } from "react";

function PageControl({
  handleNextPage,
  handlePrevPage,
  currentPage,
  setCurrentPage,
  pageRefs,
  pageLength,
  setFontSize,
  fontSize,
  searchText,
}) {
  const [openPageTextInput, setOpenPageTextInput] = useState(false);
  const [openSearchTextInput, setOpenSearchTextInput] = useState(false);
  const [inputPageNumber, setInputPageNumber] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState(null);
  function openTextInput() {
    setOpenPageTextInput((prev) => {
      return !prev;
    });
  }

  function openSearchInput() {
    setOpenSearchTextInput((prev) => {
      return !prev;
    });
  }
  const { title, summary, paragraph, subtitle } = fontSize;
  function zoomIn() {
    setFontSize((font) => {
      return {
        ...font,
        title: title + 1,
        summary: summary + 1,
        paragraph: paragraph + 1,
        subtitle: subtitle + 1,
      };
    });
  }
  function zoomOut() {
    setFontSize((font) => {
      return {
        ...font,
        title: title - 1,
        summary: summary - 1,
        paragraph: paragraph - 1,
        subtitle: subtitle - 1,
      };
    });
  }

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSearchInputValue(value);

    searchText(value)
  };


  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setInputPageNumber(value);

    if (value < 1) {
      setCurrentPage(currentPage);
    } else if (value > pageLength) {
      setCurrentPage(pageLength);
      pageRefs.current[
        Math.max(0, Math.min(value - 1, pageLength - 1))
      ].scrollIntoView({ behavior: "smooth" });
    } else {
      setCurrentPage(value);
      pageRefs.current[
        Math.max(0, Math.min(value - 1, pageLength - 1))
      ].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box className="page-control-main-div">
      <Box className="pagination-div">
        <ArrowCircleUpRoundedIcon
          className="pageControlIcon"
          onClick={handlePrevPage}
        />

        <SearchRoundedIcon
          className="pageControlIcon"
          onClick={openSearchInput}
        />
        <Typography onClick={openTextInput}>
          {currentPage}/{pageLength}
        </Typography>
        <ArrowCircleDownRoundedIcon
          className="pageControlIcon"
          onClick={handleNextPage}
        />
        <ZoomInRoundedIcon onClick={zoomIn} className="pageControlIcon" />
        <ZoomOutRoundedIcon onClick={zoomOut} className="pageControlIcon" />
        <MenuRoundedIcon className="pageControlIcon" />
      </Box>

      {openPageTextInput == true ? (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
            display: "flex",
            justifyContent: "center",
          }}
          noValidate
          autoComplete="off"
          className="input-pop-up-div"
        >
          <SearchRoundedIcon className="pageControlIcon pageSearchIcon" />
          <Input
            type="number"
            placeholder="Skip to a page"
            className="input"
            value={inputPageNumber}
            defaultValue={currentPage}
            min={1}
            max={pageLength}
            sx={{
              fontSize: "12px",
              padding: "0px",
              color: "var(--mui-palette-primary-contrastText)",
              "&::before": {
                borderBottom: "none", // Remove default underline
              },
              "&::after": {
                borderBottom: "none", // Ensure no underline after focus
              },
              "&:hover:not(.Mui-disabled)::before": {
                borderBottom: "none", // Remove underline on hover
              },
            }}
            onChange={handleChange}
            inputProps={{ min: 1, max: pageLength }}
          />
        </Box>
      ) : (
        ""
      )}

      {openSearchTextInput == true ? (
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
            display: "flex",
            justifyContent: "center",
          }}
          noValidate
          autoComplete="off"
          className="input-pop-up-div search"
        >
          <SearchRoundedIcon className="pageControlIcon pageSearchIcon" />
          <Input
            type="text"
            placeholder="Search a word"
            className="input search"
            value={searchInputValue}
            sx={{
              fontSize: "12px",
              padding: "0px",
              color: "var(--mui-palette-primary-contrastText)",
              "&::before": {
                borderBottom: "none", // Remove default underline
              },
              "&::after": {
                borderBottom: "none", // Ensure no underline after focus
              },
              "&:hover:not(.Mui-disabled)::before": {
                borderBottom: "none", // Remove underline on hover
              },
            }}
            onChange={handleSearchTextChange}
          />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default PageControl;
