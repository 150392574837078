const AllResultText = ({ videos }) => {
    return (
      <div className="playlists-result-status-text">
        <p style={{ textAlign: "center" }}>
          <b>
            {videos.length === 0
              ? "Oops! We couldn't find a related video"
              : "Yay! You have seen it all"}
          </b>
        </p>
      </div>
    );
  };
export default AllResultText;