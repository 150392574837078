import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import CarouselComponent from "../../Carousel/CarouselComponent";
import { useNavigate } from "react-router-dom";
import AudioCards from "../../Audio/AudioCards";

const audioDataTemp = [
  {
    id: 1,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 2,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 2",
    description: "This is the description for Audio 2.",
  },
  {
    id: 3,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 3",
    description: "This is the description for Audio 3.",
  },
  {
    id: 4,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Audio 4",
    description: "This is the description for Audio 4.",
  },
  {
    id: 5,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 5",
    description: "This is the description for Audio 5.",
  },
  {
    id: 6,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 6",
    description: "This is the description for Audio 6.",
  },
  {
    id: 7,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Audio 7",
    description: "This is the description for Audio 7.",
  },
  {
    id: 8,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Audio 8",
    description: "This is the description for Audio 8.",
  },
  {
    id: 9,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Audio 9",
    description: "This is the description for Audio 9.",
  },
  {
    id: 10,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Audio 10",
    description: "This is the description for Audio 10.",
  },
];

const AudioTab = () => {
  const navigate = useNavigate();
  const handleOnClick = (id) => {
    navigate(`/CONTENT-PLATFORM/audio/${id}`);
  };
  const [cardWidth, setCardWidth] = useState(350);
  const [titleFontSize, setTitleFontSize] = useState(20);
  const [descriptionFontSize, setDescriptionFontSize] = useState(15);
  const [firstRowMarginLeft, setFirstRowMarginLeft] = useState("0px");
  const [secondRowMarginLeft, setSecondRowMarginLeft] = useState("0px");
  const [subsequentRowMarginLeft, setSubsequentRowMarginLeft] = useState("0px");

  useEffect(() => {
    // Set up initial size and margin-left based on screen width
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      setCardWidth(400);
      setTitleFontSize(25);
      setDescriptionFontSize(18);
      setFirstRowMarginLeft("50px");
      setSecondRowMarginLeft("50px");
      setSubsequentRowMarginLeft("0px");
    } else if (screenWidth >= 1200) {
      setCardWidth(310);
      setTitleFontSize(18);
      setDescriptionFontSize(13);
      setFirstRowMarginLeft("-0.5px");
      setSecondRowMarginLeft("-0.5px");
      setSubsequentRowMarginLeft("0px");
    } else if (screenWidth >= 992) {
      setCardWidth(230);
      setTitleFontSize(10);
      setDescriptionFontSize(8.5);
      setFirstRowMarginLeft("0px");
      setSecondRowMarginLeft("0px"); // Adjust this margin for second row
      setSubsequentRowMarginLeft("12px");
    } else if (screenWidth >= 768) {
      setCardWidth(200);
      setTitleFontSize(16);
      setDescriptionFontSize(12);
      setFirstRowMarginLeft("0px");
      setSecondRowMarginLeft("10px"); // Adjust this margin for second row
      setSubsequentRowMarginLeft("10px");
    } else {
      setCardWidth(220);
      setTitleFontSize(14);
      setDescriptionFontSize(11);
      setFirstRowMarginLeft("0px");
      setSecondRowMarginLeft("5px"); // Adjust this margin for second row
      setSubsequentRowMarginLeft("5px");
    }
  };

  return (
    <Box sx={{ px: "4vw", py: "5vh" }}>
      <Grid2 container spacing={3}>
        {/* Carousel and First Row with Two Cards */}
        <Grid2
          item
          xs={12}
          container
          spacing={3}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          {/* Carousel on the left */}
          <Box
            sx={{
              flex: 1,
              maxWidth: "350px", // Adjust carousel width
              height: "250px",
              p: 2,
            }}
          >
            <CarouselComponent />
          </Box>

          {/* First two rows with 2 audio cards */}
          <Box sx={{ flex: 2, p: 2 }}>
            <Grid2 container spacing={4} sx={{ justifyContent: "flex-start" }}>
              {/* Row 1: 2 Cards */}
              {audioDataTemp.slice(0, 2).map((audio) => (
                <Grid2
                  item
                  key={audio.id}
                  xs={6}
                  sx={{ ml: firstRowMarginLeft }}
                >
                  <AudioCards
                    image={audio.image}
                    title={audio.title}
                    description={audio.description}
                    cardWidth={cardWidth}
                    titleFontSize={titleFontSize}
                    descriptionFontSize={descriptionFontSize}
                    onClick={() => handleOnClick(audio.id)}
                  />
                </Grid2>
              ))}

              {/* Row 2: 2 Cards */}
              {audioDataTemp.slice(2, 4).map((audio) => (
                <Grid2
                  item
                  key={audio.id}
                  xs={6}
                  sx={{ ml: secondRowMarginLeft }}
                >
                  <AudioCards
                    image={audio.image}
                    title={audio.title}
                    description={audio.description}
                    cardWidth={cardWidth}
                    titleFontSize={titleFontSize}
                    descriptionFontSize={descriptionFontSize}
                    onClick={() => handleOnClick(audio.id)}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Box>
        </Grid2>

        {/* Below the carousel: Rows with 3 Cards per row */}
        <Grid2
          container
          spacing={7}
          sx={{ mt: 2, justifyContent: "flex-start" }}
        >
          {audioDataTemp.slice(4).map((audio) => (
            <Grid2
              item
              key={audio.id}
              xs={12}
              sm={6}
              md={4}
              sx={{ ml: subsequentRowMarginLeft }}
            >
              <AudioCards
                image={audio.image}
                title={audio.title}
                description={audio.description}
                cardWidth={cardWidth}
                titleFontSize={titleFontSize}
                descriptionFontSize={descriptionFontSize}
                onClick={() => handleOnClick(audio.id)}
              />
            </Grid2>
          ))}
        </Grid2>
        {audioDataTemp.map((audio) => (
          <Grid2 size={1}>
            <AudioCards
              image={audio.image}
              title={audio.title}
              description={audio.description}
              onClick={() => handleOnClick(audio.id)}
            />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default AudioTab;
