import React from "react";
import "../../../pages/VideoPage/VideoPage.scoped.css";
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';

const VideoDescDetails = () => {
  const dashVideoObject = useSelector((state) => state.dashVideoObject);
  console.log(dashVideoObject);

  const formatIsoDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long", // Display full month name
      day: "numeric", // Display day of the month
    });
    return formatter.format(date);
  };

  return (
    <>
      <Typography variant="h1" >{dashVideoObject?.titles[0]?.title_text}</Typography>
      <Typography variant="h2" >
        {dashVideoObject.view_count} views |{" "}
        {formatIsoDate(dashVideoObject.created_at)}
      </Typography>
    </>
  );
};

export default VideoDescDetails;
