import React, { useState, useEffect } from "react";
import "./AirisChatBox.scoped.css";

const AirisChatBox = ({ conversations }) => {
  const [messageIndexes, setMessageIndexes] = useState([]);

  useEffect(() => {
    setMessageIndexes(conversations.map(() => 0));
  }, [conversations]);

  useEffect(() => {
    const timers = conversations.map((conversation, index) => {
      let messageIndex = 0;
      return setInterval(() => {
        setMessageIndexes((prevIndexes) => {
          const nextIndexes = [...prevIndexes];
          if (messageIndex >= conversation.length) {
            clearInterval(timers[index]);
            if (index === conversations.length - 1) {
              setTimeout(() => {
                setMessageIndexes(conversations.map(() => 0));
                startConversationTimers();
              }, 0);
            }
          } else {
            nextIndexes[index] = messageIndex + 1;
            messageIndex++;
          }
          return nextIndexes;
        });
      }, 1100);
    });

    const startConversationTimers = () => {
      timers.forEach((timer, index) => {
        const conversation = conversations[index];
        timers[index] = setInterval(() => {
          setMessageIndexes((prevIndexes) => {
            const nextIndexes = [...prevIndexes];
            nextIndexes[index] =
              nextIndexes[index] >= conversation.length
                ? 0
                : nextIndexes[index] + 1;
            return nextIndexes;
          });
        }, 1100);
      });
    };

    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, [conversations]);

  return (
    <div className="airis-chat-box">
      {conversations.map((conversation, index) => {
        return (
          <div key={index} className="conversation">
            {conversation.slice(0, messageIndexes[index]).map(
              (message, i) =>
                message !== null && (
                  <div
                    key={i}
                    className={
                      message.text === ""
                        ? "clear"
                        : `message ${
                            message.align === "right" ? "right" : "left"
                          }`
                    }
                  >
                    {message.text.split("\n").map((line, lineIndex) => (
                      <>
                        {line}
                        <br key={lineIndex} />
                      </>
                    ))}
                  </div>
                )
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AirisChatBox;
