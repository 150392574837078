import React, { useState, useEffect } from "react";
import useQueryParam from "../../../hooks/useQueryParam";
import "./PlaylistHeader.scoped.css";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

const PlaylistHeader = () => {
  const { updateQueryParam, getAllParams } = useQueryParam();

  const params = getAllParams();

  const [activeTab, setActiveTab] = useState(params.tab || "all"); // Initially, no active tab
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleTabClick = (tabName) => {
    updateQueryParam("tab", tabName.toLowerCase());
    setActiveTab(tabName.toLowerCase()); // Set the clicked tab as active
  };

  useEffect(() => {
    if (!params.tab) {
      updateQueryParam("tab", "all");
    }
  }, [params, updateQueryParam]);

  //variables to handle the count on the tabs

  const allContent = 20;
  const downloadedContent = 7;
  const bookmarkedContent = 6;
  const playlistContent = 7;

  const [open, setOpen] = useState(false); // State for modal visibility
  const [type, setType] = useState("");
  const [language, setLanguage] = useState("");
  const [date, setDate] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setIsMenuOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
    setIsMenuOpen(false);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (name === "type") setType(value);
    if (name === "language") setLanguage(value);
    if (name === "date") setDate(value);
  };

  return (
    <Box className="playlistHeader">
      <Box className="container">
        <Box className="playlist-title">
          <Typography variant="h2">Saved Content</Typography>
        </Box>
        <Box
          className="navbar"
          display="flex"
          justifyContent="space-between"
          sx={{ overflowX: "scroll" }}
        >
          <Box className="navbar-list">
            <Button
              className={`nav-item-btn ${activeTab === "all" ? "active" : ""}`}
              onClick={() => handleTabClick("all")}
              disableTouchRipple
            >
              {`All (${allContent})`}
            </Button>
            <Button
              className={`nav-item-btn ${activeTab === "bookmarks" ? "active" : ""}`}
              onClick={() => handleTabClick("bookmarks")}
              disableTouchRipple
            >
              {`Bookmarks (${bookmarkedContent})`}
            </Button>
            <Button
              className={`nav-item-btn ${activeTab === "downloads" ? "active" : ""}`}
              onClick={() => handleTabClick("downloads")}
              disableTouchRipple
            >
              {`Downloads (${downloadedContent})`}
            </Button>
            <Button
              className={`nav-item-btn ${activeTab === "playlists" ? "active" : ""}`}
              onClick={() => handleTabClick("playlists")}
              disableTouchRipple
            >
              {`Playlists (${playlistContent})`}
            </Button>
            <TextField
              className="playlist-search"
              type="text"
              variant="standard"
              sx={{ input: { color: "white" } }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button className="search-icon">
                        <SearchOutlinedIcon />
                      </Button>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Button className="filter-playlist" onClick={handleClickOpen}
              style={{
                backgroundColor: isMenuOpen ? '#fff' : '', // Change the background when menu is open
                margin: '0px 10px 0 10px',
              }}
            >
              <FilterListOutlinedIcon fontSize="large" />
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="xs" // Compact size
              fullWidth
              PaperProps={{
                sx: {
                  maxWidth: "600px",
                  padding: "16px",
                  position: "absolute", // Make it absolute
                  top: 245, // Position it aligned with the button (adjust as necessary)
                  right: 90, // Slight adjustment for spacing,
                  '& .MuiDialogContent-root': {
                    overflow: 'hidden !important',
                    overflowY: 'hidden !important',
                    padding: 2,
                  },
                  // Adding multiple levels of specificity
                  '& .MuiDialog-paper .MuiDialogContent-root': {
                    overflow: 'hidden !important',
                    overflowY: 'hidden !important',
                  }
                }, // Reduce the width
              }}
            >
              <DialogTitle>
                Filter by
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {/* Type Filter */}
                  <Grid item xs={12} sm={6} md={4}> {/* Reduce width to fit better */}
                    <FormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={type}
                        onChange={handleFilterChange}
                        name="type"
                        label="Type"
                        sx={{ width: '150px' }}
                      >
                        <MenuItem value={"video"}>Video</MenuItem>
                        <MenuItem value={"audio"}>Audio</MenuItem>
                        <MenuItem value={"text"}>Text</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Language Filter */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel>Language</InputLabel>
                      <Select
                        value={language}
                        onChange={handleFilterChange}
                        name="language"
                        label="Language"
                        sx={{ width: '150px' }}
                      >
                        <MenuItem value={"english"}>English</MenuItem>
                        <MenuItem value={"spanish"}>Spanish</MenuItem>
                        <MenuItem value={"french"}>French</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Date Filter */}
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <InputLabel>Date</InputLabel>
                      <Select
                        value={date}
                        onChange={handleFilterChange}
                        name="date"
                        label="Date"
                        sx={{ width: '150px' }}
                      >
                        <MenuItem value={"today"}>Today</MenuItem>
                        <MenuItem value={"this_week"}>This Week</MenuItem>
                        <MenuItem value={"this_month"}>This Month</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Apply
                </Button>
              </DialogActions> */}
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PlaylistHeader;
