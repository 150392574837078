import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  isDropdownOpen: false,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (_, index) => index !== action.payload
      );
    },
    openDropdown: (state) => {
      state.isDropdownOpen = true;
    },
    closeDropdown: (state) => {
      state.isDropdownOpen = false;
    },
  },
});

export const {
  setNotifications,
  clearNotifications,
  removeNotification,
  openDropdown,
  closeDropdown,
} = notificationSlice.actions;

export default notificationSlice.reducer;
