import { useState, useEffect } from "react";
import {
  getMovieRating,
  getSeriesRating,
  getSeasonRating,
  getEpisodeRating,
} from "../../api/rating";
import { getMovieReviews, getSeriesReviews } from "../../api/reviews";
import { Card, CardContent, Grid2, Typography } from "@mui/material";
import { StarOutline, ThumbUpOffAlt, InfoOutlined } from "@mui/icons-material";
import "./VideoReviewsAndRatings.scoped.css";

const VideoReviewsAndRatings = () => {
  const [rating, setRating] = useState(4.3);
  const [showMore, setShowMore] = useState(false);
  // const [review, setReview] = useState("");

  const id = 60059;
  const season = 6;
  const episode = 13;

  useEffect(() => {
    const getRating = async () => {
      try {
        const vote_average = await getEpisodeRating(id, season, episode);
        if (vote_average) {
          setRating(vote_average);
        }
      } catch (error) {
        console.error("Failed to fetch rating:", error);
        // Optionally, you could display an error message or keep the default rating
      }
    };

    getRating();
  }, [id, season, episode]);
  // useEffect(() => {
  //   const getReview = async () => {
  //     const result = await getSeriesReviews(id);
  //     setReview(result[0].content);
  //   };

  //   getReview();
  // }, [id]);

  const review =
    "In true Breaking Bad fashion, Better Call Saul also excels at developing multifaceted characters that leave a lasting impression. From the morally conflicted Kim Wexler (Rhea Seehorn) to the lovable yet enigmatic Mike Ehrmantraut (Jonathan Banks), the supporting cast delivers stellar performances that add depth and richness to the story. Each character feels fully fleshed out, with their own motivations and flaws that make them relatable and compelling.";

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Card className="card-container">
      <CardContent style={{ padding: 14 }}>
        <Grid2
          container
          spacing={3}
          columns={{ xs: 1, sm: 1, md: 2 }}
          alignItems="baseline"
        >
          <Grid2 className="rating-grid" xs={12} sm={12} md={1}>
            <Typography component="p">
              {rating} <StarOutline className="icon" /> from across the web |
              78% liked
              <ThumbUpOffAlt className="icon" />
              <InfoOutlined className="icon info-icon" />
            </Typography>
          </Grid2>
          <Grid2 className="review-grid" xs={12} sm={12} md={1}>
            <Typography component="p">
              {showMore ? review : `${review.substring(0, 100)}...`}
              {review.length > 100 && (
                <span onClick={toggleShowMore}>
                  {showMore ? "Show Less" : "Show More"}
                </span>
              )}
            </Typography>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default VideoReviewsAndRatings;
