import React, { useEffect, useState } from "react";
import "./CreatePlaylistModal.scoped.css";
import {
  addContentToPlaylist,
  checkPlaylistsOfVideo,
  createPlaylist,
  removeFromPlaylist,
} from "../../api/playlists";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, setLoggedIn } from "../../reducers/authSlice";
import { useForm } from "react-hook-form";
import { setToast } from "../../reducers/snackbarSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import useLoadMore from "./../../hooks/useLoadMore";
import { fetchAllPlayList } from "../../utils/fetchAllPlaylist";
import AllResultText from "../Dropdown/AllResultText";
import { LOGIN_URL } from "../../constant/constantUrls";

const CreatePlaylistModal = ({
  playlists = [], // Array of existing playlists
  onClose, // Function to close the modal,
  contentId, // id of the video card clicked
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
  // State for toggling between viewing existing playlists and creating a new one
  const [isCreating, setIsCreating] = useState(false);

  // State to track which playlists have been selected (checked)
  const [playListsOfVideo, setPlaylistsOfVideo] = useState([]);
  const [playlistError, setPlaylistError] = useState(false);
  const [loading, setLoading] = useState(false);
  // State of auto adjust margin top values
  const [top, setTop] = useState(10);
  const [page, setPage] = useState(1);
  

  useEffect(() => {
    const adjustModalPosition = () => {
      const modalContent = document.querySelector("#modal-content");
      if (!modalContent) return;
  
      const viewportHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const modalHeight = modalContent.offsetHeight;
  
      // Calculate the top margin to center the modal vertically in the current view
      const marginTop = (viewportHeight - modalHeight) / 2 + scrollY;
  
      setTop(marginTop);
  
      modalContent.style.marginTop = `${top}px`;
    }
  
    // Initially adjust modal position
    adjustModalPosition();
  
    // Listen for scroll events
    window.addEventListener("scroll", adjustModalPosition);
  
    // Cleanup: Remove scroll event listener when component unmounts or isOpen changes
    return () => {
      window.removeEventListener("scroll", adjustModalPosition);
    };
  }, [top]);

  const checkPlaylistsOfContent = async () => {
    try {
      let res = await checkPlaylistsOfVideo(contentId);
      if (res?.status === 200) {
        if (res?.data?.data) {
          let playlistArr = [];
          res?.data?.data.forEach((obj) => {
            playlistArr.push(obj?.playlist);
          });
          setPlaylistsOfVideo(playlistArr);
        }
      }
    } catch (err) {
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        localStorage.clear();
        dispatch(setLoggedIn(false));
        navigate(LOGIN_URL);
      }
    }
  };

  const fetchAllPlayLists = async () => {
    return await fetchAllPlayList(page, setPage, navigate);
  };

  const { data, handleLoadMore, hasMore } = useLoadMore(
    [],
    fetchAllPlayLists,
    false
  );

  useEffect(() => {
    handleLoadMore();
    checkPlaylistsOfContent();
  }, []);

  const createPlayLists = async (data) => {
    try {
      setLoading(true);
      let res = await createPlaylist(data);
      setLoading(false);
      if (res?.status === 201) {
        onClose();
        addPlaylistContent(res?.data?.id);
      }
    } catch (err) {
      setLoading(false);
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate(LOGIN_URL);
      }
      if (statusCode === 400) {
        setPlaylistError(true);
      }
    }
  };

  const addPlaylistContent = async (playlistId) => {
    let data = {
      content_id: contentId,
      playlist: playlistId,
    };
    try {
      let res = await addContentToPlaylist(data);
      if (res?.status === 201) {
        setPlaylistsOfVideo((prev) => [...prev, playlistId]);
      }
    } catch (err) {
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate(LOGIN_URL);
      }else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error occurred. Please Try again later!",
            type: "red",
          })
        );
      }
    }
  };

  // Function to handle checkbox state change and update playlist selection
  const handleCheckboxChange = (playlistId) => {
    if (playListsOfVideo.some((playlist) => playlist === playlistId)) {
      removeContentFromPlaylist(playlistId, contentId);
    } else {
      addPlaylistContent(playlistId);
    }
  };

  const removeContentFromPlaylist = async (playlistId, contentId) => {
    try {
      let res = await removeFromPlaylist(playlistId, contentId);
      if (res?.status === 204) {
        setPlaylistsOfVideo((prev) => {
          let pllist = [...prev];
          var index = pllist.indexOf(playlistId);
          if (index !== -1) {
            pllist.splice(index, 1);
          }
          return pllist;
        });
      }
    } catch (err) {
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate(LOGIN_URL);
      }else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error occurred. Please Try again later!",
            type: "red",
          })
        );
      }
    }
  };

  // Function to handle form submission for creating a new playlist
  const handleCreatePlaylist = (data) => {
    const formData = {
      name: data.playlistName,
      is_public: data.privacy === "Public" ? true : false,
    };
    createPlayLists(formData);
  };

  return (
    <div className="create-playlist-modal">
      <div id="modal-content" className="create-playlist-modal-content">
        <div className="create-playlist-modal-header">
          <span>Save video to...</span>
          <i
            className={`fas fa-times create-playlist-modal-close-icon`}
            onClick={onClose}
          ></i>
        </div>
        {!isCreating ? (
          //Conditionally renders the playlist selection view or the new playlist creation form
          <>
            <div
              className="create-playlist-modal-playlist-items-container"
              id="createPlaylistModalPlaylistItemsContainer"
            >
              <InfiniteScroll
                style={{ overflow: "visible" }}
                className="playlists-infinite-scroll"
                dataLength={data.length}
                next={handleLoadMore}
                hasMore={hasMore}
                loader={
                  <div className="playlists-spinner-loadmore">
                    <i className="fas fa-spinner fa-spin" aria-hidden="true" />
                  </div>
                }
                endMessage={<AllResultText videos={playlists} />}
                scrollThreshold="90%"
                scrollableTarget="createPlaylistModalPlaylistItemsContainer"
              >
                {data &&
                  data.map((playlist, index) => (
                    <div key={playlist?.id} className="create-playlist-item">
                      <input
                        type="checkbox"
                        key={playlist?.id}
                        checked={playListsOfVideo.some(
                          (item) => item === playlist?.id
                        )}
                        onChange={() => handleCheckboxChange(playlist?.id)}
                      />
                      <span>{playlist.name}</span>
                      <i
                        className={`fas ${
                          playlist.is_public ? "fa-lock-open" : "fa-lock"
                        }`}
                      ></i>
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
              <button
                onClick={() => setIsCreating(true)}
                className="create-playlist-modal-create-button"
              >
                <i className="fas fa-plus-circle"></i> Create Playlist
              </button>
          </>
        ) : (
          //New playlist creation form
          <form
            onSubmit={handleSubmit(handleCreatePlaylist)}
            className="create-playlist-form-container"
          >
            <input
              {...register("playlistName", {
                required: true,
                maxLength: 20,
                onChange: () => {
                  if (playlistError) setPlaylistError(false);
                },
              })}
              type="text"
              placeholder="Playlist Name"
            />
            <p className="fieldError">
              &nbsp; {playlistError && "This playlist name already exists"}
            </p>
            <select
              {...register("privacy")}
              className="playlist-form-container-select"
            >
              <option value="Private">Private</option>
              <option value="Public">Collaborative</option>
            </select>
            <button
              disabled={loading}
              type="submit"
              className={`create-playlist-modal-create-button ${
                !formState.isValid ? "disabled-button" : ""
              }`}
            >
              Create
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreatePlaylistModal;
