import React, { useState, useEffect, useRef } from "react";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import VideoComment from "../../components/VideoComment/VideoComment";
import VideoDesc from "../../components/VideoDesc/VideoDesc";
import "./VideoPage.scoped.css";
import { SuggestionVideoCard } from "../../components/SuggestionVideoCard/SuggestionVideoCard";
import { useParams, useNavigate } from "react-router-dom";
import data from "../../data/dashvideos.json";
import VideoPageHeader from "../../components/VideoPageHeader/VideoPageHeader";
import VideoContainer from "../../components/VideoContainer/VideoContainer";
import VideoDescriptionSection from "../../components/VideoDescriptionSection/VideoDescriptionSection";
import VideoReviewsAndRatings from "../../components/VideoReviewsAndRatings/VideoReviewsAndRatings";
import ContextualInfo from "../../components/ContextualInfo/ContextualInfo";
import VideoPlaylistCardList from "../../components/Playlist/VideoPlaylistCardList";

import { fetchVideoDetails } from "../../api/video";
import { logout, setLoggedIn } from "../../reducers/authSlice";
import { setToast } from "../../reducers/snackbarSlice";
import { useDispatch } from "react-redux";
import { setDashVideoObject } from "../../reducers/dashVideoObjectSlice";
import Summary from "../../components/Summary/Summary";
import Translator from "../../components/MediaTranslator/Translator";

/**
 * Fetch and handle video details.
 * @param {string} videoId - The ID of the video to fetch details for.
 * @param {function} setVideoDetails - Function to set the video details in state.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 */
const fetchAndHandleVideoDetails = async (
  videoId,
  setVideoDetails,
  dispatch,
  navigate
) => {
  try {
    const res = await fetchVideoDetails(videoId);
    if (res?.status === 200) {
      setVideoDetails(res?.data);
      dispatch(
        setDashVideoObject({
          description: res.data.description,
          titles: res.data.titles,
          view_count: res.data.view_count,
          created_at: res.data.created_at,
          id: res.data.id,
        })
      );
      return res.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

/**
 * The main VideoPage component that manages the overall structure.
 * @param {Object} searchInputRef - Ref object for search input.
 */
function VideoPage({ searchInputRef }) {
  const { videoId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [videoViewCount, setVideoViewCount] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoDetails, setVideoDetails] = useState();
  const [isContextInfoOpen, setIsContextInfoOpen] = useState(false);

  const handleContextInfoToggle = () =>
    setIsContextInfoOpen(!isContextInfoOpen);

  useEffect(() => {
    fetchAndHandleVideoDetails(videoId, setVideoDetails, dispatch, navigate);
  }, [videoId, dispatch, navigate]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const firstVideo = data[0];
      setVideoUrl(firstVideo.file_stream_cdn_url);
      setVideoViewCount(firstVideo.view_count);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBackClick = () => {
    navigate("/CONTENT-PLATFORM/services");
  };

  const handleSuggestedVideoClick = (video) => {
    setVideoUrl(video.file_stream_cdn_url);
  };

  return (
    <div>
      <VideoPageHeader videoId={videoId} onGoBack={handleGoBackClick} />
      <div className="video">
        <VideoContainer
          isCollapsed={isCollapsed}
          isPortrait={isPortrait}
          isVisible={isVisible}
        >
          <div className="video-layout">
            <div className="video-content">
              <div className="video-player-container">
                <VideoPlayer
                  src={videoUrl}
                  videoViewCount={videoViewCount}
                  setVideoViewCount={setVideoViewCount}
                  dashVideoObject={videoDetails}
                  searchInputRef={searchInputRef}
                />
              </div>
              <div className="video-details-container">
                <div>
                  <VideoDescriptionSection
                    videoDetails={videoDetails}
                    videoId={videoId}
                  />
                </div>
                <VideoReviewsAndRatings />
                <VideoComment />
              </div>
            </div>

            <div className="video-suggestion-container">
              <div className="video-translator">
                <Translator />
                <ContextualInfo />
                <VideoPlaylistCardList />
                <SuggestionVideoCard videoArray={data} />
              </div>

              {/* <div className="suggestion-video-card">
                <SuggestionVideoCard videoArray={data} />
              </div> */}
            </div>
          </div>
        </VideoContainer>
      </div>
    </div>
  );
}

export default VideoPage;
