import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTime: 0,
  playing: true,
  hasEnded: false,
  isFullScreen: false,
  duration: 0,
  hovering: false,
  volume: 1,
  captionsEnabled: false,
  playbackSpeed: 1,
  pinnedLanguages: [],
  is_user_session_owner : false
};

const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState,
  reducers: {
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setPlaying: (state, action) => {
      state.playing = action.payload;
    },
    setHasEnded: (state, action) => {
      state.hasEnded = action.payload;
    },
    setFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setHovering: (state, action) => {
      state.hovering = action.payload;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    setCaptionsEnabled: (state, action) => {
      state.captionsEnabled = action.payload;
    },
    setPlaybackSpeed: (state, action) => {
      state.playbackSpeed = action.payload;
    },
    setPinnedLanguage: (state, action) => {
      state.pinnedLanguages = action.payload;
    },
    setIsUserOwner: (state, action) => {
      state.is_user_session_owner = action.payload;
    },
  },
});

export const {
  setCurrentTime,
  setPlaying,
  setHasEnded,
  setFullScreen,
  setDuration,
  setVolume,
  setHovering,
  setCaptionsEnabled,
  setPlaybackSpeed,
  setPinnedLanguages,
  setIsUserOwner
} = videoPlayerSlice.actions;

export default videoPlayerSlice.reducer;
