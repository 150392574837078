import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onboardingStage: -1,
  dob: "",
  gender: "",
  preferred_language: "",
  country: "",
  city: "",
  interests: [],
  formData: {},
};

const userPrefSlice = createSlice({
  name: "userPref",
  initialState,
  reducers: {
    incrementStage(state) {
      return { ...state, onboardingStage: state.onboardingStage + 1 };
    },
    decrementStage(state) {
      return { ...state, onboardingStage: state.onboardingStage - 1 };
    },
    skipToStage(state, action) {
      return { ...state, onboardingStage: action.payload };
    },
    updateBirthdate(state, action) {
      return { ...state, dob: action.payload };
    },
    updateGender(state, action) {
      return { ...state, gender: action.payload };
    },
    updateLanguage(state, action) {
      return { ...state, preferred_language: action.payload };
    },
    updateLocation(state, action) {
      return {
        ...state,
        country: action.payload.country,
        city: action.payload.city,
      };
    },
    updateInterests(state, action) {
      return { ...state, interests: action.payload };
    },
    updateFormData(state, action) {
      return { ...state, formData: { ...state.formData, ...action.payload } };
    },
    clearFormData(state) {
      return { ...state, formData: {} };
    },
  },
});

export const {
  incrementStage,
  decrementStage,
  skipToStage,
  updateLanguage,
  updateLocation,
  updateInterests,
  updateBirthdate,
  updateGender,
  updateFormData,
  clearFormData,
} = userPrefSlice.actions;

export default userPrefSlice.reducer;
