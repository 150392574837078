import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = {
  fullChatHistory: [],
};

const airisChatHistorySlice = createSlice({
  name: "chatHistorySlice",
  initialState,
  reducers: {
    clearFullChatHistory: (state) => {
      state.fullChatHistory = [];
    },
    setChatTypewrited: (state) => {
      state.fullChatHistory[state.fullChatHistory.length - 1].typewrited = true;
    },
    addLoadingChatItem: (state, action) => {
      const payload = action.payload;
      let languageSwitchText = null;

      if (
        state.fullChatHistory[state.fullChatHistory.length - 1] !== undefined
      ) {
        if (
          state.fullChatHistory[state.fullChatHistory.length - 1].language !==
          payload.language && i18next.hasResourceBundle(payload.language, 'airis') 
        ) {
          languageSwitchText = payload.languageSwitchText;
        }
      }

      let newChatItem = {
        id: payload.messageID,
        language: payload.language,
        languageType: payload.searchDirection,
        languageSwitchText: languageSwitchText,
        userInputText: payload.userInputText,
        resultsLoading: true,
        results: [{ textBlockList: "" }],
        errorResponse: false,
        videoResponse: false,
        thirdParty: false,
        typewrited: null,
      };

      let newChat = [...state.fullChatHistory, newChatItem];
      state.fullChatHistory = newChat;
    },
    updateLoadingChatItem: (state, action) => {
      const payload = action.payload;

      let newChat = [...state.fullChatHistory];
      const chatIdx = newChat.findIndex((chatItem) => {
        return chatItem.id === payload.messageID;
      });

      if (chatIdx !== -1) {
        newChat[chatIdx].resultsLoading = false;
        newChat[chatIdx].results = [{ textBlockList: payload.response }];
        newChat[chatIdx].errorResponse = payload.errorResponse;
        newChat[chatIdx].videoResponse = payload.videoResponse;
        newChat[chatIdx].thirdParty = payload.thirdParty;
        newChat[chatIdx].typewrited = false;
      }

      state.fullChatHistory = newChat;
    },
  },
});

export const {
  clearFullChatHistory,
  setChatTypewrited,
  addLoadingChatItem,
  updateLoadingChatItem,
} = airisChatHistorySlice.actions;

export default airisChatHistorySlice.reducer;
