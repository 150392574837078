import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./DragAndDrop.scoped.css";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TRANSLATE_URL } from "../../constant/constantUrls";
import { setSelectedFile } from "../../reducers/languageSlice";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { Button } from "@mui/material";

const DragAndDrop = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadDate, setUploadDate] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //geting info on username
  const userNameInfo = useSelector((state) => state.userInfo.userProfile);

  const onDrop = useCallback((acceptedFiles) => {
    const currentDate = new Date(); // Capture the current date as the upload date
    setUploadDate(currentDate);

    const file = acceptedFiles[0];
    const fileType = file.type.split("/")[0];

    const newFiles = acceptedFiles.map((file) => ({
      dateUploaded: currentDate,
      user: userNameInfo,
      fileName: file.name,
    }));

    setUploadedFiles((prevFile) => [...prevFile, ...newFiles]);

    dispatch(setSelectedFile(file.name));

    navigate(`${TRANSLATE_URL}/${fileType}/${newFiles.name}`);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Box className="dragAndDropDiv" {...getRootProps()}>
      <input {...getInputProps()} />
      <Box>
        {isDragActive ? (
          <Typography className="typography">
            Drop the files here ...
          </Typography>
        ) : (
          <Typography className="typography">Drag & Drop</Typography>
        )}
      </Box>
    </Box>
  );
};

export default DragAndDrop;
