/**
 * This file contains the implementation of the VosynAssist sidebar component.
 * It manages the display and behavior of the VosynAssist chatbox within the application.
 */

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  toggleAirisSidebar,
  hideAirisSidebar,
} from "../../reducers/airisSidebarSlice.js";
import AirisChatboxContainer from "./AirisChatboxContainer.jsx";
import AirisBlob from "./AirisBlob/AirisBlob.jsx";
import { AIRIS_BIG_SIDEBAR_WIDTH } from "../../constant/constantValues.js";

import "./Airis.scoped.css";

function AirisSidebar() {
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const { isAirisDisplayed } = useSelector((state) => state.airisSidebar);
  const location = useLocation();
  const videopage = location.pathname.startsWith("/CONTENT-PLATFORM/video/");

  // Function to open the Airis sidebar
  const openAiris = () => {
    sidebarRef.current.style.right = "-550px";
    setTimeout(() => {
      dispatch(toggleAirisSidebar());
      sidebarRef.current.style.right = "";
    }, 200);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    dispatch(hideAirisSidebar());
  };

  return (
    <div
      ref={sidebarRef}
      className={`${videopage ? "videopage-va-location" : "va-location"} ${
        isAirisDisplayed && "va-sidebar"
      }`}
      style={{ maxWidth: AIRIS_BIG_SIDEBAR_WIDTH }}
    >
      <div className="va-small-chatbox">
        {isAirisDisplayed ? (
          <AirisChatboxContainer
            isAirisOpen={isAirisDisplayed}
            closeSidebar={closeSidebar}
          />
        ) : (
        <div onClick={openAiris}>
            <div className={`${videopage ? "videopage-va" : "va"}`}>
              <button className="va-sidebar-blob">
                <AirisBlob roughness={10} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AirisSidebar;
