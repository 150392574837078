import { createTheme } from "@mui/material/styles";

let muiTheme = createTheme({
  cssVariables: true,
  palette: {
    contrastThreshold: 4.5,
    neutral: {
      25: "#FBFBFB",
      50: "#F9F9F9",
      100: "#EAEAEA",
      200: "#DFDFDF",
      300: "#C9C9C9",
      400: "#9A9A9A",
      500: "#777777",
      600: "#575757",
      700: "#3B3E3B",
      800: "#1F1E1E",
      900: "#121118",
    },
    primary: {
      25: "#F7F9FF",
      100: "#CBD7FD",
      200: "#97AFFB",
      300: "#7595FA",
      400: "#527AF9",
      main: "#324DA3",
      550: "#244095",
      600: "#010922",
      700: "#121118",
    },
    secondary: {
      100: "#E8F8FE",
      200: "#BAE9FB",
      300: "#A3E1FA",
      main: "#41C4FA",
      500: "#1391C2",
      600: "#0E6D92",
      700: "#0A4861",
    },
    tertiary: {
      100: "#FBE9F9",
      200: "#F2BEEE",
      300: "#EDA8E8",
      main: "#E57DDC",
      500: "#E900CC",
      600: "#7F1776",
      700: "#3F0B3B",
    },
    warning: {
      main: "#F18724",
    },
    success: {
      main: "#008A00",
    },
    danger: {
      25: "#FFF4F2",
      100: "#EEB4B0",
      200: "#E53429",
      300: "#C92A20",
      400: "#AA1E13",
      500: "#731A15",
    },
  },
  spacing: [4, 6, 8, 10, 12, 16, 20, 24, 32], //px
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "0.5rem",
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});

export default muiTheme;
