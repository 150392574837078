import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './Modal.scoped.css'
import { Box, Button, Typography } from '@mui/material'

const Modal = ({ show, onClose, children, isGlobal = true, hideCloseIcon = false, contentClassName = '', containerClassName = '' }) => {
    const modalNode = document.createElement('div')

    useEffect(() => {
        document.body.appendChild(modalNode)
        return () => {
            document.body.removeChild(modalNode)
        }
    }, [modalNode])

    if (!show) {
        return null
    }

    const handleBackdropClick = (e) => {
        if (e.target.classList.contains('modal-backdrop')) {
            onClose();
        }
    };
  
  const modalContent = (
      <Box
          className={`modal-backdrop ${show ? 'show' : ''}`}
          onClick={handleBackdropClick}
      >
        <Box className={`modal_container ${containerClassName}`}>
          <Box className={`modal ${contentClassName}`}>
              <Box className='modal-content'>
                <Box className='buttonWrapper'>
              {!hideCloseIcon && (
              <Button
                  onClick={onClose}
                  className='fa-solid fa-close sticky-close'
              >
                  <Typography component="span" className='visually-hidden'>Close</Typography>
              </Button>
              )}</Box>
                
                {children}
                
                </Box>
              
          </Box>
          </Box>
      </Box>
  ) 
  
  if (!isGlobal) {
    return modalContent
  }

  return ReactDOM.createPortal(modalContent, modalNode)
}

export default Modal
