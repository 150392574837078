// import { useState, useEffect } from "react";
// import { getMovieReviews, getSeriesReviews } from "../../api/reviews";
// import {
//   getMovieRating,
//   getSeriesRating,
//   getSeasonRating,
//   getEpisodeRating,
// } from "../../api/rating";
import { Card, CardContent, Grid2, Typography } from "@mui/material";
import { StarOutline, ThumbUpOffAlt, InfoOutlined } from "@mui/icons-material";
import ReadMore from "../ReadMore/ReadMore";
import data from "../../data/services-data.json";
import "./ReviewsAndRatings.scoped.css";

const ReviewsAndRatings = () => {
  // const seriesId = data.results[1].series_id;
  // const [rating1, setRating] = useState(null);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchMovieRating = async () => {
  //     try {
  //       const ratingData = await getEpisodeRating(seriesId);
  //       setRating(ratingData);
  //     } catch (err) {
  //       setError("Failed to fetch episode rating. " + err);
  //     }
  //   };

  //   fetchMovieRating();
  // }, [seriesId]);

  const reviewText = data.results[1].review;
  const rating = (Math.round(data.results[1].vote_average * 10) / 10).toFixed(
    1
  );

  return (
    <Card sx={{ borderRadius: "0.5rem" }}>
      <CardContent style={{ backgroundColor: "neutral.800", padding: 16 }}>
        <Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
          alignItems="baseline"
        >
          <Grid2
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#b9b9b9",
              borderRadius: "1rem",
              marginBottom: "1rem"
            }}
            xs={12}
            md={1}
          >
            <Typography className="rating-text">
              {rating} <StarOutline className="icon" /> from across the web |
              78% liked
              <ThumbUpOffAlt className="icon" />
              <InfoOutlined className="icon info-icon" />
            </Typography>
          </Grid2>
          <Grid2 xs={12} md={1}>
            <ReadMore text={reviewText} />
          </Grid2>
        </Grid2>
      </CardContent>
    </Card >
  );
};

export default ReviewsAndRatings;
