import { useEffect, useState } from "react";
import { loadAudioFile } from "../utils/fetchTrack";

const useAudioControls = () => {
  const [audioFile, setAudioFile] = useState({
    isLoaded: false,
    audio: null,
    transcript: null,
    nativeLanguage: null,
    availableLanguages: [],
  });

  const [pageControls, setPageControls] = useState({
    sideBarOpen: true,
    isWideScreen: false,
    isFullScreen: false,
    textPanelOpen: false,
    textPanelType: "none", // "transcript" | "captions"
    translationLanguage: null,
  });

  useEffect(() => {
    const fetchAudioFile = async () => {
      try {
        const data = await loadAudioFile();
        setAudioFile(data);
      } catch (error) {
        console.error("Error loading audio file:", error);
      }
    };

    fetchAudioFile();
  }, []);

  const pageFunctions = {
    toggleSidebar: () => {
      setPageControls((prev) => ({
        ...prev,
        sideBarOpen: !prev.sideBarOpen,
        isWideScreen: prev.sideBarOpen,
      }));
    },
    toggleWideScreen: () => {
      setPageControls((prev) => ({
        ...prev,
        sideBarOpen: !prev.sideBarOpen,
        isWideScreen: prev.sideBarOpen,
      }));
    },
    toggleTextPanel: () => {
      setPageControls((prev) => ({
        ...prev,
        textPanelOpen: !prev.textPanelOpen,
      }));
    },
    toggleTranscriptMode: () => {
      setPageControls((prev) => {
        if (prev.textPanelOpen && !prev.translationLanguage) {
          return {
            ...prev,
            textPanelOpen: false,
            textPanelType: "none",
            translationLanguage: null,
            sideBarOpen: true,
            isWideScreen: false,
          };
        } else {
          return {
            ...prev,
            textPanelOpen: true,
            textPanelType: "transcript",
            translationLanguage: null,
            sideBarOpen: false,
            isWideScreen: true,
          };
        }
      });
    },
    toggleCompareMode: () => {
      setPageControls((prev) => {
        if (prev.textPanelOpen && prev.translationLanguage) {
          return {
            ...prev,
            textPanelOpen: false,
            textPanelType: "none",
            sideBarOpen: true,
            isWideScreen: false,
          };
        } else {
          return {
            ...prev,
            textPanelOpen: true,
            textPanelType: "transcript",
            translationLanguage: prev.translationLanguage ?? "es",
            sideBarOpen: false,
            isWideScreen: true,
          };
        }
      });
    },
    openCaptionsMode: (isocode) => {
      setPageControls((prev) => ({
        ...prev,
        textPanelOpen: true,
        textPanelType: "captions",
        sideBarOpen: false,
        isWideScreen: true,
        translationLanguage: isocode ?? prev.translationLanguage,
      }));
    },
    closeCaptionsMode: () => {
      setPageControls((prev) => ({
        ...prev,
        textPanelOpen: false,
        textPanelType: "none",
        sideBarOpen: true,
        isWideScreen: false,
        translationLanguage: null,
      }));
    },
    toggleCaptionsMode: () => {
      setPageControls((prev) => {
        if (prev.textPanelOpen && prev.textPanelType === "captions") {
          return {
            ...prev,
            textPanelOpen: false,
            textPanelType: "none",
            sideBarOpen: true,
            isWideScreen: false,
          };
        } else {
          return {
            ...prev,
            textPanelOpen: true,
            textPanelType: "captions",
            sideBarOpen: false,
            isWideScreen: true,
          };
        }
      });
    },
    updateTranslationLanguage: (isocode) => {
      setPageControls((prev) => ({
        ...prev,
        translationLanguage: isocode || null,
      }));
    },
  };

  return { audioFile, pageControls, pageFunctions };
};

export default useAudioControls;
