// This component represents a language banner used for displaying translations.

import i18next from "i18next";

import "./LanguageBanner.scoped.css";

function LanguageBanner({ setDisplayBanner, language, searchDirection }) {
  // Retrieving the translated banner based on the current language
  const fixedT = i18next.getFixedT(language, "airis");
  const airisBanner = fixedT("airis.banner");

  return (
    <div className="language-switch-banner" dir={searchDirection}>
      {airisBanner}
      <img
        src={process.env.PUBLIC_URL + `/assets/Airis/svg/x-bold.svg`}
        alt={"close"}
        className="banner-close"
        onClick={() => {
          setDisplayBanner(false);
        }}
      />
    </div>
  );
}

export default LanguageBanner;
