import React, { useEffect, useState } from "react";
import { Typography, Switch, Divider, Box, Link, IconButton } from "@mui/material";
import MuiIcon from "../../utils/MuiIcon.js";
import "./SettingsPrivacyData.scoped.css"; 
import { getUserSettings, updateSettings } from "../../api/settings.js";


const SettingsPrivacyData =  ({onBack}) => {

  const switchStyles = {
    marginLeft: "120%",
    bottom: "50px",
    "& .MuiSwitch-switchBase": {
      color: "#757575", 
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#424242", 
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#e0e0e0", 
    },
    "& .MuiSwitch-track.Mui-checked": {
      backgroundColor: "#e0e0e0", 
    },
  };

  

  const [allSettings, setAllSettings] = useState({
    location_tracking: false,
    generate_relevant_content: false,
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getUserSettings();
        if(settingsData) setAllSettings(settingsData);
        console.log(settingsData, "Fetched settings data"); 
      } catch (error) {
        console.error("Error fetching user settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleSwitchChange = async (event) => {
    const { name, checked } = event.target;
    const updatedSettings = { ...allSettings, [name]: checked };

    setAllSettings(updatedSettings);
    
    console.log(updatedSettings, "This is updated settings");

    try {
      const response = await updateSettings(updatedSettings);
      console.log("Updated settings response:", response);
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  return (
    <Box className="settings-privacy-container">
      {/* Back Button */}
      <IconButton onClick={onBack} className="back-button">
        <MuiIcon name="ArrowBackIos" className="back-button-icon"/>
        Back
      </IconButton>

      {/* Privacy and Data Title */}
      <Typography variant="h4" className="heading" aria-label="Privacy and Data">
        Privacy and Data
      </Typography>

      {/* Subtitle */}
      <Typography variant="body1" className="privacy-data-text">
        Manage how your data is used and customize your privacy settings
      </Typography>

      {/* Ads Section */}
      <Typography variant="h6" className="section-title" aria-label="Ads">
        Ads
      </Typography>
      <Typography variant="body2" color="text.secondary" className="ads-text">
        You may see ads based on general factors, such as the topic of the video. For more information on how ads work, including specifics for family accounts with children, please visit our{" "}
        <span className="link-text" aria-label="Help Center">Help Center</span>.
      </Typography>

      {/* Location Tracking Section */}
      <Box className="switch-container">
        <Typography variant="h6" className="section-title" aria-label="Location Tracking">Location Tracking</Typography>
        
      </Box>
      <Typography variant="body2" color="text.secondary" className="location-tracking-text">
        Location tracking allows us to provide location-specific content and recommendations. You may receive tailored suggestions based on your geographic location. For more details on our location tracking policy, please refer to our{" "}
        <span className="link-text">Privacy Policy</span>.
        <Switch 
        name="location_tracking" 
        checked={allSettings.location_tracking} 
        onChange={handleSwitchChange} 
        inputProps={{ 'aria-label': 'Location Tracking Switch' }}
        sx={switchStyles}
        />
      </Typography>

      {/* Use Data to Generate Relevant Content Section */}
      <Box className="switch-container">
        <Typography variant="h6" className="section-title">Use Data to Generate Relevant Content</Typography>
      </Box>
      <Typography variant="body2" color="text.secondary" className="use-data-content-text">
        We utilize your viewing history and preferences to generate personalized content recommendations. This helps enhance your experience by suggesting shows and movies that align with your interests. For further information on data usage, consult our{" "}
        <span className="link-text">Privacy Policy</span>.
        <Switch className="switch"
        name="generate_relevant_content" 
        checked={allSettings.generate_relevant_content} 
        onChange={handleSwitchChange} 
        inputProps={{ 'aria-label': 'Use Data to Generate Relevant Content Switch' }}        
           sx={switchStyles}
        />
      </Typography>
    </Box>
  );
};

export default SettingsPrivacyData;
