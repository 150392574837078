import { useEffect, useRef, useState } from "react";
import "./AudioPlayerControlPanel.scoped.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FullscreenButton from "../../ControlPanel/ActionControl/FullScreenButton";
import Settings from "../../ControlPanel/ActionControl/Settings";
import PlaybackSpeed from "../../ControlPanel/ActionControl/PlaybackSpeed";
import CaptionButton from "../../ControlPanel/ActionControl/CaptionButton";
import VolumeSlider from "../../ControlPanel/ActionControl/VolumeSlider";
import RewindForwardButtons from "../../ControlPanel/ActionControl/RewindForwardButtons";
import PlayPauseButton from "../../ControlPanel/ActionControl/PlayPauseButton";

const AudioPlayerControlPanel = ({
  open,
  disposition,
  audioFile,
  audioRef,
  togglePlayPause,
  pageControls,
  pageFunctions,
  setAudioControlsOnChange,
  showControls,
  mouseMoveTimer,
}) => {
  // disposition is either fullAudioPlayer or panelOnly
  // theme { bgColor, btnColor, timelineBgColor, timelineBarColor }
  // audio player controls & functions
  const timelineBarRef = useRef(null);
  const [audioControls, setAudioControls] = useState({
    isLoaded: false,
    isPlaying: false,
    totalPlaytime: 29,
    currentTime: 0,
    timeRemaining: 0,
    volume: "1",
    muted: false,
    isEnded: false,
    thumbnail: "",
    volumeSliderHidden: true,
    settingsMenuOpen: false,
    playspeedMenuOpen: false,
    playspeed: 1,
    captionsMenuOpen: false,
  });
  const audioFunctions = {
    intialize: function () {
      if (audioRef.current) {
        setAudioControls({
          ...audioControls,
          totalPlaytime: audioRef.current.duration,
          isLoaded: true,
        });
      }
    },
    play: function () {
      if (audioRef.current) {
        audioRef.current.play();
        setAudioControls({ ...audioControls, isPlaying: true, isLoaded: true });
      }
    },
    pause: function () {
      if (audioRef.current) {
        audioRef.current.pause();
        setAudioControls({ ...audioControls, isPlaying: false });
      }
    },
    togglePlay: function () {
      if (audioControls.isEnded) {
        audioFunctions.playFromBeginning();
      } else {
        if (audioControls.isPlaying) {
          audioFunctions.pause();
        } else {
          audioFunctions.play();
        }
      }
    },
    skipTo: function (seconds) {
      if (audioRef.current) {
        audioRef.current.currentTime = seconds;
        setAudioControls({ ...audioControls, currentTime: seconds });
      }
    },
    playFromBeginning: function () {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setAudioControls({ ...audioControls, isPlaying: true });
      }
    },
    fastForward10Seconds: function () {
      if (audioRef.current) {
        if (audioRef.current.currentTime < audioRef.current.duration - 10) {
          audioRef.current.currentTime += 10;
        } else {
          audioRef.current.currentTime = audioRef.current.duration;
          setAudioControls({
            ...audioControls,
            currentTime: audioControls.totalPlaytime,
          });
        }
      }
    },
    rewind10Seconds: function () {
      if (audioRef.current) {
        if (audioRef.current.currentTime > 10) {
          audioRef.current.currentTime -= 10;
        } else {
          audioRef.current.currentTime = 0;
        }
      }
    },
    setEnded: function () {
      setAudioControls({ ...audioControls, isEnded: true, isPlaying: false });
    },
    handleTimeUpdate: function (e) {
      if (e.target.ended) {
        setAudioControls({
          ...audioControls,
          currentTime: e.target.currentTime,
          timeRemaining: 0,
          isPlaying: false,
        });
      } else {
        setAudioControls({
          ...audioControls,
          currentTime: e.target.currentTime,
          timeRemaining: audioControls.totalPlaytime - e.target.currentTime,
          isEnded: false,
        });
      }
    },
    handleTimelineClick: function (e) {
      if (timelineBarRef.current) {
        const timelineRect = timelineBarRef.current.getBoundingClientRect();
        const progress =
          (e.clientX - timelineRect.left) / (timelineRect.width + 16);
        if (audioRef.current) {
          audioFunctions.skipTo(audioRef.current.duration * progress);
        }
      }
    },
    showVolumeSlider: function () {
      setAudioControls({ ...audioControls, volumeSliderHidden: false });
    },
    hideVolumeSlider: function () {
      setAudioControls({ ...audioControls, volumeSliderHidden: true });
    },
    handleVolumeChange: function (e) {
      let volume = e.target.value;
      audioRef.current.volume = volume;
      setAudioControls({ ...audioControls, volume: volume });
    },
    toggleMute: function () {
      if (audioControls.muted) {
        audioRef.current.volume = audioControls.volume;
        setAudioControls({ ...audioControls, muted: false });
      } else {
        audioRef.current.volume = "0";
        setAudioControls({ ...audioControls, muted: true });
      }
    },
    openSettingsMenu: function () {
      setAudioControls({ ...audioControls, settingsMenuOpen: true });
    },
    closeSettingsMenu: function () {
      setAudioControls({ ...audioControls, settingsMenuOpen: false });
    },
    toggleSettingsMenu: function () {
      if (audioControls.settingsMenuOpen) {
        setAudioControls({ ...audioControls, settingsMenuOpen: false });
      } else {
        setAudioControls({ ...audioControls, settingsMenuOpen: true });
      }
    },
    setPlayspeed: function (speed) {
      if (!audioRef.current) return;
      audioRef.current.playbackRate = speed;
      setAudioControls({
        ...audioControls,
        playspeed: speed,
        playspeedMenuOpen: false,
      });
    },
    openPlayspeedMenu: function () {
      setAudioControls({ ...audioControls, playspeedMenuOpen: true });
    },
    closePlayspeedMenu: function () {
      setAudioControls({ ...audioControls, playspeedMenuOpen: false });
    },
    togglePlayspeedMenu: function () {
      if (audioControls.playspeedMenuOpen) {
        setAudioControls({ ...audioControls, playspeedMenuOpen: false });
      } else {
        setAudioControls({ ...audioControls, playspeedMenuOpen: true });
      }
    },
    openCaptionsMenu: function () {
      setAudioControls({ ...audioControls, captionsMenuOpen: true });
    },
    closeCaptionsMenu: function () {
      setAudioControls({ ...audioControls, captionsMenuOpen: false });
    },
    toggleCaptionsMenu: function () {
      if (audioControls.captionsMenuOpen) {
        setAudioControls({ ...audioControls, captionsMenuOpen: false });
      } else {
        setAudioControls({ ...audioControls, captionsMenuOpen: true });
      }
    },
  };

  // refs
  const settingsBtnRef = useRef(null);
  const playspeedBtnRef = useRef(null);
  const captionsBtnRef = useRef(null);

  // helper functions
  const convertSecondsToTime = (seconds) => {
    // converts seconds to time in format mm:ss
    if (Math.floor(seconds) <= 0) {
      return "00:00";
    } else {
      let mins = Math.floor(seconds / 60);
      mins =
        mins === 0 ? "00" : mins < 10 ? "0" + mins.toString() : mins.toString();
      seconds = Math.floor(seconds % 60);
      seconds =
        seconds === 0
          ? "00"
          : seconds < 10
            ? "0" + seconds.toString()
            : seconds.toString();
      return mins + ":" + seconds;
    }
  };

  // send audio control updates to parent component
  useEffect(() => {
    if (setAudioControlsOnChange) {
      setAudioControlsOnChange(audioControls);
    }
  }, [audioControls]);
  // send audio control updates to parent component
  useEffect(() => {
    if (setAudioControlsOnChange) {
      setAudioControlsOnChange(audioControls);
    }
  }, [audioControls]);

  // responsive code
  const [responsive, setResponsive] = useState({
    iconSize: "32px",
    iconGap: "20px",
    volumeControlWidth: "120px",
    volumeSliderHidden: true,
    narrowPadding: false,
  });
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setResponsive({
        ...responsive,
        iconSize: "4.5vw",
        iconGap: "1.5vw",
        volumeControlWidth: "15vw",
        volumeSliderHidden: false,
        narrowPadding: true,
      });
    } else {
      setResponsive({
        ...responsive,
        iconSize: "32px",
        iconGap: "20px",
        volumeControlWidth: "120px",
        volumeSliderHidden: true,
        narrowPadding: false,
      });
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      className="control-panel"
      data-narrowPadding={responsive.narrowPadding}
      data-controlpanelopen={open}
      data-disposition={disposition}
      style={{ backgroundColor: disposition === "panelOnly" && "transparent" }}
    >
      {audioFile.isLoaded && (
        <audio
          ref={audioRef}
          src={audioFile.audio}
          onTimeUpdate={audioFunctions.handleTimeUpdate}
          onEnded={audioFunctions.setEnded}
        />
      )}
      return (
      <Box
        className="control-panel"
        data-narrowPadding={responsive.narrowPadding}
        data-controlpanelopen={open}
        data-disposition={disposition}
        style={{
          backgroundColor: disposition === "panelOnly" && "transparent",
        }}
      >
        {audioFile.isLoaded && (
          <audio
            ref={audioRef}
            src={audioFile.audio}
            onTimeUpdate={audioFunctions.handleTimeUpdate}
            onEnded={audioFunctions.setEnded}
          />
        )}

        <Box className="timeline">
          <Box
            ref={timelineBarRef}
            onClick={audioFunctions.handleTimelineClick}
            className="timeline-bar"
          >
            <Box
              className="progress"
              style={{
                width:
                  (audioControls.currentTime / audioControls.totalPlaytime) *
                    100 +
                  "%",
                maxWidth: "100%",
              }}
            ></Box>
          </Box>
          <Box className="timeline-time">
            <Typography>
              {audioControls.isLoaded
                ? convertSecondsToTime(audioControls.currentTime)
                : "00:00"}
            </Typography>
          </Box>
        </Box>
        <Box className="timeline">
          <Box
            ref={timelineBarRef}
            onClick={audioFunctions.handleTimelineClick}
            className="timeline-bar"
          >
            <Box
              className="progress"
              style={{
                width:
                  (audioControls.currentTime / audioControls.totalPlaytime) *
                    100 +
                  "%",
                maxWidth: "100%",
              }}
            ></Box>
          </Box>
          <Box className="timeline-time">
            <Typography>
              {audioControls.isLoaded
                ? convertSecondsToTime(audioControls.currentTime)
                : "00:00"}
            </Typography>
          </Box>
        </Box>

        <Box className="panel-buttons">
          <Box className="left-panel" sx={{ gap: responsive.iconGap }}>
            <PlayPauseButton togglePlayPause={togglePlayPause} />

            <RewindForwardButtons videoRef={audioRef} />

            <VolumeSlider videoRef={audioRef} />
          </Box>
          <Box className="right-panel" sx={{ gap: responsive.iconGap }}>
            {/* <Box className="btn toggleLangBtn">
                    </Box> */}
            <CaptionButton showControls={showControls} />

            <PlaybackSpeed
              videoRef={audioRef}
              mouseMoveTimer={mouseMoveTimer}
              showControls={showControls}
            />

            <Settings />

            <FullscreenButton
              mouseMoveTimer={mouseMoveTimer}
              showControls={showControls}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AudioPlayerControlPanel;
