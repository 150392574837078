import React from "react";
import styles from "./VideoCards.scoped.css"; // Dont remove it will change css of video cards
import VideoThumbnail from "./VideoThumbnail/VideoThumbnail";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";

const AllResultText = ({ videos }) => (
  <div className="videocards-result-status-text">
    <p style={{ textAlign: "center" }}>
      <b>{videos.length === 0 ? "Oops! We couldn't find a related video" : "Yay! You have seen it all"}</b>
    </p>
  </div>
);

const VideoCards = ({
  videos = [],
  cardLayout,
  cardType,
  loadMore,
  handleHasMore,
  onDelete,
}) => {
  const handleDelete = (id) => {
    onDelete(id);
  };

  return (
    <Box className="videocards">
      <InfiniteScroll
        style={{ overflow: "visible" }}
        className="videocards-infinite-scroll"
        dataLength={videos.length}
        next={loadMore}
        hasMore={handleHasMore}
        loader={
          <Box className="videocards-spinner-loadmore">
            <Box component="i" className="fas fa-spinner fa-spin" aria-hidden="true" />
          </Box>
        }
        endMessage={<AllResultText videos={videos} />}
        scrollThreshold="90%"
      >
        {cardType === "video" && (
          <Box className="wrap-videocards-horizontal-list">
            {videos.map((video) => (
              <VideoThumbnail
                key={video.id}
                data={video}
                cardLayout={cardLayout}
                cardType={cardType}
              />
            ))}
          </Box>
        )}
        {cardType === "playlists" && (
          <Box
            className={
              cardType === "playlists" ? "wrap-videocards-horizontal-list" : ""
            }
          >
            {videos.map(
              (playlist) =>
                playlist?.content_overview?.count && (
                  <VideoThumbnail
                    key={playlist.id}
                    data={playlist}
                    cardLayout={cardLayout}
                    cardType={cardType}
                  />
                )
            )}
          </Box>
        )}
        {cardType === "playlist" && (
          <Box
            className={
              cardType === "playlists" ? "wrap-videocards-horizontal-list" : ""
            }
          >
            {videos.map((playlist) => (
              <VideoThumbnail
                key={playlist.id}
                data={playlist}
                cardLayout={cardLayout}
                cardType={cardType}
              />
            ))}
          </Box>
        )}
        {cardType === "history" && (
          <Box>
            {videos.map((history) => (
              <VideoThumbnail
                key={history.id}
                data={history}
                cardLayout="horizontal"
                cardType="history"
                onDelete={handleDelete}
              />
            ))}
          </Box>
        )}
        {cardType === "search" && (
          <Box>
            {videos.map((search) => (
              <VideoThumbnail
                key={search.id}
                data={search}
                cardLayout="horizontal"
                cardType={cardType}
              />
            ))}
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};

export default VideoCards;
