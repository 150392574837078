/**
 * This file contains the ActionButtons component.
 * It renders buttons for clearing the chat history and closing the chatbox.
 */

import "./ActionButtons.scoped.css";

const ActionButtons = ({ closeSidebar, newChat, hideClearChatButton }) => {
  return (
    <div className="va-action-button-container">
      {!hideClearChatButton && (
        <div className="va-action-button-wrapper">
          <button onClick={newChat} className="va-action-button">
            <img
              src={process.env.PUBLIC_URL + `/assets/Airis/svg/trash.svg`}
              alt="trash"
              className="new-chat-icon"
            />
            <span className="visually-hidden">Clear Chat</span>
          </button>
          <div className="va-action-popup-container">
            <div className="va-action-popup-triangle"></div>
            <span>Clear Chat</span>
          </div>
        </div>
      )}

      <div className="va-action-button-wrapper">
        <button onClick={closeSidebar} className="va-action-button">
          <img
            src={process.env.PUBLIC_URL + `/assets/Airis/svg/x-bold.svg`}
            alt="x"
            className="close-chat-icon"
          />
          <span className="visually-hidden">Close Chat</span>
        </button>
        <div className="va-action-popup-container">
          <div className="va-action-popup-triangle"></div>
          <span>Close Chat</span>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
