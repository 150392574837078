import React, { useState } from "react";
import CreatePlaylistModal from "../../CreatePlaylist/CreatePlaylistModal";
import ShareModal from "../../ShareModal/ShareModal";
import "../../../pages/VideoPage/VideoPage.scoped.css";
import { useSelector, useDispatch } from "react-redux";
import SubjectIcon from "@mui/icons-material/Subject";
import Button from "@mui/material/Button";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setHasEnded } from "../../../reducers/videoPlayerSlice";
import ReportFlagModal from "../../ReportFlagModal/ReportFlagModal";
import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";

const flagging_bad_translations = `${process.env.REACT_APP_PROD_URL}/user/badTranslations`;
const send_additional_Flagged_Feedback = `${process.env.REACT_APP_PROD_URL}/user/additionalFlaggedFeedback`;

const VideoDescHeader = ({
  handleTranscriptBtnClick,
  flaggedTranscripts,
  resetFlaggedTranscripts,
}) => {
  const navigate = useNavigate();

  const [videoSubscribe, setVideoSubscribe] = useState(false);
  const [videoSubscriberCount, setVideoSubscriberCount] = useState(9);
  const [videoLike, setVideoLike] = useState(true);

  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] =
    useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isReportFlagModalOpen, setReportFlagModalOpen] = useState(false);
  const dashVideoObject = useSelector((state) => state.dashVideoObject);
  const hasEnded = useSelector(({ videoPlayer }) => videoPlayer.hasEnded);
  const dispatch = useDispatch();
  console.log(dashVideoObject);

  const toggleCreatePlaylistModal = () => {
    setIsCreatePlaylistModalOpen(!isCreatePlaylistModalOpen);
  };

  const handleVideoSubscribeBtnClick = () => {
    if (videoSubscribe) {
      setVideoSubscriberCount(videoSubscriberCount - 1);
    } else {
      setVideoSubscriberCount(videoSubscriberCount + 1);
    }
    setVideoSubscribe(!videoSubscribe);
  };

  const handleVideoLikeBtnClick = () => {
    setVideoLike(!videoLike);
  };

  const toggleShareModal = () => {
    setShareModalOpen(!isShareModalOpen);
  };

  const toggleReportFlagModal = () => {
    setReportFlagModalOpen(!isReportFlagModalOpen);
  };

  //Logic for sending initial flags and suggested translations
  const handleFlagSubmit = async () => {
    const reportData = {
      flaggedTranscripts,
      videoId: dashVideoObject?.id,
    };
    console.log("Submitting flag data intially:", reportData);
    try {
      const res = await axios.post(flagging_bad_translations, reportData, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Successfully submited", res);
      toggleReportFlagModal();
      handleTranscriptBtnClick();
      resetFlaggedTranscripts();
      dispatch(setHasEnded(false));
    } catch (err) {
      console.error("Error submitting the report:", err);
      throw err;
    }
  };

  //Logic for sending rating and additional feedback for flag data to backend
  const handleReportFlagSubmit = async (reportFlagData) => {
    const reportAdditionalData = {
      reportFlagData,
      videoId: dashVideoObject?.id,
    };
    console.log("Submitting additional feedback:", reportAdditionalData);
    try {
      const response = await axios.post(
        send_additional_Flagged_Feedback,
        reportAdditionalData,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Successfully submited additional feedback", response);
    } catch (err) {
      console.error("Error submitting the report:", err);
      throw err;
    }
  };

  const handleStartWatchParty = () => {
    navigate(`/CONTENT-PLATFORM/watch-party/${dashVideoObject?.id}`);
  };

  return (
    <Box className="header">
      <Box className="header-user">
        <img
          alt="your profile"
          src={process.env.PUBLIC_URL + `/assets/general/image/profile-pic.jpg`}
          sx={{ width: 50, height: 50 }}
        />
        <Box>
          <Typography variant="h1">Jane Doe</Typography>
          <Typography variant="h2">
            {videoSubscriberCount} Subscribers
          </Typography>
        </Box>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#3f59af",
            textTransform: "capitalize",
          }}
          onClick={handleVideoSubscribeBtnClick}
        >
          {videoSubscribe ? "Unsubscribe" : "Subscribe"}
        </Button>
      </Box>
      <div className="header-buttons">
        {hasEnded && flaggedTranscripts.length > 0 && (
          <button className="button" onClick={handleFlagSubmit}>
            <i className="fa-solid fa-paper-plane"></i>
            Report Flagged Translations
          </button>
        )}
        <Button
          variant="contained"
          startIcon={<SubjectIcon style={{ fontSize: "18px" }} />}
          style={{
            backgroundColor: "#585858",
            textTransform: "capitalize",
          }}
          onClick={handleTranscriptBtnClick}
        >
          Transcript
        </Button>
        <Button
          variant="contained"
          startIcon={<ThumbUpIcon style={{ fontSize: "14px" }} />}
          style={{
            backgroundColor: videoLike ? "#585858" : "#3f59af",
            textTransform: "capitalize",
          }}
          onClick={handleVideoLikeBtnClick}
        >
          Like
        </Button>
        <Button
          variant="contained"
          startIcon={<GroupsOutlinedIcon style={{ fontSize: "18px" }} />}
          style={{ backgroundColor: "#585858", textTransform: "capitalize" }}
          onClick={handleStartWatchParty}
        >
          Watch Party
        </Button>
        <>
          <Button
            variant="contained"
            startIcon={<ShareIcon style={{ fontSize: "18px" }} />}
            style={{ backgroundColor: "#585858", textTransform: "capitalize" }}
            onClick={toggleShareModal}
          >
            Share
          </Button>
          <ShareModal isOpen={isShareModalOpen} onClose={toggleShareModal} />
        </>
        <Button
          variant="contained"
          startIcon={<DownloadIcon style={{ fontSize: "18px" }} />}
          style={{ backgroundColor: "#585858", textTransform: "capitalize" }}
        >
          Download
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon style={{ fontSize: "18px" }} />}
          style={{ backgroundColor: "#585858", textTransform: "capitalize" }}
          onClick={toggleCreatePlaylistModal}
        >
          Add to Playlist
        </Button>
        {isCreatePlaylistModalOpen && (
          <CreatePlaylistModal
            contentId={dashVideoObject?.id}
            onClose={toggleCreatePlaylistModal}
          />
        )}
      </div>
      <ReportFlagModal
        open={isReportFlagModalOpen}
        handleClose={toggleReportFlagModal}
        onSubmit={handleReportFlagSubmit}
      />
    </Box>
  );
};

export default VideoDescHeader;
