import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedRegion: 'United States of America',
    selectedRegionFlag: 'us.png'
}


const regionSlice = createSlice({
    name: 'region', 
    initialState,
    reducers: {
        setRegion: (state, action) => {
            state.selectedRegion = action.payload.name
            state.selectedRegionFlag = action.payload.flag
            state.selectedRegionCode = action.payload.code

        },
    },
})

export const { setRegion } = regionSlice.actions

export default regionSlice.reducer
  