import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./SearchResultPage.scoped.css";
import useLoadMore from "../../hooks/useLoadMore";
import { fetchVideosList } from "../../api/video";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../reducers/authSlice";
import { setToast } from "../../reducers/snackbarSlice";
import { Box, Typography } from "@mui/material";
import { Grid2 } from "@mui/material";
import AudioComponent from "../../components/Audio/AudioComponent";
import TextThumbnailGrid from "../../components/TextThumbnail/TextThumbnailGrid";
import VideoThumbnail from "../../components/VideoCards/VideoThumbnail/VideoThumbnail";

const SearchResultPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const queryParamsObject = {};
  const [paramObj, setParamObj] = useState({});
  const [hasAudio, setHasAudio] = useState(true);
  const [hasText, setHasText] = useState(true);
  const [tabData, setTabData] = useState([]);
  const dataTemp = [
    {
      id: 1,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 1",
      description: "This is the description for Audio 1.",
    },
    {
      id: 2,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 2",
      description: "This is the description for Audio 1.",
    },
    {
      id: 3,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 3",
      description: "This is the description for Audio 1.",
    },
    {
      id: 4,
      image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
      title: "Audio 4",
      description: "This is the description for Audio 2.",
    },
    {
      id: 5,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 1",
      description: "This is the description for Audio 1.",
    },
    {
      id: 6,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 2",
      description: "This is the description for Audio 1.",
    },
    {
      id: 7,
      image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
      title: "Audio 3",
      description: "This is the description for Audio 1.",
    },
    {
      id: 8,
      image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
      title: "Audio 4",
      description: "This is the description for Audio 2.",
    },
    {
      id: 9,
      image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
      title: "Audio 4",
      description: "This is the description for Audio 2.",
    },
  ];

  searchParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  useEffect(() => {
    let obj = {};
    searchParams.forEach((value, key) => {
      obj[key] = value;
    });
    setParamObj(obj);
  }, [searchParams]);

  useEffect(() => {
    console.log("Call APIs", paramObj);
    if (Object.keys(paramObj).length !== 0) {
      console.log("Not empty Call APIs", paramObj);
      setPage(1);
      handleLoadMore(true);
    }
  }, [paramObj]);

  const updateTabList = (list) => {
    let tags = [];
    if (list) {
      list.forEach((item) => {
        let newTags = item?.document?.tags;
        tags = [...tags, ...newTags];
      });
    }
    let tagList = [...new Set(tags)];
    console.log("new List update Tabs", tagList);
    tagList = tagList.slice(0, 5);
    let tagArray = tagList.map((tag) => ({
      title: `${tag[0].toUpperCase()}${tag.slice(1, tag.length).toLowerCase()}`,
      link: tag,
    }));
    setTabData(tagArray);
  };

  const fetchVideos = async (newList) => {
    let data = {
      ...paramObj,
      limit: 10,
      page: newList ? 1 : page,
    };
    setPage((p) => p + 1);
    try {
      let res = await fetchVideosList(data);
      if (res?.status === 200) {
        if (newList) {
          updateTabList(res?.data);
        }
        return res.data;
      }
      return [];
    } catch (err) {
      if (err?.response?.status === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error ocurred. Please try again later",
            type: "red",
          })
        );
      }
      return [];
    }
  };

  const { data, handleLoadMore, hasMore, isLoading } = useLoadMore(
    [],
    fetchVideos
  );

  return (
    <Box
      sx={{
        mx: "2vw",
        mt: "3vh",
        mb: "10vh",
      }}
    >
      <Typography sx={{ pb: 8 }} className="search-result-info">
        Showing results for: {'"'}
        {searchParams.getAll("query").toString().trim()}
        {'"'}
      </Typography>
      {data.length > 0 && (
        <Box>
          <Typography
            sx={{
              borderRadius: 10,
              bgcolor: "#575757",
              px: "6vh",
              py: 3,
              width: "fit-content",
              mb: 5,
            }}
            className="search-result-title"
          >
            Video Content
          </Typography>
          {queryParamsObject.category !== "shorts" && (
            <Grid2 container spacing={8} columns={5}>
              {data.map((video) => (
                <Grid2 size={1}>
                  <VideoThumbnail
                    key={video.id}
                    data={video}
                    cardLayout="vertical"
                    cardType="search"
                  />
                </Grid2>
              ))}
            </Grid2>
          )}
        </Box>
      )}
      {hasAudio && (
        <Box>
          <Typography
            sx={{
              borderRadius: 10,
              bgcolor: "#575757",
              px: "6vh",
              py: 3,
              width: "fit-content",
              mt: "2vh",
              mb: 5,
            }}
            className="search-result-title"
          >
            Audio Content
          </Typography>
          {/* Replace with real data when api is done */}
          <AudioComponent audioData={dataTemp} />
        </Box>
      )}
      {hasText && (
        <Box>
          <Typography
            sx={{
              borderRadius: 10,
              bgcolor: "#575757",
              px: "6vh",
              py: 3,
              width: "fit-content",
              mt: "2vh",
              mb: 5,
            }}
            className="search-result-title"
          >
            Text Content
          </Typography>
          {/* Replace with real data when api is done */}
          <TextThumbnailGrid textData={dataTemp} />
        </Box>
      )}
    </Box>
  );
};

export default SearchResultPage;
