import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../LoginProd/Form.scoped.css";
import {
  incrementStage,
  updateLocation,
  updateFormData,
} from "../../../../reducers/userPrefReducer";
import { Country, City } from "country-state-city";
import { useState, useEffect, useRef } from "react";
import "./ProfileLocation.scoped.css";

const ProfileLocation = () => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const formData = useSelector((state) => state.userPref.formData);
  const [searchCountryTerm, setSearchCountryTerm] = useState(
    formData.country || ""
  );
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchCityTerm, setSearchCityTerm] = useState(formData.city || "");
  const [filteredCities, setFilteredCities] = useState([]);

  const countryref = useRef(null);
  const cityref = useRef(null);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [countryErrorMessage, setCountryErrorMessage] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");

  const [dropdownWidth, setDropdownWidth] = useState("100%");
  const [isValidCountry, setIsValidCountry] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);

  useEffect(() => {
    setCountries(Country.getAllCountries());

    function setWidth() {
      if (countryref.current) {
        setDropdownWidth(`${countryref.current.offsetWidth}px`);
      }
    }
    setWidth();
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (countryref.current && !countryref.current.contains(event.target)) {
        setIsCountryOpen(false);
      }
      if (cityref.current && !cityref.current.contains(event.target)) {
        setIsCityOpen(false);
      }
    };

    if (isCountryOpen || isCityOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCountryOpen, isCityOpen]);

  useEffect(() => {
    if (searchCountryTerm) {
      const selectedCountry = countries.find(
        (country) =>
          country.name.toLowerCase() === searchCountryTerm.toLowerCase()
      );
      if (selectedCountry?.isoCode) {
        const fetchedCities = City.getCitiesOfCountry(selectedCountry.isoCode);
        setCities(fetchedCities);
      } else {
        setCities([]);
      }
    }
  }, [searchCountryTerm, countries]);

  const handleSearchCountryChange = (e) => {
    const term = e.target.value;
    setSearchCountryTerm(term);
    setSearchCityTerm("");
    setCountryErrorMessage("");
    setCities([]);
    if (term) {
      const filter = countries.filter((country) =>
        country.name.toLowerCase().startsWith(term.toLowerCase())
      );
      setFilteredCountries(filter);
      setIsCountryOpen(true);
    } else {
      setFilteredCountries([]);
    }
    setIsValidCountry(true);
  };

  const handleCountrySelection = (country) => {
    setSearchCountryTerm(country.name);
    setFilteredCountries([]);
    setIsCountryOpen(false);
    if (country.isoCode) {
      setCities(City.getCitiesOfCountry(country.isoCode));
    } else {
      setCities([]);
    }
    setIsValidCountry(true);
  };

  const handleSearchCityChange = (e) => {
    const term = e.target.value;
    setSearchCityTerm(term);
    setCityErrorMessage("");
    if (term) {
      const filter = cities.filter((city) =>
        city.name.toLowerCase().startsWith(term.toLowerCase())
      );
      setFilteredCities(filter);
      setIsCityOpen(true);
    } else {
      setFilteredCities([]);
    }
    setIsValidCity(true);
  };
  const handleCitySelection = (city) => {
    setSearchCityTerm(city.name + ", " + city.stateCode);
    setFilteredCities([]);
    setIsCityOpen(false);
    setIsValidCity(true);
  };

  const validateCountry = () => {
    const isValid = countries.find(
      (country) =>
        country.name.toLowerCase() === searchCountryTerm.toLowerCase()
    );
    if (!isValid && !isCountryOpen) {
      setCountryErrorMessage("Please select a valid country");
    } else {
      if (isValid?.isoCode) {
        setCities(City.getCitiesOfCountry(isValid.isoCode));
      } else {
        setCities([]);
      }
      setCountryErrorMessage("");
    }
  };

  const validateCity = () => {
    const isValid = cities.find(
      (city) =>
        (city.name + ", " + city.stateCode).toLowerCase() ===
        searchCityTerm.toLowerCase()
    );
    if (!isValid && !isCityOpen) {
      setCityErrorMessage("Please select a valid city");
    } else {
      setCityErrorMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedCountry = searchCountryTerm.trim().toLowerCase();
    const selectedCity = searchCityTerm.trim().toLowerCase();

    if (
      !selectedCountry ||
      !countries.find(
        (country) =>
          country.name.toLowerCase() === selectedCountry.toLowerCase()
      )
    ) {
      setCountryErrorMessage("Please select a valid country");
      setIsValidCountry(false);
      return;
    }

    const countryCode = countries?.find(
      (country) => country?.name?.toLowerCase() === selectedCountry
    );
    if (
      !selectedCity ||
      !cities.find(
        (city) =>
          (city.name + ", " + city.stateCode).toLowerCase() ===
          selectedCity.toLowerCase()
      )
    ) {
      setCityErrorMessage("Please select a valid city");
      setIsValidCity(false);
      return;
    }
    console.log("countryCode", countryCode);
    dispatch(
      updateLocation({ country: countryCode?.isoCode, city: selectedCity })
    );
    dispatch(updateFormData({ country: selectedCountry, city: selectedCity }));
    dispatch(incrementStage());
    setIsValidCountry(true);
    setIsValidCity(true);
    setCityErrorMessage("");
  };

  return (
    <form className="flexForm" id="ob-profile-form" onSubmit={handleSubmit}>
      <label>
        {/* <p className="labels"> Country/Region </p> */}
        <div ref={countryref}>
          <input
            className={`inputs ${isValidCountry ? "" : "inputError"} ${
              searchCountryTerm ? "selectedOption" : ""
            }`}
            value={searchCountryTerm}
            onBlur={validateCountry}
            onChange={handleSearchCountryChange}
            placeholder="Please select a country or region"
            name="country"
            required
          />
          {isCountryOpen && (
            <ul className="dropdownMenu" style={{ width: dropdownWidth }}>
              {filteredCountries.map((country, index) => (
                <li
                  key={`${country.name}-${index}`}
                  value={country.isoCode}
                  onClick={() => handleCountrySelection(country)}
                >
                  {country.name}
                </li>
              ))}
            </ul>
          )}
          <p className="fieldError" style={{ paddingBottom: "0px" }}>
            &nbsp;{countryErrorMessage}
          </p>
        </div>
      </label>
      <label>
        <div ref={cityref}>
          <input
            className={`inputs ${isValidCity ? "" : "inputError"} ${
              searchCityTerm ? "selectedOption" : ""
            }`}
            value={searchCityTerm}
            onChange={handleSearchCityChange}
            placeholder="Please select a city or district"
            required
            disabled={!searchCountryTerm || countryErrorMessage}
            onBlur={validateCity}
          />
          {isCityOpen && (
            <ul
              className="dropdownMenu"
              style={{ maxHeight: "152px", width: dropdownWidth }}
            >
              {filteredCities.map((city, index) => (
                <li
                  key={`${city.name}-${index}`}
                  value={city.stateCode}
                  onClick={() => handleCitySelection(city)}
                >
                  {city.name + ", " + city.stateCode}
                </li>
              ))}
            </ul>
          )}
          <p className="fieldError" style={{ paddingBottom: "0px" }}>
            &nbsp;{cityErrorMessage}
          </p>
        </div>
      </label>
      {/* <Button label="Next" /> */}
    </form>
  );
};

export default ProfileLocation;
