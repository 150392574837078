const tourSampleTagData = [
  {
    id: 1,
    title: "CTV News Spotlight: Inside the Canadian Government",
    url: "https://youtu.be/YgKs-URGWvY?t=20",
    thumbnail:
      "https://www.ctvnews.ca/content/dam/ctvnews/en/images/2023/7/21/justin-trudeau-1-6489988-1689992013729.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    views: "3.4M",
    channelName: "HexClad Cookware",
    channelImg:
      "https://yt3.ggpht.com/hA10unX8u0g5Ckp8xYNHxG1R0q3YuZQvyLgJ1S4A4nlH1AB9BIZIvUr1peGmBEDErG2LvavPBH0=s48-c-k-c0x00ffffff-no-rj",
    lat: 43.651,
    lng: -79.347,
    videoId: "eggs",
    category: "Health & Fitness",
    date: "2023-01-05",
    __threeObj: {
      metadata: {
        version: 4.6,
        type: "Object",
        generator: "Object3D.toJSON",
      },
      object: {
        uuid: "379a467f-1cbb-473e-925e-1868cb360430",
        type: "Object3D",
        layers: 1,
        matrix: [
          1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -71.10870473659956,
          69.02638688043201, 13.375725210844656, 1,
        ],
        up: [0, 1, 0],
      },
    },
  },
  {
    id: 3,
    title:
      "'Wonka' stars Timothée Chalamet & Hugh Grant | AP extended interview",
    url: "./Wonka_mute.mp4",
    thumbnail: "https://i.ytimg.com/vi/YgTm_caUkIA/maxresdefault.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    views: "240K",
    channelName: "Associated Press",
    channelImg:
      "https://yt3.ggpht.com/eYjjY5MUJ422vBuGFg--wNR1b093BaAFzJhbZYLhp8rye5gcwXyPQAtNz2j_4wXSf-Qc5J3UsA=s48-c-k-c0x00ffffff-no-rj",
    lat: 45.5017,
    lng: -73.5673,
    videoId: "wonka",
    category: "News",
    date: "2023-03-08",
    __threeObj: {
      metadata: {
        version: 4.6,
        type: "Object",
        generator: "Object3D.toJSON",
      },
      object: {
        uuid: "21823d49-335e-4560-a5ce-307b3f885004",
        type: "Object3D",
        layers: 1,
        matrix: [
          1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -67.22587006119277,
          71.32712452367393, 19.82734731879032, 1,
        ],
        up: [0, 1, 0],
      },
    },
  },
  {
    id: 4,
    title:
      "Taylor Swift: 'Lover', Politics, & Friendship with Selena Gomez | Apple Music",
    url: "./TS Video Muted.mp4",
    thumbnail: "https://i.ytimg.com/vi/TC1UnBDfrQA/maxresdefault.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    views: "783K",
    channelName: "Apple Music",
    channelImg:
      "https://yt3.ggpht.com/ytc/AIf8zZT1nbQ7re2-12jCsO1JNGaYWTW1nrtSwmGoMobA1w=s48-c-k-c0x00ffffff-no-rj",
    lat: 49.2827,
    lng: -123.1207,
    videoId: "swift",
    category: "Music",
    date: "2023-04-12",
    __threeObj: {
      metadata: {
        version: 4.6,
        type: "Object",
        generator: "Object3D.toJSON",
      },
      object: {
        uuid: "cdb19099-9bf1-4c50-aa19-613cd91ae600",
        type: "Object3D",
        layers: 1,
        matrix: [
          1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -54.63380392405145,
          75.7937405770936, -35.64346164614955, 1,
        ],
        up: [0, 1, 0],
      },
    },
  },
  {
    id: 5,
    title: "What You Don't Know About the Real James Bond",
    url: "./arb 3_Main_video.mp4",
    thumbnail: "https://i.ytimg.com/vi/s0Mcz3viXEc/sddefault.jpg",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    views: "129K",
    channelName: "Ahmad Fakhouri",
    channelImg:
      "https://yt3.ggpht.com/ytc/AIf8zZQI3-CAeVuuPQz7_763zHXyeEr_XU14AfsGUYaY2g=s48-c-k-c0x00ffffff-no-rj",
    lat: 51.0447,
    lng: -114.0719,
    videoId: "bond",
    category: "Series",
    date: "2023-05-20",
    __threeObj: {
      metadata: {
        version: 4.6,
        type: "Object",
        generator: "Object3D.toJSON",
      },
      object: {
        uuid: "8abb1af9-074c-48fd-a3f2-be3621e9e95b",
        type: "Object3D",
        layers: 1,
        matrix: [
          1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -57.403737293818025,
          77.76366968665162, -25.644153750273468, 1,
        ],
        up: [0, 1, 0],
      },
    },
  },
];

export default tourSampleTagData;
