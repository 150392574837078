import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import "./TourBox.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementStage,
  incrementStage,
} from "../../../reducers/userPrefReducer";

/**
 * A component that renders a tour box with an arrow
 *
 * @param {string} arrowDirection - should be either: arrow-top, arrow-right, arrow-bottom, arrow-left
 */

const TourBox = ({
  content = false,
  arrowDirection,
  title,
  tourText,
  backArrow = true,
  stage,
  progressBar = false,
  tourContent,
}) => {
  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);
  const dispatch = useDispatch();

  return (
    <div className={`box ${arrowDirection}`}>
      <div className="box-content">
        <div
          className="go-next"
          onClick={() => {
            dispatch(incrementStage());
          }}
        />
        <div className="box-header">
          {backArrow && (
            <FaArrowLeft
              size={32}
              cursor="pointer"
              style={{ zIndex: 3000 }}
              onClick={() => {
                dispatch(decrementStage());
              }}
            />
          )}
          {content && <div className="airis-content">{tourContent}</div>}

          {progressBar && (
            <div className="progress-container">
              <div
                className={`progress-step ${
                  tourStep >= 3 && tourStep <= 9 && tourStep !== 6 && "done"
                }`}
              />
              <div
                className={`progress-step ${
                  (tourStep === 4 ||
                    tourStep === 5 ||
                    tourStep === 8 ||
                    tourStep === 9) &&
                  "done"
                }`}
              />
              <div
                className={`progress-step ${
                  (tourStep === 5 || tourStep === 9) && "done"
                }`}
              />
            </div>
          )}
          <h2>{title}</h2>
        </div>
        <p>{tourText}</p>
      </div>
      <p className="stage-number">{`${stage} of 3`}</p>
    </div>
  );
};

export default TourBox;
