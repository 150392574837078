import React from "react";
import { Grid2, Box } from "@mui/material";

import TextThumbnail from "./TextThumbnail";

// Sample data array
const cardData = [
  {
    id: 1,
    image:
      "https://cdn4.iconfinder.com/data/icons/photography-and-videography-1/24/picture-video-photo-photography--256.png",
    title: "Text Title",
    description: "This is the description for Text.",
  },
  {
    id: 2,
    image:
      "https://cdn4.iconfinder.com/data/icons/photography-and-videography-1/24/picture-video-photo-photography--256.png",
    title: "Text Title",
    description: "This is the description for Text.",
  },
  {
    id: 3,
    image:
      "https://cdn4.iconfinder.com/data/icons/photography-and-videography-1/24/picture-video-photo-photography--256.png",
    title: "Text Title",
    description: "This is the description for Text.",
  },
  {
    id: 4,
    image:
      "https://cdn4.iconfinder.com/data/icons/photography-and-videography-1/24/picture-video-photo-photography--256.png",
    title: "Text Title",
    description: "This is the description for Text.",
  },
  {
    id: 5,
    image:
      "https://cdn4.iconfinder.com/data/icons/photography-and-videography-1/24/picture-video-photo-photography--256.png",
    title: "Text Title",
    description: "This is the description for Text.",
  },
];

const TextThumbnailGrid = ({ textData = cardData }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid2 container spacing={8} columns={5}>
        {textData.map((card) => (
          <Grid2 item size={1} key={card.id}>
            <TextThumbnail
              image={card.image}
              title={card.title}
              description={card.description}
            />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default TextThumbnailGrid;
