import { useState } from "react";
import i18next from "i18next";

import AirisBlob from "../AirisBlob/AirisBlob";
import TypewriterAnimation from "./TypewriterAnimation/TypewriterAnimation";
import VosynVideoCard from "./content/AirisVideos/VosynVideoCard";
import YTVideoCard from "./content/AirisVideos/YTVideoCard";

import "./ResultsBlock.scoped.css";

/**
 * ResultsBlockWrapper component represents a block in the chat containing various types of responses.
 * It dynamically renders different types of content based on the message received from the chat.
 * 
 * Props:
 * - block: The block of content to be displayed.
 * - message: The message containing information about the response.
 * - typeAnimationScroll: Function to trigger animation scroll.
 * - scrollToBottom: Function to scroll to the bottom of the chat.
 * - lastElement: Boolean indicating if this is the last element in the chat.
 */
const ResultsBlockWrapper = ({
  block,
  message,
  typeAnimationScroll,
  scrollToBottom,
  lastElement,
}) => {
  const {
    language,
    languageType,
    resultsLoading,
    errorResponse,
    videoResponse,
    thirdParty,
    typewrited,
  } = message;

  //get the translated text
  const fixedT = i18next.getFixedT(language, "airis");
  const errorText = fixedT("airis.error");
  const videoRecommendationText = fixedT("airis.vosynVideoSuggestion");
  const showVideoDescriptionText = fixedT("airis.showDescription");
  const hideVideoDescriptionText = fixedT("airis.hideDescription");
  const ytVideoRecommendationText = fixedT("airis.ytVideoSuggestion");
  const ytRedirectText = fixedT("airis.ytLink");

  //state for the text loading 
  const [textLoading, setTextLoading] = useState(true);

  //Function to handle the text finish loading
  const setTextFinishLoading = () => {
    setTextLoading(false);
  };

  return (
    <div className="results-block-container">
      <div className="text-response-container">
        <span>VosynAssist</span>
        <div className="blob-and-text-container">
          <AirisBlob
            size={56}
            roughness={8}
            glowSpread={8}
            animationState={errorResponse ? 2 : resultsLoading ? 1 : 0}
            initialAnimationState={0}
            animationEnabled={lastElement}
          />
          {/* Render loading text or TypewriterAnimation */}
          {resultsLoading ? (
            <div className="loading-results-container">
              <div className="loading-text"></div>
            </div>
          ) : (
            <div
              className="ai-response-container text-response"
              dir={languageType}
            >
              <TypewriterAnimation
                text={
                  errorResponse
                    ? errorText
                    : videoResponse
                      ? videoRecommendationText
                      : thirdParty
                        ? ytVideoRecommendationText
                        : block.textBlockList
                }
                typeAnimationScroll={typeAnimationScroll}
                scrollToBottom={scrollToBottom}
                setTextFinishLoading={setTextFinishLoading}
                typewrited={typewrited}
                delay={450}
              />
            </div>
          )}
        </div>
      </div>
      {/* Render VosynVideoCard if videoResponse is available and text has finished loading */}
      {videoResponse && (typewrited || !textLoading) && (
        <VosynVideoCard
          videoResponse={videoResponse}
          hideDescriptionText={hideVideoDescriptionText}
          showVideoDescriptionText={showVideoDescriptionText}
        />
      )}
      {/* Render YTVideoCard if thirdParty is available and text has finished loading */}
      {thirdParty && (typewrited || !textLoading) && (
        <YTVideoCard
          ytURL={block?.textBlockList || ""}
          ytRedirectText={ytRedirectText}
        />
      )}
    </div>
  );
};

export default ResultsBlockWrapper;
