import React, { useState } from "react";
import { Box, ButtonBase, Button, Typography } from "@mui/material";
import "./OnboardingBody.scoped.css";

const OnboardingBody = ({ onboardingStep, steps, handleGoForward }) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionClick = (stepIndex, optionId) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [stepIndex]: optionId,
    }));
  };

  return (
    <Box className="onboarding-body">
      <Box className="onboarding-body-container">
        <Box className="stepper-header">
          <Typography className="stepper-title" variant="h5" gutterBottom>
            {steps[onboardingStep].stepperTitle.text}
            <Typography
              component="span"
              variant="h5"
              className="stepper-title title-highlight"
            >
              {steps[onboardingStep].stepperTitle.highlightText}
            </Typography>
          </Typography>
        </Box>
        <Box className="stepper-subheading">
          <Typography variant="body1" gutterBottom>
            {steps[onboardingStep].stepperSubheading}
          </Typography>
        </Box>
        <Box className="stepper-options">
          {steps[onboardingStep].options.map((option, index) => (
            <ButtonBase
              className={`option-button ${
                selectedOptions[onboardingStep] === option.id ? "selected" : ""
              }`}
              key={index}
              variant="outlined"
              disableRipple
              onClick={() => handleOptionClick(onboardingStep, option.id)}
            >
              <Typography
                component="span"
                className={option.optionText.boldText ? "option-bold" : ""}
              >
                {option.optionText.boldText
                  ? option.optionText.boldText
                  : option.optionText}
              </Typography>
              &nbsp;
              <Typography component="span" className="option-italic">
                {option.optionText.italicText}
              </Typography>
            </ButtonBase>
          ))}
        </Box>
        <Box className="continue-button-container">
          <Button
            className="continue-button"
            variant="contained"
            disableElevation
            disableRipple
            onClick={handleGoForward}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingBody;
