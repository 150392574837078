import Dropdown from "../Dropdown/Dropdown";
import {useRef, useState} from "react";
import {setRegion} from "../../reducers/regionSlice";
import {useDispatch} from "react-redux";
import RegionModal from "./RegionModal"; 
import './RegionSelector.scoped.css';

const CountrySelector = ({ selectedCountry, regionList, setSelectedCountry }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const handleModalOpen = () => setIsOpen(true);
    const handleModalClose = () => setIsOpen(false);

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        dispatch(setRegion(country));
        sessionStorage.setItem("selectedCountry", JSON.stringify(country));
        handleModalClose();
    };
    return (
        <div className={`country-dropdown`}>
            <div className="country-dropdown-header" onClick={handleModalOpen}>
                <img
                    src={process.env.PUBLIC_URL + "/assets/flags/" + selectedCountry.flag}
                    alt={selectedCountry.name}
                />
                <i className={`fa-solid fa-chevron-down ${isOpen && "rotate"}`} />
            </div>
            <RegionModal isOpen={isOpen} onClose={handleModalClose}>
                <Dropdown
                    search
                    list={regionList}
                    handleSelection={handleCountrySelect}
                    type="Region"
                    height={400}
                />
            </RegionModal>
        </div>
    );
}

export default CountrySelector