import React, { useState } from "react";
import Background from "../../components/Background/Background";
import CenterBox from "../../components/CenterBox/CenterBox";
import "./ResetPasswordPage.scoped.css";
import { useSearchParams, Navigate, useNavigate } from "react-router-dom";
import { userChangePasswordloggedout } from "../../api/auth";
import { setToast } from "../../reducers/snackbarSlice";
import { useDispatch } from "react-redux";

const ResetPasswordPage = ({ onFormSubmit = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const usertoken = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const NEW_PASSWORD = "Input new password";
  const PASSWORD_GENERIC_ERROR = "Some error ocurred. Please try again later";

  if (!usertoken) return <Navigate to="/CONTENT-PLATFORM/auth/login" />;

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordError("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError("");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Validate the new password and check that the two passwords match before calling form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Password validation
    if (!newPassword || !confirmPassword) {
      setPasswordError("Please enter both new and confirm passwords.");
      return;
    }

    if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(
        newPassword
      )
    ) {
      setPasswordError(
        "It must be a combination of minimum 8 letters, numbers and symbols"
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    sendNewPasswords();

    console.log("New Password:", newPassword);
    console.log("Confirm Password:", confirmPassword);

    // You can pass newPassword and confirmPassword to onFormSubmit function
    onFormSubmit({ newPassword, confirmPassword });
  };

  // call the backend to change password and provide an error if the call fails
  const sendNewPasswords = async () => {
    let data = {
      password: newPassword,
      password2: confirmPassword,
      token: usertoken,
    };
    try {
      setIsLoading(true);
      let res = await userChangePasswordloggedout(data);
      setIsLoading(false);
      if (res?.status === 200) {
        navigate("/CONTENT-PLATFORM/auth/login");
        dispatch(
          setToast({
            showSnackBar: true,
            message:
              "Password has been reset successfully. Please login to continue",
            type: "green",
          })
        );
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err?.response);
      let statusCode = err?.response?.status;
      if (statusCode && statusCode === 400 && err?.response?.token) {
        console.log("Token Expired");
        dispatch(
          setToast({
            showSnackBar: true,
            message: PASSWORD_GENERIC_ERROR,
            type: "red",
          })
        );
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: PASSWORD_GENERIC_ERROR,
            type: "red",
          })
        );
      }
    }
  };

  return (
    <Background>
      <CenterBox
        airisText={
          "Forgot your password? Don't worry! Just tell me your new one below"
        }
        maxWidth={516}
        style={{
          paddingTop: "72px",
          paddingBottom: "10px",
          paddingLeft: "53px",
          paddingRight: "53px",
        }}
      >
        <form className="resetForm" onSubmit={handleSubmit}>
          <label htmlFor="new-password" className="label">
            New Password:
          </label>

          <div className="passwordInputGroup">
            <input
              type={showPassword ? "text" : "password"}
              id="new-password"
              placeholder={NEW_PASSWORD}
              value={newPassword}
              onChange={handleNewPasswordChange}
              className={`passwordInput ${passwordError && "error"}`}
            />
            <button
              type="button"
              onClick={handleTogglePasswordVisibility}
              className="showPasswordButton"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/assets/ResetPassword/svg/show-password-icon.svg`
                }
                alt="Show Password"
                className="showPasswordIcon"
              />
            </button>
          </div>
          <label htmlFor="confirm-password" className="confirmLabel">
            Confirm Password:
          </label>
          <div className="passwordInputGroup">
            <input
              type={showPassword ? "text" : "password"}
              id="confirm-password"
              placeholder={NEW_PASSWORD}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={`passwordInput ${passwordError && "error"}`}
            />
            <button
              type="button"
              onClick={handleTogglePasswordVisibility}
              className="showPasswordButton"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  `/assets/ResetPassword/svg/show-password-icon.svg`
                }
                alt="Show Password"
                className="showPasswordIcon"
              />
            </button>
          </div>

          {passwordError && <p className="fieldError">{passwordError}</p>}

          <button disabled={isLoading} type="submit" className="resetButton">
            Reset Password
          </button>
        </form>
      </CenterBox>
    </Background>
  );
};

export default ResetPasswordPage;
