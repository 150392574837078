import React, { useState } from 'react';
import { TextField, Button, InputAdornment } from '@mui/material';
import Earth from '../Earth/Earth';
import countriesData from '../../data/countries.json';
import SearchIcon from '@mui/icons-material/Search';
import './RegionModal.scoped.css'; // Add your styles here

const RegionModal = ({ isOpen, onClose, regionList }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEarthVisible, setIsEarthVisible] = useState(true);

  // Hardcoded list of countries
  const countries = [
    { name: 'United States', lat: 37.0902, lng: -95.7129 },
    { name: 'Canada', lat: 56.1304, lng: -106.3468 },
    { name: 'Brazil', lat: -14.235, lng: -51.9253 },
    { name: 'United Kingdom', lat: 55.3781, lng: -3.436 },
    { name: 'Australia', lat: -25.2744, lng: 133.7751 },
    // Add more countries as needed
  ];

  if (!isOpen) return null;

  // Handle search input changes
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Check if the searched country exists in the hardcoded list
    const foundCountry = countries.find((country) =>
      country.name.toLowerCase().includes(value.toLowerCase())
    );

    if (foundCountry) {
      setSelectedCountry(foundCountry); // Set the found country
    } else {
      setSelectedCountry(null);
    }
  };

  // Get markers data based on selected country
  const getMarkersData = () => {
    if (!selectedCountry) return [];
    return [
      {
        coordinates: [selectedCountry.lng, selectedCountry.lat],
        size: 1.5,
        color: 'rgba(255, 0, 0, 0.8)',
      },
    ];
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        {/* Left side: Search box */}
        <div className="modal-left" style={{ flex: 1, padding: '20px' }}>
          <TextField
            className='search-input'
            placeholder="Search for region"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              "input::placeholder": {
                color: "#a8aeb5",
                opacity: 1
              }
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }
          }
          />
        </div>

        {/* Right side: Globe */}
        <div className="modal-right" style={{ flex: 2 }}>
          <div className="globe-container">
            {isEarthVisible && (
              <div className="home-earth">
                <Earth countries={countriesData} isCollapsed={isCollapsed} />
              </div>
            )}
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="select-button"
          onClick={() => {
            onClose();
            if (selectedCountry) {
              alert(`Selected country: ${selectedCountry.name}`);
            }
          }}
        >
          Select
        </Button>
      </div>
      {/* Overlay close button */}
      <button className="modal-close" onClick={onClose}>
        X
      </button>
    </div>
  );
};

export default RegionModal;
