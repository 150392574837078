import "./GlobeTour.scoped.css";
import TourBox from "../TourBox/TourBox";

const GlobeTour = ({ onClick }) => {
  return (
    <>
      <div className="globe-highlight"></div>
      <div className="globe-tour">
        <TourBox
          arrowDirection="arrow-left"
          title="Globe"
          tourText={
            "Click and drag to navigate the globe, each pin represents a video with its location"
          }
          stage={1}
          backArrow={false}
        />
      </div>
      <div class="globe-navigate">
        <div class="proceed-tour" onClick={onClick}>
          <span>
            {" "}
            <i class="fa-solid fa-computer-mouse globe-mouse"></i>
          </span>
          <span class="text-icon"> {"CLICK ANYWHERE TO PROCEED FURTHER"}</span>
        </div>
        <hr />
        <div class="skip-tour">
          <span>
            {" "}
            <i class="fa-solid fa-keyboard globe-keyboard"></i>
          </span>
          <span class="text-icon"> {"PRESS ESC TO FINISH THE TOUR"} </span>
        </div>
      </div>
    </>
  );
};

export default GlobeTour;
