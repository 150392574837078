import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { VideocamOutlined, Stop, Close } from "@mui/icons-material";
import { setRecording } from "../../reducers/recordingSlice";
import { uploadVideo } from "../../api/recording";
import { useAlert } from "../../context/AlertProvider";
import "./RecordVideo.scoped.css";

const RecordVideo = ({ onRecorded, children }) => {
  const dispatch = useDispatch();
  const { isRecording } = useSelector((state) => state.recording);
  const { showAlert } = useAlert();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const videoRef = useRef(null);

  const handleStartRecording = useCallback(async () => {
    setOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = async (e) => {
        const videoBlob = new Blob([e.data], { type: "video/mp4" });
        try {
          const response = await uploadVideo(videoBlob);
          onRecorded(response);
          showAlert("success", "Video uploaded and translated successfully!");
        } catch (err) {
          showAlert("error", "Failed to upload video for translation.");
        }
      };

      recorder.start();
      dispatch(setRecording(true));
    } catch (err) {
      setError("Camera or microphone access denied.");
      showAlert("error", "Camera or microphone access denied.");
      setOpen(false);
    }
  }, [dispatch, onRecorded, showAlert]);

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      dispatch(setRecording(false));
      setOpen(false);
    }
  };

  return (
    <>
      {children(handleStartRecording)}

      {/* Modal for video recording */}
      <Dialog
        open={open}
        onClose={handleStopRecording}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            position: "relative",
            overflow: "visible",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <Box className="video-input-container">
             <IconButton
              onClick={handleStopRecording}
              sx={{ alignSelf: "flex-end", color: "white" }}
              aria-label="close"
            >
              <Close />
            </IconButton>

            <Box className="video-display-container">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                style={{ width: "100%", maxWidth: "400px", height: "auto" }}
              />
            </Box>

            <Box className="status-container">
              <Typography className="video-input-status">
                {isRecording ? "Recording in Progress ..." : "Ready to Record"}
              </Typography>
            </Box>

            <IconButton
              onClick={handleStopRecording}
              sx={{ alignSelf: "center", color: "red" }}
              aria-label="stop"
            >
              <Stop sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordVideo;
