import { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiIcon from "../../utils/MuiIcon.js";
import "./Translator.scoped.css";
import Flag from "react-world-flags";

const Translator = () => {
  const [translatedLanguage, setTranslatedLanguage] = useState({
    name: "English",
    code: "US",
  });
  const [originalLanguage, setOriginalLanguage] = useState({
    name: "Japanese",
    code: "JP",
  });

  const handleTranslatedLanguageChange = (event) => {
    setTranslatedLanguage(event.target.value);
  };

  const handleOriginalLanguageChange = (event) => {
    setOriginalLanguage(event.target.value);
  };
  const languageOptionsTranslated = [
    { name: "English", code: "US" },
    { name: "Spanish", code: "ES" },
    { name: "French", code: "FR" },
  ];

  const languageOptionsOriginal = [
    { name: "Japanese", code: "JP" },
    { name: "English", code: "US" },
    { name: "Chinese", code: "CN" },
  ];

  const selectStyles = {
    height: "35px",
    width: "100%",
    backgroundColor: "transparent",
    color: "black",
    border: "0.5px solid darkgrey",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      color: "black",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
    disableScrollLock: false,
  };

  return (
    <Box className="language-selector">
      <FormControl fullWidth className="dropdown-container">
        <Typography
          variant="body1"
          sx={{
            color: "darkgrey",
          }}
        >
          Translated Language
        </Typography>
        <Select
          value={translatedLanguage}
          onChange={handleTranslatedLanguageChange}
          MenuProps={menuProps}
          sx={selectStyles}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "100%",
              }}
            >
              <Flag
                code={selected.code}
                style={{ width: "20px", height: "15px", marginRight: "8px" }}
              />
              <Typography sx={{ flex: 1, textAlign: "center" }}>
                {selected.name}
              </Typography>
            </Box>
          )}
        >
          {languageOptionsTranslated.map((lang) => (
            <MenuItem key={lang.name} value={lang}>
              <ListItemIcon>
                <Flag
                  code={lang.code}
                  style={{ width: "20px", height: "15px" }}
                />
              </ListItemIcon>
              <ListItemText>{lang.name}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth className="dropdown-container">
        <Typography
          variant="body1"
          sx={{
            color: "darkgrey",
          }}
        >
          Original Language
        </Typography>
        <Select
          value={originalLanguage}
          onChange={handleOriginalLanguageChange}
          MenuProps={menuProps}
          sx={selectStyles}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "100%",
              }}
            >
              <Flag
                code={selected.code}
                style={{ width: "20px", height: "15px", marginRight: "8px" }}
              />
              <Typography sx={{ flex: 1, textAlign: "center" }}>
                {selected.name}
              </Typography>
            </Box>
          )}
        >
          {languageOptionsOriginal.map((lang) => (
            <MenuItem key={lang.name} value={lang}>
              <ListItemIcon>
                <Flag
                  code={lang.code}
                  style={{ width: "20px", height: "15px", left: "0" }}
                />
              </ListItemIcon>
              <ListItemText>{lang.name}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box className="compare-text">
        <MuiIcon name="FormatAlignLeft" className="format-align-left-icon" />
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.rem",
            color: "white",
          }}
        >
          Compare
        </Typography>
      </Box>
    </Box>
  );
};

export default Translator;
