import React, { useState, useEffect } from "react";
import ContextualInfo from "../../components/ContextualInfo/ContextualInfo";
import VideoPageHeader from "../../components/VideoPageHeader/VideoPageHeader";
import VideoContainer from "../../components/VideoContainer/VideoContainer";
import { fetchVideoDetails } from "../../api/video";
import { setDashVideoObject } from "../../reducers/dashVideoObjectSlice";
import { setToast } from "../../reducers/snackbarSlice";
import { logout } from "../../reducers/authSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import data from "../../data/dashvideos.json";
import "./WatchPartyPage.scoped.css";
import VideoDescriptionSection from "../../components/VideoDescriptionSection/VideoDescriptionSection";
import WatchPartyChatBox from "../../components/WatchPartyChatBox/WatchPartyChatBox";
import WatchPartyModal from "../../components/WatchPartyModal/WatchPartyModal";
import { Box, Typography } from "@mui/material";
import WatchPartyChatButtons from "../../components/WatchPartyChatButtons/WatchPartyChatButtons";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Translator from "../../components/MediaTranslator/Translator";
import { setIsUserOwner } from "../../reducers/videoPlayerSlice";

/**
 * Fetch and handle video details.
 * @param {string} videoId - The ID of the video to fetch details for.
 * @param {function} setVideoDetails - Function to set the video details in state.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 */

const fetchAndHandleVideoDetails = async (
  videoId,
  setVideoDetails,
  dispatch,
  navigate,
  setVideoUrl
) => {
  try {
    const res = await fetchVideoDetails(videoId);

    if (res?.status === 200) {
      //const isHost = res.data.is_user_session_owner
      //hard coding to true for now
      const isHost = true;
      const videoData = res.data;
      setVideoUrl(videoData.file_stream_cdn_url);
      setVideoDetails(videoData);
      dispatch(
        setDashVideoObject({
          description: videoData.description,
          titles: videoData.titles,
          view_count: videoData.view_count,
          created_at: videoData.created_at,
          id: videoData.id,
        })
      );
      dispatch(setIsUserOwner(isHost));
      return videoData;
    }
  } catch (err) {
    // TODO: Have generalized/modularized code for handling unauthorized requests
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

/**
 * The main VideoPage component that manages the overall structure.
 * @param {Object} searchInputRef - Ref object for search input.
 */

const WatchPartyPage = ({ searchInputRef }) => {
  const { videoId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoViewCount, setVideoViewCount] = useState(0);
  const [videoDetails, setVideoDetails] = useState();
  const [chatBtnSelected, setChatBtnSelected] = useState("chat");

  useEffect(() => {
    fetchAndHandleVideoDetails(
      videoId,
      setVideoDetails,
      dispatch,
      navigate,
      setVideoUrl
    );
  }, [videoId, dispatch, navigate]);

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
  //   };

  //   window.addEventListener("resize", handleOrientationChange);
  //   return () => {
  //     window.removeEventListener("resize", handleOrientationChange);
  //   };
  // }, []);

  // TODO: Need to handle case when video data is loading
  // useEffect(() => {
  //   if (data.length > 0) {
  //     const firstVideo = data[0];
  //     setVideoUrl(firstVideo.file_stream_cdn_url);
  //     setVideoViewCount(firstVideo.view_count);
  //   }
  // }, []);

  const handleGoBackClick = () => {
    navigate(`/CONTENT-PLATFORM/video/${videoId}`);
  };

  const toggleShareModal = () => {
    setIsShareModalOpen((prevState) => !prevState);
  };

  return (
    <Box component="main">
      <Box className="watch-party-content">
        <VideoContainer
          isCollapsed={isCollapsed}
          isPortrait={isPortrait}
          isVisible={isVisible}
        >
          <Box className="watch-party-page-layout">
            <Box className="watch-party-page-leftside">
              <Box className="video-player-container">
                <VideoPlayer
                  src={videoUrl}
                  videoViewCount={videoViewCount}
                  setVideoViewCount={setVideoViewCount}
                  dashVideoObject={videoDetails}
                  searchInputRef={searchInputRef}
                />
              </Box>
              <Box>
                <Typography variant="h2" sx={{ color: "white" }}>
                  {videoDetails?.titles[0]?.title_text || "Unknown title"}
                </Typography>
              </Box>
            </Box>

            <Box className="watch-party-page-rightside">
              <Translator />
              <ContextualInfo />

              <WatchPartyChatButtons
                toggleWatchPartyModal={toggleShareModal}
                chatBtnSelected={chatBtnSelected}
                setChatBtnSelected={setChatBtnSelected}
              />
              <Box className="chatbox-container">
                {chatBtnSelected === "chat" && <WatchPartyChatBox />}
                {chatBtnSelected === "call" && (
                  <Box sx={{ color: "white" }}>Dummy Phone Component</Box>
                )}
              </Box>
            </Box>
          </Box>
        </VideoContainer>
      </Box>
      <WatchPartyModal isOpen={isShareModalOpen} onClose={toggleShareModal} />
    </Box>
  );
};
export default WatchPartyPage;
