export const moreDropdown = {
    video: [
        { icon: 'fas fa-plus-circle', text: 'Add to queue' },
        { icon: 'fas fa-clock', text: 'Save to Watch Later' },
        { icon: 'fas fa-list-alt', text: 'Save to playlist' },
        { icon: 'fas fa-download', text: 'Download' },
        { icon: 'fas fa-flag', text: 'Report' },
    ],
    playlist: [
        { icon: 'fas fa-trash', text: 'Remove from Playlist' },
        { icon: 'fas fa-arrow-up', text: 'Move Top' },
        { icon: 'fas fa-arrow-down', text: 'Move Bottom' },
        { icon: 'fas fa-download', text: 'Download' },
        { icon: 'fas fa-share', text: 'Share' },
        { icon: 'fas fa-flag', text: 'Report' },
    ],
    history: [
        { icon: 'fas fa-plus-circle', text: 'Add to queue' },
        { icon: 'fas fa-clock', text: 'Save to Watch Later' },
        { icon: 'fas fa-list-alt', text: 'Save to playlist' },
        { icon: 'fas fa-download', text: 'Download' },
        { icon: 'fas fa-flag', text: 'Report' },
    ]
}