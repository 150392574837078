import React from "react";
import "./AudioPage.scoped.css";
import { Box, Container, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
//import { fetchAudioDetails } from "../../../api/audio"; //Uncomment this line once API is ready
import { logout, setLoggedIn } from "../../../reducers/authSlice";
import { setToast } from "../../../reducers/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Summary from "../../../components/Summary/Summary";
import AudioPlayerControlPanel from "../../../components/AudioPlayer/AudioPlayerControlPanel/AudioPlayerControlPanel";
import data from "../../../data/audio.json";
import TranslationPanel from "../../../components/TranslationPanel/TranslationPanel";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { setAudioObject } from "../../../reducers/audioObjectSlice";
import dummyTranscriptEN from "../../../data/transcript.json";
import dummyTranscriptES from "../../../data/transcriptES.json";
import dummyTranscriptFR from "../../../data/transcriptFR.json";
import ReviewsAndRatings from "../../../components/ReviewsAndRatings/ReviewsAndRatings";
import ControlPanel from "../../../components/ControlPanel/ControlPanel";
import AudioPlayerTranscript from "../AudioPage/AudioPlayerTranscript/AudioPlayerTranscript";

/**
 * Fetch and handle video details.
 * @param {string} audioId - The ID of the video to fetch details for.
 * @param {function} setAudioDetails - Function to set the video details in state.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 */

const fetchAndHandleAudioDetails = (
  audioId,
  setAudioDetails,
  dispatch,
  navigate
) => {
  try {
    /**
      Uncomment below code once Audio API is ready
      const res = await fetchAudioDetails(audioId);
      if (res?.status === 200) {
        setAudioDetails(res?.data);
      }
     */
    setAudioDetails(data[0].results[0]); //Remove this line once above code is uncommented
    //Uncomment below code once Audio API is ready

    // const res = await fetchAudioDetails(audioId);
    // if (res?.status === 200) {
    setAudioDetails(data[0].results[0]);
    dispatch(
      setAudioObject({
        audioURL: data[0].results[0].audio,
        title: data[0].results[0].title,
        id: data[0].results[0].id,
        description: data[0].results[0].description,
      })
    );
    // }
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

const getTranscript = (language) => {
  return language === "EN"
    ? dummyTranscriptEN
    : language === "ES"
      ? dummyTranscriptES
      : dummyTranscriptFR;
};

function AudioPage({ searchInputRef }) {
  const { audioId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isPortrait, setIsPortrait] = useState(false);
  const [audioDetails, setAudioDetails] = useState();
  const audioRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const originalLanguage = useSelector(
    (state) => state.languages.selectedOriginalLanguage
  );
  const translatedLanguage = useSelector(
    (state) => state.languages.selectedTranslatedLanguage
  );
  const [originalTranscript, setOriginalTranscript] =
    useState(dummyTranscriptEN);
  const [translatedTranscript, setTranslatedTranscript] =
    useState(dummyTranscriptFR);

  useEffect(() => {
    setOriginalTranscript(getTranscript(originalLanguage));
    setTranslatedTranscript(getTranscript(translatedLanguage));
  }, [originalLanguage, translatedLanguage]);

  useEffect(() => {
    fetchAndHandleAudioDetails(audioId, setAudioDetails, dispatch, navigate);
  }, [audioId, dispatch, navigate]);

  const handleGoBackClick = () => {
    navigate("/CONTENT-PLATFORM/services");
  };

  useEffect(() => {
    dispatch(setLoggedIn(true));
    window.scrollTo(0, 0);
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };
  });

  return (
    <>
      {audioDetails && (
        <Container>
          <Box>
            <Button
              variant="text"
              onClick={handleGoBackClick}
              sx={{
                color: "neutral.25",
                textTransform: "none",
              }}
              startIcon={<ArrowBackIosNewIcon />}
              data-testid="back-button-audio-upload"
            >
              Back
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              padding: "0 2rem",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {/* Audio Details */}
            <Box
              sx={{
                width: "70%",
                margin: isMobile ? "0 0 2rem 0" : "0 2rem 0 0",
                minWidth: isMobile ? "100%" : "300px",
              }}
              component="section"
            >
              <Box component="section" className="audio-player">
                <AudioPlayerControlPanel
                  open={true}
                  audioFile={audioDetails}
                  audioRef={audioRef}
                  pageControls={false}
                  pageFunctions={false}
                  setCurrentTimeOnTimeChange={""}
                />
              </Box>

              <Box
                component="section"
                sx={{
                  color: "neutral.25",
                  borderRadius: "0.5rem",
                }}
              >
                <Typography sx={{ fontSize: "1.5rem" }}>
                  {audioDetails.title}
                </Typography>
              </Box>

              <Box
                component="section"
                sx={{
                  color: "neutral.25",
                  margin: "0 0 2rem 0",
                  borderRadius: "0.5rem",
                }}
              >
                <p>{audioDetails.description}</p>
              </Box>

              <Box
                component="section"
                sx={{
                  backgroundColor: "neutral.800",
                  borderRadius: "0.5rem",
                  height: "40%",
                }}
              >
                <Box component="section">
                  <AudioPlayerTranscript fileUrl={audioDetails.audio} />
                  {/* Replace audioDetails.audio with API response data */}
                </Box>
              </Box>
            </Box>

            {/* Audio Detail Section */}
            <Box
              component="section"
              sx={{
                width: "30%",
                margin: isMobile ? "0 0 2rem 0" : "0 2rem 0 0",
                minWidth: isMobile ? "100%" : "300px",
              }}
            >
              <Box component="section" sx={{ margin: "0 0 2rem 0" }}>
                <TranslationPanel fileUrl={audioDetails.audio} />
              </Box>

              <Box component="section" sx={{ margin: "0 0 2rem 0" }}>
                <Summary summary={audioDetails.summary} />
              </Box>

              <Box
                component="section"
                sx={{
                  backgroundColor: "neutral.800",
                  borderRadius: "0.5rem",
                }}
              >
                <ReviewsAndRatings />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
}
export default AudioPage;
