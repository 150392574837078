import React, { useEffect, useState } from "react";
import { Button, Switch, TextField, Typography, Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from 'axios';
import { SETTINGS_URL, UPDATE_SETTINGS_URL } from '../../constant/constantUrls';
import "./ParentalControlSettings.scoped.css"; // Import the CSS file

const ParentalControlSettings = ({ onBack }) => {
  const [isParentalControlEnabled, setParentalControlEnabled] = useState(false);
  const [parentalControlPassword, setParentalControlPassword] = useState("");

  // Fetch settings on component mount
  useEffect(() => {
    axios.get(SETTINGS_URL, {
      headers: { "Content-Type": "application/json" } 
    })
      .then(response => {
        const { enable_parental_control, parental_control_password } = response.data.user;
        setParentalControlEnabled(enable_parental_control);
        setParentalControlPassword(parental_control_password);
      })
      .catch(error => {
        console.error("Error fetching settings:", error);
      });
  }, []);

  const handleToggle = () => {
    const newEnabledState = !isParentalControlEnabled;
    setParentalControlEnabled(newEnabledState);

    // Update settings on toggle
    axios.patch(UPDATE_SETTINGS_URL, {
      enable_parental_control: newEnabledState,
      parental_control_password: newEnabledState ? parentalControlPassword : ""
    }, {
      headers: { "Content-Type": "application/json" } 
    })
      .then(response => {
        console.log("Settings updated successfully:", response.data);
      })
      .catch(error => {
        console.error("Error updating settings:", error);
      });
  };

  const handlePasswordChange = (event) => {
    setParentalControlPassword(event.target.value);
  };

  return (
    <Box bgcolor="#FFFFFF" minHeight="60vh">
      <IconButton onClick={onBack} className="back-button">
        <ArrowBackIosNewIcon />
        <span> Back</span>
      </IconButton>

      <Box className="parental-control-container">
        <Typography variant="h4" className="title">
          Parental Control
        </Typography>

        <Typography variant="body1" className="subtitle">
          Control content access and screen time for a safer experience.
        </Typography>

        <Box className="toggle-section">
          <Typography variant="body1" className="toggle-label">
            Enable Parental Controls
          </Typography>
          <Switch
            checked={isParentalControlEnabled}
            onChange={handleToggle}
            className="parental-control-switch"
          />
        </Box>

        <Box className="manage-restrictions-section">
          <Typography variant="body1" className="restrictions-label">
            Manage Restrictions
          </Typography>
          <Box
            className="link"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              disabled={!isParentalControlEnabled}
              value={parentalControlPassword}
              onChange={handlePasswordChange}
              placeholder="Set password"
              fullWidth
              variant="outlined"
              className={`restrictions-input ${!isParentalControlEnabled ? "disabled" : ""}`}
            />
            <Typography
              variant="body2"
              className="password-reset"
              component="a"
              href="#"
            >
              Create or reset password
            </Typography>
          </Box>
        </Box>

        <Button variant="contained" className="next-button">
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ParentalControlSettings;