import { useMemo } from "react";
import ServiceNav from "../ServiceNav/ServiceNav";
import ServiceSlider from "../ServiceSlider/ServiceSlider";
import ServiceHeader from "../ServiceHeader/ServiceHeader";
import { useParams } from "react-router-dom";
import ServiceWatchlist from "../ServiceWatchlist/ServiceWatchlist";
import useQueryParam from "../../../hooks/useQueryParam";
import "./ServiceNetflix.scoped.css";

const movieData = [
  {
    title: "Movie 1",
    imageUrl: "https://placehold.co/600x400",
    // Other movie information
  },
  {
    title: "Movie 2",
    imageUrl: "https://placehold.co/600x400",
    // Other movie information
  },
];

const ServiceNetflix = () => {
  const categories = useMemo(
    () => ["All", "Movies", "TV Shows", "Watchlist"],
    []
  );
  const { serviceName } = useParams();
  const { getAllParams } = useQueryParam();
  const selectedCategory = useMemo(
    () => getAllParams().category || categories[0],
    [categories, getAllParams]
  );

  return (
    <>
      {selectedCategory === "Watchlist" ? (
        <ServiceWatchlist />
      ) : (
        <div>
          <ServiceHeader serviceName={serviceName} />
          <ServiceNav
            categories={categories}
            selectedCategory={selectedCategory}
            className="service-page-category-nav"
          />
          <h3 className="service-page-subtitle-recommended ">Recommended</h3>
          <ServiceSlider
            items={movieData}
            slidesToShow="4"
            ServiceSliderType="recommended-slider"
            serviceName={serviceName}
          />
          <h3 className="service-page-subtitle">Most Popular</h3>
          <ServiceSlider
            items={movieData}
            slidesToShow="6"
            isLargeRow
            serviceName={serviceName}
          />
          <h3 className="service-page-subtitle">New Releases</h3>
          <ServiceSlider
            items={movieData}
            slidesToShow="4"
            serviceName={serviceName}
          />
        </div>
      )}
    </>
  );
};

export default ServiceNetflix;
