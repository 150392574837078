import { useSelector } from "react-redux";

export default function useProfileCompletion() {
  const userProfile = useSelector(({ userInfo }) => userInfo.userProfile);

  const profileCompletion = {
    language: userProfile.preferred_language,
    location: userProfile.city,
    gender: userProfile.gender,
  };

  const numberOfCompleted = Object.values(profileCompletion).reduce(
    (a, value) => {
      if (value) return a + 1;
      else return a;
    },
    1
  );

  return [profileCompletion, numberOfCompleted];
}
