import React, { useState, useEffect } from "react";
import { SuggestionVideoCard } from "../../../components/SuggestionVideoCard/SuggestionVideoCard";
import { useParams, useNavigate } from "react-router-dom";
import data from "../../../data/dashvideos.json";
import VideoContainer from "../../../components/VideoContainer/VideoContainer";
import { fetchVideoDetails } from "../../../api/video";
import { logout } from "../../../reducers/authSlice";
import { setToast } from "../../../reducers/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDashVideoObject } from "../../../reducers/dashVideoObjectSlice";
import { Box, Button, useMediaQuery } from "@mui/material";
import TranslationPanel from "../../../components/TranslationPanel/TranslationPanel";
import TranslationButton from "../../../components/TranslationPanel/TranslationButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme } from "@emotion/react";
import dummyTranscriptEN from "../../../data/transcript.json";
import dummyTranscriptES from "../../../data/transcriptES.json";
import dummyTranscriptFR from "../../../data/transcriptFR.json";
import { Typography } from "@mui/material";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import VideoPlayerTranscript from "../UploadVideoPage/VideoPlayerTranscript/VideoPlayerTranscript";

/**
 * Fetch and handle video details.
 * @param {string} videoId - The ID of the video to fetch details for.
 * @param {function} setVideoDetails - Function to set the video details in state.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 */
const fetchAndHandleVideoDetails = async (
  videoId,
  setVideoDetails,
  dispatch,
  navigate
) => {
  try {
    const res = await fetchVideoDetails(videoId);
    if (res?.status === 200) {
      setVideoDetails(res?.data);
      dispatch(
        setDashVideoObject({
          description: res.data.description,
          titles: res.data.titles,
          view_count: res.data.view_count,
          created_at: res.data.created_at,
          id: res.data.id,
        })
      );
      return res.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

const getTranscript = (language) => {
  return language === "EN"
    ? dummyTranscriptEN
    : language === "ES"
      ? dummyTranscriptES
      : dummyTranscriptFR;
};

function UploadVideoPage({ searchInputRef }) {
  const { videoId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [videoViewCount, setVideoViewCount] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoDetails, setVideoDetails] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const originalLanguage = useSelector(
    (state) => state.languages.selectedOriginalLanguage
  );
  const translatedLanguage = useSelector(
    (state) => state.languages.selectedTranslatedLanguage
  );
  const [originalTranscript, setOriginalTranscript] =
    useState([]);
  const [translatedTranscript, setTranslatedTranscript] =
    useState([]);

  useEffect(() => {
    setOriginalTranscript(getTranscript(originalLanguage));
    setTranslatedTranscript(getTranscript(translatedLanguage));
  }, [originalLanguage, translatedLanguage]);

  const dashVideoObject = useSelector((state) => state.dashVideoObject);

  useEffect(() => {
    fetchAndHandleVideoDetails(videoId, setVideoDetails, dispatch, navigate);
  }, [videoId, dispatch, navigate]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const firstVideo = data[0];
      setVideoUrl(firstVideo.file_stream_cdn_url);
      setVideoViewCount(firstVideo.view_count);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBackClick = () => {
    navigate("/CONTENT-PLATFORM/home");
  };

  const handleSuggestedVideoClick = (video) => {
    setVideoUrl(video.file_stream_cdn_url);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }} component="section">
      <Button
        variant="text"
        onClick={handleGoBackClick}
        sx={{
          color: "neutral.25",
          margin: "1rem 0 1rem 2rem",
          textTransform: "none",
        }}
        startIcon={<ArrowBackIosIcon />}
        data-testid="back-button-video-upload"
      >
        Back
      </Button>

      <VideoContainer
        isCollapsed={isCollapsed}
        isPortrait={isPortrait}
        isVisible={isVisible}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            border: "none",
            padding: "0 2rem",
            zIndex: 1,
            transition: "all 0.2s ease-in-out",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box
            sx={{
              margin: isMobile ? "0 0 2rem 0" : "0 2rem 0 0",
            }}
            component="section"
          >
            <VideoPlayer
              src={videoUrl}
              videoViewCount={videoViewCount}
              setVideoViewCount={setVideoViewCount}
              dashVideoObject={videoDetails}
              searchInputRef={searchInputRef}
            />
            <Box
              component="section"
              sx={{
                color: "neutral.25",
                borderRadius: "0.5rem",
                margin: "2rem 0 0 0",
              }}
            >
              <Typography sx={{ fontSize: "1.5rem" }}>
                {dashVideoObject?.titles?.length > 0
                  ? dashVideoObject.titles[0].title_text
                  : data[0].title_en}
              </Typography>
            </Box>

            <Box
              component="section"
              sx={{ margin: isMobile ? "2rem 0 0 0" : "2rem 0" }}
            >
              <VideoPlayerTranscript
                originalTranscript={originalTranscript}
                translatedTranscript={translatedTranscript}
                fileUrl={videoUrl}
              />
            </Box>
          </Box>
          <Box
            component="section"
            sx={{ minWidth: isMobile ? "100%" : "300px" }}
          >
            <Box sx={{ margin: "0 0 2rem 0" }}>
              <TranslationPanel fileUrl={videoUrl}>
                <TranslationButton label="Compare" />
              </TranslationPanel>
            </Box>
            <SuggestionVideoCard
              videoArray={data}
              onSuggestedVideoClick={handleSuggestedVideoClick}
            />
          </Box>
        </Box>
      </VideoContainer>
    </Box>
  );
}

export default UploadVideoPage;
