import { useEffect, useState, memo } from "react";
import { motion, useAnimate } from "framer-motion";

import {
  generateBottomTransformations,
  getBottomTransformationProperties,
  getGlowAnimation,
  getGlowStyle,
} from "./airis-blob-utils";
import useFirstRender from "./useFirstRender";
import "./AirisBlob.scoped.css";

const AirisBlobBottom = ({
  size,
  glowSpread,
  animationState,
  initialAnimationState,
  animationEnabled,
}) => {
  const [layerScope, animateLayer] = useAnimate();
  const [glowScope, animateGlow] = useAnimate();

  const isFirstRender = useFirstRender();
  const [duringStateTransition, setDuringStateTransition] = useState(
    initialAnimationState !== -1 && initialAnimationState !== animationState
  );
  const [transformations, setTransformations] = useState(
    generateBottomTransformations(animationState)
  );

  // Re-generate transformations if animation state has changed
  useEffect(() => {
    // Do not re-generate transformations on mount, only during state changes
    if (!isFirstRender) {
      setTransformations(generateBottomTransformations(animationState));
      setDuringStateTransition(true);
    }
    // eslint-disable-next-line
  }, [animationState]);

  // Animate layer transformations (e.g., skew, scale)
  useEffect(() => {
    let animation = undefined;
    if (animationEnabled || duringStateTransition) {
      animation = animateLayer(layerScope.current, transformations, {
        ...getBottomTransformationProperties(
          animationState,
          duringStateTransition,
          animationEnabled
        ),
        onComplete: () => {
          // After transform animation completes, generate a new set of transforms to animate to
          setTransformations(generateBottomTransformations(animationState));
          setDuringStateTransition(false);
        },
      });
    }

    return () => {
      if (animation !== undefined) {
        animation.stop();
      }
    };
    // eslint-disable-next-line
  }, [
    animationEnabled,
    transformations,
    duringStateTransition,
    layerScope,
    animateLayer,
  ]);

  // Animate glow effect (e.g., opacity, glow colour)
  useEffect(() => {
    let glowAnimation = undefined;
    if (animationEnabled || duringStateTransition) {
      const [animationProperties, transitionProperties] = getGlowAnimation(
        animationState,
        duringStateTransition,
        glowSpread,
        animationEnabled
      );

      glowAnimation = animateGlow(
        glowScope.current,
        animationProperties,
        transitionProperties
      );
    }

    return () => {
      if (glowAnimation !== undefined) {
        glowAnimation.stop();
      }
    };
  }, [
    animationState,
    duringStateTransition,
    glowSpread,
    animationEnabled,
    glowScope,
    animateGlow,
  ]);

  function transformOrder({ skewX, skewY, scaleX, scaleY, rotate }) {
    return `rotate(${rotate}) scaleX(${scaleX}) scaleY(${scaleY}) skewX(${skewX}) skewY(${skewY})`;
  }

  return (
    <motion.div
      ref={layerScope}
      transformTemplate={transformOrder}
      initial={generateBottomTransformations(
        initialAnimationState === -1 ? animationState : initialAnimationState
      )}
      style={{
        ...{
          width: size === -1 ? "100%" : `${size}px`,
          height: size === -1 ? "100%" : `${size}px`,
        },
        ...(animationEnabled || duringStateTransition
          ? {}
          : generateBottomTransformations(animationState)),
      }}
      className="va-blob-bottom-layer-wrapper"
    >
      <svg
        width={size === -1 ? "100%" : size}
        height={size === -1 ? "100%" : size}
        viewBox="0 0 144 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="va-blob-svg-container"
      >
        {/* Layer fill */}
        <g
          filter={
            animationState === 2
              ? "url(#filter0_i_347_5_state2)"
              : "url(#filter0_i_347_5)"
          }
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M58.3048 21.3268C79.5018 15.6256 103.6156 23.722 116.4289 44.06S126.3833 89.3264 111.4717 106.6063S70.8194 130.0702 48.0099 118.7137S16.8224 86.4645 20.0374 64.4301S37.8030 26.841 58.3048 21.3268Z"
            fill="#222222"
          />
        </g>
        {/* Layer stroke */}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.3048 21.3268C79.5018 15.6256 103.6156 23.722 116.4289 44.06S126.3833 89.3264 111.4717 106.6063S70.8194 130.0702 48.0099 118.7137S16.8224 86.4645 20.0374 64.4301S37.8030 26.841 58.3048 21.3268Z"
          stroke={animationState === 2 ? "#a7190c" : "#eda8e8"}
          stroke-width="0.642597"
        />
        <defs>
          <filter
            id="filter0_i_347_5"
            x="15"
            y="15"
            width="128"
            height="128"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.57039" />
            <feGaussianBlur stdDeviation="16.0649" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.375 0 0 0 0 0.92221 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_347_5"
            />
          </filter>
          <filter
            id="filter0_i_347_5_state2"
            x="15"
            y="15"
            width="128"
            height="128"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.57039" />
            <feGaussianBlur stdDeviation="16.0649" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.56 0 0 0 0 0.05 0 0 0 0 0.02 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_347_5_state2"
            />
          </filter>
        </defs>
      </svg>
      {/* Glow effect */}
      <motion.div
        ref={glowScope}
        initial={
          animationEnabled || duringStateTransition
            ? getGlowStyle(
                initialAnimationState === -1
                  ? animationState
                  : initialAnimationState,
                glowSpread
              )
            : undefined
        }
        style={
          animationEnabled || duringStateTransition
            ? undefined
            : getGlowStyle(animationState, glowSpread)
        }
        className="va-blob-bottom-layer-glow"
      ></motion.div>
    </motion.div>
  );
};

export default memo(AirisBlobBottom);
