import React, { useState } from "react";
import {
  Box,
  Grid2,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { PlaylistHeader } from "../../components/Playlist/Playlist";
import VideoPlaylistCardList from "../../components/Playlist/VideoPlaylistCardList";
import AudioPlaylist from "../../components/AudioPlaylist/AudioPlaylist";
import "./PlaylistsPage.scoped.css";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import SortByAlphaOutlinedIcon from "@mui/icons-material/SortByAlphaOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { BorderAll, BorderAllOutlined, Padding } from "@mui/icons-material";
import MultivideoPlaylist from "../../components/Playlist/MulitivideoPlaylist/MultivideoPlaylist";


function PlaylistsPage() {
  const [viewFolders, setViewFolders] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const showFolders = () => {
    setViewFolders(!viewFolders);
  };

  const [layouts, setLayouts] = useState("list");

  const handleLayoutChange = (_event, updatedLayout) => {
    setLayouts(updatedLayout);
  };

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleSortClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  return (
    <Box className="playlistsContainer">
      <PlaylistHeader />
      <Box className="playlistContent">
        <Grid2 container>
          <Grid2 size={3}>
            <Box className="side-panel">
              <Box>
                <Button className="create-playlist">
                  <Typography>New</Typography>
                  <AddOutlinedIcon />
                </Button>
              </Box>
              <Box>
                <Button className="folder-button" onClick={showFolders}>
                  <ArrowForwardIosOutlinedIcon
                    fontSize="small"
                    className={`folder-arrow ${viewFolders ? "open" : ""}`}
                  />
                  <Typography>All folders</Typography>
                </Button>
                <Box
                  className="folder-container"
                  sx={{ visibility: viewFolders ? "visible" : "hidden" }}
                >
                  <Typography>Folder 1</Typography>
                  <Typography>Folder 2</Typography>
                  <Typography>Folder 3</Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={9}>
            <Box className="filter-buttons">
              <ToggleButtonGroup
                className="layout"
                aria-label="playlists layout"
                value={layouts}
                onChange={handleLayoutChange}
                exclusive
              >
                <ToggleButton
                  className={`toggle ${layouts === "list" ? "active" : ""}`}
                  value={"list"}
                  aria-label="list layout"
                  disableTouchRipple
                >
                  <ListAltOutlinedIcon />
                </ToggleButton>
                <ToggleButton
                  className={`toggle ${layouts === "grid" ? "active" : ""}`}
                  value={"grid"}
                  aria-label="grid layout"
                  disableTouchRipple
                >
                  <DashboardOutlinedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                className="sort-alpha"
                onClick={handleSortClick}
                style={{
                  backgroundColor: isMenuOpen ? "#fff" : "", // Change the background when menu is open
                  margin: "0px 10px 0 10px",
                  Padding: "9px",
                  width: "50px", // Set a fixed width if needed
                  height: "50px", // Set a fixed height if needed
                }}
              >
                <SortByAlphaOutlinedIcon />
              </Button>
              <Menu
                sx={{
                  margin: "16px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleSortClose}
                disableScrollLock={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center ",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem dividers onClick={handleSortClose}>
                  By Bookmarked Date
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSortClose}>By Upload Date</MenuItem>
                <Divider />
                <MenuItem onClick={handleSortClose}>Most Watched</MenuItem>
                <Divider />
                <MenuItem onClick={handleSortClose}>By Type</MenuItem>
              </Menu>
            </Box>
            <Box className="saved-content">
              <MultivideoPlaylist />
              {/* Audio Playlists */}
              <AudioPlaylist />
              <VideoPlaylistCardList />
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}

export default PlaylistsPage;
