import AudioPlayerTextPanel from "./AudioPlayerTranscript/AudioPlayerTranscript";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { useEffect, useState } from "react";
import "./AudioPage.scoped.css";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import AudioSidebar from "../../components/AudioSidebar/AudioSidebar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import { loadAudioFile } from "../../utils/fetchTrack";
import Translator from "../../components/MediaTranslator/Translator";
import ContextualInfo from "./ContextualInfo/ContextualInfo";
import useAudioControls from "../../hooks/useAudioControls";
import { ShowChart } from "@mui/icons-material";
import { SuggestionVideoCard } from "../../components/SuggestionVideoCard/SuggestionVideoCard";

import data from "../../data/dashvideos.json";

const AudioPage = () => {
  // Custom hook for the audio transcript and compare screens.
  const { audioFile, pageControls, pageFunctions } = useAudioControls();

  // State to control visibility of AudioPlayerTextPanel
  const [showTranscript, setShowTranscript] = useState(false);

  const [mockComments, setMockComments] = useState([
    { username: "User 1", comment: "This is a comment" },
    { username: "User 2", comment: "This is another comment" },
  ]);
  const [audioControls, setAudioControls] = useState(null);
  const [activeTextLineIndex, setActiveTextLineIndex] = useState(0);

  const updateActiveTextLineIndex = () => {
    let timePointsArr = Object.keys(audioFile.transcript);
    let formattedTime = audioControls
      ? convertSecondsToTime(audioControls.currentTime)
      : "00:00";
    if (timePointsArr.includes(formattedTime)) {
      setActiveTextLineIndex(timePointsArr.indexOf(formattedTime));
    }
  };
  useEffect(() => {
    if (audioFile.isLoaded) {
      updateActiveTextLineIndex();
    }
  }, [audioControls ? audioControls.currentTime : null]);

  const convertSecondsToTime = (seconds) => {
    // Converts seconds to time in format mm:ss
    if (Math.floor(seconds) <= 0) {
      return "00:00";
    } else {
      let mins = Math.floor(seconds / 60);
      mins =
        mins === 0 ? "00" : mins < 10 ? "0" + mins.toString() : mins.toString();
      seconds = Math.floor(seconds % 60);
      seconds =
        seconds === 0
          ? "00"
          : seconds < 10
            ? "0" + seconds.toString()
            : seconds.toString();
      return mins + ":" + seconds;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "70%" }}>
          <Box className="audio-page-container">
            <Box className="body">
              <Box
                data-widescreen={pageControls.isWideScreen}
                sx={{ flex: 1, display: "flex", flexDirection: "row" }}
              >
                <Box className="audio-flow">
                  <Box className="audio-player-container">
                    <AudioPlayer
                      pageControls={pageControls}
                      pageFunctions={pageFunctions}
                      audioFile={audioFile}
                    />
                  </Box>

                  <Box sx={{ py: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "36px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      Graduation Song
                    </Typography>
                  </Box>

                  <Box className="header-container">
                    <Box className="publisher">
                      <Typography className="publisher-text">
                        Publisher
                      </Typography>
                    </Box>
                    <Box className="social-buttons">
                      <Button
                        className="transcript"
                        variant="outlined"
                        onClick={() => setShowTranscript(!showTranscript)} // Toggle visibility
                      >
                        <FormatAlignLeftIcon className="icon" />
                        <Typography className="text">Transcript</Typography>
                      </Button>

                      <Button className="peer-play" variant="outlined">
                        <GroupsIcon className="icon" />
                        <Typography className="text">Peer Play</Typography>
                      </Button>
                      <Button className="share" variant="outlined">
                        <ShareIcon className="icon" />
                        <Typography className="text">Share</Typography>
                      </Button>
                      <Button className="download" variant="outlined">
                        <StarIcon className="icon" />
                        <Typography className="text">Download</Typography>
                      </Button>
                    </Box>
                  </Box>
                  <Box className="transcriptData">
                    {showTranscript && (
                      <>
                        <Box className="specs">
                          <Box className="specsDirection">
                            <Box>
                              <Typography className="text">
                                200,567 views
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="text">
                                1 month ago
                              </Typography>
                            </Box>
                          </Box>

                          <AudioPlayerTextPanel
                            open={pageControls.textPanelOpen}
                            textPanelType={pageControls.textPanelType}
                            audioFile={audioFile}
                            pageControls={pageControls}
                            activeTextLineIndex={activeTextLineIndex}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box className="audio-info">
                    {/* <Box className="header">
                      <Box className="specs">
                        <Typography className="text">200,567 views</Typography>
                        <Typography className="text">1 month ago</Typography>
                      </Box>
                    </Box> */}

                    <Box className="summary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quos, dolor. Illo alias eveniet dolorum eaque mollitia
                      vitae voluptate dolores id vero, sed cum quaerat
                      voluptatem accusamus ducimus rerum enim praesentium.
                      <span className="more-text-btn">more</span>
                    </Box>
                  </Box>

                  <Box className="review-snippets">
                    <Box className="snippet">
                      <Typography className="text">
                        <strong>"Graduation Song"</strong> is a song about
                        bidding farewell and reminiscing on pleasant memories.
                      </Typography>
                    </Box>
                    <Box className="snippet">
                      <Typography className="text">
                        Some reviewers liked winsome recounted their nostalgic
                        school... <span className="more-text-btn">more</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="comments-section">
                    <Typography className="header">45 comments</Typography>
                    <Box className="comments">
                      {mockComments.map((comment, index) => (
                        <Box key={index} className="commentBox">
                          <Box className="profile-imgDiv">
                            <PersonIcon sx={{ fontSize: "24px" }} />
                          </Box>
                          <Box
                            className="textDiv"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              className="username"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {comment.username}
                            </Typography>
                            <Typography
                              className="text"
                              sx={{ fontSize: "14px" }}
                            >
                              {comment.comment}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                      <Box className="addComment">
                        <Box className="profile-imgDiv">
                          <PersonIcon sx={{ fontSize: "24px" }} />
                        </Box>
                        <input
                          type="text"
                          className="comment-input"
                          placeholder="Add comment..."
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "30%" }}>
          <Box className="audio-suggestion-container">
            <Box className="audio-translator">
              <Translator />
              <ContextualInfo />
              <SuggestionVideoCard
                videoArray={data}
                // onSuggestedVideoClick={handleSuggestedVideoClick}
              />
              <AudioSidebar open={pageControls.sideBarOpen} />
            </Box>

            <Box className="suggestion-video-card">
              {/* <SuggestionVideoCard videoArray={data} /> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AudioPage;
