import React, { useEffect, useState, useRef } from 'react';
import './VideoCard.scoped.css';
import formatViewCount from "../../utils/formatViewCount";
import search from "./img/search.png";
import { getAllMyPlaylists, createPlaylist } from "../../api/playlists"; // Import the API function

const VideoCardEarthMinimized = ({ onClose, pinClass, videoThumbnail, videoTitle, videoData, isDropdownOpen, onDropdownToggle, onSelectedOptionsChange, setIsDropdownOpen }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]); // State for filtered options
  const [isEditingNewPlaylist, setIsEditingNewPlaylist] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const res = await getAllMyPlaylists();
        if (res?.status === 200) {
          const playlists = res?.data?.data.map(playlist => playlist.name);
          setOptions(playlists);
          setFilteredOptions(playlists);
        }
      } catch (err) {
        console.error("Error fetching playlists:", err);
      }
    };
    fetchPlaylists();
  }, []);

  const handleCheckboxChange = (event, option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(previousOptions => previousOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions(previousOptions => [...previousOptions, option]);
    }
  };

  const CustomCheckbox = ({ label, isChecked, onChange }) => (
    <div className="custom-checkbox" onClick={event => event.stopPropagation()}>
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <label>{label}</label>
    </div>
  );

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength ? title.slice(0, maxLength) + ".." : title;
  };

  const handleNewPlaylistNameChange = (event) => {
    setNewPlaylistName(event.target.value);
  };

  const handleCreateNewPlaylist = async (event) => {
    event.preventDefault();
    try {
      const res = await createPlaylist({ name: newPlaylistName });
      if (res?.status === 201) {
        // Add the new playlist to the state only if the request was successful
        setOptions((prevOptions) => [...prevOptions, newPlaylistName]);
        setFilteredOptions((prevOptions) => [...prevOptions, newPlaylistName]);
        setIsEditingNewPlaylist(false);
        setNewPlaylistName('');
      } else {
        console.error('Failed to create new playlist:', res.status);
        // Handle error response from the backend
      }
    } catch (err) {
      console.error('Error creating new playlist:', err);
      // Handle any other errors
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    setFilteredOptions(options.filter(option => option.toLowerCase().includes(searchQuery.toLowerCase())));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        console.log("You clicked inside the dropdown!");
      } else {
        console.log("You clicked outside the dropdown!");
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={pinClass}>
      <button className="minimized-close-button" onClick={onClose}>&times;</button>

      <div className="info">
        <div className="minimized-thumbnail-container">
          <img className="minimized-thumbnail-image" src={videoThumbnail ? videoThumbnail : "../assets/Unavailable.png"} alt={videoTitle} />
          <div className="minimized-overlay-text">2:21</div>

          <div className="minimized-play-button">
            <img className="PlayButton" src={'../assets/PlayButton.png'} alt="" />
          </div>
        </div>
        <div className="minimized-title">
        {videoData?.titles?.[0] ? truncateTitle(videoData.titles[0], 35) : 'Video Unavailable or Deleted'}
          <div className="minimized-description">
            <img className="minimized-channel-img" src={videoData?.channelImg ? videoData?.channelImg : '../assets/ProfileUnavailable.png'} alt={videoData?.channelName} />
            <div className="minimized-channelname">Vosyn</div>
            <div className="minimized-viewcount">
              {videoData?.view_count ? formatViewCount(videoData.view_count) + ' views' : ''}
            </div>
          </div>
        </div>
        <div className="minimized-playlist-button">
          <button 
            className="follow-button-minimized" 
            onClick={(event) => onDropdownToggle(event)}
            style={isDropdownOpen ? { background: 'var(--blue)', color: '#b2b2b2' } : null}
          >
            <img className="playlist-img" src={'../assets/AddtoPlaylist.png'} alt={videoData?.channelName} />
          </button>
        </div>
        {isDropdownOpen && 
          <div className="dropdown-menu" ref={dropdownRef}>
            <div className='dropdown-heading-container'>
            <div className="dropdown-heading">
              Save To...
              <button className="close-dropdown-button" onClick={(event) => onDropdownToggle(event)}>&times;</button>
            </div>
            <div className="search-container">
              <input 
                className="search" 
                type="text" 
                placeholder="Search" 
                value={searchQuery} 
                onChange={handleSearchChange} 
              />
              <button className='search-button' onClick={handleSearch}>
                <img src={search} className="search-icon" alt="Search" />
              </button>
            </div>
            </div>
            {filteredOptions.map((option) => (
              <CustomCheckbox
                key={option}
                label={option}
                isChecked={selectedOptions.includes(option)}
                onChange={(event) => handleCheckboxChange(event, option)}
              />
            ))}
            {isEditingNewPlaylist && (
              <form className='create-playlist-container' onSubmit={handleCreateNewPlaylist}>
                <input
                  className='create-playlist-input'
                  type="text"
                  value={newPlaylistName}
                  onChange={handleNewPlaylistNameChange}
                  placeholder="New Playlist Name"
                  required
                />
                <button type="submit" style={{ display: 'none' }}>Save</button>
              </form>
            )}
            <div className='dropdown-end-container'>
              <button className="dropdown-end" onClick={() => setIsEditingNewPlaylist(!isEditingNewPlaylist)}>+ New Playlist</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default VideoCardEarthMinimized;