import "./ServiceNav.scoped.css";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQueryParam from "../../../hooks/useQueryParam";
import Dropdown from "../../Dropdown/Dropdown";

const ServiceNav = ({ selectedCategory, categories, serviceName }) => {
  const navigate = useNavigate();
  const { updateQueryParam } = useQueryParam();
  const categoriesToggleRef = useRef();
  const [dropDownOpen, setDropDownOpen] = useState(false);


  const handleSelectSection = (category) => () => {
    if (serviceName === "netflix" && category === "Watchlist") {
      navigate(`/CONTENT-PLATFORM/services/${serviceName}/?category=Watchlist`);
    } else if (serviceName === "coursera" && category === "Home") {
      navigate(`/CONTENT-PLATFORM/services/${serviceName}/?category=home`);
    } else if (serviceName === "coursera" && category === "My Learning") {
      navigate(
        `/CONTENT-PLATFORM/services/${serviceName}/?category=My+Learning`
      );
    } else {
      updateQueryParam("category", category);
    }
  };

  const courseraCategoriesList = [
    { name: "Art and Humanities", param: "Art and Humanities" },
    { name: "Business", param: "Business" },
    { name: "Computer Science", param: "Computer Science" },
    { name: "Data Science", param: "Data Science" },
    { name: "Information Technology", param: "Information Technology" },
    { name: "Health", param: "Health" },
    { name: "Math and Logic", param: "Math and Logic" },
    { name: "Personal Development", param: "Personal Development" },
    {
      name: "Physical Science and Engineering",
      param: "Physical Science and Engineering",
    },
    { name: "Social Sciences", param: "Social Sciences" },
    { name: "Language Learning", param: "Language Learning" },
  ];

  const handleDropDownOpen = (open) => () => setDropDownOpen(open);

  const handleSelection = (selection) => {
    updateQueryParam("category", selection.param);
  };

  return (
    <div className="service-nav">
      {categories.map((navSection) => (
        <>
          {serviceName === "coursera" && navSection === "Categories" ? (
            <div ref={categoriesToggleRef} className="service-nav-filter-dropdown-container">
              <div
                className="service-nav-filter-dropdown-title-container"
                onClick={handleDropDownOpen(!dropDownOpen)}
              >
                Categories
                <i className={`fa-solid fa-angle-down ${dropDownOpen && "rotate"}`} /> 
              </div>
              <Dropdown
                className="service-nav-filter-dropdown"
                anchorRef={categoriesToggleRef}
                list={courseraCategoriesList}
                handleDropDownOpen={handleDropDownOpen}
                handleSelection={handleSelection}
                type="Sort By"
                isOpen={dropDownOpen}
                direction={"down"}
                height={400}
              />
            </div>
          ) : (
            <div
              key={navSection}
              className={`service-nav-item ${selectedCategory.toLowerCase() === navSection.toLowerCase() && "active"}`}
              onClick={handleSelectSection(navSection)}
            >
              {navSection}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ServiceNav;
