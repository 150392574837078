import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./ServiceSlider.scoped.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceData from "../../../data/services-data.json";
import ServiceCard from "../ServiceCard/ServiceCard";
import ServiceCardModal from "../ServiceCardModal/ServiceCardModal";

const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} service-slider-next-arrow-container`}
    style={{ ...style, display: "flex" }}
    onClick={onClick}
  >
    <i className="fa-solid fa-chevron-right service-slider-arrow-icon" />
  </div>
);

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} service-slider-prev-arrow-container`}
    style={{ ...style, display: "flex" }}
    onClick={onClick}
  >
    <i className="fa-solid fa-chevron-left service-slider-arrow-icon" />
  </div>
);

const ServiceSlider = ({
  items,
  fetchUrl,
  slidesToShow,
  isLargeRow,
  ServiceSliderType,
  serviceName,
}) => {
  const [popUpData, setPopupData] = useState(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [movies, setMovies] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: slidesToShow, // Number of slides to show at a time
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShow - 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow - 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchData() {
      // const request = await axios.get(fetchUrl);
      setMovies(ServiceData.results);
      // return request;
    }
    fetchData();
  }, [fetchUrl]);

  return (
    <div className="service-slider">
      <Slider {...settings}>
        {movies.map((item, index) => (
          <ServiceCard
            item={item}
            setPopupPosition={setPopupPosition}
            setPopupData={setPopupData}
            key={index}
            isLargeRow={isLargeRow}
            ServiceSliderType={ServiceSliderType}
            serviceName={serviceName}
          />
        ))}
      </Slider>
      {popUpData && (
        <ServiceCardModal
          position={popupPosition}
          item={popUpData}
          isLargeRow={isLargeRow}
          serviceName={serviceName}
          setPopupData={setPopupData}
          setPopupPosition={setPopupPosition}
        />
      )}
    </div>
  );
};

export default ServiceSlider;
