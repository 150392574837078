import axios from "axios";
import {
  LOGIN_URL,
  SIGNUP_URL,
  LOGOUT_URL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD,
  RESEND_VERIFICATION_EMAIL,
  CHECK_USER_EXISTS,
} from "../constant/constantUrls";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const userLogin = async (data) => {
  try {
    let res = await axios.post(LOGIN_URL, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userSignup = async (data) => {
  try {
    let res = await axios.post(SIGNUP_URL, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userLogout = async () => {
  try {
    let res = await axios.post(LOGOUT_URL, {});
    return res;
  } catch (err) {
    throw err;
  }
};

export const userResetPassword = async (data) => {
  try {
    let res = await axios.post(RESET_PASSWORD_REQUEST, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userChangePasswordloggedout = async (data) => {
  try {
    let res = await axios.post(RESET_PASSWORD, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const userResendVerificationEmail = async (data) => {
  try {
    let res = await axios.post(RESEND_VERIFICATION_EMAIL, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const checkUserExists = async (data) => {
  try {
    let res = await axios.post(CHECK_USER_EXISTS, data);
    return res;
  } catch (err) {
    throw err;
  }
};
