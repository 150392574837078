import Image1 from "../components/Service/imgs/0_5UF0aqmOcLkQ0eFV.jpg";
import Image2 from "../components/Service/imgs/Cybersecurity_readiness_index.jpg";
import Image3 from "../components/Service/imgs/data-analyst-presentation-office.jpg";
import Image4 from "../components/Service/imgs/Deep_Learning_746x419.jpg";

export const mockData = [
  {
    id: "0",
    title: "Google Data Analytics Professional Certificate",
    description:
      "Get on the fast track to a career in Data Analytics. In this certificate program, you'll learn in-demand skills at your own pace, no degree or experience required.",
    icon: null,
    image: Image1,
    type: "Professional Certificate",
    level: "Beginner Level",
    company: "Google",
  },
  {
    id: "1",
    title: "Google UX Design Professional Certificate",
    description:
      "Get on the fast track to a career in UX design. In this certificate program, you'll learn in-demand skills at your own pace, no degree or experience required.",
    icon: null,
    image: Image2,
    type: "Specialization",
    level: "Beginner Level",
    company: "Google",
  },
  {
    id: "2",
    title: "How Can You Build Essential Cybersecurity Skills for Your Resume",
    description:
      "@WithSandra explores some of the most commonly required cybersecurity skills for beginners to help you make your resume stand out.",
    icon: null,
    image: "https://i.ytimg.com/vi/CmSTWABw8ic/hqdefault.jpg",
    type: "Specialization",
    level: "Beginner Level",
    company: "Coursera",
  },
  {
    id: "3",
    title: "Google Project Management",
    description:
      "Get on the fast track to a career in project management. In this certificate program, you'll learn in-demand skills at your own pace, no degree or experience required.",
    icon: null,
    image: Image4,
    type: "Professional Certificate",
    level: "Beginner Level",
    company: "Google",
  },
];

export const materialsData = {
  "Course Materials": [
    "Module 1",
    "Module 2",
    "Module 3",
    "Module 4",
    "Module 5",
  ],
  "Start the program": [
    {
      title: "Welcome to the Google UX Design Certificate",
      type: "Video",
      duration: "5 min",
      status: "Finished",
    },
    {
      title: "Michael - Get Started in UX Design",
      type: "Video",
      duration: "3.5 min",
      status: "Resume",
    },
    {
      title: "Begin the Google UX Certificate",
      type: "Reading",
      duration: "20 min",
      status: "Get Started",
    },
    {
      title: "Introduction to Course 1: Foundations of User Experience Design",
      type: "Video",
      duration: "2 min",
      status: "Get Started",
    },
    {
      title: "Introduce Yourself",
      type: "Discussion Prompt",
      duration: "20 min",
      status: "Get Started",
    },
    {
      title: "Welcome to course 1",
      type: "Reading",
      duration: "20 min",
      status: "Get Started",
    },
  ],
};
