import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Collapse,
  CardActions,
  useMediaQuery,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlagButton from "../FlagButton/FlagButton";
import "./Summary.scoped.css";
import { useTheme } from "@emotion/react";
import axios from "axios";

const flagging_summary = `${process.env.REACT_APP_PROD_URL}/user/badSummary`;
/**
 *
 * @param {* The media item summary to display} summary
 * @returns A summary card for media item
 */
const Summary = ({ summary, title }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet")); // Get screen size

  const [expanded, setExpanded] = useState(isMobile ? false : true); // If it's not a phone show summary by default

  useEffect(() => {
    // Update expanded state when screen size changes
    setExpanded(!isMobile);
  }, [isMobile]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleFlag = (isFlagged) => {
    if (isFlagged) {
      const flagData = {
        title: title,
        summary,
        objectId: "objectID",
      };

      console.log("Sending flag data:", flagData);

      try {
        const response = axios.post(flagging_summary, flagData, {
          headers: { "Content-Type": "application/json" },
        });
        console.log("Successfully flagged summary", response);
      } catch (err) {
        console.error("Error submitting the flag description:", err);
        throw err;
      }
    }
  };

  return (
    <Card sx={{ padding: "1.25rem", pb: 0 }}>
      <CardHeader
        title={title ? title : "Summary"}
        className="summary-header"
        sx={!isMobile && { paddingBottom: "0" }}
        action={
          // If it's a mobile screen give option to fold and expand the summary.
          isMobile && (
            <CardActions disableSpacing>
              <IconButton
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={
                  expanded ? "iconButton iconButtonExpanded" : "iconButton"
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          )
        }
      />

      {/** Show collapsed content when expanded is true */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography className="summary-container">{summary}</Typography>
          <FlagButton onFlag={handleFlag} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Summary;
