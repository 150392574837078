import data from "../../../data/dummyUploadText2.json";
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import dummyData from "../../../data/dummyUploadText2.json";

import TextPageTranslationPanel from "./TextPageTranslationPanel/TextPageTranslationPanel";
import PageControl from "./PageControl/PageControl";
import { setToast } from "../../../reducers/snackbarSlice";
import { setTextObject } from "../../../reducers/textObjectSlice";
import TextContainer from "../../../components/TextContainer/TextContainer";
import Bookmark from "../../../components/Bookmark/Bookmark";
import OfflineViewers from "../../../components/OfflineViewers/OfflineViewers";
import Translator from "../../../components/MediaTranslator/Translator";
import ContextualInfo from "../../../components/ContextualInfo/ContextualInfo";
import TextPageActionButtonPanel from "./TextPageActionButtonPanel/TextPageActionButtonPanel";
import { logout, setLoggedIn } from "../../../reducers/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/**
 * Fetch and handle video details.
 * @param {string} textId - The ID of the text to fetch details for.
 * @param {function} settextDetails - Function to set the text details in state.
 * @param {function} dispatch - Redux dispatch function.
 * @param {function} navigate - React Router navigate function.
 */

const fetchAndHandleTextDetails = async (textId, dispatch, navigate) => {
  try {
    const textData = dummyData.find((item) => item.id === parseInt(textId)) || dummyData[1];
    //Uncomment below code once Audio API is ready

    // const res = await fetchTextDetails(textId);
    // if (res?.status === 200) {
    dispatch(
      setTextObject({
        textUrlPdf: textData.textUrls.pdf,
        textUrlWord: textData.textUrls.word,
      })
    );
    return textData;
    // }
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      dispatch(logout());
      navigate("/CONTENT-PLATFORM/auth/login");
    } else {
      dispatch(
        setToast({
          showSnackBar: true,
          message: "Some error occurred. Please try again later.",
          type: "red",
        })
      );
    }
  }
};

const UploadText = () => {
  const {textId} = useParams();
  const [data, setData] = useState(dummyData[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState("EN");
  const [pageLength, setPageLength] = useState();
  const [paginatedContent, setPaginatedContent] = useState([]);

  const [fontSize, setFontSize] = useState({
    title: 30,
    summary: 12,
    paragraph: 16,
    subtitle: 20,
  });
  //this approach has to be changed when backend integration happens
  const charLimitPage1 = 1395;
  const charLimitOtherPages = 4091;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Refs for each page to scroll
  const pageRefs = useRef([]);
  const textRef = useRef([]);

  // Choose the content based on the selected language
  const contentData = data?.translations[language] || data;
  const content = contentData?.translatedContent || data.content;
  const title = contentData?.translatedTitle || data.title;
  const subtitle = contentData?.translatedSubtitle || data.subtitle;
  const featuredImage = data?.featuredImage;
  const summary = contentData?.translatedSummary || data.summary;

  // Split content based on character limits per page

  useEffect(() => {
    const loadTextData = async () => {
      const fetchedData = await fetchAndHandleTextDetails(textId, dispatch, navigate);
      setData(fetchedData);
    };
    loadTextData();
  }, [textId, dispatch, navigate]);

  useEffect(() => {
    const pages = [];
    let contentToSplit = content;

    // Split for Page 1 (with header)
    if (contentToSplit.length > charLimitPage1) {
      pages.push(contentToSplit.slice(0, charLimitPage1));
      contentToSplit = contentToSplit.slice(charLimitPage1);
    } else {
      pages.push(contentToSplit);
      contentToSplit = "";
    }

    // Split for other pages (without header)
    while (contentToSplit.length > 0) {
      pages.push(contentToSplit.slice(0, charLimitOtherPages));
      contentToSplit = contentToSplit.slice(charLimitOtherPages);
    }

    setPaginatedContent(pages);

    setPageLength(pages.length);
  }, [content]);

  // for filter the text and highlight it
  function searchText(input) {
    const filterText = textRef.current.map((content) => {
      if (!content) return; // Check if the element exists
      const textToSearch = input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      const pattern = new RegExp(textToSearch, "gi");

      content.innerHTML = content.innerText.replace(
        pattern,
        (match) => `<mark className=highlighted-text>${match}</mark>`
      );
    });

    return filterText;
  }

  const handleNextPage = () => {
    if (currentPage < paginatedContent.length) {
      setCurrentPage(currentPage + 1);
      pageRefs.current[currentPage].scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      pageRefs.current[currentPage - 2].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Box sx={{ paddingTop: "1%" }}>
        <Typography
          variant="h2"
          aria-label="Back button"
          component={Link}
          to="/home"
          sx={{ textDecoration: "none", "&:visited": { color: "#FBFBFB" } }}
        >
          <ArrowBackIosIcon sx={{ transform: "translateY(4px)" }} />
          Back
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ width: "75%", paddingTop: "0%" }}>
          <TextContainer
            fetchAndHandleTextDetails={fetchAndHandleTextDetails}
            data={data}
          />
        </Box>
        <Box sx={{ paddingTop: "4%", width: "25%", mt: { xs: 2, md: 0 } }}>
          {/* <TextPageTranslationPanel setLanguage={() => {}} />
          <Box
            className="summary"
            sx={{
              padding: "20px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              marginTop: "16px",
            }}
          >
            <Typography>
              <strong>"The Future of School Education in Japan"</strong>{" "}
              explores how technological advancements are reshaping classroom
              learning by introducing digital resources and online platforms.
              These changes offer students more interactive and flexible
              educational experiences while enabling teachers to provide
              personalized instruction. Additionally, the integration of
              community connections enhances practical learning opportunities,
              preparing students for the future.
            </Typography>
          </Box> */}
          {/* <VideoTranslator /> */}
          <Translator />
          <TextPageActionButtonPanel />
          <Box className="summary">
            <Typography
              sx={{
                fontSize: `12px`,
                color: "var(--mui-palette-neutral-500)",
                padding: "20px 30px",
                backgroundColor: "var(--mui-palette-neutral-100)",
                borderRadius: "18px",
                marginTop: "20px",
              }}
            >
              <Typography variant="h3"> Summary </Typography>
              <Typography variant="subtitle2" sx={{ marginTop: "10px" }}>
                {" "}
                The Future of School Education in Japan{" "}
              </Typography>
              <Typography variant="body2">
                {" "}
                This is body paragraph for this post{" "}
              </Typography>
            </Typography>
          </Box>

          <Bookmark />
          <OfflineViewers />
          <Box>
            <TextPageTranslationPanel setLanguage={setLanguage} />
            <Box className="summary">
              <Typography
                sx={{
                  fontSize: `${fontSize.summary}px`,
                  color: "var(--mui-palette-common-white)",
                  padding: "20px 30px",
                  backgroundColor: "var(--mui-palette-neutral-600)",
                  borderRadius: "0px 0px 18px 18px",
                }}
              >
                <strong>{`"${title}"`}</strong>{" "}
                {summary}
              </Typography>
            </Box>
            <PageControl
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageRefs={pageRefs}
              pageLength={pageLength}
              setFontSize={setFontSize}
              fontSize={fontSize}
              searchText={searchText}
            />

            {/*MY stuff*/}
            {paginatedContent.map((pageContent, index) => (
              <Box
                key={index}
                ref={(el) => (pageRefs.current[index] = el)} // Save refs for scrolling
                sx={{
                  mt: "45px",
                  mb: 4,
                  p: "2.5rem",
                  border: "1px solid var(--mui-palette-neutral-50)",
                  borderRadius: "18px",
                  color: "var(--mui-palette-common-white)"
                }}
              >
                {index === 0 && (
                  <Box sx={{ mb: 4, textAlign: "center" }}>
                    <Box sx={{ mb: 2 }}>
                      <img
                        src={featuredImage ? featuredImage : process.env.PUBLIC_URL + `/assets/noImage.png`}
                        alt="Featured"
                        style={{ width: "100%", borderRadius: "8px" }}
                      />
                    </Box>
                    <Typography
                      variant="h1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: `${fontSize.title}px`,
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: `${fontSize.subtitle}px` }}
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                )}

                <Typography
                  ref={(el) => (textRef.current[index] = el)}
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-wrap",
                    fontSize: `${fontSize.paragraph}px`,
                  }}
                >
                  {pageContent}
                </Typography>

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}
                >
                  <Typography variant="caption"> {index + 1}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UploadText;
