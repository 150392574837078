import React, { useEffect } from "react";
import { FaXmark } from "react-icons/fa6";
import "./Snackbar.scoped.css";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "../../reducers/snackbarSlice";

const SNACKBAR_TIMEOUT = 10000;

const Snackbar = () => {
  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setToast({ showSnackBar: false, message: "", type: "green" }));
    }, SNACKBAR_TIMEOUT);

    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <div className={`bar ${toast?.type}`}>
      <p className="barText">{toast?.message}</p>
      <FaXmark
        size={20}
        color="white"
        onClick={() =>
          dispatch(
            setToast({ showSnackBar: false, message: "", type: "green" })
          )
        }
        cursor="pointer"
      />
    </div>
  );
};

export default Snackbar;
