import React from "react";
import {
  Select,
  MenuItem,
  TextField,
  FormControl,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Link,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";

import Grid from "@mui/material/Grid2";
import { ArrowDropDown } from "@mui/icons-material";
import Flag from "react-world-flags";
import { useSelector, useDispatch } from "react-redux";
import {
  setTranslatedLanguage,
  setOriginalLanguage,
  setSelectedFile,
} from "../../reducers/languageSlice";
import "./TranslationPanel.scoped.css";
import ActionButtonPanelMobile from "./ActionButtonMobilePanel/ActionButtonPanelMobile";

// Language list with country codes for flags
const languages = [
  { code: "EN", name: "English", countryCode: "CA" },
  { code: "FR", name: "French", countryCode: "FR" },
  { code: "ES", name: "Spanish", countryCode: "ES" },
  { code: "DE", name: "German", countryCode: "DE" },
  { code: "JP", name: "Japanese", countryCode: "JP" },
];

const FileSelectTextField = styled(TextField)(({ theme }) => ({
  "input": {
    color: theme.palette.common['white'],
  },
}));

const TranslationPanel = ({ children, fileUrl }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet")); // Get screen size
  const dispatch = useDispatch();

  const selectedTranslatedLanguage = useSelector(
    (state) => state.languages.selectedTranslatedLanguage
  );
  const selectedOriginalLanguage = useSelector(
    (state) => state.languages.selectedOriginalLanguage
  );
  const selectedFile = useSelector((state) => state.languages.selectedFile);

  // Handle file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(setSelectedFile(file.name));
    }
  };

  // Handle language selection change for both translated and original languages
  const handleLanguageChange = (event, type) => {
    if (type === "translated") {
      dispatch(setTranslatedLanguage(event.target.value));
    } else {
      dispatch(setOriginalLanguage(event.target.value));
    }
  };

  return (
    <Box className="translation-panel-container">
      <Box className="translation-panel-box">
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{
            flexDirection: isMobile ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="select-lang-panel-box"
            sx={{ flexWrap: isMobile ? "nowrap" : "wrap" }}
          >
            <Grid item xs={12} sx={{ width: isMobile ? "45%" : "100%" }}>
              <Typography
                variant="subtitle2"
                className="translation-panel-typography"
                sx={{ fontSize: isMobile ? "11px" : "14px" }}
                data-testid="translated-language-caption"
              >
                Translated Language
              </Typography>
              <FormControl fullWidth>
                <Select
                  id="translated-language-select"
                  data-testid="translated-language-select"
                  name="translatedLanguage"
                  value={selectedTranslatedLanguage}
                  onChange={(event) =>
                    handleLanguageChange(event, "translated")
                  }
                  IconComponent={ArrowDropDown}
                  className="translation-panel-select"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        color:"var(--mui-palette-common-white)",
                        backgroundColor: "var(--mui-palette-neutral-600)"
                      },
                    },
                  }}
                  renderValue={(value) => {
                    const selected = languages.find(
                      (lang) => lang.code === value
                    );
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          columnGap: "5px",
                          color: "common.white"
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Flag
                            code={selected.countryCode}
                            className="translation-panel-flag"
                          />
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: isMobile ? "13px" : "",
                          }}
                        >
                          {selected.name}
                        </Box>
                      </Box>
                    );
                  }}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      <ListItemIcon>
                        <Flag
                          code={lang.countryCode}
                          className="translation-panel-flag"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: isMobile ? "13px" : "",
                          color: "common.white",
                        }}
                      >
                        {lang.name}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ width: isMobile ? "45%" : "100%" }}>
              <Typography
                variant="subtitle2"
                className="translation-panel-typography"
                data-testid="original-language-caption"
                sx={{ fontSize: isMobile ? "11px" : "14px" }}
              >
                Original Language
              </Typography>
              <FormControl fullWidth>
                <Select
                  id="original-language-select"
                  data-testid="original-language-select"
                  name="originalLanguage"
                  value={selectedOriginalLanguage}
                  onChange={(event) => handleLanguageChange(event, "original")}
                  IconComponent={ArrowDropDown}
                  className="translation-panel-select"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        color:"var(--mui-palette-common-white)",
                        backgroundColor: "var(--mui-palette-neutral-600)"
                      },
                    },
                  }}
                  renderValue={(value) => {
                    const selected = languages.find(
                      (lang) => lang.code === value
                    );
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Flag
                            code={selected.countryCode}
                            className="translation-panel-flag"
                          />
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: isMobile ? "13px" : "",
                            color: "common.white"
                          }}
                        >
                          {selected.name}
                        </Box>
                      </Box>
                    );
                  }}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      <ListItemIcon>
                        <Flag
                          code={lang.countryCode}
                          className="translation-panel-flag"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: isMobile ? "13px" : "",
                          color: "common.white",
                        }}
                      >
                        {lang.name}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {isMobile ? <ActionButtonPanelMobile fileUrl={fileUrl} /> : ""}
          </Box>
          <Grid item xs={12} className="translation-file-panel">
            <Typography
              variant="subtitle2"
              className="translation-panel-typography"
              data-testid="file-field-caption"
            >
              File
            </Typography>
            <FileSelectTextField
              fullWidth
              value={selectedFile}
              className="translation-panel-file-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                      className="translation-panel-link"
                    >
                      Change
                    </Link>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                "data-testid": "file-input",
              }}
            />
            <input
              id="fileInput"
              data-testid="hidden-file-input"
              type="file"
              className="translation-panel-file-input-hidden"
              onChange={handleFileChange}
            />
          </Grid>
          {children}
        </Grid>
      </Box>
    </Box>
  );
};

export default TranslationPanel;
