import React, { useEffect, useRef, useState } from "react";
import "./VideoThumbnail.scoped.css";
import formatDateString from "../../../utils/formatDateString";
import { useNavigate } from "react-router-dom";
import SideMoreModal from "../SideMoreModal/SideMoreModal";
import ShareModal from "../../ShareModal/ShareModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import {
  calculateTotalPlaytime,
  formatDuration,
  formatStringDuration,
} from "../../../utils/calcVideoDurations";
import CreatePlaylistModal from "../../CreatePlaylist/CreatePlaylistModal";
import Avatar from "../../Avatar/Avatar";
import formatViewCount from "../../../utils/formatViewCount";
import IconButton from "../../IconButton/IconButton";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Typography,
} from "@mui/material";
import { FormatAlignJustify, Height } from "@mui/icons-material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

const VideoThumbnail = ({
  data,
  cardLayout,
  cardType,
  onDelete,
  featured = false,
  id = "0",
}) => {
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] =
    useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isSideMoreOpen, setIsSideMoreOpen] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Playlist Modal
  const [open, setOpen] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]); // To store playlist checkboxes
  const [playlistCount, setPlaylistCount] = useState(0); // Counter to track number of playlists

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle adding a new playlist
  const handleAddPlaylist = () => {
    // Add a new playlist and immediately update the checkboxes state
    setPlaylistCount((prev) => prev + 1);
    setCheckboxes((prev) => [...prev, false]); // Add a new unchecked playlist checkbox
  };

  const handleCheckboxChange = (index) => (event) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = event.target.checked;
    setCheckboxes(newCheckboxes);
  };
  // Playlist Modal

  const navigate = useNavigate();
  const sideMoreModalRef = useRef();

  const handleVideoCardNavigate = (id) => () => {
    if (cardType === "playlists") {
      navigate(`/CONTENT-PLATFORM/playlist/${id}`);
    } else {
      navigate(`/CONTENT-PLATFORM/video/${id}`);
    }
  };

  const handleOpenCreatePlaylistModal = () =>
    setIsCreatePlaylistModalOpen(true);

  const handleCloseCreatePlaylistModal = () =>
    setIsCreatePlaylistModalOpen(false);

  const handleOpenShareModal = () => setShareModalOpen(true);

  const handleCloseShareModal = () => setShareModalOpen(false);

  const handleOpenSideMoreModal = (event) => {
    event.stopPropagation();
    setIsSideMoreOpen((prev) => !prev);
  };

  const handleDeleteCard = (e) => {
    e.stopPropagation();
    onDelete(data.id);
  };

  const handleBookmark = (e) => {
    e.stopPropagation();
    setIsBookmarked((prev) => {
      const newBookmarkedState = !prev;
      setPopupMessage(newBookmarkedState ? "Bookmarked!" : "Unbookmarked!");
      setIsPopupVisible(true);
      setTimeout(() => setIsPopupVisible(false), 3000);
      return newBookmarkedState;
    });
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const isHistory = cardType === "history";
  const cardClasses = featured
    ? `videocard-featured ${
        cardLayout === "horizontal"
          ? "horizontal-videocard"
          : "vertical-videocard"
      }`
    : `videocard ${
        cardLayout === "horizontal"
          ? "horizontal-videocard"
          : "vertical-videocard"
      }`;

  const playlists = [
    { id: 1, name: "Workout Playlist", isPrivate: true },
    { id: 2, name: "Chill Beats", isPrivate: false },
    { id: 3, name: "Tutorials", isPrivate: true },
    { id: 4, name: "Recipes", isPrivate: false },
  ];

  useEffect(() => {
    console.log("datadatadata", data);
  }, []);

  const onSavePlaylist = (playlistId) =>
    console.log(`Save video to playlist ${playlistId}`);
  const onRemoveVideoFromPlaylist = (playlistId) =>
    console.log(`Remove video from playlist ${playlistId}`);
  const onCreatePlaylist = (name, privacy) =>
    console.log(`Create playlist with name ${name} and privacy ${privacy}`);

  // Function to format total playtime for playlists
  const getPlaylistPlaytime = (contentOverview) => {
    if (!contentOverview) return null;
    return `${contentOverview.count} videos | ${formatStringDuration(
      calculateTotalPlaytime(contentOverview.total_duration)
    )} playtime`;
  };

  return (
    <Box
      className={
        isHistory || cardType === "playlist" ? "videocard-container" : null
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        className={cardClasses}
        onClick={
          cardType === "playlist"
            ? handleVideoCardNavigate(data?.content_object?.id)
            : handleVideoCardNavigate(data.id)
        }
        data-testid={id}
      >
        <Box
          className={`videocard-thumbnail-container ${
            ["history", "search", "playlist"].includes(cardType)
              ? "history-videocard"
              : ""
          }`}
        >
          {cardType !== "playlists" && cardType !== "playlist" && (
            <Box>
              <CardMedia
                component="img"
                className="videocard-thumbnail-img"
                image={data?.thumbnail_url}
                alt={data.title_en}
              />
              <Box className="icon-wrapper">
                <AddBoxOutlinedIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpen();
                  }}
                  className="addicon"
                />
                {/* Bookmark Icon */}

                {isHovered && (
                  <Box className="bookmark-icon">
                    <button
                      className="bookmark"
                      onClick={handleBookmark}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {isBookmarked ? (
                        <BookmarkIcon style={{ color: "black" }} />
                      ) : (
                        <BookmarkBorderIcon />
                      )}
                    </button>
                  </Box>
                )}
              </Box>
              <CardMedia
                className="videocard-channel-img"
                component="img"
                image={
                  data?.channelImg
                    ? data.channelImg
                    : "../assets/vosyn_logo.png"
                }
                alt={data.channelName}
              />
            </Box>
          )}
          {cardType === "playlists" && (
            <>
              <CardMedia
                component="img"
                image={data?.content_overview?.thumbnail_url}
                alt={data.title_en}
              />
              <Avatar variant="playlist" type={data.type} />
            </>
          )}
          {cardType === "playlist" && (
            <>
              <CardMedia
                component="img"
                image={data?.content_object?.thumbnail_detail?.url}
                alt={data?.content_title}
              />
              <Avatar variant="playlist" type={data.type} />
            </>
          )}
        </Box>

        <Box className="horizontal-videocard-info-container">
          <Box className="videocard-info">
            <Box className="videocard-main-info">
              <Typography variant="h3" className="videocard-title">
                {cardType === "playlist"
                  ? data?.content_title
                  : data.titles?.[0] ?? "Sample Video"}
              </Typography>
              <Box className="videocard-data">
                {cardType === "video" && (
                  <>
                    <Typography component="p" className="videocard-channel">
                      {data.channelName}
                    </Typography>
                    <Typography
                      component="span"
                      className="videocard-views videocard-line"
                    >
                      {formatViewCount(data?.view_count)} views
                    </Typography>

                    <Typography component="span" className="videocard-date">
                      {formatDateString(data?.released_date)}
                    </Typography>
                    {/* Display the formatted duration */}
                    <Typography component="span" className="videocard-duration">
                      {formatDuration(data?.duration)}
                    </Typography>
                  </>
                )}
                {cardType === "playlist" && (
                  <>
                    <Typography component="p" className="videocard-channel">
                      {data.channelName}
                    </Typography>
                    <Typography component="span" className="videocard-views">
                      {formatViewCount(data?.content_object?.view_count)} views{" "}
                    </Typography>

                    <Typography
                      component="span"
                      className="videocard-views-date videocard-line"
                    >
                      {formatDateString(data?.content_object?.released_date)}
                    </Typography>
                    {/* Display total playtime */}
                    <Typography component="span" className="videocard-playtime">
                      {getPlaylistPlaytime(data?.content_overview)}
                    </Typography>
                  </>
                )}
                {cardType === "history" && (
                  <>
                    <Typography
                      component="span"
                      className={`videocard-views videocard-dot`}
                    >
                      {formatViewCount(data.view_count)} views
                    </Typography>

                    <Typography
                      component="p"
                      className="videocard-description videocard-description-truncate"
                    >
                      {data.description}
                    </Typography>
                  </>
                )}
                {cardType === "search" && (
                  <>
                    <Typography component="p" className="videocard-channel">
                      {data.channelName}
                    </Typography>
                    <Typography component="span" className="videocard-views">
                      {formatViewCount(data?.view_count)} views
                    </Typography>
                    <Typography
                      component="span"
                      className="videocard-date videocard-line"
                    >
                      {formatDateString(data?.released_date)}
                    </Typography>
                  </>
                )}
                {cardType === "episode" && (
                  <>
                    <Typography component="span" className="videocard-date">
                      {`${data?.released_date} - ${data.duration || "58m"}`}
                    </Typography>
                    <Typography
                      component="p"
                      className="videocard-description videocard-description-truncate"
                    >
                      {data.description}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {/* {isHovered && (
            <Box
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: 1,
                backgroundColor: "white",
                borderRadius: "50%", // 50% for a perfect circle
                padding: "5px",
                display: "flex", // Use flex to center content
                alignItems: "center", // Vertically center
                justifyContent: "center", // Horizontally center
              }}
            >
              <button
                onClick={handleBookmark}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {isBookmarked ? (
                  <BookmarkIcon style={{ color: "black" }} />
                ) : (
                  <BookmarkBorderIcon />
                )}
              </button>
            </Box>
          )} */}
        </Box>
      </Box>

      {/* Bookmark Popup */}
      {isPopupVisible && (
        <Box
          className="bookmark-popup"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            width: "280px", // Slightly wider
            height: "70px", // Slightly taller
            transform: "translateX(-50%)",
            backgroundColor: "white", // Solid color background
            padding: "10px 20px",
            borderRadius: "16px", // More rounded corners
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Deeper shadow for more depth
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "1.5rem !important",
            fontWeight: "bold",
            color: "black",
            zIndex: 1000,
            transition: "transform 0.3s ease, opacity 0.3s ease", // Smooth transition for animations
            opacity: 1, // Make sure it’s fully visible
          }}
        >
          {isBookmarked ? (
            <BookmarkAddedIcon style={{ marginRight: 8, color: "black" }} />
          ) : (
            <BookmarkBorderIcon style={{ marginRight: 8, color: "black" }} />
          )}
          <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>
            {popupMessage}
          </Typography>
        </Box>
      )}

      {/* Side More Modal */}
      {isSideMoreOpen && (
        <SideMoreModal
          anchorRef={sideMoreModalRef}
          onClose={handleOpenSideMoreModal}
          cardType={cardType}
          onOpenCreatePlaylistModal={handleOpenCreatePlaylistModal}
          onOpenShareModal={handleOpenShareModal}
        />
      )}

      {/* Info and Help Icons and Options */}
      <Box className="videocard-options-container">
        {cardType === "video" && (
          <Box className="videocard-options">
            <InfoIcon
              ref={sideMoreModalRef}
              className="tooltip"
              onClick={handleOpenSideMoreModal}
            />
            {isSideMoreOpen && (
              <SideMoreModal
                anchorRef={sideMoreModalRef}
                onClose={handleOpenSideMoreModal}
                cardType="video"
                onOpenCreatePlaylistModal={handleOpenCreatePlaylistModal}
                onOpenShareModal={handleOpenShareModal}
              />
            )}
          </Box>
        )}
        {cardType === "history" && (
          <Box className="histroy-videocard-options">
            <i className="fas fa-trash-can" onClick={handleDeleteCard} />
            <InfoIcon
              className="tooltip"
              ref={sideMoreModalRef}
              onClick={handleOpenSideMoreModal}
            />
            {isSideMoreOpen && (
              <SideMoreModal
                onClose={handleOpenSideMoreModal}
                anchorRef={sideMoreModalRef}
                cardType="history"
                onOpenCreatePlaylistModal={handleOpenCreatePlaylistModal}
                onOpenShareModal={handleOpenShareModal}
              />
            )}
          </Box>
        )}
        {cardType === "playlist" && (
          <Box className="playlist-videocard-options">
            <InfoIcon
              className="tooltip"
              ref={sideMoreModalRef}
              onClick={handleOpenSideMoreModal}
            />
            {isSideMoreOpen && (
              <SideMoreModal
                anchorRef={sideMoreModalRef}
                cardType="playlist"
                onClose={handleOpenSideMoreModal}
                onOpenShareModal={handleOpenShareModal}
              />
            )}
          </Box>
        )}
        {cardType === "episode" && (
          <IconButton
            icon="fa-download fa-solid"
            style={{ fontSize: "1rem" }}
          />
        )}
        {/* HelpOutlineIcon */}
        <HelpOutlineIcon className="tooltip" />
      </Box>

      {/* Create Playlist Modal */}
      {isCreatePlaylistModalOpen && (
        <CreatePlaylistModal
          contentId={data.id}
          onClose={handleCloseCreatePlaylistModal}
          playlists={playlists}
          onSavePlaylist={onSavePlaylist}
          onRemoveVideoFromPlaylist={onRemoveVideoFromPlaylist}
          onCreatePlaylist={onCreatePlaylist}
        />
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <ShareModal
          className="videocard-share-modal"
          isOpen={handleOpenShareModal}
          onClose={handleCloseShareModal}
          url={data.url}
          title={data.title_en ?? "Sample Video"}
        />
      )}

      {/* Playlist Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-container">
          <Box className="modal-header">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modal-title"
            >
              Save Video To
            </Typography>
            <CloseOutlinedIcon onClick={handleClose} className="close-icon" />
          </Box>

          {/* Dynamic Playlist Checkboxes */}
          <div className="playlist-scroll-container">
            <Grid container spacing={2} className="grid-container">
              {Array.from({ length: playlistCount }).map((_, index) => (
                <Grid item xs={12} key={index} className="grid-item">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxes[index] || false}
                        onChange={handleCheckboxChange(index)}
                        name={`playlist-checkbox-${index}`}
                        color="primary"
                      />
                    }
                    label={`Playlist ${index + 1}`}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          {/* Create New Playlist */}
          <div onClick={handleAddPlaylist} className="create-playlist">
            <AddBoxOutlinedIcon
              name="create-playlist-checkbox"
              className="create-playlist-icon"
            />
            <span>Create New Playlist</span>
          </div>
        </Box>
      </Modal>
    </Box>

    /* Playlist Modal */
  );
};

export default VideoThumbnail;
