import "./AudioPlayer.scoped.css";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AudioPlayerTextPanel from "./AudioPlayerTextPanel/AudioPlayerTextPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTime,
  setDuration,
  setHasEnded,
  setPlaying,
  setFullScreen,
} from "../../reducers/videoPlayerSlice";
import TimeLine from "../ControlPanel/ActionControl/TimeLine";
import { useEventListener } from "../../Utils";
import useMediaPlayerControls from "../../hooks/useMediaPlayerControl";
import useKeyboardControls from "../../hooks/useKeyboardControls";
import IconSet from "../IconSet/IconSet";
import ControlPanelAudio from "../ControlPanel/ControlPanelAudio";

const AudioPlayer = ({ audioFile, pageControls, pageFunctions }) => {
  // audio
  const audioRef = useRef(null);
  const mouseMoveTimer = useRef(null);
  const dispatch = useDispatch();

  // gets audio controls from control panel (currentTime, volume, captionsMenuOpen etc.)
  // const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [audioControls, setAudioControls] = useState(null);
  // const [showTimeline, setShowTimeline] = useState(true);
  const hovering = useSelector(({ videoPlayer }) => videoPlayer.isFullScreen);
  const playing = useSelector(({ videoPlayer }) => videoPlayer.playing);
  const currentTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);
  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const {
    showTimeline,
    togglePlayPause,
    handleMouseMove,
    showControls,
    setShowTimeline,
    handleTimeUpdate,
  } = useMediaPlayerControls(audioRef, playing, hovering);

  // Use keyboard controls hook
  useKeyboardControls(audioRef, togglePlayPause, null, handleTimeUpdate);

  // Update audio element's `currentTime` only if audio is defined and fully loaded
  useEffect(() => {
    const audio = audioRef.current;
    if (
      audio &&
      !isNaN(currentTime) &&
      Math.floor(audio.currentTime) !== Math.floor(currentTime)
    ) {
      audio.currentTime = currentTime;
    }
    console.log(audio.currentTime, "currentTime");
  }, [currentTime]);

  // transcript control
  const [activeTextLineIndex, setActiveTextLineIndex] = useState(0);
  const updateActiveTextLineIndex = () => {
    let timePointsArr = Object.keys(audioFile.transcript);
    let formattedTime = audioControls
      ? convertSecondsToTime(audioControls.currentTime)
      : "00:00";
    if (timePointsArr.includes(formattedTime)) {
      setActiveTextLineIndex(timePointsArr.indexOf(formattedTime));
    }
  };
  useEffect(() => {
    if (audioFile.isLoaded) {
      updateActiveTextLineIndex();
    }
  }, [audioControls ? audioControls.currentTime : null]);

  // control panel
  const [controlPanelOpen, setControlPanelOpen] = useState(true);
  const controlPanelFunctions = {
    hide: function () {
      setControlPanelOpen(false);
    },
    show: function () {
      setControlPanelOpen(true);
    },
  };

  // helper functions
  const convertSecondsToTime = (seconds) => {
    // converts seconds to time in format mm:ss
    if (Math.floor(seconds) <= 0) {
      return "00:00";
    } else {
      let mins = Math.floor(seconds / 60);
      mins =
        mins === 0 ? "00" : mins < 10 ? "0" + mins.toString() : mins.toString();
      seconds = Math.floor(seconds % 60);
      seconds =
        seconds === 0
          ? "00"
          : seconds < 10
            ? "0" + seconds.toString()
            : seconds.toString();
      return mins + ":" + seconds;
    }
  };
  // Update the playback state and increment the view count when the video ends
  const handleVideoEnded = () => {
    const audio = audioRef.current;
    audio.currentTime = 0;
    dispatch(setPlaying(false));
    dispatch(setHasEnded(true));
    // setVideoViewCount((videoViewCount) => videoViewCount + 1);
  };

  const handlePlay = () => {
    dispatch(setPlaying(true));
  };

  // Handle mouse movement to show controls if video is playing
  // const handleMouseMove = () => {
  //   clearTimeout(mouseMoveTimer.current);
  //   if (playing) {
  //     showControls();
  //   }
  // };

  // Update fullscreen state based on whether the document is in fullscreen mode
  const fullscreenChange = () => {
    dispatch(setFullScreen(Boolean(document.fullscreenElement)));
  };

  useEventListener("fullscreenchange", fullscreenChange);
  useEventListener("mousemove", handleMouseMove, audioRef.current);

  return (
    <Box
      className={`audio-player ${isFullScreen ? "" : ""}`}
      onMouseOver={controlPanelFunctions.show}
      data-controlpanelopen={controlPanelOpen}
      data-textpanelopen={pageControls.textPanelOpen}
      data-textpaneltype={pageControls.textPanelType}
      onMouseEnter={() => showControls()}
      onMouseLeave={() => {
        setTimeout(() => {
          setShowTimeline(false);
        }, 2000);
      }}
      onMouseMove={() => handleMouseMove()}
    >
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => {
          const rect = audioRef.current.getBoundingClientRect();
          console.log("Audio dimensions:", rect);
          if (audioRef.current) {
            dispatch(setDuration(audioRef.current.duration || 0));
          }
        }}
        src={audioFile.audio}
        onClick={togglePlayPause}
        onEnded={handleVideoEnded}
        preload="metadata"
        onPlay={handlePlay}
      />
      <IconSet showIcons={!playing || showTimeline ? true : false}></IconSet>
      {/* <Button
        onClick={() => pageFunctions.toggleTranscriptMode()}
        className="on-screen-button transcript"
      >
        <FormatAlignLeftIcon sx={{ fontSize: "14px" }} />
        <Typography sx={{ fontSize: "12px" }}>Transcript</Typography>
      </Button>
      <Button
        onClick={() => pageFunctions.toggleCompareMode()}
        className="on-screen-button compare"
      >
        <FormatQuoteIcon sx={{ fontSize: "14px" }} />
        <Typography sx={{ fontSize: "12px" }}>Compare</Typography>
      </Button> */}
      <Box className="screen-window">
        <Box className="screen">screen</Box>

        {/* <AudioPlayerTextPanel
          open={pageControls.textPanelOpen}
          textPanelType={pageControls.textPanelType}
          audioFile={audioFile}
          pageControls={pageControls}
          activeTextLineIndex={activeTextLineIndex}
        /> */}
      </Box>
      <div className={`controls ${!playing || showTimeline ? "show" : "hide"}`}>
        <TimeLine mediaRef={audioRef} showControls={showControls} />

        <ControlPanelAudio
          togglePlayPause={togglePlayPause}
          mediaRef={audioRef}
          dashVideoObject={undefined}
          showControls={showControls}
          mouseMoveTimer={mouseMoveTimer}
        />
      </div>
    </Box>
  );
};
export default AudioPlayer;
