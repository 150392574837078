import { useEffect, useMemo, useState } from "react";
import {
  materialsData,
  mockData as courseraData,
} from "../../data/service-data-coursera";
import { useNavigate, useParams } from "react-router-dom";
import ServiceTitle from "../../components/Service/ServiceBanner/ServiceTitle";
import "./ServiceMaterials.scoped.css";
import Nav from "../../components/Service/ServiceNav/ServiceNav";
import useQueryParam from "../../hooks/useQueryParam";
import CourseraButton from "../../components/Service/ServicesDetails/Coursera/CourseraButton";

const Section = ({ dropdownData, sectionName, type }) => {
  const [sectionOpen, setSectionOpen] = useState(false);
  const navigate = useNavigate();

  const handleSetOpen = () => {
    setSectionOpen((prev) => !prev);
  };

  const handleCourseClick = () =>
    navigate("/CONTENT-PLATFORM/video/c0227a36-c99e-4d62-90fd-7c4d6780ca6e");

  return (
    <div
      className={`section-container
            ${sectionOpen ? "section-active" : ""} 
            ${
              type === "left"
                ? "section-container-left"
                : "section-container-right"
            }`}
    >
      <div onClick={handleSetOpen}>
        <i className={`fa-solid fa-angle-${sectionOpen ? "down" : "right"}`} />
        <span>{sectionName}</span>
      </div>
      <div className={sectionOpen ? "section-dropdown-active" : ""}>
        {sectionName === "Start the program" &&
          dropdownData?.map(({ title, type, duration, status }, index) => (
            <div className="section" key={index}>
              <div>
                <span>{title}</span>
                <span>{`${type} - ${duration}`}</span>
              </div>
              {status === "Finished" ? (
                <div className="status-finished">
                  <i className="fa-solid fa-check" />
                </div>
              ) : (
                <CourseraButton
                  action={handleCourseClick}
                  resume={status === "Resume"}
                >
                  {status}
                </CourseraButton>
              )}
            </div>
          ))}
        {sectionName === "Course Materials" &&
          dropdownData?.map((course) => (
            <div className="section-module">{course}</div>
          ))}
      </div>
    </div>
  );
};

const ServiceMaterials = () => {
  const [data, setData] = useState();
  const { serviceName, videoId } = useParams();
  const categories = useMemo(() => ["Home", "My Learning", "Categories"], []);
  const { getAllParams } = useQueryParam();
  const selectedCategory = useMemo(
    () => getAllParams().category || categories[0],
    [categories, getAllParams]
  );
  const sectionsLeft = [
    "Course Materials",
    "Grades",
    "Notes",
    "Discussion Forums",
    "Messages",
    "Resources",
    "Course Info",
  ];
  const sectionsRight = [
    "Start the program",
    "Get to know user experience design",
    "Explore jobs in user experience",
    "Understanding the types of companies that hire UX designers",
    "Pursue a career in UX design",
  ];
  useEffect(() => {
    setData({
      info: courseraData.find(({ id }) => id === videoId),
      sections: materialsData,
    });
  }, [setData, videoId]);

  return (
    <div className="service-materials">
      <Nav
        serviceName={serviceName}
        categories={categories}
        selectedCategory={selectedCategory}
      />
      {data && (
        <ServiceTitle icon={data.info.icon} company={data.info.company}>
          {data.info.title}
        </ServiceTitle>
      )}
      <div className="service-materials--sections">
        <div>
          {data &&
            sectionsLeft.map((sectionName) => (
              <Section
                type="left"
                sectionName={sectionName}
                dropdownData={data.sections[sectionName]}
              />
            ))}
        </div>
        <div>
          {data &&
            sectionsRight.map((sectionName) => (
              <Section
                type="right"
                sectionName={sectionName}
                dropdownData={data.sections[sectionName]}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceMaterials;
