import React from "react";
import "./Tabs.scoped.css";
import useQueryParam from "../../hooks/useQueryParam";

const Tabs = ({ tabs }) => {
  const { updateQueryParam, getAllParams } = useQueryParam();
  const currentParams = getAllParams();

  return (
    <div className="tabs">
      {tabs.map(({ title, link }) => {
        const activeTab = currentParams.tags === link;

        const handleTabChange = () => {
          if (currentParams.tags) {
            return updateQueryParam("tags", null);
          }
          updateQueryParam("tags", link);
        };

        return (
          <div
            onClick={handleTabChange}
            className={`tab ${activeTab ? "tab-active" : ""}`}
            key={link || title}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
