import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./AudioCardsGrid.scoped.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Grid from "@mui/material/Grid2";

const AudioCardsGrid = ({ cardsData }) => {
  const navigate = useNavigate();

  const handleAudioCardNavigate = (id) => () => {
    navigate(`/CONTENT-PLATFORM/video/${id}`);
  };

  const videosBreakpoint = 4;

  return (
    <Box className="audio-wrapper">
      <Box className="audiocards-container">
        {cardsData.slice(0, 2).map((audio, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              className="card"
              sx={{
                backgroundImage: `url(${
                  audio.image || "https://via.placeholder.com/336x200"
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              data-testid="audioBackground"
              onClick={handleAudioCardNavigate(audio.id)}
            >
              <Box className="icon" data-testid="audioIcon">
                <YouTubeIcon sx={{ color: "#fff", fontSize: 20 }} />
              </Box>
              <Box sx={{ padding: "0px" }}>
                <CardContent sx={{ padding: 0 }}>
                  <Typography variant="subtitle1" className="title">
                    {audio.title}
                  </Typography>
                  <Typography variant="body2" className="description">
                    {audio.description}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Box>
      <Box className="audiocards-container">
        {cardsData.slice(2, videosBreakpoint).map((audio, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              className="card"
              sx={{
                backgroundImage: `url(${
                  audio.image || "https://via.placeholder.com/336x200"
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              data-testid="audioBackground"
              onClick={handleAudioCardNavigate(audio.id)}
            >
              <Box className="icon" data-testid="audioIcon">
                <YouTubeIcon sx={{ color: "#fff", fontSize: 20 }} />
              </Box>
              <Box sx={{ padding: "0px" }}>
                <CardContent sx={{ padding: 0 }}>
                  <Typography variant="subtitle1" className="title">
                    {audio.title}
                  </Typography>
                  <Typography variant="body2" className="description">
                    {audio.description}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default AudioCardsGrid;
