import "./WelcomeModal.scoped.css";
import { useDispatch } from "react-redux";
import { skipToStage } from "../../../reducers/userPrefReducer";
import { PiNavigationArrowFill } from "react-icons/pi";

const WelcomeModal = ({ onClick }) => {
  const dispatch = useDispatch();

  return (
    <div className="container">
      <div className="welcome-text">
        {" "}
        <p>How should we welcome you ?</p>
      </div>
      <div className="modal-content">
        <div className="banner">
          <div className="big-box"></div>
          <div className="curved-box"></div>
          <div className="line-box"></div>
          <PiNavigationArrowFill className="icon" />
        </div>

        <button className="modal-button" onClick={onClick}>
          Let's start with the tour
        </button>
      </div>
      <button
        className="skip-button"
        onClick={() => {
          dispatch(skipToStage(16));
        }}
      >
        <span className="skip-text">I'd love to explore on my own</span>
      </button>
    </div>
  );
};

export default WelcomeModal;
