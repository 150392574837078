/**
 * This file contains the implementation of the vosyn's video card component
 * It represents a card displaying a video response from Vosyn.
 */

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../../ResultsBlock.scoped.css";
import "./AirisVideos.scoped.css";

function VosynVideoCard({
  videoResponse,
  hideDescriptionText,
  showVideoDescriptionText,
}) {
  const navigate = useNavigate();

  const [showDescription, setShowDescription] = useState(false);

  // Function to toggle the display description
  function displayDescription() {
    setShowDescription((prev) => !prev);
  }

  return (
    <div className="ai-response-container video-response-container">
      <div className="va-video-thumbnail">
        <img
          src={videoResponse?.thumbnail?.url}
          alt="video thumbnail"
          className="va-thumbnail-image"
          onClick={() => {
            navigate(`/CONTENT-PLATFORM/video/${videoResponse?.id}`);
          }}
        />
        <div className="response-video-duration">{videoResponse?.duration}</div>
        <img
          src={process.env.PUBLIC_URL + `/assets/Airis/svg/vosynPlayButton.svg`}
          alt="play button"
          className="response-video-play-button-image"
          onClick={() => {
            navigate(`/CONTENT-PLATFORM/video/${videoResponse?.id}`);
          }}
        />
      </div>
      <div className="va-video-title">
        {videoResponse?.titles?.find((title) => title?.is_native)?.title_text}
      </div>
      <button className="toggle-description-text" onClick={displayDescription}>
        {showDescription ? hideDescriptionText : showVideoDescriptionText}
      </button>
      {showDescription && (
        <div className="response-video-description">
          {videoResponse?.description}
        </div>
      )}
    </div>
  );
}

export default VosynVideoCard;
