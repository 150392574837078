import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import "./AccountNavigation.scoped.css";
import { useNavigate } from "react-router-dom";
import MuiIcon from "../../utils/MuiIcon.js";

function AccountNavigation({ activeTab, onTabChange }) {
  const menuItems = [
    { label: "Profiles", value: "profiles", icon: "ModeEditOutlined" },
    { label: "Viewer's Dashboard", value: "dashboard", icon: "ShareOutlined" },
    { label: "Payment & Subscription", value: "payment", icon: "Payment" },
    { label: "Appearance", value: "appearance", icon: "Contrast" },
    { label: "Language", value: "language", icon: "TranslateOutlined" },
    {
      label: "Login & Security",
      value: "security",
      icon: "AdminPanelSettingsOutlined",
    },
    {
      label: "Keyboard Shortcuts",
      value: "shortcuts",
      icon: "KeyboardOutlined",
    },
    { label: "Help", value: "help", icon: "HelpOutlineOutlined" },
  ];

  const navigate = useNavigate();

  // Function to go back to the previous route
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleSignOut = () => {
    // Logic for sign out, e.g., clearing tokens or redirecting to the login page
    console.log("Signing out");
  };

  return (
    <div className="navigation-menu">
      {/* back button */}
      <div className="back-button">
        <Button
          startIcon={<MuiIcon name="ArrowBackIosNew" />}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </div>

      {/* search bar */}

      <div className="search-bar-container">
        <TextField
          variant="standard"
          placeholder="Search"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <MuiIcon name="Search" />
              </InputAdornment>
            ),
          }}
          sx={{
            "input::placeholder": {
              color: "#656565",
              opacity: 1,
            },
          }}
          className="search-bar"
        />
      </div>

      {/* sidebar menu */}
      <List className="sidebar">
        {menuItems.map((item) => (
          <ListItem
            key={item.value}
            button
            className={`list-item ${activeTab === item.value ? "active" : ""}`}
            onClick={() => onTabChange(item.value)}
          >
            <ListItemIcon>
              <MuiIcon name={item.icon} />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>

      {/* sign out button */}
      <div className="sign-out-container">
        <ListItem button className={"sign-out-button"} onClick={handleSignOut}>
          <ListItemIcon>{<MuiIcon name="Logout" />}</ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </div>
    </div>
  );
}

export default AccountNavigation;
