import { useMemo } from 'react';
import * as THREE from 'three';

const useEarthAtmosphere = () => {
  const vertexShader = useMemo(() => `
    varying vec3 vNormal;
    void main() {
      vNormal = normalize(normalMatrix * normal); 
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `, []);

  const fragmentShader = useMemo(() => `
    varying vec3 vNormal;
    uniform vec3 color1;
    uniform vec3 color2;
    void main() {
      float blendFactor = (vNormal.x + 1.0) / 2.0;
      vec3 colorMix = mix(color1, color2, blendFactor);
      float intensity = pow(0.5 - dot(vNormal, vec3(0.0, 0.0, 1.0)), 3.0); 
      gl_FragColor = vec4(colorMix, intensity);
    }
  `, []);

  const atmosphereMaterial = useMemo(() => new THREE.ShaderMaterial({
    uniforms: {
      color1: { type: "vec3", value: new THREE.Color("#52b2eb") },
      color2: { type: "vec3", value: new THREE.Color("#da10c1") },
    },
    vertexShader,
    fragmentShader,
    side: THREE.BackSide,
    blending: THREE.AdditiveBlending,
    transparent: true,
  }), [vertexShader, fragmentShader]);

  return atmosphereMaterial;
};

export default useEarthAtmosphere;
