import React from "react";
import "../../components/LogoVosyn/Logo.scoped.css";
import "./AuthPage.scoped.css";
import NewLogin from "../../components/NewLogin/NewLogin";
import NewSignUp from "../../components/NewSignUp/NewSignUp";
import { Box } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const AuthPage = ({ activeTab }) => {
  return (
    <Box className="auth-layout">
      <Box className="auth-left">
        <Box className="auth-content">
          {activeTab === "login" && <NewLogin />}
          {activeTab === "signup" && <NewSignUp />}
        </Box>
      </Box>

      <Box className="auth-right">
        <img
          src={process.env.PUBLIC_URL + "/assets/auth-page-image.png"}
          alt="Auth illustration"
          className="auth-image background-image"
        />
        <PlayArrowIcon className="auth-play-icon" />
      </Box>
    </Box>
  );
};

export default AuthPage;
