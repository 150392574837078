import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./SideMoreModal.scoped.css";
import { moreDropdown } from "../../../utils/more-dropdown";
import { Box, Typography } from "@mui/material";

const SideMoreModal = ({
  cardType,
  onOpenCreatePlaylistModal,
  onClose,
  anchorRef,
  onOpenShareModal,
}) => {
  const sideMoreRef = useRef(null);
  const [position, setPosition] = useState(null);

  const handleSaveToPlaylistClick = (event) => {
    event.stopPropagation();
    onOpenCreatePlaylistModal();
  };

  const handleShareClick = (event) => {
    event.stopPropagation();
    onClose(event);
    onOpenShareModal();
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !sideMoreRef?.current?.contains(event.target) &&
        !anchorRef?.current?.contains(event.target)
      ) {
        onClose(event);
      }
    },
    [onClose, anchorRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const cardRect = sideMoreRef.current.getBoundingClientRect();
    const distanceToBottom = window.innerHeight - cardRect.bottom;
    setPosition(distanceToBottom > 0 ? "below" : "above");
  }, [sideMoreRef]);

  const handleDefaultClick = (event) => {
    event.stopPropagation();
  };

  const handleMore = (action, event) => {
    switch (action) {
      case "Save to playlist": {
        return handleSaveToPlaylistClick;
      }
      case "Share": {
        return handleShareClick;
      }
      default:
        return handleDefaultClick;
    }
  };

  return (
    <Box
      className={`videocard-sidemore-modal  
        ${position === "below" ? "videocard-sidemore-modal-below" : null} 
        ${position === "above" ? "videocard-sidemore-modal-above" : null}`}
      ref={sideMoreRef}
      onMouseLeave={(event) => {
        onClose(event);
      }}
    >
      {moreDropdown[cardType].map(({ icon, text }, inx) => (
        <Box onClick={handleMore(text)} key={icon + inx}>
          <Box component="i" className={icon} />
          <Typography component="span">{text}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SideMoreModal;
