const BASE_URL = process.env.REACT_APP_PROD_URL;
// const BASE_URL = "http://localhost:8000";


export const LOGIN_URL = `${BASE_URL}/user/login/`;
export const SIGNUP_URL = `${BASE_URL}/user/register/`;
export const LOGOUT_URL = `${BASE_URL}/user/logout/`;
export const RESET_PASSWORD_REQUEST = `${BASE_URL}/user/reset-password-request/`;
export const RESET_PASSWORD = `${BASE_URL}/user/reset-password/`;
export const RESEND_VERIFICATION_EMAIL = `${BASE_URL}/user/resend-verification-email/`;
export const CHECK_USER_EXISTS = `${BASE_URL}/user/find/`;
export const USER_PROFILE = `${BASE_URL}/user/profile/`;
export const PROFILE_UPDATE_URL = `${BASE_URL}/user/update/`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/change-password/`;
export const SETTINGS_FETCH_URL = `${BASE_URL}/settings/`;
export const SETTINGS_UPDATE_URL = `${BASE_URL}/settings/updateSettings/`;
export const ACCOUNT_LANGUAGE_URL = `${BASE_URL}/user/profile/language/`;

export const PRIVACY_URL = "https://vosyn.ai/privacy-policy/";
export const CONTACT_URL = "https://vosyn.ai/contact-us/";

export const VIDEO_LIST_URL = `${BASE_URL}/video/videos/search`;
export const VIDEO_DETAIL_URL = `${BASE_URL}/video/videos/`;
export const AUDIO_DETAIL_URL = `${BASE_URL}/audio/audios/`;
export const LANGUAGES_URL = `https://pkgstore.datahub.io/core/language-codes/language-codes-full_json/data/573588525f24edb215c07bec3c309153/language-codes-full_json.json`;
export const HOME_PAGE_URL = `/CONTENT-PLATFORM/home`;
export const VIDEO_PAGE_URL = `/CONTENT-PLATFORM/video`;
export const PLAYLIST_PAGE_URL = `/CONTENT-PLATFORM/playlist`;
export const HISTORY_PAGE_URL = `/CONTENT-PLATFORM/history`;
export const NETFLIX_SERVICE_PAGE_URL = `/CONTENT-PLATFORM/services/netflix`;
export const AMAZON_PRIME_SERVICE_PAGE_URL = `/CONTENT-PLATFORM/services/amazon-prime`;
export const DISNEY_SERVICE_PAGE_URL = `/CONTENT-PLATFORM/services/disney`;
export const YOUTUBE_SERVICE_PAGE_URL = `/CONTENT-PLATFORM/services/youtube`;
export const COURSERA_SERVICE_PAGE_URL = `/CONTENT-PLATFORM/services/coursera`;
export const FEATURED_PAGE_URL = `/CONTENT-PLATFORM/menu/featured`;
/* there is an existing VIDEO_PAGE_URL const, rename the below once the existing gets removed */
export const VIDEO_URL = `/CONTENT-PLATFORM/menu/video`;
export const AUDIO_PAGE_URL = `/CONTENT-PLATFORM/menu/audio`;
export const TEXT_PAGE_URL = `/CONTENT-PLATFORM/menu/text`;
export const MYCHANNEL_PAGE_URL = `/CONTENT-PLATFORM/menu/my-channel`;
export const TRANSLATE_URL = `/CONTENT-PLATFORM/translate`;
export const SETTING_PAGE_URL = "/CONTENT-PLATFORM/settings";
export const ACCOUNT_PAGE_URL = `/CONTENT-PLATFORM/account`;

export const MOVIE_REVIEWS_URL = `${BASE_URL}/interactions/movie-reviews/`;
export const SERIES_REVIEWS_URL = `${BASE_URL}/interactions/series-reviews/`;
export const MOVIE_RATING_URL = `${BASE_URL}/interactions/movie-rating/`;
export const SERIES_RATING_URL = `${BASE_URL}/interactions/series-rating/`;
export const SEASON_RATING_URL = `${BASE_URL}/interactions/season-rating/`;
export const EPISODE_RATING_URL = `${BASE_URL}/interactions/episode-rating/`;

export const SETTINGS_URL = `${BASE_URL}/settings/`;
export const UPDATE_SETTINGS_URL = `${BASE_URL}/settings/updateSettings/`;