import { useEffect, useState } from "react";
import "./ServiceCourseraMyLearning.scoped.css";
import { mockData } from "../../../../data/service-data-coursera.js";

const ServiceCourseraMyLearning = () => {
  const filters = ["In Progrss", "Completed"];
  const [filter, setFilter] = useState(filters[0]);
  const handleSetFilter = (value) => () => setFilter(value);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(mockData);
  }, []);

  return (
    <div className="service-coursera-my-learning-container">
      <h2 className="service-coursera-my-learning-title">My Learning</h2>
      <div className="service-coursera-my-learning-filter-container">
        {filters.map((option) => (
          <div
            className={`service-coursera-my-learning-filter-button ${
              option === filter
                ? "service-coursera-my-learning-filter-button-active"
                : ""
            }`}
            onClick={handleSetFilter(option)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className="service-coursera-my-learning-seperation-line"></div>
      <div className="service-coursera-my-learning-list-count-container">
        0 of 7 Completed
      </div>
      <div className="service-coursera-my-learning-card-list-container">
        {data?.map((item, index) => (
          <div className="service-coursera-my-learning-card-container">
            <div className="service-coursera-my-learning-card-image-container">
              <img
                className="service-coursera-my-learning-card-image"
                src={item.image}
                alt={item.title}
              />
            </div>
            <div className="service-coursera-my-learning-card-content-container">
              <div className="service-coursera-my-learning-card-content-subtitle">
                <i className="fa-brands fa-google" />
                <span>Google</span>
              </div>
              <div className="service-coursera-my-learning-card-content-title">
                {item.title}
              </div>
            </div>
            <div className="service-coursera-my-learning-card-action-container">
              <div className="service-coursera-my-learning-card-action-button">
                Resume Course
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCourseraMyLearning;
