import { Box } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import "./OnboardingNavbar.scoped.css";

const OnboardingNavbar = ({
  onboardingStep,
  totalSteps,
  handleSkipOnboarding,
}) => {
  return (
    <Box className="onboarding-navbar">
      <Box className="vosyn-logo">
        <img
          className="vosynLogo"
          src={process.env.PUBLIC_URL + "/assets/vosyn-logo-dark.svg"}
          alt="company's logo"
        />
      </Box>
      <Box className="page-stepper">
        {onboardingStep < totalSteps && (
          <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            activeStep={onboardingStep}
            sx={{
              maxWidth: 400,
              flexGrow: 1,
              "& .MuiMobileStepper-dots": {
                gap: "30px",
              },
            }}
          />
        )}
        <Button
          className="skip-button"
          variant="text"
          onClick={handleSkipOnboarding}
          disableRipple
        >
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingNavbar;
