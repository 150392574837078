import React from "react";
import VideoDesc from "../../components/VideoDesc/VideoDesc";

/**
 * Component for the video description section.
 */
const VideoDescriptionSection = ({ videoDetails, videoId }) => (
  <>
    {videoDetails && videoId !== "a2e8cf81-9b5c-4391-baf9-47cec8609149" && (
      <VideoDesc />
    )}
  </>
);

export default VideoDescriptionSection;
