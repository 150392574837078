import React from "react";
import "./SuggestionVideoCard.scoped.css";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CloseIcon from "@mui/icons-material/Close";

const changeNumViews = (labelValue) => {
  const number = Math.abs(Number(labelValue));
  if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(2) + "K";
  }
  return number.toString();
};

const formatIsoDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "numeric",
  });
  return formatter.format(date);
};

export const SuggestionVideoCard = ({ videoArray }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`video-list-container ${
        videoArray.length > 5 ? "active" : ""
      }`}
    >
      <Box className="heading-wrapper">
        <Box className="heading-title ">
          <Typography
            variant="subtitle2"
            className="recommended-panel-typography"
          >
            Recommended Videos
          </Typography>
          <Typography variant="body">{`${videoArray.length} videos`}</Typography>
        </Box>
        <Box className="heading-icons">
          <MoreVertOutlinedIcon />
          <CloseIcon />
        </Box>
      </Box>
      {videoArray.map((item, i) => {
        const cardStyle = i > 0 ? { paddingTop: "30px" } : {};
        return (
          <Card
            className="mainList"
            key={i}
            sx={{ background: "transparent" }}
            style={cardStyle}
            onClick={() => {
              navigate(`/CONTENT-PLATFORM/video/${item.id}`);
            }}
          >
            <CardActionArea className="video-suggestion-card">
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  image={item.thumbnail.url}
                  alt={item.title_en}
                  className="video-suggestion-card-image"
                />

                <img
                  className="video-suggestion-card-logo"
                  src={process.env.PUBLIC_URL + "/assets/vosyn_logo.png"}
                  alt={"Vosyn"}
                />
              </Box>
              <CardContent sx={{ px: 0 }}>
                <Typography className="video-suggestion-card-title">
                  {item.title_en}
                </Typography>
                <Typography className="video-suggestion-card-views">
                  {changeNumViews(item.view_count)} views |{" "}
                  {formatIsoDate(item.created_at)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </div>
  );
};
