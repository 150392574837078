import { useEffect, useState, useRef } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useScrollToBottom } from "../../hooks/useScrollToBottom.jsx";
import AirisSidebar from "../Airis/AirisSidebar.jsx";
import { AIRIS_BIG_SIDEBAR_WIDTH } from "../../constant/constantValues.js";
import Navbar from "../Navbar/Navbar";
import Overlay from "../TourOverlay/Overlay.jsx";
import { skipToStage } from "../../reducers/userPrefReducer.js";

const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isPortrait, setIsPortrait] = useState(false);
  const [searchInputRef, setSearchInputRef] = useState(null);
  const { isAirisDisplayed } = useSelector((state) => state.airisSidebar);

  const isOnboardingCompleted = useSelector(
    (state) => state?.userInfo?.userProfile?.has_finished_onboarding
  );
  // Counter for onboarding steps
  const obStep = useSelector(({ userPref }) => userPref.onboardingStage);
  const dispatch = useDispatch();
  const homeStartRef = useRef(null);
  const { scrollToTop } = useScrollToBottom(null, homeStartRef);

  useEffect(() => {
    const showProfileSetupAt = localStorage.getItem("showProfileSetupAt");
    if (showProfileSetupAt !== null) {
      const currentTime = Date.now();
      const timeRemaining = showProfileSetupAt - currentTime;
      if (timeRemaining <= 0) dispatch(skipToStage(11));
      else
        setTimeout(() => {
          dispatch(skipToStage(11));
        }, timeRemaining);
    } else if (!isOnboardingCompleted) dispatch(skipToStage(0));
  }, []);

  const scrollToTopHandler = () => {
    scrollToTop();
  };

  useEffect(() => {
    // Add to the startProfileSetupTime the number of ms the profile setup modal should be delayed
    if (obStep === 16 && !isOnboardingCompleted) {
      const startProfileSetupTime = Date.now() + 0;
      localStorage.setItem("showProfileSetupAt", startProfileSetupTime);
      const currentTime = Date.now();
      const timeRemaining = startProfileSetupTime - currentTime;
      setTimeout(() => {
        dispatch(skipToStage(11));
      }, timeRemaining);
    }
  }, [obStep]);

  return (
    <div
      className={`${
        isAirisDisplayed ? "home-container airis-open" : "home-container"
      }`}
      style={{
        marginRight: isAirisDisplayed
          ? `min(100vw, ${AIRIS_BIG_SIDEBAR_WIDTH})`
          : "0",
      }}
      ref={homeStartRef}
    >
      <AirisSidebar />

      <div
        className={`home-body ${isCollapsed ? "collapsed" : ""} ${
          isPortrait ? "portrait" : ""
        }`}
      >
        <Navbar
          isCollapsed={isCollapsed}
          setSearchInputRef={setSearchInputRef}
        />

        {/* Iterate over the child components to conditionally pass props for "VideoPage" type else render with existing props.*/}
        {React.Children.map(children, (child) => {
          if (child.type.name === "VideoPage") {
            return React.cloneElement(child, {
              searchInputRef: searchInputRef,
            });
          }
          return child;
        })}
      </div>

      {/* Display the overlay component if the requierd oboarding steps are not achieved*/}
      {obStep >= 0 && obStep < 16 && <Overlay />}
    </div>
  );
};

export default Layout;
