import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import { Scale } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TextThumbnail = ({ image, title, description, hoverEff = true, id="0" }) => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/CONTENT-PLATFORM/text/${id}`);
  }
  return (
    <Card
      data-testid={id}
      className="hoverable-card"
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: 0,
        transition: "0.1s",
        "&:hover": {
          transform: hoverEff ? "scale(1.05)" : "",
          zIndex: 1,
        },
        overflow: "hidden",
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            image={image}
            alt={title}
            sx={{
              objectFit: "cover",
            }}
            onClick={() => handleNavigate(id)}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              backgroundColor: "#585858",
              padding: "8px",
              borderRadius: 2,
            }}
          >
            <ArticleIcon
              sx={{
                color: "#fff",
              }}
              data-testid="ArticleIcon"
            />
          </Box>
        </Box>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              padding: "2px",
              borderRadius: 2,
              marginBottom: "2px",
              color: "white",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              padding: "2px",
              borderRadius: 2,
              color: "white",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TextThumbnail;
