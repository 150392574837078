import React from "react";
import "./ProfileSetupBox.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementStage,
  skipToStage,
} from "../../../../reducers/userPrefReducer";
import { FaArrowLeft } from "react-icons/fa6";

const ProfileSetupBox = ({
  formTitle,
  formText,
  profileStep,
  showBackButton,
  children,
}) => {
  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);
  const dispatch = useDispatch();

  return (
    <div className="box">
      <div className="box-content-container">
        <div className="box-header">
          <h3>Profile setup</h3>
          {showBackButton && (
            <FaArrowLeft
              size={20}
              cursor="pointer"
              style={{ zIndex: 3000 }}
              onClick={() => {
                dispatch(decrementStage());
              }}
            />
          )}
          <div className="progress-container">
            <div className="progress-step done" />
            <div className="progress-step done" />
            <div className={`progress-step ${profileStep >= 1 && "done"}`} />
            <div className={`progress-step ${profileStep >= 2 && "done"}`} />
            <div className={`progress-step ${profileStep >= 3 && "done"}`} />
          </div>
        </div>
        <div className="box-content-text">
          <h2>{formTitle}</h2>
          <p>{formText}</p>
        </div>
        {children}
      </div>
      <div className="footer-buttons">
        <button
          className="save-button"
          type="button"
          onClick={() => dispatch(skipToStage(16))}
          style={{ display: tourStep === 15 && "none" }}
        >
          Save and close
        </button>
        <button
          className="continue-button"
          form="ob-profile-form"
          type="submit"
        >
          {tourStep !== 15 ? "Continue" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default ProfileSetupBox;
