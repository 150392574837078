/**
 * This file contains the implementation of the search bar in the vosyn assist section;
 * It focuses on manipulating the search input field and the microphone
 * 
 * Props:
 * - submitSearch: Function to handle the submission of the search query
 * - userSearch: Current user search query
 * - setUserSearch: Function to update the user search query
 * - chatHistoryLength: Length of chat history (used for background fade effect)
 * - startBlobAnimation: Boolean flag indicating whether to start the blob animation
 * - isLoading: Boolean flag indicating whether data is loading
 * - searchDirection: Direction of text (ltr or rtl)
 * - inputLanguage: Language of the input text
 */

import { useState, useEffect, useRef } from "react";
import "./AirisSearchBar.scoped.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import i18next from "i18next";

const AirisSearchBar = ({
  submitSearch,
  userSearch,
  setUserSearch,
  chatHistoryLength,
  startBlobAnimation,
  isLoading,
  searchDirection,
  inputLanguage,
}) => {
  // Retrieve translation function based on input language and set placeholder for search input
  const fixedT = i18next.getFixedT(inputLanguage, "airis");
  const placeholder = fixedT("airis.placeholder");
  const languageMap = {
    ko: "ko",
    ar: "ar-SA",
    fr: "fr-FR",
    es: "es-MX",
    default: "en-CA"
  };
  const [wordInput, setWordInput] = useState(false);
  const [micLanguage, setMicLanguage] = useState(languageMap.default);

  // Speech recognition functionality
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  // Reference to search input
  const inputRef = useRef(null);
  
  // Flag to track whether the component has rendered at least once or not
  const hasRendered = useRef(false);

  //Effect to set microphone language based on input language
  useEffect(() => {
    setMicLanguage(languageMap[inputLanguage] || languageMap.default);
  }, [inputLanguage]);

  // Effect for managing word input state and updating user search
  useEffect(() => {
    setUserSearch(transcript);
    if (hasRendered.current) {
      setWordInput(true);
    }
    setTimeout(() => {
      setWordInput(false);
    }, 300);
    hasRendered.current = true;
  }, [transcript]);

  // Function to start listening via microphone
  const startListening = () =>
    SpeechRecognition.startListening({
      continuous: true,
      language: micLanguage,
    });
  
  // Function to stop listening via microphone
  const stopListening = () => SpeechRecognition.stopListening();

  // Check for browser support for speech recognition
  const speechSupportCheck = browserSupportsSpeechRecognition ? true : false;

  // Check for microphone availability
  const micPermCheck = isMicrophoneAvailable;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      resetTranscript();
      if (!startBlobAnimation && userSearch !== "" && !isLoading) {
        submitSearch(e);
      }
    }
  };

  // Function to toggle voice search via microphone
  const startStopListening = () => {
    if (!listening) {
      resetTranscript();
    }
    listening ? stopListening() : startListening();
  };

  // Function to toggle voice search or focus input
  const toggleVoiceSearch = () => {
    if (speechSupportCheck && micPermCheck) {
      startStopListening();
    }
    inputRef.current.focus();
  };

  return (
    <form className="va-search-form" onSubmit={submitSearch}>
      {chatHistoryLength !== 0 && <div className="background-fade"></div>}
      {/* Search bar wrapper */}
      <div className="va-searchbar-wrapper">
        {/* Search input */}
        <input
          dir={searchDirection}
          ref={inputRef}
          type="text"
          className="va-searchbar"
          value={userSearch}
          placeholder={placeholder}
          onChange={(e) => setUserSearch(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        {/* Submit button */}
        <div className="va-search-button-wrapper">
          <button
            type="submit"
            className="va-search-button"
            disabled={startBlobAnimation || userSearch === "" || isLoading}
          >
            <i className="fa-solid fa-paper-plane"></i>
            <span className="visually-hidden">Send Message</span>
          </button>
        </div>
      </div>
      {/* Microphone button */}
      <div className="va-mic-button-wrapper">
        <div
          className={`va-mic-button ${
            listening && `va-search-microphone-wrapper-active`
          } ${
            listening && wordInput && "va-search-microphone-wrapper-active-word"
          }`}
          onClick={toggleVoiceSearch}
        >
          <i className="fa fa-microphone" aria-hidden="true"></i>
          <span className="visually-hidden">Toggle User Speech to Text</span>
        </div>
      </div>
    </form>
  );
};

export default AirisSearchBar;
