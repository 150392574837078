import "./TourFinalPage.scoped.css";
import { FaXmark } from "react-icons/fa6";
import { PiConfettiBold } from "react-icons/pi";
import { FaArrowDownLong } from "react-icons/fa6";
import profileImage from "./assets/finalPage.png";
import { useDispatch } from "react-redux";
import { skipToStage } from "../../../reducers/userPrefReducer";

const TourFinalPage = () => {
  const dispatch = useDispatch();

  return (
    <div className="container-box">
      <div
        className="close-button"
        onClick={() => {
          dispatch(skipToStage(16));
        }}
      >
        <FaXmark style={{ width: 18, height: 24 }} />
      </div>
      <div className="header-text">
        <div className="confetti">
          <PiConfettiBold />
        </div>
        <div className="text-container">
          <div className="congrats-header-text">
            <h1>Congratulations!</h1>
          </div>
          <div className="para-content">
            <p>
              We hope you enjoyed the tour, in case you want to re-visit it,
              it's in the profile menu.
            </p>
          </div>
          <div className="arrow-down">
            <FaArrowDownLong style={{ width: 17, height: 22.67 }} />
          </div>
        </div>
        <div className="image-container">
          <img
            src={profileImage}
            alt="profile menu"
            width="220px"
            height="132px"
            border-radius="8px"
          />
        </div>
      </div>
    </div>
  );
};

export default TourFinalPage;
