import "./AudioSidebar.scoped.css";
import { Box } from "@mui/material";
import AudioCard from "../Audio/AudioCard";

const audioDataTemp = [
  {
    id: 1,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
    title: "Audio Title 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 2,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 2",
    description: "This is the description for Audio 2.",
  },
  {
    id: 3,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
    title: "Audio Title 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 4,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 2",
    description: "This is the description for Audio 2.",
  },
  {
    id: 5,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
    title: "Audio Title 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 6,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 2",
    description: "This is the description for Audio 2.",
  },
];

const AudioSidebar = ({ data = audioDataTemp }) => {
  return (
    <Box className="audio-sidebar">
      {data.map((audio) => (
        <AudioCard
          image={audio.image}
          title={audio.title}
          description={audio.description}
          hoverEff={false}
        />
      ))}
    </Box>
  );
};
export default AudioSidebar;
