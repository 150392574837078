import { useState, useRef } from "react";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import { Menu, MenuItem, Typography, Button } from "@mui/material";
import "./Settings.scoped.css";
import { useSelector } from "react-redux";

const Settings = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [resolution, setResolution] = useState("1080p");
  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const settingsRef = useRef(null);

  const toggleSettings = () => {
    setShowSettings((prev) => !prev);
  };

  const handleMenuClose = () => {
    setShowSettings(false);
  };

  const resolutions = ["1080p", "720p", "480p", "240p"];

  const menuPaperProps = {
    sx: {
      backgroundColor: "darkgrey",
      borderRadius: "10px",
      padding: "10px",
      width: "120px",
      zIndex: 9999,
      marginTop: "-40px",
    },
  };

  const titleStyle = {
    marginLeft: "10px",
    color: "white",
  };

  const buttonStyle = {
    color: "white",
  };

  return (
    <div className="settings-popup-container" ref={settingsRef}>
      <ToolTip content="Settings">
        <MuiIcon
          name="SettingsOutlined"
          id="controls-btn-setting"
          onClick={toggleSettings}
          style={{ cursor: "pointer" }}
        />
      </ToolTip>

      {/* Settings Menu */}
      <Menu
        anchorEl={settingsRef.current}
        container={isFullScreen ? settingsRef.current : document.body}
        open={showSettings}
        onClose={handleMenuClose}
        PaperProps={menuPaperProps}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disableScrollLock
      >
        <Typography variant="subtitle1" sx={titleStyle}>
          Quality
        </Typography>
        {resolutions.map((res) => (
          <MenuItem
            key={res}
            onClick={() => {
              setResolution(res);
              handleMenuClose();
            }}
            selected={resolution === res}
            sx={{ justifyContent: "center" }}
          >
            <Button variant="text" sx={buttonStyle}>
              {res}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Settings;
