import {
  FormControlLabel,
  Switch,
  Box,
  Button,
  Typography,
} from "@mui/material";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import AvailableLanguages from "../../AvailableLanguages/AvailableLanguages";
import { useEffect, useRef, useState } from "react";
import dashjs from "dashjs";
import { setToast } from "../../../reducers/snackbarSlice";
import { useDispatch } from "react-redux";
import "./AutodubbingButton.scoped.css";
import { useEventListener } from "../../../Utils";

const AutoDubbingButton = ({ mediaRef, dashVideoObject }) => {
  const dispatch = useDispatch();

  const player = useRef(null);
  const audioLangIndexRef = useRef(-1);
  const audioLangRef = useRef(null);
  const languagesListRef = useRef(null);

  const [autoDubbingEnabled, setAutoDubbingEnabled] = useState(false);
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [changeLanguagePopup, setChangeLanguagePopup] = useState(false);
  const [audioTracks, setAudioTracks] = useState([]);
  const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);
  const [trackSelectionDisplay, setTrackSelectionDisplay] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(
    trackSelectionDisplay
  );
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState("");
  const [sliderVisible, setSliderVisible] = useState(false);

  // Initializes the video player and manages audio tracks
  useEffect(() => {
    player.current = dashjs.MediaPlayer().create();

    player.current.initialize(
      mediaRef.current,
      dashVideoObject?.file_stream_cdn_url,
      true
    );

    player.current.on(dashjs.MediaPlayer.events.CAN_PLAY, () => {
      try {
        const tracks = player.current.getTracksFor("audio");
        setAudioTracks(tracks);

        // Set audio track
        if (tracks && tracks.length > 0) {
          const trackToSet =
            audioLangIndexRef.current === -1
              ? tracks[selectedTrackIndex]
              : tracks[audioLangIndexRef.current];
          player.current.setCurrentTrack(trackToSet);
        }

        // Set the language of the current audio track
        const displayLanguage =
          audioLangRef.current === null
            ? tracks[selectedTrackIndex].lang
            : audioLangRef.current;
        setTrackSelectionDisplay(displayLanguage);
      } catch (err) {
        console.error(err);
      }
    });

    return () => {
      if (player.current) {
        player.current.reset();
      }
    };
  }, [dashVideoObject]);

  useEffect(() => {
    if (showLanguagePopup) {
      const timer = setTimeout(() => {
        setShowLanguagePopup(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showLanguagePopup]);

  // Switches to a new audio track, updates the UI, and handles playback transitions
  const handleAudioChange = (langObj) => {
    const index = langObj.index - 1;

    if (player.current) {
      try {
        // Pause the video and show the spinner before changing the audio track - handle delays
        mediaRef.current.pause();
        setSpinner(true);

        const selectedTrack = audioTracks[index];
        if (!selectedTrack) {
          console.error("Selected track not found");
          setSpinner(false);
          return;
        }

        // Switch to the selected audio track
        player.current.setCurrentTrack(selectedTrack);

        // Update state and UI
        setSelectedTrackIndex(index);
        setSelectedLanguage(selectedTrack.lang);
        setTrackSelectionDisplay(selectedTrack.lang);

        // Store selected language and index for future reference
        audioLangRef.current = selectedTrack.lang;
        audioLangIndexRef.current = index;

        // Show a toast notification
        dispatch(
          setToast({
            showSnackBar: true,
            message: `Audio is translated to ${selectedTrack.lang}`,
            type: "green",
          })
        );

        // Resume video playback and hide the spinner after the track is set
        mediaRef.current.play();
        setSpinner(false);

        // Hide the toast message after 3 seconds
        setTimeout(() => {
          dispatch(
            setToast({
              showSnackBar: false,
              message: "",
              type: "",
            })
          );
        }, 3000);
      } catch (error) {
        console.error("Error occurred while changing audio track", error);
        setSpinner(false);
      }
    }
  };

  const handleToggleAutoDubbing = () => {
    setAutoDubbingEnabled(!autoDubbingEnabled);
    setSliderVisible(!sliderVisible);

    if (!autoDubbingEnabled) {
      setShowLanguagePopup(true);
    }
  };

  const handleChangeLanguage = () => {
    setChangeLanguagePopup(!changeLanguagePopup);
  };

  const handleShowLanguageListClick = (e) => {
    if (
      open &&
      languagesListRef.current &&
      !languagesListRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };

  useEventListener("click", handleShowLanguageListClick);

  return (
    <Box className="auto-dubbing-container">
      <ToolTip content="Auto Dubbing">
        <FormControlLabel
          control={
            <Switch
              className="switch"
              checked={autoDubbingEnabled}
              onChange={handleToggleAutoDubbing}
              color="default"
              icon={
                <Box
                  style={{
                    width: 20, // Adjust to be larger than the icon
                    height: 20,
                    backgroundColor: "lightgray",
                    color: "#3d3d3d",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MuiIcon
                    name="Translate"
                    sx={{
                      fontSize: 15, // Icon size
                    }}
                  />
                </Box>
              }
              checkedIcon={
                <Box
                  style={{
                    width: 20, // Adjust to be larger than the icon
                    height: 20,
                    backgroundColor: "#88898B",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MuiIcon
                    name="Translate"
                    sx={{
                      fontSize: 15, // Icon size
                    }}
                  />
                </Box>
              }
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: autoDubbingEnabled
                    ? "Darkgray"
                    : "lightgray",
                },
              }}
            />
          }
        />
      </ToolTip>
      {autoDubbingEnabled && showLanguagePopup && (
        <Box className="language-popup" ref={languagesListRef}>
          <Typography variant="body1">
            Live dubbing in {selectedLanguage}
          </Typography>
          <Button
            onClick={handleChangeLanguage}
            variant="outlined"
            style={{ color: "lightgrey" }}
          >
            Change
          </Button>

          {/* This section shows up when "Change" is clicked */}
          {changeLanguagePopup && (
            <AvailableLanguages
              languageList={audioTracks}
              selectedTrackIndex={selectedTrackIndex}
              handleAudioChange={handleAudioChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AutoDubbingButton;
