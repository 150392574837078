import React, { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DownloadIcon from "@mui/icons-material/Download";
import "../../ActionButtonsPanel/ActionButtonsPanel.scoped.css";
import "../ActionButtonMobilePanel/ActionButtonPanelMobile.scoped.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Snackbar, Alert } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { downloadFile } from "../../../utils/fileActions";

export default function ActionButtonPanelMobile({ fileUrl }) {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  function toggleMenu() {
    setToggle((prev) => {
      return !prev;
    });
  }

  const handleButtonClick = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleDownloadClick = (msg) => {
    downloadFile(fileUrl);
    setMessage(msg);
    setOpen(true);
  }

  // Handle close event for the Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box onClick={toggleMenu}>
      <MoreVertIcon style={{ color: "black", marginBottom: "8px" }} />
      {toggle ? (
        <List className="action-button-list">
          <ListItem
            button
            className="action-button"
            onClick={() => handleButtonClick("Shared successfully!")}
          >
            <ListItemIcon style={{ color: "white" }}>
              <ShareIcon className="action-button-icon" />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </ListItem>

          <ListItem
            className="action-button"
            button
            onClick={() => handleDownloadClick("Downloaded successfully!")}
          >
            <ListItemIcon style={{ color: "white" }}>
              <DownloadIcon className="action-button-icon" />
            </ListItemIcon>
            <ListItemText primary="Download" />
          </ListItem>

          <ListItem
            className="action-button"
            button
            onClick={() => handleButtonClick("Bookmarked successfully!")}
          >
            <ListItemIcon style={{ color: "white" }}>
              <BookmarkIcon className="action-button-icon" />
            </ListItemIcon>
            <ListItemText primary="Bookmark" />
          </ListItem>
        </List>
      ) : (
        ""
      )}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>{" "}
    </Box>
  );
}
