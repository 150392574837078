import React, { useState } from 'react';
import { TextField, Switch, IconButton, Typography, Box, Avatar, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './ProfileSettings.scoped.css'; 

const ProfileSettings = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    birthday: '',
    bio: '',
    playlists: ['Anime Watch Later'],
    showName: false,
    showBirthday: false,
    showBio: false,
  });

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitchChange = (field) => {
    setProfileData({
      ...profileData,
      [field]: !profileData[field],
    });
  };

  return (
    <Box className="profile-edit-container">
      <Typography variant="h4" className="profile-title">
        Profiles
      </Typography>
      <Typography variant="h5" className="profile-subtitle">
        Edit Display Information
      </Typography>
      <Grid container spacing={4} className="profile-edit-panel">
        {/* Left Panel */}
        <Grid item xs={12} md={6} className="left-panel">
          {/* Profile Image Section */}
  <Box display="flex" alignItems="center" className="profile-image-section">
            <Box display="flex" flexDirection="column" alignItems="center" className="avatar-text-section">
        <Typography variant="body1" >
        Profile Image
    </Typography>          
  <Avatar
    src="https://via.placeholder.com/100"
    alt="Profile"
    className="profile-avatar"
      />
              </Box>     
  <IconButton color="primary" component="label" className="camera">
    <CameraAltIcon className="camera-icon" />
    <input type="file" hidden />
  </IconButton>
</Box>
          {/* Name Input */}
          <Box className="input-row">
            <Typography variant="body1" className="input-label">Name</Typography>
            <TextField
              id="name"
              placeholder="Add a name"
              variant="outlined"
              name="name"
              value={profileData.name}
              onChange={handleChange}
              fullWidth
              className="input-field"
            />
          </Box>

          {/* Birthday Input */}
          <Box className="input-row">
            <Typography variant="body1" className="input-label">Birthday</Typography>
            <TextField
              id="birthday"
              type="date"
              placeholder="20XX/MM/DD"
              name="birthday"
              value={profileData.birthday}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              className="input-field"
            />
          </Box>

          {/* Bio Input */}
          <Box className="input-row">
            <Typography variant="body1" className="input-label">Note/Bio</Typography>
            <TextField
              id="bio"
              placeholder="Add a note"
              name="bio"
              value={profileData.bio}
              onChange={handleChange}
              fullWidth
              rows={3}
              className="input-field"
            />
          </Box>

          {/* Playlists Section */}
 <Box display="flex" alignItems="center" className="playlists-section">
    <Typography variant="body1" className="input-label">Playlists</Typography>
    <Box display="flex" flexDirection="column" alignItems="center" className="avatar-text-section">
                          
    <Avatar
      src="https://via.placeholder.com/60"
      alt="Anime Watch Later"
      className="playlist-avatar"
    />
    <Typography variant="body1" className="playlist-name">
      Anime Watch Later
    </Typography>
  </Box>
  <IconButton color="primary" className="add-playlist-icon">
    <AddIcon className='addicon' />
  </IconButton>
</Box>


        </Grid>

        {/* Right Panel */}
        <Grid item xs={12} md={6} className="right-panel">
          <Typography variant="h6" className="privacy-title">Display Information Privacy</Typography>
          
          {/* Privacy Toggles */}
          <Box className="privacy-toggle">
            <Typography variant="body2" className="toggle-label">Display Name on profile?</Typography>
            <Switch
              checked={profileData.showName}
              onChange={() => handleSwitchChange('showName')}
            />
          </Box>
          <Box className="privacy-toggle">
            <Typography variant="body2" className="toggle-label">Display Birthday on profile?</Typography>
            <Switch
              checked={profileData.showBirthday}
              onChange={() => handleSwitchChange('showBirthday')}
            />
          </Box>
          <Box className="privacy-toggle">
            <Typography variant="body2" className="toggle-label">Display Bio on profile?</Typography>
            <Switch
              checked={profileData.showBio}
              onChange={() => handleSwitchChange('showBio')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileSettings;
