import React from "react";

/**
 * Component for the video page header.
 * Handles the back button navigation.
 */
const VideoPageHeader = ({ videoId, onGoBack }) => (
  <>
    {videoId === "a2e8cf81-9b5c-4391-baf9-47cec8609149" && (
      <button className="button back" onClick={onGoBack}>
        <i className="fa-solid fa-angle-left"></i>Back
      </button>
    )}
  </>
);

export default VideoPageHeader;
