import axios from "axios";
import { AUDIO_DETAIL_URL } from "../constant/constantUrls";

const axiosInstance = axios.create();


// Fetches details for a specific video by ID
export const fetchAudioDetails = async (audioId) => {
  try {
    const audioDetailUrl = `${AUDIO_DETAIL_URL}${audioId}`;
    const res = await axiosInstance.get(audioDetailUrl);
    return res;
  } catch (err) {
    console.error("Error fetching audio details:", err);
    throw err;
  }
};


// fetches audio file from spotify 
export const getAudio = async () => {
  const url =
    "https://spotify23.p.rapidapi.com/tracks/?ids=2A73XBDBQgmdXO8VsXPWIs,1c8gk2PeTE04A1pIDH9YMk,73CMRj62VK8nUS4ezD2wvi";
  const options = {
    method: "GET",
    headers: {
      "x-rapidapi-key": "78905fd2aemshcf2f1e9b438eb5ep174804jsn6d9fbc21f30a",
      "x-rapidapi-host": "spotify23.p.rapidapi.com",
    },
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();
    // console.log(result.tracks);
    return result.tracks[0].preview_url;
  } catch (error) {
    console.error(error);
  }
};
