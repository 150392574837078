import {
  Box,
  Typography,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormLabel,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LanguageIcon from "@mui/icons-material/Language";
import GoogleIcon from "@mui/icons-material/Google";
import "./NewLogin.scoped.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { userLogin } from "../../api/auth";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../reducers/authSlice";
import { setUserInfo } from "../../reducers/userSlice";
import { setToast } from "../../reducers/snackbarSlice";

const initialLoginNonFieldError = { error: false, message: "" };

const NewLogin = () => {
  const dispatch = useDispatch();

  const [loginNonFieldError, setLoginNonFieldError] = useState(
    initialLoginNonFieldError
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [language, setLanguage] = useState("EN");

  const loginSchema = z.object({
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(1, {
      message: "Password cannot be empty",
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    mode: "onBlur",
  });

  const submitLoginForm = async (data) => {
    try {
      setIsLoading(true);
      let res = await userLogin(data);
      if (res?.status === 200) {
        localStorage.setItem("token", res?.data?.token);
        setIsLoading(false);
        dispatch(setLoggedIn(true));
        dispatch(
          setUserInfo({ ...res?.data?.user, has_finished_onboarding: false })
        );
      }
    } catch (err) {
      setIsLoading(false);

      let statusCode = err?.response?.status;
      if (statusCode && statusCode === 400) {
        const nonFieldError = err?.response?.data?.non_field_errors;
        if (nonFieldError && nonFieldError[0]) {
          setLoginNonFieldError({ error: true, message: nonFieldError[0] });
        }
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error ocurred. Please try again later",
            type: "red",
          })
        );
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "EN" ? "FR" : "EN"));
  };

  return (
    <Box className="login">
      <img
        className="logo-img"
        src={process.env.PUBLIC_URL + "/assets/vosyn-logo-dark.svg"}
        alt="company's logo"
      />

      <Typography className="tagline" variant="h6">
        Translate and browse content in your language{" "}
        <Typography variant="h6" component="span" className="highlight">
          ━ easily.
        </Typography>
      </Typography>

      <Box className="signin-box">
        <Box component="form" onSubmit={handleSubmit(submitLoginForm)}>
          <FormControl
            variant="outlined"
            fullWidth
            className="formfield-control"
            error={Boolean(errors.email || loginNonFieldError?.error)}
          >
            <FormLabel className="formfield-label" htmlFor="login-email">
              Enter email address
            </FormLabel>
            <OutlinedInput
              {...register("email")}
              id="login-email"
              aria-describedby="login-email-helper-text"
              type="email"
              placeholder="username@example.com"
              className={`formfield ${
                errors.email || loginNonFieldError?.error ? "input-error" : ""
              }`}
            />
            {errors.email && (
              <FormHelperText
                id="login-email-helper-text"
                className="formfield-error-msg"
              >
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            variant="outlined"
            fullWidth
            className="formfield-control"
            error={Boolean(errors.password || loginNonFieldError?.error)}
          >
            <FormLabel className="formfield-label" htmlFor="login-password">
              Password
            </FormLabel>
            <OutlinedInput
              {...register("password")}
              id="login-password"
              aria-describedby="login-password-helper-text"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className={`formfield ${
                errors.password || loginNonFieldError?.error
                  ? "input-error"
                  : ""
              }`}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className="visibility-icon-box"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityIcon className="visibility-icon" />
                    ) : (
                      <VisibilityOffIcon className="visibility-icon" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <FormHelperText
                id="login-password-helper-text"
                className="formfield-error-msg"
              >
                {errors.password?.message}
              </FormHelperText>
            )}
          </FormControl>

          <Link className="formlink-box forgot-pwd" to="/">
            Forgot Password?
          </Link>

          <Button
            type="submit"
            fullWidth
            disabled={isLoading}
            className={`formbtn loginbtn ${isLoading ? "buttonLoading" : ""}`}
            variant="contained"
          >
            Log In
          </Button>
        </Box>

        <Typography className="formlink-box noaccount-text">
          No account?
          <Link className="signup-link" to={"/CONTENT-PLATFORM/auth/signup"}>
            Sign up
          </Link>
        </Typography>

        <Box className="divider">
          <Box component="span" className="line" />
          <Typography className="text">Or</Typography>
          <Box component="span" className="line" />
        </Box>

        <Button
          className="formbtn google-loginbtn"
          variant="contained"
          startIcon={<GoogleIcon />}
        >
          Continue with Google
        </Button>

        <Button className="formbtn other-optionsbtn">See other options</Button>

        <Box className="language-selector-box" onClick={toggleLanguage}>
          <LanguageIcon />
          <Typography>{language}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewLogin;
