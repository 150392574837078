import "./Search.scoped.css";
import { useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useDispatch } from "react-redux";
import { showAirisSidebar } from "../../reducers/airisSidebarSlice";

const Search = ({
  collapse,
  searchInputRef,
  searchRef,
  toggleSearch,
  value,
  setValue,
  style,
  onSubmit,
  placeholder,
  navSearch,
  setIsSearching,
  isListening,
  setVoiceTranscript,
  voiceTranscript,
}) => {
  const dispatch = useDispatch();

  // click on the vosyn search logo, it opens the AI chat box
  const handleClickOpenChatBox = () => {
    dispatch(showAirisSidebar());
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      onSubmit(value);
      setIsSearching(false);
      e.target.blur();
      setValue("");
    }
  };

  const handleIconClick = () => {
    if (value.trim() !== "") {
      onSubmit(value);
      setIsSearching(false);
      setValue(value);
    }
  };

  useEffect(() => {
    let timeoutId;
    if (!isListening && voiceTranscript && voiceTranscript.trim() !== "") {
      setValue(voiceTranscript);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        onSubmit(voiceTranscript);
        setVoiceTranscript("");
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [voiceTranscript, setValue, onSubmit]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setValue, setIsSearching]);

  return (
    <div
      className={
        navSearch
          ? "region-selector-text-container"
          : "search-selector-text-container"
      }
    >
      <SearchOutlinedIcon
        className="search-icon"
        onClick={handleIconClick} // Make the icon clickable
      />
      <input
        {...(collapse && { ref: searchInputRef })}
        className={`${
          navSearch ? "region-selector-text" : "search-selector-text"
        } ${collapse ? ".search-flex-row" : ""}`}
        type="text"
        placeholder={placeholder}
        value={value}
        ref={searchInputRef}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleSubmit}
        onFocus={() => setIsSearching(true)}
      />
      <img
        className="vosyn_search_logo"
        alt="Vosyn Search Logo"
        src="/assets/icons/vosyn_search.png"
        onClick={handleClickOpenChatBox}
      />
    </div>
  );
};

export default Search;
