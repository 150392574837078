import React from "react";
import { Grid2, Box, Typography, IconButton } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./AudioPlaylist.scoped.css";

const audioPlaylists = [
  {
    id: 1,
    title: "Red Bull Wololo Meets Son of Osiris - AoM Retold",
    description: "Red Bull Wololo Meets Son of Osiris - AoM Retold",
    image:
      "https://s3-alpha-sig.figma.com/img/a7b6/b835/f2522634b8dbe18991d511beccd11994?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T7Bzl9OV45mj7JgW8xy~oMl8EnKKLgW9ikT0y~VLa7l89zyL1YKCcxV17R9EmvMpuetds8mzOHzenZsuWcpX9RvcP2ZTT8PbLglqGKB8Y8xpbcT6kJ1Q-r63tjWOLihd~RCb-kmJUlbIyZ0x~o5MDg4XSat1hkle15rHJYErm~pyjw1UPACFP9khPppe9gQTyFZyz6-oAc6WjmUG6yM~3ZDlLyotEfCaGpfaJIEcl4mSCR9h-wjSpDAyS4sSIc5POKxFzF314V0PDTea5FTlE1pJ5Uv7Qj9ULe5bWN4KV9KTrxpFv3FolaD~bkqmCuitcfmjUCtfV5TW54Cj85GPTg__",
  },
  {
    id: 2,
    title: "Red Bull Wololo Meets Son of Osiris - AoM Retold",
    description: "Red Bull Wololo Meets Son of Osiris - AoM Retold",
    image:
      "https://s3-alpha-sig.figma.com/img/e16f/7429/d232b32096053d06eab432f4cdd8d765?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hn5sYzZgA2AqeeGhA1viL-fGBBU05Zrm3zYqg6OJ6QpuTUwmifsZQYKDA7l7a6OJ~ijcBSAy0EEcNysEScdsnBfE-8iqV8tZEgKjB846iGh99caLmEykhyR8H-0I9xW~yk8cHnctgS77ohcrvH0f2qVmz5rU3jUXmDCbpb7SFDeBU8K0qB4TmDGy1iyKaf4btVVpIwMWffs6Fsr~PEcb6kAJlf4PYiqRez9YhsFAwLMW3NZmNL2g7q7rM805omJ5TVv0-8ig9s~CiF~yed7aJJJtqYoa~y-mhuELn4Mt6WJoMU3i2DecgPR9~B-1jV8YI5kLfSbfJghRULJNhx-Qsg__",
  },
];

const AudioPlaylist = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {audioPlaylists.map((audio) => (
        <Box className="card" key={audio.id}>
          <Box className="card-image">
            <img
              src={audio.image || "https://via.placeholder.com/140"}
              alt={audio.title}
            />
          </Box>
          <Box className="card-content">
            <Typography className="card-title">{audio.title}</Typography>
            <Typography className="card-text">{audio.description}</Typography>
          </Box>
          <Box className="action-buttons">
            <IconButton className="action-button">
              <ShareOutlinedIcon />
            </IconButton>
            <IconButton className="action-button">
              <EditOutlinedIcon />
            </IconButton>
            <IconButton className="action-button">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AudioPlaylist;
