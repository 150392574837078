import React, { useState } from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import { Button, Box, Snackbar, Alert } from "@mui/material";
import "./ActionButtonsPanel.scoped.css";
import { downloadFile } from "../../utils/fileActions";
import ShareModal from "../ShareModal/ShareModal";

const ActionButtonsPanel = ({ showRate, showBookmark, fileUrl }) => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openShare, setShare] = useState(false);

  // Handle button clicks for Bookmark, Download, Rate and Share buttons
  const handleButtonClick = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleDownloadButtonClick = (msg) => {
    downloadFile(fileUrl);
    setMessage(msg);
    setOpen(true);
  }

  // Handle close event for the Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box className="action-buttons-panel">
      <Button
        variant="outlined"
        startIcon={<ShareIcon className="action-button-icon" />}
        className="action-button"
        onClick={() => setShare(true)}
      >
        Share
      </Button>
      <ShareModal isOpen={openShare} onClose={() => setShare(false)}/>
      <Button
        variant="outlined"
        startIcon={<DownloadIcon className="action-button-icon" />}
        className="action-button"
        onClick={() => handleDownloadButtonClick("Downloaded successfully!")}
      >
        Download
      </Button>
      {showRate && (
        <Button
          variant="outlined"
          startIcon={<StarIcon className="action-button-icon" />}
          className="action-button"
          onClick={() => handleButtonClick("Rated successfully!")}
        >
          Rate
        </Button>
      )}
      {showBookmark && (
        <Button
          variant="outlined"
          startIcon={<BookmarkIcon className="action-button-icon" />}
          className="action-button"
          onClick={() => handleButtonClick("Bookmarked successfully!")}
        >
          Bookmark
        </Button>
      )}
      {/* Snackbar for toasts */}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "20%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ActionButtonsPanel;