import React, { useEffect, useState } from "react";
import "./ShareModal.css";
import Modal from "../Modal/Modal";
import { Icon, Box, Typography, Grid2, Button, TextField } from "@mui/material";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
//import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";

const socialMediaPlatforms = [
  {
    name: "WhatsApp",
    iconClass: WhatsAppIcon,
    testID: "WhatsAppTest",
    shareUrl: "https://wa.me/?text=",
  },
  {
    name: "Facebook", //NOTE: This will only work when trying to share a public link. Meaning that trying to share a localhost link will not work because it is a private link
    iconClass: FacebookIcon,
    testID: "FacebookTest",
    shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    name: "Twitter",
    iconClass: TwitterIcon,
    testID: "TwitterTest",
    shareUrl: "https://twitter.com/share?url=",
  },

  /*{
       name: "Instagram", //NOTE: Instagram has no API support for a share link
       iconClass: InstagramIcon,
       shareUrl: "https://www.instagram.com/?url=",
  },*/

  {
    name: "Telegram",
    iconClass: TelegramIcon,
    testID: "TelegramTest",
    shareUrl: "https://telegram.me/share/url?url=",
  },
  {
    name: "Email", //NOTE: For email to work you need default email set in Windows and if its still won't work you need to grant your email of choice handler permission in your browser setting
    iconClass: EmailIcon, //NOTE 2: When using this on a macOS product you might get a warning, nothing is wrong and the email will send propertly
    testID: "EmailTest",
    shareUrl: "mailto:? &subject=VosynVerse%20Video &body=",
  },
];

const ShareModal = ({ isOpen, onClose }) => {
  const [top, setTop] = useState(0);
  const [buttonText, setButtonText] = useState("Copy Link");

  useEffect(() => {
    const handleScroll = () => {
      const modalElement = document.querySelector(".share-modal");

      // Ensure the modal element exists before accessing its properties
      if (modalElement) {
        const modalHeight = modalElement.offsetHeight;
        const viewportHeight = window.innerHeight;
        const newTop = (viewportHeight - modalHeight) / 2 + window.scrollY;
        setTop(newTop);
      }
    };

    if (isOpen) {
      document.body.classList.add("no-scroll"); // Prevent scrolling
      handleScroll();
    } else {
      document.body.classList.remove("no-scroll"); // Restore scrolling
    }

    return () => {
      document.body.classList.remove("no-scroll"); // Ensure cleanup
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("share-modal")) {
      onClose();
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);

    setButtonText("Copied!");

    setTimeout(() => {
      setButtonText("Copy Link");
    }, 2000);
  };

  return (
    <Modal show={isOpen} onClose={onClose} hideCloseIcon={true}>
      <Box className="share-modal" onClick={handleBackdropClick}>
        <Box className="share-modal-content">
          <Grid2 className="share-close">
            <Icon
              onClick={onClose}
              component={CloseIcon}
              aria-hidden="true"
            ></Icon>
          </Grid2>
          <Typography className="share-modal-text">
            Share with Others!
          </Typography>
          <Box className="share-modal-dialog share-modal-dialog-text">
            <Typography>Preview</Typography>
          </Box>
          <Box className="share-modal-text">
            <Typography>File Name. ext</Typography>
          </Box>
          <Box role="dialog" className="modal-backdrop">
            <Box className="social-media-icons">
              {socialMediaPlatforms.map((platform) => (
                <Box
                  key={platform.name}
                  className="social-media-icon-container"
                >
                  <Icon
                    className="social-media-icon"
                    key={platform.name}
                    component={platform.iconClass}
                    aria-label={platform.testID}
                    onClick={() =>
                      window.open(
                        platform.shareUrl + window.location.href,
                        "_blank",
                        "noopener noreferrer"
                      )
                    }
                  />
                  <Typography variant="caption" className="social-media-text">
                    {platform.name}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box className="share-modal-input-copybtn">
              <TextField
                className="custom-textfield"
                fullWidth
                variant="outlined"
                size="small"
                value={window.location.href}
                readOnly
              />
              <Box className="container">
                <Button
                  className="button btn-blue copy-button"
                  onClick={handleCopy}
                >
                  {buttonText}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;
