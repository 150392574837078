import React, { useEffect, useRef, useState } from "react";
import "./Transcript.scoped.css";
import Dialogue from "../Dialouge/Dialogue";
import { useSelector } from "react-redux";
import { parseDurationToSeconds } from "../../utils/calcVideoDurations.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Transcript = ({ transcriptJson }) => {
  const transcriptList = useRef();
  const [transcripts, setTranscripts] = useState([]);
  const [activeItemId, setActiveItemId] = useState(transcriptJson[0].timestamp);
  const playing = useSelector(({ videoPlayer }) => videoPlayer.playing);
  const videoTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);
  const [autoScroll, setAutoScroll] = useState(true);
  const hasEnded = useSelector(({ videoPlayer }) => videoPlayer.hasEnded);
  const [activeItemIndex, setActiveItemTndex] = useState(0);
  const [showResumeScrolling, setShowResumeScrolling] = useState(false);
  const LIST_ITEM_HEIGHT = 85;
  const [isAnyTranscriptflagged, setIsAnyTranscriptflagged] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    const mappedTranscripts = transcriptJson.map((transcript, index) => {
      if (index !== 0) {
        return {
          ...transcript,
          startTime:
            parseDurationToSeconds(transcriptJson[index - 1].timestamp) + 1,
        };
      } else {
        return { ...transcript, startTime: 0 };
      }
    });
    setTranscripts(mappedTranscripts);
  }, [transcriptJson]);

  useEffect(() => {
    if (!hasEnded) {
      transcripts.forEach((item) => {
        if (
          videoTime >= item.startTime &&
          videoTime <= parseDurationToSeconds(item.timestamp)
        ) {
          setActiveItemId(item.timestamp);
        }
      });
    }
  }, [videoTime, playing, transcripts]);

  useEffect(() => {
    if (transcripts) {
      if (autoScroll) {
        if (activeItemId === transcripts[0]?.timestamp) {
          transcriptList.current.scrollBy({
            top: 0,
            left: 0,
          });
        } else {
          transcriptList.current.scrollBy({
            top: LIST_ITEM_HEIGHT,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      const activeItemIdx = transcripts.findIndex(
        (transcript) => transcript.timestamp === activeItemId
      );
      setActiveItemTndex(activeItemIdx);
      if (!showResumeScrolling && autoScroll) {
        setCurrentItemPosition(activeItemIdx);
      }
    }
  }, [activeItemId, transcripts]);

  useEffect(() => {
    if (transcripts) {
      transcriptList.current.onscrollend = (e) => {
        const top = e.target.scrollTop;
        if (!isAnyTranscriptflagged) {
          if (
            top > activeItemIndex * LIST_ITEM_HEIGHT ||
            top < activeItemIndex * LIST_ITEM_HEIGHT
          ) {
            setAutoScroll(false);
            setShowResumeScrolling(true);
          } else {
            setAutoScroll(true);
            setShowResumeScrolling(false);
          }
        }
      };
    }
  });

  useEffect(() => {
    const item = transcripts.find((transcript) => transcript.flagged == true);
    if (item !== undefined) {
      setIsAnyTranscriptflagged(true);
    } else {
      setIsAnyTranscriptflagged(false);
    }
  }, [transcripts]);

  const handleflagging = (timestamp) => {
    const copiedTranscript = [...transcripts];
    const filteredElIndex = copiedTranscript.findIndex(
      (transcript) => transcript.timestamp === timestamp
    );
    copiedTranscript[filteredElIndex].flagged =
      !transcripts[filteredElIndex].flagged;
    setTranscripts(copiedTranscript);
    setAutoScroll(false);
  };

  const handleSubmitSuggestionText = (text, id) => {
    const copiedTrascripts = [...transcripts];
    const selectedItemIndex = copiedTrascripts.findIndex(
      (transcript) => transcript.timestamp === id
    );
    const copiedObject = {
      ...copiedTrascripts[selectedItemIndex],
      suggestedText: text,
    };
    copiedTrascripts[selectedItemIndex] = copiedObject;
    setTranscripts(copiedTrascripts);
  };

  const setCurrentItemPosition = (index) => {
    transcriptList.current.scroll({
      top: index * LIST_ITEM_HEIGHT,
      behavior: "smooth",
    });
  };

  return (
    <Box className="transcripts-container">
      <Typography variant="h2">Transcript</Typography>
      <Box className="transcript-list" ref={transcriptList}>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => {
            setIsSnackbarOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setIsSnackbarOpen(false);
            }}
            severity="warning"
            variant="filled"
            sx={{ width: "20%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
        {
          <Box style={{ marginBottom: LIST_ITEM_HEIGHT * 3 }}>
            {transcripts.map((transcript, index) => {
              return (
                <Dialogue
                  key={transcript.timestamp}
                  transcript={transcript}
                  handleflagging={handleflagging}
                  showFlagAndTime={true}
                  listItemHeight={LIST_ITEM_HEIGHT}
                  setCurrentItemPosition={setCurrentItemPosition}
                  index={index}
                  setAutoScroll={setAutoScroll}
                  setShowResumeScrolling={setShowResumeScrolling}
                  handleSubmitSuggestionText={handleSubmitSuggestionText}
                  activeItemIndex={activeItemIndex}
                  isAnyTranscriptflagged={isAnyTranscriptflagged}
                  transcriptList={transcriptList}
                  LIST_ITEM_HEIGHT={LIST_ITEM_HEIGHT}
                  setIsSnackbarOpen={setIsSnackbarOpen}
                  setSnackMessage={setSnackMessage}
                />
              );
            })}
            <Box
              className={
                !showResumeScrolling ? "scroll-badge" : "scroll-badge show"
              }
              onClick={() => {
                if (!isAnyTranscriptflagged) {
                  setCurrentItemPosition(activeItemIndex);
                  setAutoScroll(true);
                } else {
                  setSnackMessage("Please complete the open suggestion");
                  setIsSnackbarOpen(true);
                }
              }}
            >
              <Typography variant="body1">Resume AutoScrolling</Typography>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default Transcript;
