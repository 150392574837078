import { Box, Card, Typography } from "@mui/material";
import "./TextCard.scoped.css";

const truncateText = (text, charLimit = 100) => {
  if (text.length > charLimit) {
    return text.substring(0, charLimit) + "...";
  }
  return text;
};
function TextCard({ textCardDummyData }) {
  const { title, subText, logo, cardPic } = textCardDummyData;
  const displayedTitle = truncateText(title);
  const displayedText = truncateText(subText);

  return (
    <Card className="textcard-container" data-testid="card">
      <Box
        className="news-image"
        data-testid="background"
        style={{ backgroundImage: `url(${cardPic})` }}
      >
        <Box className="cnn-logo-container">
          <Box
            className="cnn-logo"
            data-testid="logo"
            style={{ backgroundImage: `url(${logo})` }}
          />
        </Box>
        <Box className="text-container">
          <Typography className="news-header" data-testid="text1">
            {displayedTitle}
          </Typography>

          <Typography className="news-text" data-testid="text2">
            {displayedText}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default TextCard;
