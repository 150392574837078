import React from "react";
import ToolTip from "../../ToolTip/ToolTip";
import { Box, Typography } from "@mui/material";
import "./LikeDislike.scoped.css";
import {
  ThumbUp,
  ThumbUpOutlined,
  ThumbDown,
  ThumbDownAltOutlined,
} from "@mui/icons-material";

const LikeDislikeButton = () => {
  // Set the values later for both like and dislike from database.
  // Once the database endpoint is ready.
  const [likeCount, setLikeCount] = React.useState(9999);
  const [dislikeCount, setDislikeCount] = React.useState(500);
  const [isLiked, setIsLiked] = React.useState(false);
  const [isDisliked, setIsDisliked] = React.useState(false);
  //   React.useEffect(() => {
  //     // Replace with the API endpoint
  //     fetch('/api/likes-dislikes')
  //         .then(response => response.json())
  //         .then(data => {
  //             setLikeCount(data.likeCount);
  //             setDislikeCount(data.dislikeCount);
  //             setIsLiked(data.isLiked);
  //             setIsDisliked(data.isDisliked);
  //         })
  //         .catch(error => console.error('Error fetching like/dislike counts:', error));
  // }, []);
  const formatCount = (count) => {
    return count >= 10000 ? `${Math.round(count / 1000)}K` : count;
  };
  const handleLikeClick = async () => {
    if (isLiked) {
      setLikeCount(likeCount - 1);
      setIsLiked(false);
      //   await fetch('/api/likes-dislikes', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({ likeCount: likeCount - 1, isLiked: false }),
      //   });
    } else {
      setLikeCount(likeCount + 1);
      setIsLiked(true);
      //   await fetch('/api/likes-dislikes', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({ likeCount: likeCount + 1, isLiked: true }),
      //   });
      if (isDisliked) {
        setDislikeCount(dislikeCount - 1);
        setIsDisliked(false);
        // await fetch('/api/likes-dislikes', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ dislikeCount: dislikeCount - 1, isDisliked: false }),
        // });
      }
    }
  };
  const handleDislikeClick = async () => {
    if (isDisliked) {
      setDislikeCount(dislikeCount - 1);
      setIsDisliked(false);
      //   await fetch('/api/likes-dislikes', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({ dislikeCount: dislikeCount - 1, isDisliked: false }),
      //   });
    } else {
      setDislikeCount(dislikeCount + 1);
      setIsDisliked(true);
      //   await fetch('/api/likes-dislikes', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({ dislikeCount: dislikeCount + 1, isDisliked: true }),
      //   });
      if (isLiked) {
        setLikeCount(likeCount - 1);
        setIsLiked(false);
        // await fetch('/api/likes-dislikes', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ likeCount: likeCount - 1, isLiked: false }),
        // });
      }
    }
  };
  return (
    <Box className="like-dislike-container" display="flex" alignItems="center">
      <ToolTip content={"Like"}>
        <Box
          onClick={handleLikeClick}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          data-testid="like-button"
        >
          {isLiked ? <ThumbUp /> : <ThumbUpOutlined />}
          <Typography
            variant="body2"
            className="like-count"
            sx={{ marginLeft: "4px" }}
          >
            {formatCount(likeCount)}
          </Typography>
        </Box>
      </ToolTip>
      <Box
        className="separator"
        sx={{
          width: "1px",
          height: "20px",
          backgroundColor: "grey",
          margin: "0 8px",
        }}
      />
      <ToolTip content={"Dislike"}>
        <Box
          onClick={handleDislikeClick}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          {isDisliked ? <ThumbDown /> : <ThumbDownAltOutlined />}
        </Box>
      </ToolTip>
    </Box>
  );
};

export default LikeDislikeButton;
