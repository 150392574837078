// src/pages/Featured.js
import React from "react";
import { Box, Grid2 } from "@mui/material";
import CarouselComponent from "../../Carousel/CarouselComponent";
import TextThumbnail from "../../TextThumbnail/TextThumbnail";
import Filter from "../../Filter/Filters";

const textDataTemp = [
  {
    id: 1,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 1",
    description: "This is the description for Text 1.",
  },
  {
    id: 2,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 2",
    description: "This is the description for Text 2.",
  },
  {
    id: 3,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 3",
    description: "This is the description for Text 3.",
  },
  {
    id: 4,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Text 4",
    description: "This is the description for Text 4.",
  },
  {
    id: 5,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 5",
    description: "This is the description for Text 5.",
  },
  {
    id: 6,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 6",
    description: "This is the description for Text 6.",
  },
  {
    id: 7,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg",
    title: "Text 7",
    description: "This is the description for Text 7.",
  },
  {
    id: 8,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Text 8",
    description: "This is the description for Text 8.",
  },
  {
    id: 9,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Text 9",
    description: "This is the description for Text 9.",
  },
];

const TextTab = () => {
  return (
    <>
      <Filter />
      <Box sx={{ px: "3vw", py: "5vh" }}>
        <Grid2 container spacing={8} columns={5}>
          <Grid2 item size={2}>
            <Box data-testid="carousel-box">
              <CarouselComponent />
            </Box>
          </Grid2>
          {textDataTemp.map((text, index) => (
            <Grid2 size={1} key={text.id}>
              <TextThumbnail
                image={text.image}
                title={text.title}
                description={text.description}
                id={text.id}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
};

export default TextTab;
