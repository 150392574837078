import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Alert from "./components/Alert/Alert.jsx";
import { AlertProvider } from "./context/AlertProvider.jsx";
import "./App.css";
import { userProfile } from "./api/profile.js";
import PrivateRoute from "./utils/PrivateRoutes.js";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn } from "./reducers/authSlice.js";

import Layout from "./components/Layout/Layout.jsx";
import { setUserInfo } from "./reducers/userSlice.js";
import Snackbar from "./components/Snackbar/Snackbar.jsx";
import { authItems, navItems } from "./utils/mainNavPaths.js";

function App() {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const isOnboardingCompleted = useSelector(
    (state) => state?.userInfo?.userProfile?.has_finished_onboarding
  );
  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      let res = await userProfile();
      dispatch(setUserInfo(res?.data));
      setLoading(false);
      if (res?.status === 200) {
        dispatch(setLoggedIn(true));
      }
    } catch (err) {
      setLoading(false);
    }
  };

  // Make the element to render based on nav item properties.
  const getRootElement = (navItem) => {
    const isPrivatePath = navItem?.isPrivateRoute;
    const hasLayoutParent = navItem?.hasLayoutParent;

    let element = navItem?.element;

    if (hasLayoutParent) {
      element = <Layout>{element}</Layout>;
    }

    if (isPrivatePath) {
      element = <PrivateRoute>{element}</PrivateRoute>;
    }

    return element;
  };

  // Get auth paths UI.
  const getAuthRootElements = (authItem) => {
    return !isLoggedIn ? authItem?.element : authItem?.redirectElement;
  };

  const getNavigationMenuElements = (menuItem) => {
    const isPrivatePath = menuItem?.isPrivateRoute;
    const hasLayoutParent = menuItem?.hasLayoutParent;

    let element = menuItem?.element;

    if (hasLayoutParent) {
      element = <Layout>{element}</Layout>;
    }

    if (isPrivatePath) {
      element = <PrivateRoute>{element}</PrivateRoute>;
    }

    return element;
  };

  if (loading) {
    return <div>Loading.........</div>; // To be changed: with loading component in future
  }

  return (
    <>
      <AlertProvider>
        <Alert />
        {toast?.showSnackBar && <Snackbar />}

        <Routes>
          {navItems?.map((navItem) => (
            <Route
              path={navItem?.path}
              element={getRootElement(navItem)}
              key={navItem?.path}
            />
          ))}
          {authItems?.map((authItem) => (
            <Route
              path={authItem?.path}
              element={getAuthRootElements(authItem)}
              key={authItem?.path}
            />
          ))}
        </Routes>
      </AlertProvider>
    </>
  );
}

export default App;
