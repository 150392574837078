import React, { useState, useEffect } from "react";
import "./ProfileBirthDay.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementStage,
  updateBirthdate,
  updateGender,
  updateFormData,
} from "../../../../reducers/userPrefReducer";

const ProfileBirthDay = () => {
  const [month, setMonth] = useState({ month: "", valid: true });
  const [day, setDay] = useState({ day: "", valid: true });
  const [year, setYear] = useState({ year: "", valid: true });
  const [gender, setGender] = useState("");
  const formData = useSelector((state) => state.userPref.formData);

  useEffect(() => {
    if (formData.birthdate) {
      const [parsedYear, parsedMonth, parsedDay] =
        formData.birthdate.split("-");
      setYear({ year: parsedYear, valid: true });
      setMonth({ month: parsedMonth, valid: true });
      setDay({ day: parsedDay, valid: true });
    }
    setGender(formData.gender || "");
  }, [formData]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dispatch = useDispatch();

  const isValidDay = (input) => {
    const dayNumber = Number(input);
    return !isNaN(dayNumber) && dayNumber >= 1 && dayNumber <= 31;
  };

  const isValidYear = (input) => {
    const yearNumber = Number(input);
    return !isNaN(yearNumber) && yearNumber >= 1900 && yearNumber <= 2023;
  };

  const showError = (valid) => {
    return (
      <p className="fieldError" style={{ visibility: valid && "hidden" }}>
        Not a valid input
      </p>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!day.valid || !year.valid) return;

    const d = new Date(`${month.month} ${day.day} ${year.year}`);
    const monthDigit = String(d.getMonth() + 1).padStart(2, "0");
    const dob = `${year.year}-${monthDigit}-${String(day.day).padStart(
      2,
      "0"
    )}`;

    dispatch(updateGender(gender));
    dispatch(updateBirthdate(dob));
    dispatch(updateFormData({ birthdate: dob, gender: gender }));
    dispatch(incrementStage());
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="ob-profile-form"
      className="birthday-form"
    >
      <div className="gender">
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          required
          className={`select ${gender ? "selectedOption" : "defaultColor"}`}
        >
          <option value="" disabled selected>
            Gender
          </option>
          <option value="M">Male</option>
          <option value="F">Female</option>
          <option value="N">Rather not say</option>
        </select>
      </div>
      <div className="verticalLine" />
      <div className="date">
        <div className="gender">
          <select
            value={month.month}
            onChange={(e) => setMonth({ month: e.target.value, valid: true })}
            required
            className={`select ${
              month.month ? "selectedOption" : "defaultColor"
            }`}
          >
            <option value="" disabled selected>
              Months
            </option>
            {months.map((m, index) => (
              <option key={index} value={m}>
                {m}
              </option>
            ))}
          </select>
          {showError(month.valid)}
        </div>
        <div className="formGroup">
          <input
            type="text"
            placeholder="Day"
            value={day.day}
            onChange={(e) => {
              const input = e.target.value;
              setDay({ day: e.target.value, valid: isValidDay(input) });
            }}
            required
          />
          {showError(day.valid)}
        </div>
        <div className="formGroup year">
          <input
            type="text"
            placeholder="Year"
            value={year.year}
            onChange={(e) => {
              const input = e.target.value;
              setYear({ year: e.target.value, valid: isValidYear(input) });
            }}
            required
          />
          {showError(year.valid)}
        </div>
      </div>
    </form>
  );
};

export default ProfileBirthDay;
