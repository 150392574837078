const parseVTT = (vttCues) => {
  const parsedSubtitles = [];

  for (let cue of vttCues) {
    parsedSubtitles.push({
      start: cue.startTime,
      end: cue.endTime,
      text: cue.text,
    });
  }
  return parsedSubtitles;
};

export default parseVTT;