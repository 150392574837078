import "./ServicePage.scoped.css";
import { useParams } from "react-router-dom";
import ServiceNetflix from "../../components/Service/ServiceNetflix/Netflix";
import ServiceAmazonPrime from "../../components/Service/ServiceAmazonPrime/ServiceAmazonPrime";
import ServiceDisney from "../../components/Service/ServiceDisney/ServiceDisney";
import ServiceYoutube from "../../components/Service/ServiceYoutube/Youtube";
import ServiceCoursera from "../../components/Service/ServiceCoursera/ServiceCoursera";
import { mockData } from "../../data/service-data-coursera.js";
import { useEffect, useState } from "react";

const ServicePage = () => {
  const { serviceName } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    setData(mockData);
  }, []);

  return (
    <div className="service-page">
      {serviceName === "netflix" && <ServiceNetflix />}
      {serviceName === "amazon-prime" && <ServiceAmazonPrime />}
      {serviceName === "disney" && <ServiceDisney />}
      {serviceName === "youtube" && <ServiceYoutube />}
      {serviceName === "coursera" && <ServiceCoursera data={data} />}
    </div>
  );
};

export default ServicePage;
