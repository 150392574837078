import { useState } from "react";
import UserProfileDropdown from "../Dropdown/UserProfileDropdown";
import "./UserProfile.scoped.css";

const UserProfile = ({
  numberOfNotifications
}) => {
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  return (
    <>
      <UserProfileDropdown
        toggleDropdown={() => setIsUserProfileOpen(!isUserProfileOpen)}
        type="Profile"
        isOpen={true}
        setIsOpen={setIsUserProfileOpen}
      />
    </>
  );
};

export default UserProfile;
