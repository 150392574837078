import React, { useState, useEffect } from "react";
import {
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { fetchVideosPageData } from "../../api/filtersApi.js";
import {
  PLATFORM_OPTIONS,
  CATEGORY_OPTIONS,
  GENRE_OPTIONS,
} from "../../constant/filtersConstant.js";
import "./Filters.scoped.css";

const Filters = () => {
  const [platformName, setPlatformName] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [genreName, setGenreName] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchVideosPageData();
      setData(fetchedData);
      setFilteredData(fetchedData);
    };
    loadData();
  }, []);

  const handleSelectionChange = (
    selectedState,
    setSelectedState,
    event,
    options
  ) => {
    const { value } = event.target;

    // If "All" is checked
    if (value.includes("All")) {
      if (selectedState.includes("All")) {
        // If "All" is already in the selection, deselect it
        setSelectedState(value.filter((v) => v !== "All"));
      } else {
        // If "All" is selected and is not currently in the state, select all options
        setSelectedState(options);
      }
    } else {
      // If another option is selected and "All" is in the selection, remove "All"
      const newSelectedState = selectedState.includes("All") ? [] : [...value];

      setSelectedState(newSelectedState);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      const filtered = data.filter((item) => {
        const platformMatch =
          platformName.includes("All") ||
          platformName.length === 0 ||
          platformName.some((platform) =>
            item.document.sourcePlatform.some((itemPlatform) =>
              itemPlatform.toLowerCase().includes(platform.toLowerCase())
            )
          );

        const categoriesMatch =
          categoriesName.includes("All") ||
          categoriesName.length === 0 ||
          categoriesName.some((category) =>
            item.document.categories.some((itemCat) =>
              itemCat.toLowerCase().includes(category.toLowerCase())
            )
          );

        const genreMatch =
          genreName.includes("All") ||
          genreName.length === 0 ||
          genreName.some((genre) =>
            item.document.genre.some((itemGenre) =>
              itemGenre.toLowerCase().includes(genre.toLowerCase())
            )
          );

        return platformMatch && categoriesMatch && genreMatch;
      });
      setFilteredData(filtered);
    };

    applyFilters();
  }, [platformName, categoriesName, genreName, data]);

  const MenuProps = {
    PaperProps: {
      sx: {
        backgroundColor: "var(--side-bg)",
        color: "var(--text)",
      },
    },
  };

  const selectStyles = {
    "& .MuiSvgIcon-root": { color: "var(--text)" },
    "& .MuiOutlinedInput-input": { color: "var(--text)" },
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiSelect-select": { display: "flex", alignItems: "center" },
  };

  const renderFilteredData = () =>
    filteredData.length > 0 ? (
      filteredData.map((singleItem) => (
        <Box key={singleItem.document.id} className="filter-box">
          <Typography className="typographyplatform">
            Video Id: {singleItem.document.id}
          </Typography>
          <Typography className="typographyplatform">
            Category: {singleItem.document.categories.join(", ")}
          </Typography>
          <Typography className="typographyplatform">
            Source Platform: {singleItem.document.sourcePlatform + " "}
          </Typography>
          <Typography className="typographyplatform">
            Genre: {singleItem.document.genre}
          </Typography>
        </Box>
      ))
    ) : (
      <Typography className="no-data">
        No data found for the selected filters.
      </Typography>
    );

  return (
    <>
      <Box className="filter-container">
        <Typography className="filter-label">{"Source Platform"}</Typography>
        <FilterSelect
          label="Source Platform"
          value={platformName}
          onChange={(event) =>
            handleSelectionChange(
              platformName,
              setPlatformName,
              event,
              PLATFORM_OPTIONS
            )
          }
          options={PLATFORM_OPTIONS}
          selectStyles={selectStyles}
          MenuProps={MenuProps}
        />
        <Typography className="filter-label">{"Categories"}</Typography>
        <FilterSelect
          label="Categories"
          value={categoriesName}
          onChange={(event) =>
            handleSelectionChange(
              categoriesName,
              setCategoriesName,
              event,
              CATEGORY_OPTIONS
            )
          }
          options={CATEGORY_OPTIONS}
          selectStyles={selectStyles}
          MenuProps={MenuProps}
        />
        <Typography className="filter-label">{"Genre"}</Typography>
        <FilterSelect
          label="Genre"
          value={genreName}
          onChange={(event) =>
            handleSelectionChange(genreName, setGenreName, event, GENRE_OPTIONS)
          }
          options={GENRE_OPTIONS}
          selectStyles={selectStyles}
          MenuProps={MenuProps}
        />
      </Box>
      {/* <Box sx={{ marginTop: "24px", marginLeft: "30px" }}>
        {renderFilteredData()}
      </Box> */}
    </>
  );
};

const FilterSelect = ({
  label,
  value,
  onChange,
  options,
  selectStyles,
  MenuProps,
}) => (
  <FormControl>
    <Select
      multiple
      value={value}
      onChange={onChange}
      renderValue={() => ""}
      sx={selectStyles}
      MenuProps={MenuProps}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option} className="typographyplatform">
          <Checkbox checked={value.indexOf(option) > -1} className="checkbox" />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default Filters;
