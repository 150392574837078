import React from 'react';
import { Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiIcon from '../../../utils/MuiIcon';

// Custom-styled square Radio component
const SquareRadio = styled((props) => (
  <Radio
    {...props}
    icon={<MuiIcon name="CheckBoxOutlineBlankSharp" />} // Unchecked square
    checkedIcon={<MuiIcon name="CheckBoxSharp" />}      // Checked square
  />
))(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 27, // You can adjust the size of the square radio button
  },
  '&.Mui-checked': {
    color: '#656565', // Customize color when checked
  },
}));

export default SquareRadio;
