import './ServiceBanner.scoped.css'

const ServiceTitle = ({icon, company, children}) => (
    <div className="service-title">
        <div>
            {icon || <i className="fa-brands fa-google" />}
            <span>{company}</span>
        </div>
        <h2>{children}</h2>
    </div>
)

export default ServiceTitle