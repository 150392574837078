import React from "react";
import "./VoiceAssistAnimation.scoped.css";
import { PiMicrophoneFill } from "react-icons/pi";

const VoiceAssistAnimation = () => {
  return (
    <div>
      <div className="voice-container">
        <div className="voice-text">
          <div className="v-text-1">Hi, </div>
          <div className="v-text-2">how are you?</div>
        </div>
        <div className="mic-icon">
          <PiMicrophoneFill size={26} />
        </div>
      </div>
    </div>
  );
};

export default VoiceAssistAnimation;
