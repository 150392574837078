import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setChatTypewrited } from "../../../../reducers/airisChatHistorySlice";

/**
 * Typewriter component displays text with a typewriter effect.
 * It progressively reveals the text character by character.
 *
 * Props:
 * - text: The text to be displayed with the typewriter effect.
 * - typeAnimationScroll: Function to trigger animation scroll.
 * - scrollToBottom: Function to scroll to the bottom of the chat.
 * - setTextFinishLoading: Function to indicate that text loading is finished.
 * - typewrited: Boolean indicating whether the text has been fully typewritten.
 * - delay: Optional delay before starting the typewriter effect (default is 0).
 */

const Typewriter = ({
  text,
  typeAnimationScroll,
  scrollToBottom,
  setTextFinishLoading,
  typewrited,
  delay = 0,
}) => {
  const dispatch = useDispatch();
  const [displayText, setDisplayText] = useState("\u00A0");

  useEffect(() => {
    //Visual effect if the text has not been fully typewritten yet
    if (!typewrited) {
      setTimeout(() => {
        // Loop through each character of the text
        for (let i = 0; i <= text.length; i++) {
          setTimeout(() => {
            // update the display text with each character and trigger the animation
            setDisplayText(text.slice(0, i));
            typeAnimationScroll();
          }, 12 * i);
        }

        // After all characters are displayed, finish loading text and indicate that text has been fully displayed via the dispatch
        setTimeout(() => {
          setTextFinishLoading();
          dispatch(setChatTypewrited());
          setTimeout(() => {
            scrollToBottom();
          }, 200); // Wait for animation to complete before scrolling
        }, text.length * 12);
      }, delay);
    } else {
      // If text has been fully typewritten, display the complete text
      setDisplayText(text);
    }
  }, [text]);

  return displayText;
};

export default Typewriter;
