import React, { useContext } from "react";
import "./ButtonsTour.scoped.css";
import TourBox from "../TourBox/TourBox";
import { useSelector } from "react-redux";

const ButtonsTour = () => {
  const titles = [
    "Globe side buttons",
    "Trending Videos",
    "My Highlights",
    "My Playlist",
  ];

  const tourTexts = [
    "Embark on a tailored video experience with just a click of the side buttons.",
    "Discover what’s trending worldwide with top videos by country and topic.",
    "Explore curated content based on your viewing history and selected country.",
    "Revisit your saved favourites by visualizing your playlists on the Globe.",
  ];

  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);

  const subStep = tourStep - 2;

  return (
    <>
      <div className="highlighted" />
      <div className="buttons-tour">
        <TourBox
          arrowDirection="arrow-top"
          title={titles[subStep]}
          tourText={tourTexts[subStep]}
          stage="2"
          progressBar={true}
        />
      </div>
    </>
  );
};

export default ButtonsTour;
