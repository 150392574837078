import ChatIcon from "@mui/icons-material/Chat";
import CallIcon from "@mui/icons-material/Call";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box, IconButton, Button, Typography } from "@mui/material";
import "./WatchPartyChatButtons.scoped.css";

const WatchPartyChatButtons = ({
  toggleWatchPartyModal,
  chatBtnSelected,
  setChatBtnSelected,
}) => {
  const handleChat = () => {
    setChatBtnSelected("chat");
  };

  const handleCall = () => {
    setChatBtnSelected("call");
  };

  const handleAddFriends = () => {
    toggleWatchPartyModal();
  };

  return (
    <Box className="watch-party-chat-elements">
      <Box className="watch-party-chat-call">
        <Box className="watch-party-btn-box">
          <IconButton
            className="watch-party-chat-call-btn"
            disableRipple
            aria-label="Switch to watch party chat"
            onClick={handleChat}
          >
            <ChatIcon fontSize="inherit"></ChatIcon>
          </IconButton>
          <Box className={chatBtnSelected === "chat" ? "btn-active" : ""}></Box>
        </Box>
        <Box className="watch-party-btn-box">
          <IconButton
            className="watch-party-chat-call-btn"
            disableRipple
            aria-label="Switch to watch party call"
            onClick={handleCall}
          >
            <CallIcon fontSize="inherit"></CallIcon>
          </IconButton>
          <Box className={chatBtnSelected === "call" ? "btn-active" : ""}></Box>
        </Box>
      </Box>
      <Button
        disableRipple
        variant="contained"
        className="watch-party-chat-add-friends-box"
        onClick={handleAddFriends}
      >
        <GroupAddIcon fontSize="inherit" />
        Add Friends
      </Button>
    </Box>
  );
};
export default WatchPartyChatButtons;
