import React from "react";
import { Box, Typography } from "@mui/material";
import PrimaryComponent from "../../PrimaryThumbnail/PrimaryComponent";
import VideoThumbnail from "../../VideoCards/VideoThumbnail/VideoThumbnail";
import AudioCard from "../../Audio/AudioCard";
import TextThumbnail from "../../TextThumbnail/TextThumbnail";
import TextCard from "../../TextCard/TextCard";
import AudioCardsGrid from "../../Audio/AudioCardsGrid";
import Grid from "@mui/material/Grid2";
import VideoCard from "../../VideoCard/VideoCard";
import "./FeaturedTab.scoped.css";
const videos = [
  {
    id: 1,
    thumbnail_url: "../../assets/moneyheist.png",
    title_en: "Sample Video",
    view_count: 1000,
    released_date: "2023-09-23",
    channelName: "Awesome Channel",
    channelImg: "../../assets/prime.png",
    url: "/sample-video",
    description: "This is a sample description for the video.",
  },
  {
    id: 2,
    thumbnail_url: "../../assets/moneyheist.png",
    title_en: "Adventure Awaits",
    view_count: 2500,
    released_date: "2023-09-20",
    channelName: "Adventure Channel",
    channelImg: "https://via.placeholder.com/50",
    url: "/adventure-awaits",
    description: "Join us on an epic journey through the mountains.",
  },
  {
    id: 3,
    thumbnail_url: "../../assets/moneyheist.png",
    title_en: "Cooking Made Easy",
    view_count: 1500,
    released_date: "2023-09-15",
    channelName: "Cooking Channel",
    channelImg: "https://via.placeholder.com/50",
    url: "/cooking-made-easy",
    description: "Learn quick and easy recipes for delicious meals.",
  },
  {
    id: 4,
    thumbnail_url: "../../assets/moneyheist.png",
    title_en: "Tech Trends 2023",
    view_count: 3200,
    released_date: "2023-09-10",
    channelName: "Tech Channel",
    channelImg: "https://via.placeholder.com/50",
    url: "/tech-trends-2023",
    description: "Discover the latest in technology and gadgets.",
  },
  {
    id: 5,
    thumbnail_url: "../../assets/moneyheist.png",
    title_en: "Tech Trends 2023",
    view_count: 3200,
    released_date: "2023-09-10",
    channelName: "Tech Channel",
    channelImg: "https://via.placeholder.com/50",
    url: "/tech-trends-2023",
    description: "Discover the latest in technology and gadgets.",
  },
];

const temp = [
  {
    id: 1,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
    title: "Audio Title 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 2,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 2",
    description: "This is the description for Audio 2.",
  },
  {
    id: 3,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 3",
    description: "This is the description for Audio 3.",
  },
  {
    id: 4,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Text Title",
    description: "This is the description for Text.",
  },
  {
    id: 5,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg",
    title: "Text Title",
    description: "This is the description for Text.",
  },
];

// dummy data for text card, so it can pass as prop and then make the card component more reuseable.
const textCardDummyData = {
  title: "Breaking News: Major Developments Unfolding Right Now",
  subText:
    "Lorem ipsum dolor sit amet consectetur. Sagittis. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  cardPic: "/assets/cnnLogo/CNN-News.png",
  logo: "/assets/cnnLogo/CNN.png",
};

const VideoDatas = {
  thumbnail_url: "/assets/AudioCardsImages/Nature.png", // Dummy image
  title_en: "Fall in Love with Nature in an Island",
  view_count: 553000,
  released_date: "6 months ago",
  channelName: "Nature Channel",
};

// dummy data for audio card
const audioData = [
  {
    id: 1,
    image: "/assets/AudioCardsImages/Anime.png",
    title: "Top 10 Must-Watch Anime on Crunchyroll for Every Fan",
    description: "Lorem ipsum dolor sit amet",
    logo: "/assets/AudioCardsImages/AnimeLogo.png",
  },
  {
    id: 2,
    image: "/assets/AudioCardsImages/Guitar.png",
    title: "Chill Vibes: Your Perfect Mood Booster",
    description: "Lorem ipsum dolor sit amet consectetur. Donec lectus.",
    logo: "/assets/AudioCardsImages/SpotifyLogo.png",
  },
  {
    id: 3,
    image: "/assets/AudioCardsImages/Nature.png",
    title: "Exploring the Serenity of Forests: A Journey Into Nature's Heart",
    description: "Lorem ipsum dolor sit amet consectetur. Donec lectus.",
    logo: "/assets/AudioCardsImages/YoutubeLogo.png",
  },
  {
    id: 4,
    image: "/assets/AudioCardsImages/Music.jpg",
    title: "Mindful Conversations: Exploring Life, Growth, and Inspiration",
    description: "Lorem ipsum dolor sit amet consectetur. Donec lectus.",
    logo: "/assets/AudioCardsImages/logo.png",
  },
];

const FeaturedTab = ({
  videoData = videos,
  dataTemp = temp,
  auodiolmt = 3,
}) => {
  return (
    <Box sx={{ flexGrow: 1, margin: 3, borderRadius: 2 }}>
      <Box
        sx={{
          display: "flex", // Row layout
          justifyContent: "space-between", // Space between the two boxes
          alignItems: "stretch", // Ensure both boxes align to the same height
          // px: "6vh",
          py: "5vh",
          gap: 8,
        }}
        className="top-container"
      >
        {/* Featured Banner */}
        <Box sx={{ flex: 1 }}>
          <PrimaryComponent />
        </Box>

        {/* Video Card beside carousal */}
        <Grid container row={12} column={1} spacing={8}>
          <VideoCard videoData={VideoDatas} size="large" />
        </Grid>
      </Box>

      <Box className="bottom-container">
        <VideoCard videoData={VideoDatas} size="medium" />
        <AudioCardsGrid cardsData={audioData} />
        <TextCard textCardDummyData={textCardDummyData} />
      </Box>
    </Box>
  );
};

export default FeaturedTab;
