import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Button,
  Divider
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiIcon from '../../utils/MuiIcon';
import SettingSwitch from './SettingSwitch/SettingSwitch';
import SettingSquareRadio from './SettingSquareRadio/SettingSquareRadio';
import './SettingPlayback.scoped.css'; // Import CSS file
import axios from 'axios'; // Axios for API requests

import { SETTINGS_URL, UPDATE_SETTINGS_URL } from '../../constant/constantUrls'; 

const PlaybackSettings = ({ onBack }) => {
  const [autoplayNextEpisode, setAutoplayNextEpisode] = useState(true);
  const [autoplayVideoPreviews, setAutoplayVideoPreviews] = useState(true);
  const [videoQuality, setVideoQuality] = useState('auto');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(SETTINGS_URL);
        const {
          autoplay_next_episode,
          autoplay_video_preview,
          video_quality
        } = response.data.user; 

        setAutoplayNextEpisode(autoplay_next_episode);
        setAutoplayVideoPreviews(autoplay_video_preview);
        setVideoQuality(video_quality);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch settings', error);
      }
    };

    fetchSettings();
  }, []);

  // Save settings to backend
  const saveSettings = async (newSettings) => {
    try {
      await axios.patch(UPDATE_SETTINGS_URL, newSettings);
    } catch (error) {
      console.error('Failed to save settings', error);
    }
  };


  const handleAutoPlayChange = (e) => {
    const updatedValue = e.target.checked;
    setAutoplayNextEpisode(updatedValue);
    saveSettings({ autoplay_next_episode: updatedValue }); 
  };

  const handleVideoPreviewsChange = (e) => {
    const updatedValue = e.target.checked;
    setAutoplayVideoPreviews(updatedValue);
    saveSettings({ autoplay_video_preview: updatedValue }); 
  };

  const handleVideoQualityChange = (e) => {
    const updatedValue = e.target.value;
    setVideoQuality(updatedValue);
    saveSettings({ video_quality: updatedValue }); 
  };

  // Function to handle back button click
  const handleBackClick = () => {
    window.history.back();
  };

  // Render loading state if necessary
  // if (loading) {
  //   return <Typography>Loading settings...</Typography>;
  // }

  return (
    <Box className="playback-settings-container">
      <Grid container spacing={2}>
        {/* Left Column: Back Button */}
        <Grid item size={2}>
          <div className="back-button">
            <Button startIcon={<MuiIcon name="ArrowBackIosNew" />} onClick={onBack}>
              Back
            </Button>
          </div>
        </Grid>

        {/* Right Column: Playback Settings */}
        <Grid item size={8}>
          <Typography variant="h4" className="title">Playback Settings</Typography>
          <Typography variant="body1" className="subtitle">Control your video viewing experience</Typography>

          {/* Autoplay Settings */}
          <Box className="section">
            <Typography variant="h6" className="section-title">Autoplay</Typography>
            
            {/* Autoplay next episode */}
            <Box className="setting-item-container">
              <Box className="switch-container">
                <FormControlLabel
                  control={<SettingSwitch 
                    inputProps={{ 'aria-label': 'Autoplay Next Episode Switch' }} 
                    checked={autoplayNextEpisode}
                    onChange={handleAutoPlayChange}/>}
                  className="form-control"
                />
              </Box>
              <Box className="label-container">
                <Typography variant="body2" className="setting-title">Autoplay Next Episode</Typography>
                <Typography variant="body2" className="description">
                  Automatically plays the next episode in a series when the current one finishes
                </Typography>
              </Box>
            </Box>

            {/* Autoplay video previews */}
            <Box className="setting-item-container">
              <Box className="switch-container">
                <FormControlLabel
                  control={<SettingSwitch
                    inputProps={{ 'aria-label': 'autoplay video previews' }}
                    checked={autoplayVideoPreviews}
                    onChange={handleVideoPreviewsChange}  />}
                  className="form-control"
                />
              </Box>
              <Box className="label-container">
                <Typography variant="body2" className="setting-title">Autoplay Video Previews</Typography>
                <Typography variant="body2" className="description">
                  Automatically plays short video previews while browsing the app
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Divider */}
          <Divider sx={{ my: 3 }} />

          {/* Video Quality Settings */}
          <Box className="section">
            <Typography variant="h6" className="section-title">Video Quality</Typography>
            <FormControl component="fieldset">
              <RadioGroup value={videoQuality} onChange={handleVideoQualityChange}>
                
                {/* Auto Video Quality */}
                <Box className="setting-item-container">
                  <Box className="switch-container">
                    <FormControlLabel
                      value="auto"
                      control={<SettingSquareRadio inputProps={{ 'aria-label': 'auto' }} />}
                      className="form-control"
                    />
                  </Box>
                  <Box className="label-container">
                    <Typography variant="body2" className="setting-title">Auto</Typography>
                    <Typography variant="body2" className="description">Adjust Based on Network</Typography>
                  </Box>
                </Box>

               {/* 480p Video Quality */}
                <Box className="setting-item-container">
                  <Box className="switch-container">
                    <FormControlLabel
                      value="480p"
                      control={<SettingSquareRadio inputProps={{ 'aria-label': '480p' }} />}
                      className="form-control"
                    />
                  </Box>
                  <Box className="label-container">
                    <Typography variant="body2" className="setting-title">
                      480p
                    </Typography>
                    <Typography variant="body2" className="description">
                    Standard Definition (SD) medium quality
                    </Typography>
                  </Box>
                </Box>

                {/* 720p Video Quality */}
                <Box className="setting-item-container">
                  <Box className="switch-container">
                    <FormControlLabel
                      value="720p"
                      control={<SettingSquareRadio inputProps={{ 'aria-label': '720p' }} />}
                      className="form-control"
                    />
                  </Box>
                  <Box className="label-container">
                    <Typography variant="body2" className="setting-title">
                      720p
                    </Typography>
                    <Typography variant="body2" className="description">
                    High Definition (HD) quality
                    </Typography>
                  </Box>
                </Box>

                {/* 1080p Video Quality */}
                <Box className="setting-item-container">
                  <Box className="switch-container">
                    <FormControlLabel
                      value="1080p"
                      control={<SettingSquareRadio  inputProps={{ 'aria-label': '1080p' }} />}
                      className="form-control"
                    />
                  </Box>
                  <Box className="label-container">
                    <Typography variant="body2" className="setting-title">
                      1080p
                    </Typography>
                    <Typography variant="body2" className="description">
                    Full HD quality
                    </Typography>
                  </Box>
                </Box>

                {/* 4K Video Quality */}
                <Box className="setting-item-container">
                  <Box className="switch-container">
                    <FormControlLabel
                      value="4k"
                      control={<SettingSquareRadio inputProps={{ 'aria-label': '4k' }} />}
                      className="form-control"
                    />
                  </Box>
                  <Box className="label-container">
                    <Typography variant="body2" className="setting-title">
                      4K (Ultra HD)
                    </Typography>
                    <Typography variant="body2" className="description">
                    Ultra-High Definition
                    </Typography>
                  </Box>
                </Box>
    
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlaybackSettings;
