import React, { useState } from "react";
import "../../../pages/VideoPage/VideoPage.scoped.css";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const VideoDescDescription = () => {
  const [videoDescShowMore, setVideoDescShowMore] = useState(false);

  const dashVideoObject = useSelector((state) => state.dashVideoObject);

  const videoDescContent = {
    videoFullDesc: dashVideoObject.description,
  };

  const toggleVideoDescShowMore = () => {
    setVideoDescShowMore(!videoDescShowMore);
  };

  return (
    <>
      <Box className="body-main">
        <Typography variant="h2">Description</Typography>
        <Typography variant="body1">
          {videoDescContent?.videoFullDesc
            ? videoDescShowMore
              ? videoDescContent?.videoFullDesc
              : `${videoDescContent?.videoFullDesc?.substring(0, 172)}`
            : "NA"}
        </Typography>
      </Box>
      <Box component="span" onClick={toggleVideoDescShowMore}>
        {videoDescShowMore ? "Show less" : "Show More"}
      </Box>
    </>
  );
};
export default VideoDescDescription;
