export const searchSidebarItems = [
  {
    name: "Clear all watch history",
    icon: "fa-trash",
    handler: () => {},
  },
  {
    name: "Pause watch history",
    icon: "fa-pause",
    handler: () => {},
  },
  {
    name: "Manage all history",
    icon: "fa-history",
    handler: () => {},
  },
  // {
  //     name: "Comments",
  //     icon: "fa-comments",
  //     handler: () => {},
  // },
  // {
  //     name: "Community post",
  //     icon: "fa-file",
  //     handler: () => {},
  // },
  // {
  //     name: "Live chat",
  //     icon: "fa-comment-dots",
  //     handler: () => {},
  // },
];
