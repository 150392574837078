/**
 * This file  contains the implementation of the main layout of the VosynAssist;
 * which is the one that open when clicking on the AirisBlob (animated logo)
 * It focuses on displaying the action button on top, the search bar at the bottom, and the
 * main content which include the welcome layout, the result block
 */

import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

// Importing necessary components and hooks
import HomePage from "./HomePage/HomePage";
import AirisSearchBar from "./SearchBar/AirisSearchBar";
import ResultsBlockWrapper from "./ResultsBlock/ResultsBlockWrapper";
import ActionButtons from "./ActionButtons/ActionButtons";
import AirisBlob from "./AirisBlob/AirisBlob";
import UserMessage from "./ResultsBlock/content/userMessage/UserMessage";
import LanguageBanner from "./LanguageBanner/LanguageBanner";

// Importing Redux actions and API functions
import { clearFullChatHistory } from "../../reducers/airisChatHistorySlice";
import { useBlobAnimation } from "../../hooks/useBlobAnimation";
import { useScrollToBottom } from "../../hooks/useScrollToBottom";
import { useAddToChatHistory } from "../../hooks/useAddToChatHistory";
import { airisChat, languageDetection } from "../../api/airis";

// Importing assets and styles
import "./Airis.scoped.css";

function AirisChatboxContainer({ isAirisOpen, closeSidebar }) {
  const messagesEndRef = useRef(null); // reference to bottom of chat history
  const chatContainerRef = useRef(null);

  const dispatch = useDispatch();
  const { fullChatHistory } = useSelector((state) => state.airisChatHistory);
  const { scrollToBottom } = useScrollToBottom(fullChatHistory, messagesEndRef);
  const { addLoadingMessage, updateLoadingMessage } = useAddToChatHistory();
  const {
    airisBlobControls,
    searchbarControls,
    startBlobAnimationSequence,
    startResultsBlockAnimationSequence,
  } = useBlobAnimation(isAirisOpen);

  // State variables
  const [userSearch, setUserSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(
    fullChatHistory[fullChatHistory.length - 1]?.language || "en"
  );
  const [searchDirection, setSearchDirection] = useState(
    fullChatHistory[fullChatHistory.length - 1]?.languageType || "ltr"
  );
  const [displayBanner, setDisplayBanner] = useState(false);
  const [startBlobAnimation, setStartBlobAnimation] = useState(false);
  const [isBlobAnimationFinished, setIsBlobAnimationFinished] = useState(false);

  // Effect to scroll to bottom on chatbox mount
  useEffect(() => {
    if (fullChatHistory.length > 1) {
      setIsBlobAnimationFinished(true);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  // Automatic language detector
  useEffect(() => {
    if (userSearch.length > 3) {
      if (userSearch.length % 5 === 0) {
        callLanguageDetect();
      } else {
        const languageDetectionTimeout = setTimeout(() => {
          callLanguageDetect();
        }, 300);

        return () => {
          clearTimeout(languageDetectionTimeout);
        };
      }
    }
  }, [userSearch]);

  // Effect to start blob animation sequence
  useEffect(() => {
    if (startBlobAnimation) {
      startBlobAnimationSequence().then(() => {
        setIsBlobAnimationFinished(true);
      });
    }
  }, [startBlobAnimation]);

  // Effect to start results block animation sequence
  useEffect(() => {
    if (startBlobAnimation && isBlobAnimationFinished) {
      startResultsBlockAnimationSequence().then(() => {
        setStartBlobAnimation(false);
      });
    }
  }, [isBlobAnimationFinished]);

  // Determines if language translation banner is displayed or not
  useEffect(() => {
    if (
      isBlobAnimationFinished &&
      fullChatHistory.length > 1 &&
      fullChatHistory[fullChatHistory.length - 1]?.languageType !==
        fullChatHistory[fullChatHistory.length - 2]?.languageType
    ) {
      setDisplayBanner(true);
      setTimeout(() => {
        setDisplayBanner(false);
      }, 5000);
    }
  }, [fullChatHistory.length]);

  // Function to start a new chat session
  const newChat = () => {
    setStartBlobAnimation(false);
    setIsBlobAnimationFinished(false);
    dispatch(clearFullChatHistory());
    setLanguage("en");
    setSearchDirection("ltr");
  };

  // Function to scroll to the bottom of the chat container
  const typeAnimationScroll = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const getResponseFromAiris = async (airisSearch) => {
    // Mock API response
    // const messageID = Math.floor(Date.now() / 100);
    // addLoadingMessage(messageID, language, searchDirection, airisSearch);
    // setIsLoading(true);
    // setTimeout(() => {
    //   if (airisSearch === "error") {
    //     updateLoadingMessage(messageID, "", true, false, false);
    //   } else if (airisSearch === "yt") {
    //     updateLoadingMessage(
    //       messageID,
    //       "https://www.youtube.com/watch?v=XRi0kpWI7tM",
    //       false,
    //       false,
    //       true
    //     );
    //   } else if (airisSearch === "vosyn") {
    //     updateLoadingMessage(
    //       messageID,
    //       "",
    //       false,
    //       {
    //         thumbnail: {
    //           url: "https://img.youtube.com/vi/g2F5RO6vNSs/0.jpg",
    //         },
    //         id: "466b4542-7b66-4d3e-a173-8d6ba666a36c",
    //         duration: "00:09:45",
    //         titles: [
    //           {
    //             is_native: true,
    //             title_text: "Birria de Res FÁCIL | La Capital",
    //           },
    //         ],
    //         description:
    //           "Birria de Res casera y fácil para preparar los famosos tacos con consomé - quesabirria.\n\nGrand Western Steaks: www.grandwesternsteaks.com\n15% de descuento usando el código: LACAPITAL\n—————————————————————————————\nContacto directo: oscarlacapital@gmail.com",
    //       },
    //       false
    //     );
    //   } else {
    //     updateLoadingMessage(
    //       messageID,
    //       "Hello, how was your day today?",
    //       false,
    //       false,
    //       false
    //     );
    //   }
    //   setIsLoading(false);
    // }, 4000);
    const messageID = Math.floor(Date.now() / 100);
    /**
     * This block of code handles sending a request to the airisChat API,
     * updating loading messages based on the response, and managing loading state.
     */
    try {
      addLoadingMessage(messageID, language, searchDirection, airisSearch);
      setIsLoading(true);
      const res = await airisChat(airisSearch);
      if (res.status === 200) {
        if (res?.data?.video_info) {
          updateLoadingMessage(
            messageID,
            "",
            false,
            res?.data?.video_info,
            false
          );
        } else if (res?.data?.Third_Party) {
          updateLoadingMessage(
            messageID,
            res?.data?.response,
            false,
            false,
            true
          );
        } else {
          updateLoadingMessage(
            messageID,
            res?.data?.response,
            false,
            false,
            false
          );
        }
        scrollToBottom();
      } else {
        updateLoadingMessage(messageID, "", true, false, false);
      }
      setIsLoading(false);
    } catch (err) {
      updateLoadingMessage(messageID, "", true, false, false);
      setIsLoading(false);
    }
  };

  // Function to submit the search
  const submitSearch = (e) => {
    e.preventDefault();
    getResponseFromAiris(userSearch);
    if (fullChatHistory.length === 0) {
      setStartBlobAnimation(true);
    }
    setUserSearch("");
    scrollToBottom();
  };

  // Function to call language detection API
  /**
   * The bug where the input direction is wrong is due to the "not too accurate" response from the api.
   * I put it in comment an alert in the callLanguageDetect() function which help seeing the response score.
   * Feel free to uncomment it to see the api's response.
   * So if a text is in Arabic, the direction will be set "right to left" in the input.
   * Now, if the user clears the input and copy and paste an english word (here the example is "university"), the direction
   * given by the api is left to right, but the result score is 0.325. Since the passing score is set 0.86,
   * the direction will not be updated in the input.
   * Ofcourse, for a human the word "hospital" is logically from a left to right language
   * To remedy that, We can think about setting the passing score to a lower value, but this will create another issue,
   * since the certainty of the direction will not be good.
   */
  const callLanguageDetect = () => {
    languageDetection(userSearch)
      .then((response) => {
        //alert(` ${userSearch.length} words, the score is ${response.data[0].score} and the label is ${i18next.dir(response.data[0].label)}`);
        if (response.data[0].score > 0.86) {
          setLanguage(response.data[0].label);
          setSearchDirection(i18next.dir(response.data[0].label));
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="va-chatbox-container">
      <div className={`va-header ${startBlobAnimation && "hide-va-header"}`}>
        <img
          src={
            process.env.PUBLIC_URL + `/assets/Airis/svg/vosyn-assist-logo.svg`
          }
          alt="Vosyn Assist"
          className={`vosyn-assist-logo ${
            ((!startBlobAnimation && fullChatHistory.length === 0) ||
              (startBlobAnimation &&
                !isBlobAnimationFinished &&
                fullChatHistory !== 0)) &&
            "hide-vosyn-assist-logo"
          }`}
        />
        <ActionButtons
          closeSidebar={closeSidebar}
          newChat={newChat}
          hideClearChatButton={
            (!startBlobAnimation && fullChatHistory.length === 0) ||
            (startBlobAnimation &&
              !isBlobAnimationFinished &&
              fullChatHistory !== 0)
          }
        />
      </div>

      {fullChatHistory.length === 0 && (
        <HomePage fullChatHistory={fullChatHistory} />
      )}

      {startBlobAnimation && !isBlobAnimationFinished && (
        <div className="va-loading-blob-wrapper">
          <motion.div animate={airisBlobControls}>
            <div className="va-loading-blob">
              <AirisBlob glowSpread={20} roughness={3} />
            </div>
          </motion.div>
        </div>
      )}

      {((!startBlobAnimation && fullChatHistory.length !== 0) ||
        isBlobAnimationFinished) && (
        <div className="va-chatbox-wrapper" ref={chatContainerRef}>
          <>
            {fullChatHistory.map((message, idx) => (
              <div key={message.id}>
                <div className="language-switch-transition">
                  {message.languageSwitchText === null
                    ? "\u00A0"
                    : message.languageSwitchText}
                </div>
                <UserMessage message={message} />
                {message.results.map((block, index) => (
                  <div key={index}>
                    <ResultsBlockWrapper
                      block={block}
                      message={message}
                      typeAnimationScroll={typeAnimationScroll}
                      scrollToBottom={scrollToBottom}
                      lastElement={
                        idx === fullChatHistory.length - 1 ? true : false
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
          </>
          <div ref={messagesEndRef} />
        </div>
      )}
      {displayBanner && (
        <div className="language-banner-position">
          <LanguageBanner
            setDisplayBanner={setDisplayBanner}
            language={language}
            searchDirection={searchDirection}
          />
        </div>
      )}
      <motion.div animate={searchbarControls}>
        <AirisSearchBar
          submitSearch={submitSearch}
          userSearch={userSearch}
          setUserSearch={setUserSearch}
          chatHistoryLength={fullChatHistory.length}
          isAirisOpen={isAirisOpen}
          startBlobAnimation={startBlobAnimation}
          isLoading={isLoading}
          searchDirection={searchDirection}
          inputLanguage={language}
        />
      </motion.div>
    </div>
  );
}

export default AirisChatboxContainer;
