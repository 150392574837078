import Banner from "../../ServiceBanner/ServiceBanner";
import Actions from "../../ServiceActions/ServiceActions";
import ServiceContent from "../../ServiceContent/ServiceContent";
import {useState} from "react";

const Netflix = ({data, selectedCategory}) => {
    const sections = ['Episodes', 'Recommended', 'Details', 'Extras']
    const [selectedSection, setSelectedSection] = useState(sections[0])
    const [selectedSeason, setSelectedSeason] = useState(data?.seasons && data.seasons[0])

    return (
        <>
            <Banner selectedSeason={selectedSeason} onSeasonSelect={setSelectedSeason} data={data}/>
            <Actions
                sections={sections}
                selectedCategory={selectedCategory}
                selectedSection={selectedSection}
                onSelectedSection={setSelectedSection}
            />
            <ServiceContent selectedSection={selectedSection} selectedCategory={selectedCategory}/>
        </>
    )
}

export default Netflix