import React, {useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./ServiceCards.scoped.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import ServiceCardModal from "../ServiceCardModal/ServiceCardModal";
import VideoThumbnail from "../../VideoCards/VideoThumbnail/VideoThumbnail";

const  AllResultText = ({serviceItems}) => {
  return (
    <div className='servicecards-result-status-text'>
      <p style={{ textAlign: "center" }}>
        <b>{serviceItems.length === 0 ? "Oops! We couldn't find a related video" : "Yay! You have seen it all"}</b>
      </p>
    </div>
  );
}
/*
  prop value  any of below accroding ui;
  cardType =  videos , playslist, playlists, history, search ;
  cardLayout=   horizontal , vertical;
*/

const ServiceCards = ({ serviceItems = [], serviceName,cardLayout, cardType, loadMore, handleHasMore, loading }) => {
    const [popUpData, setPopupData] = useState(null)
    const [popupPosition, setPopupPosition] = useState(null)
    return (
        <div className='servicecards'>
            <InfiniteScroll
         style={{ overflow : "visible"}}
         className="servicecards-infinite-scroll"
          dataLength={serviceItems.length}
          next={loadMore}
          hasMore={handleHasMore}
          loader={
            <div className='servicecards-spinner-loadmore'>
              <i className="fas fa-spinner fa-spin" aria-hidden="true" />
            </div>
          }
          endMessage={<AllResultText serviceItems= {serviceItems} />}
          scrollThreshold="90%"
        >
          {serviceName === "netflix" && (
            <>
              {cardType === "recommenedServiceCard" && (
                <div className="wrap-servicecards-horizontal-list wrap-netflix-servicecards-horizontal-list">
                  {serviceItems.map((serviceItem, index) => (
                    <ServiceCard setPopupPosition={setPopupPosition} setPopupData={setPopupData} key={index + Math.random()} item={serviceItem} serviceName={serviceName} />
                  ))}
                </div>
              )}

              {cardType === "episodesServiceCard" && (
                <div className="wrap-servicecards-horizontal-list">
                  {serviceItems.map((serviceItem, index) => (
                    <VideoThumbnail
                      key={serviceItem.id}
                      data={serviceItem}
                      cardLayout="horizontal"
                      cardType="episode"
                    />
                  ))}
                </div>
              )}
              {cardType === "watchServiceCard" && (
                <div className="wrap-servicecards-horizontal-list wrap-netflix-servicecards-horizontal-list ">
                  {serviceItems.map((serviceItem, index) => (
                    <ServiceCard setPopupPosition={setPopupPosition} setPopupData={setPopupData} key={index + Math.random()} item={serviceItem} serviceName={serviceName} />
                  ))}
                </div>
              )}
            </>
          )}
          {serviceName === "coursera" && (
            <>
             {cardType === "courseServiceCard" && (
              <div className="wrap-servicecards-horizontal-list wrap-coursera-servicecards-horizontal-list">
                {serviceItems.map((serviceItem, index) => (
                  <ServiceCard disablePopup key={index + Math.random()} item={serviceItem} serviceName={serviceName} />
                ))}
              </div>
             )}
            </>
          )}
        </InfiniteScroll>
            {popUpData && (
                <ServiceCardModal
                    position={popupPosition}
                    item={popUpData}
                    serviceName={serviceName}
                    setPopupData={setPopupData}
                    setPopupPosition={setPopupPosition}
                />
            )}
    </div>
  );
};

export default ServiceCards;
