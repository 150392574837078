import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./MultivideoPlaylist.scoped.css";
import data from "../../../data/dashvideos.json";

const MultivideoPlaylist = () => {
  const title = "Top Business Trends in 2024";

  const Videodata = data;
  console.log(Videodata.length);

  return (
    <Box className="multi-video">
      <Box
        className="multi-images"
        aria-label="multi video playlist thumbnail with three images"
      >
        {Videodata.map(
          (video, index) =>
            index < 3 && (
              <img
                src={video.thumbnail.url}
                alt="multivideo playlist thumbnail"
              />
            )
        )}
      </Box>
      <Box className="multi-details">
        <Box className="hover-buttons" aria-label="action buttons">
          <IconButton className="hover-button" aria-label="share button">
            <ShareOutlinedIcon />
          </IconButton>
          <IconButton className="hover-button" aria-label="edit button">
            <EditOutlinedIcon />
          </IconButton>
          <IconButton className="hover-button" aria-label="close button">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="multi-text">
          <Typography className="playlist-title">{title}</Typography>
          <Typography className="content-count">{`${Videodata.length} videos`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MultivideoPlaylist;
