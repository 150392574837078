import './ServiceActions.scoped.css'

const ServiceActions = ({selectedSection, onSelectedSection, sections}) => {
    const handleSectionSelect = section => () => onSelectedSection(section)

    return (
        <div className="service-actions">
            <div className="service-actions__icons">
                <i className="fa-regular fa-thumbs-up" />
                <i style={{transform: 'rotateY(-180deg)'}} className="fa-regular fa-thumbs-down" />
                <i className="fa-solid fa-add" />
                <i className="fa-regular fa-trash-can" />
                <i className="fa-solid fa-share-nodes" />
            </div>
            <div className="service-actions__handlers">
                <div className="service-actions__watch">
                    <i className="fa-solid fa-play service-actions__play" />
                    <span>Watch</span>
                </div>
                <div className="service-actions__sections">
                    {sections.map(section => (
                        <div
                            {...selectedSection === section && { className: "active" }}
                            onClick={handleSectionSelect(section)} key={section}
                        >
                            {section}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ServiceActions