import React, { useState, useRef, useEffect } from "react";
import "./Dropdown.scoped.css";
import { userLogout } from "../../api/auth.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../reducers/authSlice.js";
import useProfileCompletion from "../../hooks/useProfileCompletion.jsx";
import { skipToStage } from "../../reducers/userPrefReducer.js";
import { ProfileDropdownInfo, ProfileDropdownOptions} from "./ProfileDropdownComponents.jsx";

function UserProfileDropdown(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profileCompletion, numberOfCompleted] = useProfileCompletion();

  const { setIsOpen, isOpen, mainElement } = props;

  const dropdownRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode") || true
  );

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);

    if (isDarkMode) {
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.add("light-mode");
    }
  }, [isDarkMode]);

  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const handleClickOutside = (event) => {
      const mainElementDOM = document.querySelector(mainElement);
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !mainElementDOM?.contains(event.target)
      ) {
        setIsOpen(!isOpen);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen, isOpen, mainElement]);

  const handleLoginLogout = async () => {
    try {
      setIsLoading(true);
      let res = await userLogout();
      setIsLoading(false);
      if (res.status == 204) {
        console.log("user authenticated, logged out");
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      }
    } catch (err) {
      setIsLoading(false);
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        console.log("user not authenticated, logged out");
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      }
    }
  };

  const profileDetails = useSelector((state) => state?.userInfo?.userProfile);

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (isOpen) {
      dropdownElement.className = "profile-dropdown open";
    } else {
      dropdownElement.className = "profile-dropdown close";
    }
  }, [isOpen]);

  const restartProductTour = () => {
    setTimeout(() => {
      navigate("/CONTENT-PLATFORM/home");
    }, 100);
    dispatch(skipToStage(1));
  };

  return (
    <div className="profile-dropdown" ref={dropdownRef}>
      <i
        className="fa-solid  fa-xmark"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      ></i>

      {/* This is where the original code for profile-dropdown-info was and now the component is used */}
      <ProfileDropdownInfo profileDetails={profileDetails} />

      {/* This is where the original code for profile-dropdown-options was and now the component is used */}
      <ProfileDropdownOptions
        numberOfCompleted={numberOfCompleted}
        setIsDarkMode={setIsDarkMode}
        isDarkMode={isDarkMode}
        restartProductTour={restartProductTour}
        handleLoginLogout={handleLoginLogout}
      />
    </div>
  );
}

export default UserProfileDropdown;
