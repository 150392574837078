import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allComments: [],
  displayComments: [],
  commentIndex: 0,
  newComment: "",
  showCommentReplyField: {},
  replyComment: "",
};

const videoCommentSlice = createSlice({
  name: "videoComment",
  initialState,
  reducers: {
    setComments: (state, action) => {
      state.allComments = action.payload;
    },
    setDisplayComments: (state, action) => {
      state.displayComments = action.payload;
    },
    setCommentIndex: (state, action) => {
      state.commentIndex = action.payload;
    },
    setNewComment: (state, action) => {
      state.newComment = action.payload;
    },
    setShowCommentReplyField: (state, action) => {
      state.showCommentReplyField = {
        ...state.showCommentReplyField,
        [action.id]: action.value,
      };
    },
    setReplyComment: (state, action) => {
      state.replyComment = action.payload;
    },
  },
});

export const {
  allComments,
  setComments,
  displayComments,
  setDisplayComments,
  commentIndex,
  setCommentIndex,
  newComment,
  setNewComment,
  showCommentReplyField,
  setShowCommentReplyField,
  replyComment,
  setReplyComment,
} = videoCommentSlice.actions;

export default videoCommentSlice.reducer;
