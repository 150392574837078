import axios from "axios";
import {
  MOVIE_RATING_URL,
  SERIES_RATING_URL,
  SEASON_RATING_URL,
  EPISODE_RATING_URL,
} from "../constant/constantUrls";

export const getMovieRating = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(MOVIE_RATING_URL, {
      params: {
        id,
      },
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting movie rating:", error);
  }
};

export const getSeriesRating = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(SERIES_RATING_URL, {
      params: {
        id,
      },
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting series rating:", error);
  }
};

export const getSeasonRating = async (id, season) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(SEASON_RATING_URL, {
      params: {
        id,
        season,
      },
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting season rating:", error);
  }
};

export const getEpisodeRating = async (id, season, episode) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(EPISODE_RATING_URL, {
      params: {
        id,
        season,
        episode,
      },
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting episode rating:", error);
  }
};
