import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  Collapse,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";
import { BsCcCircle } from "react-icons/bs";
import { SiNextui } from "react-icons/si";
import "./Settings.scoped.css";
const Settings = ({ open, handleClose }) => {
  const [advancedOpen, setAdvancedOpen] = useState(false);

  // Toggle Advanced Options
  const handleToggleAdvanced = useCallback(() => {
    setAdvancedOpen((prev) => !prev);
  }, []);

  // Dynamic Language Data Placeholder
  const languages = Array.from({ length: 24 }, (_, i) => `Dummy Text ${i + 1}`);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="settings-dialog-title"
      disableEscapeKeyDown={false}
    >
      <DialogTitle id="settings-dialog-title">
        <Typography className="modal-title text-muted">Settings</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CancelIcon sx={{ fontSize: 45 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="dialog-content-wrapper">
          {/* Left Sidebar: Language List */}
          <div className="language-list">
            <Typography className="language-title text-muted">
              Language
            </Typography>
            <List>
              {languages.map((language, index) => (
                <ListItem key={index}>
                  <ListItemText primary={language} />
                </ListItem>
              ))}
            </List>
          </div>

          {/* Right Content: Preferred Language */}
          <div className="preferred-language-container">
            <Typography className="preferred-language-title">
              Preferred Language
            </Typography>
            <div className="flex-container">
              <Typography variant="body1">English</Typography>
              <Button variant="text" className="change-button">
                Change
              </Button>
            </div>
            <Divider />
            {/* Advanced Section */}
            <div className="advanced-section">
              <Button
                onClick={handleToggleAdvanced}
                className="advanced-toggle"
              >
                <Typography className="advance-title">ADVANCED</Typography>
                {advancedOpen ? (
                  <ExpandLess sx={{ color: "#6d6d6d" }} />
                ) : (
                  <ExpandMore sx={{ color: "#6d6d6d" }} />
                )}
              </Button>
              <Collapse in={advancedOpen}>
                <List component="div" disablePadding>
                  <ListItem className="list-item">
                    <ListItemIcon>
                      <TranslateRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={"English"} />
                    <Button variant="text" className="change-button">
                      Change
                    </Button>
                  </ListItem>
                  <ListItem className="list-item">
                    <ListItemIcon className="custom-icon">
                      <BsCcCircle />
                    </ListItemIcon>
                    <ListItemText primary={"Captions"} />
                    <Button variant="text" className="change-button">
                      Change
                    </Button>
                  </ListItem>
                  <ListItem className="list-item">
                    <ListItemIcon className="custom-icon">
                      <SiNextui />
                    </ListItemIcon>
                    <ListItemText primary={"UI"} />
                    <Button variant="text" className="change-button">
                      Change
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

Settings.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default Settings;
