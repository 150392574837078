/**
 * This file partially contains the implementation of the main layout of the VosynAssist;
 * which is the one that open when clicking on the AirisBlob (animated logo)
 * It focuses on displaying the animated logo, a welcome message and an infocard
 */

import { useState } from "react";

import AirisBlob from "../AirisBlob/AirisBlob";

import "./HomePage.scoped.css";

function HomePage({ fullChatHistory }) {
  //Define details to be displayed in the info card
  const options = [
    {
      image: process.env.PUBLIC_URL + `/assets/Airis/svg/translate.svg`,
      text: "Chat in any language",
    },
    {
      image: process.env.PUBLIC_URL + `/assets/Airis/svg/video.svg`,
      text: "Watch videos of any type",
    },
    {
      image: process.env.PUBLIC_URL + `/assets/Airis/svg/white-microphone.svg`,
      text: "Want to talk? Use me",
    },
  ];

  //State to control the visibility of the info card
  const [isInfocard, setIsInfocard] = useState(true);

  //Function to close the info card
  const closeInfocard = () => {
    setIsInfocard(false);
  };

  return (
    <div
      className={`va-chatbox-wrapper va-homepage-wrapper ${
        fullChatHistory.length && "va-chatbox-wrapper-margin-bottom"
      } ${!isInfocard ? "inactive-transition" : ""}`}
    >
      {/* Vosyn Assist animated Logo (AirisBlob) */}
      <div className="va-homepage-blob">
        <div className="background-blur"></div>
        <AirisBlob size={150} glowSpread={15} roughness={5} />
      </div>
      {/* Vosyn Assist text Logo */}
      <div className="va-homepage-logo">
        <img
          src={
            process.env.PUBLIC_URL + `/assets/Airis/svg/vosyn-assist-logo.svg`
          }
          alt="vosyn logo"
        />
      </div>
      {/* Welcome Phrase */}
      <span className="va-phrase">How can I help you today?</span>

      {/* Rest of the layout including the infocard */}
      <div
        className={`${
          isInfocard ? "va-initial-options" : "va-initial-options-inactive"
        }`}
      >
        <div className="hide-infocard-button-wrapper">
          <button onClick={closeInfocard} className="hide-infocard-button">
            <img
              src={process.env.PUBLIC_URL + `/assets/Airis/svg/x-bold.svg`}
              alt="x"
              className="hide-infocard-icon"
            />
            <span className="visually-hidden">Hide Infocard</span>
          </button>
          <div className="button-text-popup-container">
            <div className="button-text-popup-triangle"></div>
            <span>Hide Infocard</span>
          </div>
        </div>
        {options.map((option, index) => (
          <div key={index} className={`initial-option-${index} initial-option`}>
            <div className={`initial-image initial-image-${index}`}>
              <img src={option.image} alt="option image" />
            </div>
            <div>{option.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
