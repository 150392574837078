import React, { useState } from "react";
import "../../pages/VideoPage/VideoPage.scoped.css";
import VideoDescHeader from "./VideoDescSubcomponents/VideoDescHeader";
import VideoDescDescription from "./VideoDescSubcomponents/VideoDescDescription";
import VideoDescDetails from "./VideoDescSubcomponents/VideoDescDetails";
import Transcript from "../Transcript/Transcript";
import transcriptJson from "../../data/transcript.json";
import Box from '@mui/material/Box';


function VideoDesc() {
  const [showTranscript, setShowTranscript] = useState(false);
  const [flaggedTranscripts, setFlaggedTranscripts] = useState([]);
  const [suggestedTranslations, setSuggestedTranslations] = useState({});

  const handleTranscriptBtnClick = () => {
    setShowTranscript((showTranscript) => !showTranscript);
    console.log(showTranscript);
  };

  const handleFlaggedTranscripts = (flaggedItems) => {
    console.log("Flagged Items:", flaggedItems);
    setFlaggedTranscripts(flaggedItems);
  };

  const resetFlaggedTranscripts = () => {
    setFlaggedTranscripts([]);
  };

  return (
    <Box className="video-description">
      <VideoDescHeader
        handleTranscriptBtnClick={handleTranscriptBtnClick}
        flaggedTranscripts={flaggedTranscripts}
        suggestedTranslations={suggestedTranslations}
        resetFlaggedTranscripts={resetFlaggedTranscripts}
      />
      <Box className="body">
        {showTranscript && (
         <Transcript 
            transcriptJson={transcriptJson}
            flaggedItems={flaggedTranscripts}
            onFlagChange={handleFlaggedTranscripts}
          />
        )}
        <VideoDescDetails />
        <VideoDescDescription />
      </Box>
    </Box>
  );
}

export default VideoDesc;
