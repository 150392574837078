import React from "react";
import Slider from "react-slick";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselComponent = () => {
  const dummyData = [
    {
      id: 1,
      mediaType: "img",
      src: "../../assets/moneyheist.png",
    },
    {
      id: 2,
      mediaType: "img",
      src: "../../assets/moneyheist.png",
    },
    {
      id: 3,
      mediaType: "img",
      src: "../../assets/moneyheist.png",
    },
    {
      id: 4,
      mediaType: "img",
      src: "../../assets/moneyheist.png",
    },
    {
      id: 5,
      mediaType: "img",
      src: "../../assets/moneyheist.png",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    waitForAnimate: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          right: "25px",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "#ddd",
          "&:hover": { backgroundColor: "#bbb" },
        }}
        onClick={onClick}
        aria-label="Next slide" // Added this label for accessibility
      >
        <ArrowForwardIosIcon />
      </IconButton>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          left: "25px",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "#ddd",
          "&:hover": { backgroundColor: "#bbb" },
        }}
        onClick={onClick}
        aria-label="Previous slide" // Added this label for accessibility
      >
        <ArrowBackIosIcon />
      </IconButton>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#e0e0e0",
      }}
    >
      {/* Title Bar */}
      <Box
        sx={{
          backgroundColor: "#757575",
          padding: "10px",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          textAlign: "center",
          color: "#fff",
        }}
      >
        <Typography variant="h6">Trending</Typography>
      </Box>

      {/* Carousel with Arrows */}
      <Box sx={{ position: "relative", height: "100%" }}>
        <Slider {...settings}>
          {dummyData.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                data-testid={`carousel-img-${index}`} // Adding testid to each image
                src={item.src}
                alt={item.title || `Image ${index + 1}`}
                style={{
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default CarouselComponent;
