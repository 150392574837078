import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Grid2,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

const AudioCards = ({
  image,
  title,
  description,
  cardWidth,
  titleFontSize,
  descriptionFontSize,
  hoverEff = true,
  onClick,
}) => {
  return (
    <Card
      sx={{
        width: `${cardWidth}px`,
        height: "110px",
        borderRadius: 2,
        backgroundColor: "white",
        boxShadow: 0,
        marginLeft: "8px",
        marginRight: "16px",
        marginBottom: "20px",
        overflow: "hidden",
        transition: "transform 1s",
        "&:hover": {
          transform: hoverEff ? "scale(1.05)" : "",
          height: "140px",
          zIndex: 1,
        },
        position: "relative",
        border: "1px solid",
        borderColor: "#d1d1d1",
      }}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          position: "relative",
          "&:hover .image-overlay": {
            opacity: 1,
            height: "50%",
          },
          "&:hover .text-content": {
            transform: "translate(-60px, 40px)",
          },
          "&:hover .hexagon-image": {
            opacity: 0,
          },
          "&:hover .icons": {
            display: "flex",
            opacity: 1, // Change to opacity
            color: "#656565",
          },
        }}
      >
        <CardMedia
          className="image-overlay"
          component="img"
          image={image || "https://via.placeholder.com/200"}
          alt={title}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: 0,
            objectFit: "cover",
            zIndex: 1,
            opacity: 0,
          }}
        />

        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: "center",
            padding: "8px 16px",
            position: "relative",
            zIndex: 2,
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Grid2 item>
            <CardMedia
              className="hexagon-image"
              component="img"
              image={image || "https://via.placeholder.com/200"}
              alt={title}
              sx={{
                clipPath:
                  "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                width: "50px",
                height: "50px",
                position: "relative",
                top: 0,
                right: 0,
                zIndex: 3,
              }}
            />
          </Grid2>

          <Grid2 item xs>
            <CardContent
              className="text-content"
              sx={{
                padding: "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "left",
              }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  color: "#656565",
                  marginBottom: "1px",
                  fontWeight: 500,
                  fontSize: `${titleFontSize}px`, // Use dynamic title font size
                  paddingTop: "13px",
                }}
              >
                {title}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "#898584",
                  fontSize: `${descriptionFontSize}px`, // Use dynamic description font size
                  marginTop: "0px",
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </Grid2>

          <Grid2
            item
            className="icons"
            sx={{
              display: "flex", // Change to flex to allow proper visibility on hover
              opacity: 0, // Initially hidden
              position: "absolute",
              top: "16px",
              right: "5px",
              gap: "5px",
            }}
            data-testid="icon-container" // Added data-testid here
          >
            <AddBoxOutlinedIcon
              sx={{
                fontSize: 24,
                backgroundColor: "#d9d9d9",
                borderRadius: "50%",
                padding: "3px",
              }}
            />
            <BookmarkBorderIcon
              sx={{
                fontSize: 24,
                backgroundColor: "#d9d9d9",
                borderRadius: "50%",
                padding: "3px",
              }}
            />
            <AudiotrackIcon
              sx={{
                backgroundColor: "#d9d9d9",
                clipPath:
                  "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                width: "35px",
                height: "35px",
                padding: "3px",
                position: "relative",
                top: "-5px",
              }}
            />
          </Grid2>
        </Grid2>
      </CardActionArea>
    </Card>
  );
};

export default AudioCards;
