import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  description: "",
  titles: [],
  view_count: 0,
  created_at: "",
  id: 1,
};

const dashVideoObjectSlice = createSlice({
  name: "dashVideoObjects",
  initialState,
  reducers: {
    setDashVideoObject: (state, action) => {
      return {
        ...state,
        description: action.payload.description,
        titles: action.payload.titles,
        view_count: action.payload.view_count,
        created_at: action.payload.created_at,
        id: action.payload.id,
      };
    },
  },
});

export const { setDashVideoObject } = dashVideoObjectSlice.actions;
export default dashVideoObjectSlice.reducer;
