//  convert a duration string into seconds
const parseDurationToSeconds = (durationString) => {
  const [hours, minutes, seconds] = durationString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

// summing up total playtime in seconds
const calculateTotalPlaytime = (videos) => {
  if (videos) {
    return videos.reduce(
      (total, video) => total + parseDurationToSeconds(video.duration),
      0
    );
  }
  return 0;
};

// convert total duration back into a readable format
const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  // Format the string as "Xh Ym" omitting seconds for simplicity
  return `${hours}h ${minutes}m`;
};

const formatStringDuration = (duration) => {
  let arr = duration?.split(":");
  let formatted = arr?.map((val) => val.replace(/^0+/, ""));
  return `${formatted?.[0] ? formatted?.[0] + "h" : ""} ${
    formatted?.[1] ? formatted?.[1] + "m" : ""
  } ${formatted?.[2] ? formatted?.[2] + "s" : ""}`;
};

export {
  formatDuration,
  calculateTotalPlaytime,
  formatStringDuration,
  parseDurationToSeconds,
};
