import React, { useState, useRef, useEffect } from "react";
import "./RegionSelector.scoped.css";
import countries from "../../data/countries.json";
import { useSelector, useDispatch } from "react-redux";
import CountrySelector from "./CountrySelector";
import SearchDropDown from "./SearchDropdown";
import useQueryParam from "../../hooks/useQueryParam";
import Search from "../Search/Search";
import { setRegion } from "../../reducers/regionSlice";
import useVoiceInput from "./useVoiceInput";
import { useSpeechRecognition } from "react-speech-recognition";

const RegionSelector = (setSearchInputRef) => {
  const [searchActive, setSearchActive] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "World",
    flag: "ca.png",
  });
  const [searchTerm, setSearchTerm] = useState(() => {
    return sessionStorage.getItem("searchTerm") || "";
  });
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const dropdownRef = useRef(null);
  const [voiceSearch, setVoiceSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { updateQueryParam } = useQueryParam();
  const { browserSupportsSpeechRecognition, isMicrophoneAvailable } =
    useSpeechRecognition();

  const {
    isListening,
    setTranscript,
    transcript,
    startListening,
    stopListening,
  } = useVoiceInput();
  const speechSupportCheck = browserSupportsSpeechRecognition ? true : false;
  const micPermCheck = isMicrophoneAvailable ? true : false;
  const selectedRegion = useSelector(({ region }) => region.selectedRegion);

  const toggleSearch = () => {
    setSearchActive((prev) => !prev);
  };

  const handleSearchSubmit = (searchValue) => {
    setSearchTerm(searchValue);
    sessionStorage.setItem("searchTerm", searchValue);
    updateQueryParam("query", searchValue);
    setSearchTerm("");
  };

  const startStopListening = () => {
    isListening ? stopListening() : startListening();
  };

  const toggleVoiceSearch = () => {
    setVoiceSearch((prev) => !prev);
    if (speechSupportCheck && micPermCheck) {
      startStopListening();
    }
  };

  useEffect(() => {
    if (!isListening) {
      setVoiceSearch(false);
    }
  }, [isListening]);

  // Call the setSearchInputRef function from props to set the searchInputRef value
  useEffect(() => {
    setSearchInputRef.setSearchInputRef(searchInputRef.current);
  }, [setSearchInputRef.setSearchInputRef, searchInputRef.current]);

  useEffect(() => {
    const savedCountry = sessionStorage.getItem("selectedCountry");
    if (savedCountry) {
      try {
        const country = JSON.parse(savedCountry);
        setSelectedCountry(country);
        dispatch(setRegion(country));
      } catch (error) {
        console.error("Error parsing country from session storage:", error);
      }
    }

    if (window.location.pathname === "/CONTENT-PLATFORM/home") {
      sessionStorage.getItem("searchTerm");
    }
  }, [dispatch]);

  useEffect(() => {
    const countryList = Object.values(countries.features).map((country) => ({
      name: country.properties?.ADMIN,
      flag: country.properties?.ISO_A2.toLowerCase() + ".png",
    }));
    setRegionList(countryList);

    const foundCountry = countryList.find(
      (country) => country.name === selectedRegion
    );
    setSelectedCountry(foundCountry || { name: "World", flag: "ca.png" });
  }, [selectedRegion]);

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current?.focus();
    }
  }, [searchActive]);

  useEffect(() => {
    const handleVoiceClickOutside = (event) => {
      if (
        voiceSearch &&
        !event.target.closest(".voice-input-container") &&
        !event.target.classList.contains("fa-microphone")
      ) {
        setVoiceSearch(false);
      }
    };
    document.addEventListener("mousedown", handleVoiceClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleVoiceClickOutside);
    };
  }, [voiceSearch]);

  useEffect(() => {
    if (selectedCountry.name !== "World") {
      updateQueryParam("country", selectedCountry.name.toLowerCase());
    }
  }, [selectedCountry, updateQueryParam]);

  return (
    <div className="region-selector-container">
      <CountrySelector
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        regionList={regionList}
        dropdownRef={dropdownRef}
      />

      <div className={`region-selector-main`}>
        <Search
          navSearch
          searchRef={searchRef}
          searchInputRef={searchInputRef}
          toggleSearch={toggleSearch}
          value={isListening && transcript.length > 0 ? transcript : searchTerm}
          setValue={setSearchTerm}
          onSubmit={handleSearchSubmit}
          setIsSearching={setIsSearching}
          listening={isListening}
          setVoiceTranscript={setTranscript}
          voiceTranscript={transcript}
        />
        <div
          className={`region-selector-search ${
            isSearching ? "mountedStyleInCollapse" : "unmountedStyleOutCollapse"
          }`}
        >
          {isSearching && searchTerm.length > 0 && (
            <SearchDropDown
              onSubmit={handleSearchSubmit}
              searchTerm={searchTerm}
              searchRef={searchRef}
              setIsSearching={setIsSearching}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegionSelector;
