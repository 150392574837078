import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { LanguageProvider } from "./context/LanguageContext";
import "regenerator-runtime/runtime";
import "./utils/i18n";
import MUIThemeProvider from "./context/MUIThemeProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* creating a coonflict between redux and context  */}
      <LanguageProvider>
        <MUIThemeProvider>
          <App />
        </MUIThemeProvider>
      </LanguageProvider>
    </BrowserRouter>
  </Provider>
);
