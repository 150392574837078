//Component file for ProfileDropdown.jsx
import React from "react";
import "./Dropdown.scoped.css";
import { useNavigate } from "react-router-dom";
import { skipToStage } from "../../reducers/userPrefReducer.js";
import { useDispatch } from "react-redux";
import { CONTACT_URL, PRIVACY_URL } from "../../constant/constantUrls.js";

//This const is going to replace the profile-dropdown-info div in the parent file
//contains the same design/functions as before but now split for readibility
export const ProfileDropdownInfo = ({ profileDetails }) => (
  <div className="profile-dropdown-info">
    <div className="profile-dropdown-username">{profileDetails?.username}</div>
    <div className="profile-dropdown-email"> {profileDetails?.email}</div>
  </div>
);

//This const is going to replace the profile-dropdown-options div in the parent file
//contains the same design/functions as before but now split for readibility
export const ProfileDropdownOptions = ({
  //change
  numberOfCompleted,
  setIsDarkMode,
  isDarkMode,
  restartProductTour,
  handleLoginLogout,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="profile-dropdown-options">
      <div className="line"></div>
      {numberOfCompleted <= 4 && (
        <div className="profile-dropdown-options-item">
          <i className="fa-laptop fa-solid"></i>
          <div
            profile-setup-container
            onClick={() => {
              dispatch(skipToStage(11));
            }}
          >
            <span>Profile Setup</span>
            <div className="profile-setup-progress">
              <span>{numberOfCompleted}/5</span>tasks completed
            </div>
          </div>
        </div>
      )}
      <div
        className="profile-dropdown-options-item"
        onClick={() => setIsDarkMode(!isDarkMode)}
      >
        <i class="fa-solid fa-circle-half-stroke"></i>
        <span>{isDarkMode ? "Dark Mode" : "Light Mode"}</span>
        <div className="mode-switch">
          <div
            className={`mode-icon-container ${isDarkMode ? "current-mode" : ""
              }`}
          >
            <i className="fa-solid fa-moon"></i>
          </div>

          <div
            className={`mode-icon-container ${!isDarkMode ? "current-mode" : ""
              }`}
          >
            <i className="fa-solid fa-sun"></i>
          </div>
        </div>
      </div>

      <div
        className="profile-dropdown-options-item"
        onClick={() => navigate("/CONTENT-PLATFORM/settings")}
      >
        <i className="fa-cog fa-solid"></i>
        <span>Settings</span>
      </div>

      <div className="line"></div>

      <div
        className="profile-dropdown-options-item"
        onClick={() => window.open(PRIVACY_URL, "_blank")}
      >
        <i className="fa-file-contract fa-solid"></i>
        <span>Privacy Policy</span>
      </div>

      <div
        className="profile-dropdown-options-item"
        onClick={() => window.open(CONTACT_URL, "_blank")}
      >
        <i className="fa-circle-question fa-solid"></i>
        <span>Help Center</span>
      </div>

      <div className="line"></div>
      <div
        onClick={() => {
          restartProductTour();
        }}
        className="profile-dropdown-options-item"
      >
        <i className="fa-laptop fa-solid"></i>
        <span>Product Tour</span>
      </div>

      <button className="signout-button" onClick={handleLoginLogout}>
        <i class="fa-solid fa-right-from-bracket"></i>
        <span>Sign Out</span>
      </button>
    </div>
  );
};
