export const steps = [
  {
    stepperTitle: {
      text: "What kind of content gets you ",
      highlightText: "excited?",
    },
    stepperSubheading: "Select the one that you like the most!",
    options: [
      {
        id: 1,
        optionText: {
          boldText: "Videos",
          italicText: "that I can binge",
        },
      },
      {
        id: 2,
        optionText: {
          boldText: "Podcasts",
          italicText: "to vibe with",
        },
      },
      {
        id: 3,
        optionText: {
          boldText: "Articles",
          italicText: "I'll 'totally' read later",
        },
      },
      {
        id: 4,
        optionText: {
          boldText: "Documents",
          italicText: "when I'm feeling professional",
        },
      },
    ],
  },
  {
    stepperTitle: {
      text: "Which language do you ",
      highlightText: "vibe with most?",
    },
    stepperSubheading: "Select the one that you like the most!",
    options: [
      {
        id: 1,
        optionText: {
          boldText: "English,",
          italicText: "because it's comfy",
        },
      },
      {
        id: 2,
        optionText: {
          boldText: "Spanish,",
          italicText: "for that fiesta flair",
        },
      },
      {
        id: 3,
        optionText: {
          boldText: "French,",
          italicText: "let's make things fancy",
        },
      },
      {
        id: 4,
        optionText: {
          boldText: "Mandarin,",
          italicText: "Ni hao future me!",
        },
      },
      {
        id: 5,
        optionText: {
          boldText: "Other,",
          italicText: "I'm cultured, obviously",
        },
      },
    ],
  },
  {
    stepperTitle: {
      text: "How will you use ",
      highlightText: "VosynVerse?",
    },
    stepperSubheading: "Select the one that you like the most!",
    options: [
      {
        id: 1,
        optionText: "Work stuff",
      },
      {
        id: 2,
        optionText: "Binge Everything",
      },
      {
        id: 3,
        optionText: "Learn & flex",
      },
      {
        id: 4,
        optionText: "I'll wing it!",
      },
    ],
  },
];
