import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { SearchOutlined, SendSharp } from "@mui/icons-material";
import "./LandingSearch.css";
import AiSuggest from "../LandingAISuggest/LandingAISuggest";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import UploadInteractions from "../UploadInteractions/UploadInteractions";
import "../RegionSelector/RegionSelector.scoped.css";
import useQueryParam from "../../hooks/useQueryParam";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Bookmark, BookmarkBorderOutlined } from "@mui/icons-material";

function LandingSearch() {
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedMovies, setSavedMovies] = useState({});
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [hoverDelay, setHoverDelay] = useState(null); // Added to handle delay for hovering
  const searchRef = useRef(null);
  const { updateQueryParam } = useQueryParam();

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm && searchTerm.length > 0) {
        setLoading(true);
        try {
          const apiKey = "72671810";
          const response = await fetch(
            `https://www.omdbapi.com/?s=${searchTerm}&apikey=${apiKey}`
          );
          const data = await response.json();

          if (data.Response === "True") {
            const detailedResults = await Promise.all(
              data.Search.map(async (movie) => {
                const detailsResponse = await fetch(
                  `https://www.omdbapi.com/?i=${movie.imdbID}&apikey=${apiKey}`
                );
                return await detailsResponse.json();
              })
            );
            setSearchResults(detailedResults);
          } else {
            setSearchResults([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [searchTerm]);

  // redircting search results using custom hook
  const handleRedirectSearchResult = () => {
    updateQueryParam("query", searchTerm);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term || "");
    setIsSearching(!!term);
  };

  const renderStars = (rating) => {
    const starCount = Math.round(rating) / 2;
    return Array.from({ length: 5 }, (_, i) =>
      i < starCount ? <StarIcon key={i} /> : <StarBorderIcon key={i} />
    );
  };

  const toggleSaveMovie = (imdbID) => {
    setSavedMovies((prevSavedMovies) => ({
      ...prevSavedMovies,
      [imdbID]: !prevSavedMovies[imdbID],
    }));
  };

  const handleImageHover = (movie) => {
    // Clear previous timeouts to avoid rapid toggling
    clearTimeout(hoverTimeout);
    clearTimeout(hoverDelay);

    // Delay opening of modal
    setHoverDelay(
      setTimeout(() => {
        setSelectedMovie(movie);
        setIsModalOpen(true);
      }, 300)
    ); // 300ms delay before opening the modal
  };

  const handleHoverLeave = () => {
    // Clear previous timeouts
    clearTimeout(hoverDelay);

    // Delay closing of modal
    setHoverTimeout(
      setTimeout(() => {
        setIsModalOpen(false);
      }, 300)
    ); // 300ms delay before closing the modal
  };

  const handleModalEnter = () => {
    clearTimeout(hoverTimeout); // Keep modal open when hovering over it
    setIsModalOpen(true);
  };

  const handleModalLeave = () => {
    setHoverTimeout(
      setTimeout(() => {
        setIsModalOpen(false);
      }, 300)
    ); // Delay closing the modal when mouse leaves
  };

  return (
    <Box className="parent-container">
      <Box className="title-container">
        Access global content,
        <br /> in <em>your</em> language.
      </Box>
      <Box className="subtitle-container">
        Live dubbings and translations in 100+ languages.
        <br />
        Movies, music, articles, and more!
      </Box>

      <Box className="search-box">
        <Box className="search-feature">
          <SearchOutlined className="search-icon" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Explore globally, paste url, or input anything"
            className="search-input"
            onChange={handleSearch}
            value={searchTerm}
            onKeyDown={(e) => {
              if (e.key === "Enter" && searchTerm) handleRedirectSearchResult();
            }}
          />
          <Box
            onClick={() => {
              if (searchTerm) {
                handleRedirectSearchResult();
              }
            }}
            className="send-icon"
          >
            <SendSharp />
          </Box>
        </Box>

        {isSearching && (
          <Box className="search-results-box">
            {searchResults.map((result) => (
              <Box key={result.imdbID} className="search-result">
                <img
                  src={
                    result.Poster !== "N/A"
                      ? result.Poster
                      : "https://via.placeholder.com/150x100?text=No+Image"
                  }
                  alt={result.Title}
                  className="result-thumbnail"
                  onMouseEnter={() => handleImageHover(result)}
                  onMouseLeave={handleHoverLeave}
                />
                <Box className="result-details">
                  <Box className="result-title">
                    <strong>{result.Title}</strong> ({result.Year})
                  </Box>
                  <Box className="result-type">
                    <p>Type: {result.Type}</p>
                    <Box
                      className="save-icon"
                      onClick={() => toggleSaveMovie(result.imdbID)}
                    >
                      {savedMovies[result.imdbID] ? (
                        <Bookmark />
                      ) : (
                        <BookmarkBorderOutlined />
                      )}
                    </Box>
                  </Box>
                  <Box className="result-rating">
                    {renderStars(result.imdbRating || 7)}
                    <p>{result.imdbRating}/10</p>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        {!isSearching && (
          <Box className="search-feature">
            <Box className="drag-drop-box">
              <DragAndDrop />
            </Box>
            <Box className="video-input">
              <UploadInteractions />
            </Box>
          </Box>
        )}
      </Box>
      <AiSuggest />

      <Modal
        open={isModalOpen}
        onClose={handleHoverLeave}
        aria-labelledby="modal-title"
        keepMounted
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          className="modal-container"
          onMouseEnter={handleModalEnter}
          onMouseLeave={handleModalLeave}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
          }}
        >
          {selectedMovie && (
            <>
              <img
                src={
                  selectedMovie.Poster !== "N/A"
                    ? selectedMovie.Poster
                    : "https://via.placeholder.com/500x750?text=No+Image"
                }
                alt={selectedMovie.Title}
                className="modal-thumbnail"
                style={{
                  maxWidth: "100%",
                  height: "100%",
                  marginBottom: "20px",
                }}
              />
              <Box
                className="movie-info"
                style={{ textAlign: "left", width: "100%" }}
              >
                <Box className="movie-title">
                  <h2>
                    {selectedMovie.Title} ({selectedMovie.Year})
                  </h2>
                </Box>
                <Box
                  className="movie-actions"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box className="ratings">
                    {renderStars(selectedMovie.imdbRating || 7)}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    className="get-started-button"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
              <Box
                className="movie-content"
                style={{ marginTop: "20px", textAlign: "left" }}
              >
                <p>
                  <strong>Type:</strong> {selectedMovie.Type}
                </p>
                <p>
                  <strong>IMDB Rating:</strong> {selectedMovie.imdbRating}/10
                </p>
                <p>{selectedMovie.Plot}</p>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default LandingSearch;
