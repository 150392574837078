import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import CourseraImage from '../imgs/coursera-image.png'
import './ServiceCard.scoped.css'; 

const ServiceCard = ({ item, isLargeRow, serviceName, cardType, setPopupPosition, setPopupData }) => {
    const handleMouseEnter = (e) => {
        if (setPopupPosition) {
            const cardPosition = e.target.getBoundingClientRect();
            const cardRight = cardPosition.right;
            const cardWidth = cardPosition.width;
            const cardMiddleFromRight = cardWidth / 2; // Calculate the distance from the right edge to the middle of the card
            const popupRight = window.innerWidth - (cardRight - cardMiddleFromRight);
            setPopupPosition({ top: cardPosition.top + window.pageYOffset, right: popupRight, cardWidth });
            setPopupData(item)
        }
    };

    const navigate = useNavigate();
    const baseUrl = "https://image.tmdb.org/t/p/original";

    const handleSlideCardNavigate = (serviceName,id) => () => {
      if (serviceName === 'amazon-prime') {
          return navigate('/CONTENT-PLATFORM/video/c855825b-1392-4fbf-8c29-9d83d2e62113');
      }
      navigate(`/CONTENT-PLATFORM/services/${serviceName}/${id}`);
    };

  return (
      <div
          className='service-card-container'
          onClick={handleSlideCardNavigate(serviceName,item.id)}
          onMouseEnter={handleMouseEnter}
      >
          <div className={`service-card-image-container`}>
              { serviceName === "netflix" && (
                  <img src={`${baseUrl}${isLargeRow ? item.poster_path : item.backdrop_path}`} alt={item.title} className='service-card-image'/>
              )}
              { serviceName === "amazon-prime" && (
                  <img src={`${baseUrl}${isLargeRow ? item.poster_path : item.backdrop_path}`} alt={item.title} className='service-card-image'/>
              )}
              { serviceName === "disney" && (
                  <img src={`${baseUrl}${isLargeRow ? item.poster_path : item.backdrop_path}`} alt={item.title} className='service-card-image'/>
              )}
              { serviceName === "youtube" && (
                  <img src={`${baseUrl}${isLargeRow ? item.poster_path : item.backdrop_path}`} alt={item.title} className='service-card-image'/>
              )}
              { serviceName === "coursera" && (
                  <img src={item.image || CourseraImage} alt={item.title} className='service-card-coursera-image'/>
              )}
          </div>
          <div className="service-card-content">
              { serviceName === "youtube" && (
                  <div className='service-card-info-container'>
                    <h3 className="service-card-title">{item.title ? item.title : "Lorem Ipsum"}</h3>
                    <p className="service-card-description">{item.overview}</p>
                </div>
              )}
              { serviceName === "coursera" && (
                  <div className="coursera-servicecard-details">
                      <div className="coursera-card-company">
                          <i className="fa-brands fa-google coursera-card-company-logo" />
                          <span>{item.company}</span>
                      </div>
                      <div className="coursera-servicecard-title">{item.title}</div>
                      <div className="coursera-servicecard-type">{item.type}</div>
                      <div className="coursera-servicecard-level">{item.level}</div>
                  </div>
              )}
          </div>
      </div>
  )
}

export default ServiceCard;