import "./Coursera.scoped.css"
import CourseraBanner from "../../ServiceBanner/CourseraBanner";
import CourseraContent from "./CourseraContent";
import CourseraTabs from "./CourseraTabs";


const ServiceDetails = ({data}) => {
    return (
        <div className="service-details">
            <CourseraBanner action {...data}/>
            <CourseraContent/>
            <CourseraTabs/>
        </div>
    )
}

export default ServiceDetails