import React from "react";
import { Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./TranslationButton.scoped.css";

const TranslationButton = ({ label, handleClick, className }) => {
  return (
    <Box className="translation-button-container">
      <Button
        variant="contained"
        fullWidth
        startIcon={<MenuIcon data-testid="menuIconId" />}
        className={`${className} translation-button`}
        onClick={handleClick}
      >
        {label}
      </Button>
    </Box>
  );
};

export default TranslationButton;
