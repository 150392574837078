import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEventListener } from "../../Utils.jsx";
import { Loader } from "../Loader/Loader.jsx";
import VideoPlayerNotification from "../VideoPlayerNotification/VideoPlayerNotification.jsx";
import "./VideoPlayer.scoped.css";
import {
  setCurrentTime,
  setHasEnded,
  setPlaying,
  setFullScreen,
  setPinnedLanguages,
} from "../../reducers/videoPlayerSlice.js";
import parseVTT from "../../utils/parseVTT.js";
import Typography from "@mui/material/Typography";
import useKeyboardControls from "../../hooks/useKeyboardControls.jsx";
import TimeLine from "../ControlPanel/ActionControl/TimeLine.jsx";
import Video from "./Video.jsx";
import ControlPanel from "../ControlPanel/ControlPanel.jsx";
import axios from "axios";
import FlagButton from "../FlagButton/FlagButton.jsx";
import { useLocation } from "react-router-dom";
import IconSet from "../IconSet/IconSet.jsx";

const flagging_video = `${process.env.REACT_APP_PROD_URL}/user/flagVideo`;

const VideoPlayer = ({ setVideoViewCount, dashVideoObject }) => {
  const dispatch = useDispatch();

  const mouseMoveTimer = useRef(null);
  const videoRef = useRef(null);
  const playing = useSelector(({ videoPlayer }) => videoPlayer.playing);
  const currentTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);
  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const captionsEnabled = useSelector(
    ({ videoPlayer }) => videoPlayer.captionsEnabled
  );
  const pinnedLanguages = useSelector(
    ({ videoPlayer }) => videoPlayer.pinnedLanguages
  );
  const isHost = useSelector(
    ({ videoPlayer }) => videoPlayer.is_user_session_owner
  );
  const hovering = useSelector(({ videoPlayer }) => videoPlayer.isFullScreen);
  const [showTimeline, setShowTimeline] = useState(true);
  const [isCursorVisible, setIsCursorVisible] = useState(true);
  const [subtitles, setSubtitles] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState("");

  const location = useLocation();
  const pathArray = location.pathname.split("/");

  useEffect(() => {
    const video = videoRef.current;
    if (video && Math.floor(video.currentTime) !== Math.floor(currentTime)) {
      video.currentTime = currentTime;
    }
  }, [currentTime]);

  // Initializes the video player and manages audio tracks

  // useEffect(() => {
  //   player.current = dashjs.MediaPlayer().create();x
  //   player.current.initialize(
  //     videoRef.current,
  //     dashVideoObject?.file_stream_cdn_url,
  //     true
  //   );
  //   })

  // Set up subtitle data: store video element text track cues in subtitles
  useEffect(() => {
    const textTrack = videoRef.current.textTracks[0];

    if (textTrack) {
      textTrack.mode = "hidden";

      const parsedSubtitles = parseVTT(textTrack.cues);
      setSubtitles(parsedSubtitles);
    }
  }, [captionsEnabled]);
  // Shows controls and sets a timeout to hide them after 2 seconds of inactivity.
  const showControls = () => {
    if (
      (pathArray[2] == "watch-party" && isHost == true) ||
      pathArray[2] !== "watch-party"
    ) {
      clearTimeout(mouseMoveTimer.current);
      setIsCursorVisible(true);
      setShowTimeline(true);
      mouseMoveTimer.current = setTimeout(() => {
        if (!hovering) {
          setIsCursorVisible(false);
          setShowTimeline(false);
        }
      }, 2000);
    } else {
      setIsCursorVisible(false);
      setShowTimeline(false);
    }
  };

  // Toggle play/pause and handle visibility of controls & cursor
  const togglePlayPause = () => {
    const video = videoRef.current;

    dispatch(setHasEnded(false));
    // clearTimeout(mouseMoveTimer.current);
    // setIsCursorVisible(true);
    if (pathArray[2] !== "watch-party") {
      if (video.paused) {
        video.play();
        dispatch(setPlaying(true));
        showControls();
      } else {
        video.pause();
        dispatch(setPlaying(false));
        setShowTimeline(false);
        setTimeout(() => {
          setShowTimeline(true);
        }, 0);
      }
    } else {
      if (isHost) {
        // websocket logic
      }
    }
  };

  // Toggle mini player
  const handleMiniPlayer = () => {
    console.log("MINIPLAYER");
    const videoContainer = document.querySelector(".video-player");
    const miniPlayerVideo = document.querySelector("video");

    if (!miniPlayerVideo) return;

    const toggleMiniPlayerState = (isMini) => {
      if (isMini) {
        videoContainer.classList.add("mini-player");
      } else {
        videoContainer.classList.remove("mini-player");
      }
    };

    if (videoContainer.classList.contains("mini-player")) {
      document.exitPictureInPicture();
    } else {
      miniPlayerVideo.requestPictureInPicture();
    }

    miniPlayerVideo.addEventListener("enterpictureinpicture", () =>
      toggleMiniPlayerState(true)
    );
    miniPlayerVideo.addEventListener("leavepictureinpicture", () =>
      toggleMiniPlayerState(false)
    );
  };

  // Update video time by `e` seconds
  const handleTimeUpdate = (e) => {
    console.log("e", e);
    const video = videoRef.current;
    if (!video) return;

    dispatch(setCurrentTime(video.currentTime));
    if (typeof e === "number") {
      video.currentTime += e;
      dispatch(setCurrentTime(video.currentTime));
    }

    if (captionsEnabled) {
      const subtitle = subtitles.find(
        (subtitle) =>
          video.currentTime >= subtitle.start &&
          video.currentTime <= subtitle.end
      );
      if (subtitle) {
        setCurrentSubtitle(subtitle.text);
      } else {
        setCurrentSubtitle("");
      }
    }
  };

  useKeyboardControls(
    videoRef,
    togglePlayPause,
    handleMiniPlayer,
    handleTimeUpdate
  );

  // Adds a language to pinned languages if not already pinned
  const addToPinnedLanguages = (language) => {
    if (!pinnedLanguages.some((item) => item.name === language)) {
      setPinnedLanguages([...pinnedLanguages, { name: language }]);
    }
  };

  // Update the playback state and increment the view count when the video ends
  const handleVideoEnded = () => {
    const video = videoRef.current;
    video.currentTime = 0;
    dispatch(setPlaying(false));
    dispatch(setHasEnded(true));
    setVideoViewCount((videoViewCount) => videoViewCount + 1);
  };
  const handlePlay = () => {
    dispatch(setPlaying(true));
  };

  // Plays the video if it's not already playing when the loader times out.
  const handleSpinnerTimeout = useCallback(() => {
    let isPlaying = document.getElementsByClassName("fa-solid fa-play")[0];
    if (!isPlaying) {
      videoRef.current.play();
    }
  }, []);

  // Handle mouse movement to show controls if video is playing
  const handleMouseMove = () => {
    clearTimeout(mouseMoveTimer.current);
    if (playing) {
      showControls();
    }
  };

  // Update fullscreen state based on whether the document is in fullscreen mode
  const fullscreenChange = () => {
    dispatch(setFullScreen(Boolean(document.fullscreenElement)));
  };

  useEventListener("mousemove", handleMouseMove, videoRef.current);
  useEventListener("fullscreenchange", fullscreenChange);

  const handleFlag = (isFlagged) => {
    //Uncomment when backend api endpoint for flagging_video is setup
    // if (isFlagged) {
    //   const videoId = dashVideoObject.id;
    //   const videoTitle =
    //     dashVideoObject.titles[0]?.title_text || "Unknown Title";
    //   const flagData = {
    //     title: videoTitle,
    //     videoId: videoId,
    //   };
    //   console.log("Sending flag data:", flagData);
    //   try {
    //     const response = axios.post(flagging_video, flagData, {
    //       headers: { "Content-Type": "application/json" },
    //     });
    //     console.log("Successfully flagged summary", response);
    //   } catch (err) {
    //     console.error("Error submitting the flag description:", err);
    //     throw err;
    //   }
    // }
  };

  return (
    <>
      <div
        onMouseEnter={() => showControls()}
        onMouseLeave={() => {
          setTimeout(() => {
            setShowTimeline(false);
          }, 2000);
        }}
        onMouseMove={() => handleMouseMove()}
        className={`video-player ${isFullScreen ? "" : ""}`}
        style={{ cursor: isCursorVisible ? "default" : "none" }}
      >
        <VideoPlayerNotification />

        <Loader className={"video-loader"} onTimeout={handleSpinnerTimeout} />
        {/* <h1
          className={`video-title ${
            !playing || showTimeline ? "show" : "hide"
          }`}
        >
          {dashVideoObject?.video_name}
        </h1>
        <div
          className={`flag-icon-container ${
            !playing || showTimeline ? "show" : "hide"
          }`}
        >
          <FlagButton onFlag={handleFlag} />
        </div> */}

        <IconSet showIcons={!playing || showTimeline ? true : false}></IconSet>

        <Video
          videoRef={videoRef}
          handleTimeUpdate={handleTimeUpdate}
          togglePlayPause={togglePlayPause}
          handleVideoEnded={handleVideoEnded}
          dashVideoObject={dashVideoObject}
          handlePlay={handlePlay}
        />

        {captionsEnabled && (
          <Typography
            variant="caption"
            className={`captions ${!playing || showTimeline ? "show" : "hide"}`}
          >
            {currentSubtitle}
          </Typography>
        )}

        <div
          className={`controls ${!playing || showTimeline ? "show" : "hide"}`}
        >
          <TimeLine mediaRef={videoRef} showControls={showControls} />

          <ControlPanel
            togglePlayPause={togglePlayPause}
            mediaRef={videoRef}
            dashVideoObject={dashVideoObject}
            showControls={showControls}
            mouseMoveTimer={mouseMoveTimer}
          />
        </div>
      </div>
    </>
  );
};
export default VideoPlayer;
