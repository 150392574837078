import React, { useEffect, useRef, useState, Suspense } from "react";

import Globe from "react-globe.gl";

import * as turf from "@turf/turf";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import playlistsData from "../../data/playlist-old.json";
import PlaylistDropdown from "../Dropdown/PlaylistDropdown";
import videosData from "../../data/videos.json";

import "./Earth.scoped.css";
import "../VideoCardPin/VideoCardPin.scoped.css";
import EarthBump from "./img/earthbump.jpg";
import { setRegion } from "../../reducers/regionSlice";
import Banner from "./Banner";
import { useNavigate } from "react-router-dom";

import useEarthAtmosphere from "./EarthAtmosphere";

import { fetchVideosList } from "../../api/video";
import { logout } from "../../reducers/authSlice";
import { setToast } from "../../reducers/snackbarSlice";

import { randomUniform, geoBounds, geoContains } from "d3";
import countryCordinates from "../../data/country_cordinates.json";
import tourSampleTagData from "../TourOverlay/GlobeTour/tourSampleTagData";

import ReactDOM from "react-dom";

import VideoCardEarth from "./VideoCardEarth";
import VideoCardEarthMinimized from "./VideoCardEarthMinimized";
import Slider from "@mui/material/Slider";

import { FaPlus, FaMinus } from "react-icons/fa6";

function Earth({
  countries,
  isCollapsed,
  selectedVideoType,
  globeContainerWidth,
}) {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [earthWidth, setEarthWidth] = useState(800);
  const [earthHeight, setEarthHeight] = useState(450);
  const [activePolygon, setActivePolygon] = useState(null);
  const world = useRef();
  const selectedRegion = useSelector(({ region }) => region.selectedRegion);
  const selectedRegionCode = useSelector(
    ({ region }) => region.selectedRegionCode
  );
  const dispatch = useDispatch();
  const [tabIsActive, setTabIsActive] = useState(true);
  const [numOfActiveCards, setNumOfActiveCards] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedColor, setSelectedColor] = useState("rgba(0, 0, 0, 0)");
  const [globeImageUrl, setGlobeImageUrl] = useState(
    "//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
  );
  const [selectedAction, setSelectedAction] = useState("Trending");
  const [isPlaylistDropdownOpen, setIsPlaylistDropdownOpen] = useState(false);
  const [selectedPlaylistIds, setSelectedPlaylistIds] = useState([]);
  const [videos, setVideos] = useState(videosData);
  const [videoReference, setVideoReference] = useState(true);
  const [renderTutorial, setRenderTutorial] = useState(false);
  const globeView = useSelector(({ globeBanner }) => globeBanner.globeView);
  const [showPoints, setShowPoints] = useState(true);
  const atmosphereMaterial = useEarthAtmosphere();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPinHovered, setIsPinHovered] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [globeIsRendered, setGlobeIsRendered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectedOptions = (newSelectedOptions) => {
    console.log(newSelectedOptions);
    setSelectedOptions(newSelectedOptions);
  };
  const handleDropdownToggle = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  // For tour (forcing buttons to be active/inactive)
  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);

  useEffect(() => {
    if (tourStep >= 0 && tourStep < 15) {
      setSelectedAction("");
      dispatch(setRegion({ name: "Canada" }));
      setTagData(tourSampleTagData);
    }
    return () => {
      setSelectedAction("Trending");
      setTagData([]);
    };
  }, [tourStep, dispatch]);

  useEffect(() => {
    console.log("FETCHING");

    if (selectedAction === "Trending") {
      console.log(
        "Call Trending Videos",
        selectedRegionCode,
        selectedVideoType
      );
      fetchVideos();
    } else if (selectedAction === "Playlist") {
      console.log(
        "Call Playlist Videos",
        selectedRegionCode,
        selectedVideoType
      );
    }
  }, [selectedRegionCode, selectedVideoType, selectedAction]);

  const handleGlobeRendered = () => {
    setGlobeIsRendered(true);
  };

  /**
   * fetchVideos
   * Fetches a list of trending videos based on the selected region and video type,
   * processes the response data, and updates the state with the processed data.
   *
   * @param {boolean} [highlights=false] - (Optional) Indicates whether to fetch highlight videos.
   *        Currently not used within the function.
   *
   * @returns {Promise<Array<Object>>} A promise that resolves to an array of processed video data, or an empty array if the fetch fails.
   *
   * @throws {Error} If an error occurs during the fetch operation, it handles specific status codes and dispatches appropriate actions.
   */
  const fetchVideos = async (highlights = false) => {
    // Get trending videos based on the country.
    // Highlights will be a separate API - Recommendation API. Re-using search API for demo purpose
    let data;
    data = {
      sort_by: "view_count",
      upload_country: selectedRegionCode,
      limit: 5,
      category: selectedVideoType,
    };

    try {
      let res = await fetchVideosList(data);
      //-----------
      res.data.forEach((item, index) => {
        console.log(`Item ${index + 1}:`, JSON.stringify(item, null, 2));
      });
      //-------------
      // console.log("DATA IS " + res.data)
      if (res?.status === 200) {
        //  return res.data
        let retData = handleResponseData(res?.data);

        // let goodCoordinates = checkSpacedOutCoordinates(res?.data);
        setTagData(retData);
        console.log(retData);
      }
      return [];
    } catch (err) {
      console.log("err", err);
      if (err?.response?.status === 401) {
        localStorage.clear();
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error ocurred. Please try again later",
            type: "red",
          })
        );
      }
      return [];
    }
  };

  /**
   * handleResponseData
   * Processes an array of items (coordinates) to ensure they are spaced out by a minimum distance,
   * then assigns random coordinates within their respective country regions.
   *
   * @param {Array<Object>} items - An array of items (coordinates), each containing a document with an upload_country property.
   *
   * @returns {Array<Object>} An array of processed documents with assigned latitude and longitude coordinates,
   *          or re-fetchVideos function if the items are not sufficiently spaced out.
   */
  const handleResponseData = (items) => {
    let spacedOut = checkSpacedOutCoordinates(items);
    // console.log("SPACED OUT IS " + checkSpacedOutCoordinates(items)) -- WORKS, returns bool
    console.log("ITEMS ARE " + items); // what type of object is items? returns [object Object]
    if (!spacedOut) {
      return fetchVideos();
    }

    return items.map((item) => {
      console.log("REGION IS: " + selectedRegion);

      console.log("country");
      let country = countries.features.find(
        (country) =>
          country?.properties?.ISO_A2?.toLowerCase() ===
          item?.document?.upload_country?.toLowerCase()
      );
      let region = countryFeature(country?.properties?.ADMIN);
      let randomCoordinates = randomFeatureCoordinates(region);
      let random = randomCoordinates();
      item.document.lat = random?.[1];
      item.document.lng = random?.[0];
      console.log(1);
      console.log(`coordinates found ${country?.properties?.ADMIN}`, random);

      return item.document;
    });
  };

  const checkSpacedOutCoordinates = (items) => {
    const desiredDistance = 50000;

    for (let i = 0; i < items.length - 1; i++) {
      for (let j = i + 1; j < items.length; j++) {
        const coord1 = [items[i].document.lat, items[i].document.lng];
        const coord2 = [items[j].document.lat, items[j].document.lng];
        const distance = calculateDistance(coord1, coord2);

        console.log("the item is " + items[i]);
        console.log("coords in checkSpacedOut: " + coord1 + " " + coord2);

        if (distance < desiredDistance) {
          return false;
        }
      }
    }

    return true;
  };

  function calculateDistance(coordinate1, coordinate2) {
    const [lat1, lon1] = coordinate1;
    const [lat2, lon2] = coordinate2;

    console.log(
      "coords in clacualteDistance: " + coordinate1 + " " + coordinate2
    );

    const earthRadius = 6371e3;
    const lat1Radians = (lat1 * Math.PI) / 180;
    const lat2Radians = (lat2 * Math.PI) / 180;
    const deltaLat = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLon = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1Radians) *
        Math.cos(lat2Radians) *
        Math.sin(deltaLon / 2) *
        Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance;
  }

  /**
   * randoBoundingBoxcoordinates
   * Generates a function that produces random coordinates (longitude and latitude)
   * within a specified bounding box.
   *
   * @param {Array<Array<number>>} boundingBox - An array of two arrays that represent
   *        the southwest and northeast corners of the bounding box.
   *        Example: [[swLongitude, swLatitude], [neLongitude, neLatitude]]
   *
   * @returns {Function} A function that, when called, returns an array containing two numbers:
   *         a random longitude and a random latitude within the bounding box.
   *
   * @example
   * const boundingBox = [[-10, -10], [10, 10]];
   * const getRandomCoordinates = randomBoundingBoxCoordinates(boundingBox);
   * const coordinates = getRandomCoordinates(); // Example output: [-4.567, 8.123]
   */
  const randomBoundingBoxCoordinates = (boundingBox) => {
    const randomLongitude = randomUniform(
      boundingBox[0][0],
      boundingBox[1][0] + 360 * (boundingBox[1][0] < boundingBox[0][0])
    );
    const randomLatitude = randomUniform(boundingBox[0][1], boundingBox[1][1]);
    return () => [randomLongitude(), randomLatitude()];
  };

  /**
   * random FeatureCoordinates
   * Generates a function that produces random coordinates within a specified geographic feature.
   *
   * @param {Object} feature - A GeoJSON feature object representing the geographic area.
   *
   * @returns {Function} A function that, when called, returns an array containing two numbers:
   *         a random longitude and a random latitude within the geographic feature.
   *         Example: [randomLongitude, randomLatitude]
   */
  const randomFeatureCoordinates = (feature) => {
    const featureBoundingBox = geoBounds(feature);
    const randomCoordinates = randomBoundingBoxCoordinates(featureBoundingBox);
    return () => {
      let p;
      do {
        p = randomCoordinates();
      } while (!geoContains(feature, p));
      return p;
    };
  };

  /**
   * countryFeature
   * Retrieves the geographic feature for a specified country name from a collection of country coordinates.
   *
   * @param {string} countryName - The name of the country for which the geographic feature is to be retrieved.
   *
   * @returns {Object} The GeoJSON feature object representing the specified country, or undefined if the country is not found.
   */
  const countryFeature = (countryName) => {
    return countryCordinates.features.filter(
      (f) => f.properties.name === countryName
    )[0];
  };

  const handlePinMouseEnter = () => {
    console.log("pin hovered");
    setIsPinHovered(true);
  };

  const handlePinMouseLeave = () => {
    console.log("pin not hovered");
    setIsPinHovered(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!globeView) {
      setGlobeIsRendered(false);
    } else if (globeIsRendered && globeView) {
      const scene = world.current.scene();
      const atmosphereGeometry = new THREE.SphereGeometry(115, 50, 50);
      const atmosphere = new THREE.Mesh(atmosphereGeometry, atmosphereMaterial);
      scene.add(atmosphere);
    }
  }, [atmosphereMaterial, globeView, globeIsRendered]);

  useEffect(() => {
    const aggregatedVideos = selectedPlaylistIds.reduce((acc, playlistId) => {
      const playlist = playlistsData.find(
        (playlist) => playlist.id === playlistId
      );
      if (playlist) {
        acc.push(...playlist.videos);
      }
      return acc;
    }, []);

    setVideos(aggregatedVideos);
  }, [selectedPlaylistIds]);

  //video tutorial refernece method
  useEffect(() => {
    const storedTutorialSeen = localStorage.getItem("tutorialReference");
    if (storedTutorialSeen === "false") {
      setVideoReference(false);
    }

    //add a delay to fetch correct value before rendering.
    const timeoutId = setTimeout(() => {
      setRenderTutorial(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleTrendingClick = () => {
    console.log("Trending Videos");
    setSelectedAction(selectedAction === "Trending" ? "" : "Trending");

    if (selectedAction === "Trending") {
      setSelectedPlaylistIds([]);
      setShowPoints(true);
    } else if (selectedAction != "Trending") {
      setVideos(videosData);
      setShowPoints(false);
    }
  };

  let isMouseOverVideoCard = false; // Step 1: Define the flag

  const handleHighlightsClick = () => {
    console.log("My Highlights");
    setSelectedPlaylistIds([]);
    setVideos(playlistsData[0].videos);
    setSelectedAction(selectedAction === "Highlights" ? "" : "Highlights");
    if (selectedAction === "Highlights") {
      setShowPoints(true);
    } else if (selectedAction != "Highlights") {
      setShowPoints(false);
    }
  };

  const handlePlaylistClick = () => {
    console.log("My Playlist");
    setSelectedPlaylistIds([]);
    setSelectedAction(selectedAction === "Playlist" ? "" : "Playlist");
    if (selectedAction === "Playlist") {
      setShowPoints(true);
      // If the playlist is already selected, deselect it and clear selected playlists
      setSelectedAction("");

      setSelectedPlaylistIds([]); // Assuming setSelectedPlaylistIds is a function to clear selected playlists
      setIsPlaylistDropdownOpen(isPlaylistDropdownOpen);
      setVideos(playlistsData[0].videos);
    } else if (selectedAction != "Playlist") {
      setSelectedPlaylistIds([]);
      setIsPlaylistDropdownOpen(!isPlaylistDropdownOpen);
      setSelectedAction("Playlist");
      setVideos();
      setShowPoints(false);
    }
  };

  const options = {
    textureQuality: 100,
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setTabIsActive(true);
      } else {
        setTabIsActive(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange); //fires method everytime visibilitychange changes.
  }, []);

  const handleEarthRotation = () => {
    setIsSpinning(!isSpinning);
  };

  useEffect(() => {
    const directionalLight = world.current
      .lights()
      .find((obj3d) => obj3d.type === "DirectionalLight");
    directionalLight && directionalLight.position.set(50, 1000, 500);
  }, []);

  //Rotation of globe based on conditions.
  useEffect(() => {
    var spinCondition =
      tabIsActive && numOfActiveCards == 0 && hoveredCountry == null;

    if (isSpinning) {
      world.current.controls().autoRotate = true;
      world.current.controls().autoRotateSpeed = spinCondition ? 0.25 : 0;
    } else {
      world.current.controls().autoRotate = false;
    }
  }, [hoveredCountry, tabIsActive, numOfActiveCards, isSpinning]);

  useEffect(() => {
    // Stop spinning when a selectedAction is chosen
    if (
      selectedAction === "Trending" ||
      selectedAction === "Highlights" ||
      selectedAction === "Playlist"
    ) {
      setIsSpinning(false);
    }
  }, [selectedAction]); // Listen for changes to selectedAction

  useEffect(() => {
    console.log("Videos updated:", videos);
    setupGlobe();
    adjustGlobeView(selectedRegion);
    setNumOfActiveCards(0);
  }, [videos, selectedRegion, isCollapsed, selectedVideoType, selectedAction]);

  useEffect(() => {
    adjustGlobeSize();
  }, [isFullScreen]);

  useEffect(() => {
    world.current.controls().enableZoom = false;
  }, []);

  function setupGlobe() {
    world.current.controls().enableZoom = false;
    world.current.pointOfView({ lat: 0, lng: 0, altitude: 3 }, 200);
    adjustGlobeSize();
  }

  function adjustGlobeSize() {
    const sizeHeight = isFullScreen ? 900 : 450;
    const sizeWidth = globeContainerWidth;
    setEarthHeight(sizeHeight);
    setEarthWidth(sizeWidth);
  }

  function adjustGlobeView(region) {
    const coordinates = getRegionCoordinates(region);
    if (coordinates) {
      world.current.pointOfView(
        {
          lat: coordinates[1],
          lng: coordinates[0],
          altitude: world.current.pointOfView().altitude,
        }, // fix altitube
        1000
      );
    }
    updateRegionPolygon(region);
  }

  function getRegionCoordinates(regionName) {
    const region = countries.features.find((country) =>
      country.properties.ADMIN.includes(regionName)
    );
    console.log(region);
    return region ? turf.centroid(region).geometry.coordinates : null;
  }

  function updateRegionPolygon(regionName) {
    const regionPolygon = countries.features.find(
      (country) => country.properties.ADMIN === regionName
    );
    setActivePolygon(regionPolygon);
    if (regionPolygon) updateMarkers(regionPolygon);
  }

  function roundToNearest3(num) {
    return Math.round(num / 3) * 3;
  }

  const latLngData = playlistsData.reduce((acc, playlist) => {
    const videosLatLng = playlist.videos.map((video) => ({
      lat: video.lat,
      lng: video.lng,
      views: video.views,
    }));
    return acc.concat(videosLatLng);
  }, []);

  // console.log(latLngData);

  // Group data based on rounding each data point to nearest 3 and determining which coordinates are identical. Aggregate the viewcount.
  const groupedData = latLngData.reduce((acc, point) => {
    const roundedLat = roundToNearest3(Math.round(point.lat));
    const roundedLng = roundToNearest3(Math.round(point.lng));
    const key = `${roundedLat},${roundedLng}`;
    if (!acc[key]) {
      acc[key] = { lat: roundedLat, lng: roundedLng, views: 0 };
    }
    acc[key].views += point.views;
    return acc;
  }, {});

  // console.log(groupedData);

  // Convert the grouped data into an array, logarithmnic viewcount calculations to account for drastically different viewcounts
  const processedData = Object.values(groupedData).map((point) => ({
    ...point,
    // Calculate radius based on views
    radius: Math.log(point.views) * 0.05, // Example scaling function
  }));

  const handlePolygonClick = (polygon, { lat, lng, altitude }) => {
    const selectedRegionName = polygon.properties.ADMIN;
    const selectedRegionFlag = `${selectedRegionName.toLowerCase()}.png`;
    const selectedRegionCode = polygon?.properties?.ISO_A2;

    dispatch(
      setRegion({
        name: selectedRegionName,
        flag: selectedRegionFlag,
        code: polygon?.properties?.ISO_A2,
      })
    );
    // console.log(selectedRegion);
    // world.current.pointOfView(
    //   { lat, lng, altitude: world.current.pointOfView().altitude },
    //   1000
    // );
    sessionStorage.setItem(
      "selectedCountry",
      JSON.stringify({
        name: selectedRegionName,
        flag: selectedRegionName.toLowerCase() + ".png",
        code: selectedRegionCode.toUpperCase(),
      })
    );
  };
  // console.log(processedData);

  const handleFullScreenClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Function to handle color selection
  const handleColorChange = (color) => {
    setSelectedColor(color);
    if (color === "rgba(157, 42, 103, 0.99)") {
      /*pink*/ setGlobeImageUrl("https://iili.io/JELmUcx.md.jpg");
    } else if (color === "rgba(190, 31, 31, 0.99)" /*Red*/) {
      setGlobeImageUrl(
        "https://cdn.pixabay.com/photo/2016/07/29/23/05/white-1556107_1280.png"
      );
    } else if (color == "rgba(233,187,24,254)") {
      setGlobeImageUrl(
        "https://cdn.pixabay.com/photo/2016/07/29/23/05/white-1556107_1280.png"
      );
    } else if (color == "rgba(109,157,49,255)" /*green*/) {
      setGlobeImageUrl(
        "https://cdn.pixabay.com/photo/2014/06/16/23/39/black-370118_1280.png"
      );
    } else if (color == "rgb(149, 125, 255)" /*purple*/) {
      setGlobeImageUrl("https://iili.io/JELpDX9.md.jpg");
    } else if (color == "rgba(233,187,24,255)" /*yellow*/) {
      setGlobeImageUrl(
        "https://cdn.pixabay.com/photo/2014/05/24/00/07/woven-cloth-352481_1280.jpg"
      );
    } else {
      handleOriginalGlobe();
    }
  };

  const handleOriginalGlobe = () => {
    setSelectedColor("rgba(0, 0, 0, 0)");
    setGlobeImageUrl(
      "https://unpkg.com/three-globe@2.30.3/example/img/earth-blue-marble.jpg"
    );
  };

  const getPolygonCapColor = (coordinates) => {
    if (coordinates === hoveredCountry && !isMouseOverVideoCard)
      return "rgba(64,196,250, 0.2)"; // Highlight hovered country
    if (coordinates.properties.ADMIN.includes(selectedRegion))
      return "rgba(82, 121, 249, 0.6)"; // Highlight countries in selected region
    return selectedColor; // Otherwise, return the selected color
  };

  useEffect(() => {
    console.log("selectedVideo", selectedVideo);
  }, [selectedVideo]);

  function updateMarkers(regionPolygon) {
    let filteredVideos;

    if (selectedAction === "Highlights") {
      //   filteredVideos = videos;
      filteredVideos = playlistsData[0].videos.filter((video) =>
        turf.booleanPointInPolygon(
          turf.point([video.lng, video.lat]),
          regionPolygon
        )
      );
    }
    if (selectedAction === "Playlist") {
      filteredVideos = videos;
    }
    if (selectedVideoType !== "All") {
      if (filteredVideos) {
        filteredVideos = filteredVideos.filter(
          (video) => video.category === selectedVideoType
        );
      }
    }
    //DELETE SOON
    setTagData(filteredVideos);
  }

  const getIconSvg = (category, selectedAction) => {
    if (selectedAction === "Highlights") {
      return `
      <g transform="translate(3, 3)">
      <path d="M9.362 9.158l-5.268.584c-.19.023-.358.15-.421.343s0 .394.14.521c1.566 1.429 3.919 3.569 3.919 3.569-.002 0-.646 3.113-1.074 5.19-.036.188.032.387.196.506.163.119.373.121.538.028 1.844-1.048 4.606-2.624 4.606-2.624l4.604 2.625c.168.092.378.09.541-.029.164-.119.232-.318.195-.505l-1.071-5.191 3.919-3.566c.14-.131.202-.332.14-.524s-.23-.319-.42-.341c-2.108-.236-5.269-.586-5.269-.586l-2.183-4.83c-.082-.173-.254-.294-.456-.294s-.375.122-.453.294l-2.183 4.83z" fill="white"/>
      </g>`;
    }
    switch (category) {
      case "Gaming":
        return `
        <path d="M11.6772 10.538C9.31863 10.538 7.40507 12.4515 7.40507 14.8101C7.40507 17.1687 9.31863 19.0823 11.6772 19.0823H17.3734C19.732 19.0823 21.6456 17.1687 21.6456 14.8101C21.6456 12.4515 19.732 10.538 17.3734 10.538H11.6772ZM18.4415 12.8521C18.6775 12.8521 18.9039 12.9458 19.0708 13.1127C19.2377 13.2797 19.3315 13.506 19.3315 13.7421C19.3315 13.9781 19.2377 14.2045 19.0708 14.3714C18.9039 14.5384 18.6775 14.6321 18.4415 14.6321C18.2054 14.6321 17.979 14.5384 17.8121 14.3714C17.6452 14.2045 17.5514 13.9781 17.5514 13.7421C17.5514 13.506 17.6452 13.2797 17.8121 13.1127C17.979 12.9458 18.2054 12.8521 18.4415 12.8521ZM16.1274 15.8782C16.1274 15.6421 16.2211 15.4157 16.3881 15.2488C16.555 15.0819 16.7814 14.9881 17.0174 14.9881C17.2535 14.9881 17.4798 15.0819 17.6468 15.2488C17.8137 15.4157 17.9074 15.6421 17.9074 15.8782C17.9074 16.1142 17.8137 16.3406 17.6468 16.5075C17.4798 16.6744 17.2535 16.7682 17.0174 16.7682C16.7814 16.7682 16.555 16.6744 16.3881 16.5075C16.2211 16.3406 16.1274 16.1142 16.1274 15.8782ZM11.1432 13.5641C11.1432 13.2681 11.3813 13.0301 11.6772 13.0301C11.9732 13.0301 12.2112 13.2681 12.2112 13.5641V14.2761H12.9233C13.2192 14.2761 13.4573 14.5142 13.4573 14.8101C13.4573 15.1061 13.2192 15.3441 12.9233 15.3441H12.2112V16.0562C12.2112 16.3521 11.9732 16.5902 11.6772 16.5902C11.3813 16.5902 11.1432 16.3521 11.1432 16.0562V15.3441H10.4312C10.1352 15.3441 9.89715 15.1061 9.89715 14.8101C9.89715 14.5142 10.1352 14.2761 10.4312 14.2761H11.1432V13.5641Z" fill="white"/>
        `;
      case "Sports":
        return `
        <path d="M10.1364 10.7121C10.1364 10.3182 10.4547 10 10.8486 10H11.5607C11.9546 10 12.2729 10.3182 12.2729 10.7121V14.2729V15.6971V19.2579C12.2729 19.6518 11.9546 19.97 11.5607 19.97H10.8486C10.4547 19.97 10.1364 19.6518 10.1364 19.2579V17.8336H9.42429C9.03038 17.8336 8.71214 17.5153 8.71214 17.1214V15.6971C8.31824 15.6971 8 15.3789 8 14.985C8 14.5911 8.31824 14.2729 8.71214 14.2729V12.8486C8.71214 12.4547 9.03038 12.1364 9.42429 12.1364H10.1364V10.7121ZM20.1064 10.7121V12.1364H20.8186C21.2125 12.1364 21.5307 12.4547 21.5307 12.8486V14.2729C21.9246 14.2729 22.2429 14.5911 22.2429 14.985C22.2429 15.3789 21.9246 15.6971 21.5307 15.6971V17.1214C21.5307 17.5153 21.2125 17.8336 20.8186 17.8336H20.1064V19.2579C20.1064 19.6518 19.7882 19.97 19.3943 19.97H18.6821C18.2882 19.97 17.97 19.6518 17.97 19.2579V15.6971V14.2729V10.7121C17.97 10.3182 18.2882 10 18.6821 10H19.3943C19.7882 10 20.1064 10.3182 20.1064 10.7121ZM17.2579 14.2729V15.6971H12.985V14.2729H17.2579Z" fill="white"/>
        `;
      case "Music":
        return `
        <path d="M19.7229 9.13651C19.8969 9.26542 20 9.47167 20 9.68866V11.2355V16.9074C20 17.857 19.0762 18.6262 17.9375 18.6262C16.7988 18.6262 15.875 17.857 15.875 16.9074C15.875 15.9578 16.7988 15.1887 17.9375 15.1887C18.1781 15.1887 18.4102 15.223 18.625 15.2875V12.1594L13.125 13.8094V18.2824C13.125 19.232 12.2012 20.0012 11.0625 20.0012C9.92383 20.0012 9 19.232 9 18.2824C9 17.3328 9.92383 16.5637 11.0625 16.5637C11.3031 16.5637 11.5352 16.598 11.75 16.6625V13.298V11.7512C11.75 11.4482 11.9498 11.1797 12.2398 11.0916L19.1148 9.02909C19.3232 8.96679 19.5488 9.00546 19.7229 9.13651Z" fill="white"/>`;
      case "Series":
        return `
        <path d="M19.3734 9.75H17.3555L17.332 9.77344L14.3555 12.75H16.514L16.5375 12.7266L19.5094 9.75703C19.4648 9.75234 19.4203 9.75 19.3734 9.75ZM20.8734 12.75V11.25C20.8734 10.8961 20.7492 10.568 20.5453 10.3125L18.1078 12.75H20.8734ZM15.7664 9.75H13.6055L13.582 9.77344L10.6055 12.75H12.764L12.7875 12.7266L15.764 9.75H15.7664ZM10.3734 9.75C9.54608 9.75 8.87342 10.4227 8.87342 11.25V12.75H9.01639L9.03983 12.7266L12.0164 9.75H10.3734ZM20.8734 13.5H8.87342V18.75C8.87342 19.5773 9.54608 20.25 10.3734 20.25H19.3734C20.2008 20.25 20.8734 19.5773 20.8734 18.75V13.5Z" fill="white"/>
         `;
      case "News":
        return `
          <path d="M12.0031 11.8101C12.0031 10.9828 12.6758 10.3101 13.5031 10.3101H20.2531C21.0805 10.3101 21.7531 10.9828 21.7531 11.8101V19.3101C21.7531 20.1375 21.0805 20.8101 20.2531 20.8101H11.6281C10.5922 20.8101 9.75315 19.9711 9.75315 18.9351V12.5601C9.75315 12.1453 10.0883 11.8101 10.5031 11.8101C10.918 11.8101 11.2531 12.1453 11.2531 12.5601V18.9351C11.2531 19.1414 11.4219 19.3101 11.6281 19.3101C11.8344 19.3101 12.0031 19.1414 12.0031 18.9351V11.8101ZM13.5031 12.3726V14.2476C13.5031 14.5594 13.7539 14.8101 14.0656 14.8101H16.6906C17.0024 14.8101 17.2531 14.5594 17.2531 14.2476V12.3726C17.2531 12.0609 17.0024 11.8101 16.6906 11.8101H14.0656C13.7539 11.8101 13.5031 12.0609 13.5031 12.3726ZM18.3781 12.1851C18.3781 12.3914 18.5469 12.5601 18.7531 12.5601H19.8781C20.0844 12.5601 20.2531 12.3914 20.2531 12.1851C20.2531 11.9789 20.0844 11.8101 19.8781 11.8101H18.7531C18.5469 11.8101 18.3781 11.9789 18.3781 12.1851ZM18.3781 14.4351C18.3781 14.6414 18.5469 14.8101 18.7531 14.8101H19.8781C20.0844 14.8101 20.2531 14.6414 20.2531 14.4351C20.2531 14.2289 20.0844 14.0601 19.8781 14.0601H18.7531C18.5469 14.0601 18.3781 14.2289 18.3781 14.4351ZM13.5031 16.6851C13.5031 16.8914 13.6719 17.0601 13.8781 17.0601H19.8781C20.0844 17.0601 20.2531 16.8914 20.2531 16.6851C20.2531 16.4789 20.0844 16.3101 19.8781 16.3101H13.8781C13.6719 16.3101 13.5031 16.4789 13.5031 16.6851ZM13.5031 18.9351C13.5031 19.1414 13.6719 19.3101 13.8781 19.3101H19.8781C20.0844 19.3101 20.2531 19.1414 20.2531 18.9351C20.2531 18.7289 20.0844 18.5601 19.8781 18.5601H13.8781C13.6719 18.5601 13.5031 18.7289 13.5031 18.9351Z" fill="white"/>
          `;
      case "Learning":
        return `
        <path d="M15.6797 9.75C15.4899 9.75 15.3024 9.78281 15.1243 9.84609L8.55019 12.2203C8.32754 12.3023 8.17989 12.5132 8.17989 12.7499C8.17989 12.9866 8.32754 13.1976 8.55019 13.2796L9.90719 13.7694C9.52282 14.3741 9.30486 15.0889 9.30486 15.8413V16.4998C9.30486 17.1654 9.05174 17.8521 8.78222 18.3935C8.62988 18.6982 8.45644 18.9982 8.25489 19.2748C8.17989 19.3755 8.15879 19.5068 8.20098 19.6263C8.24317 19.7459 8.3416 19.8349 8.46347 19.8654L9.96344 20.2404C10.0619 20.2662 10.1673 20.2474 10.2541 20.1935C10.3408 20.1396 10.4017 20.0505 10.4205 19.9498C10.622 18.9467 10.5212 18.0467 10.3712 17.4022C10.2962 17.0694 10.1955 16.7295 10.0548 16.4178V15.8413C10.0548 15.1335 10.2939 14.4655 10.7087 13.9311C11.0111 13.5679 11.4025 13.2749 11.8618 13.0945L15.5414 11.6484C15.7336 11.5734 15.9516 11.6671 16.0266 11.8593C16.1016 12.0515 16.0078 12.2695 15.8156 12.3445L12.136 13.7905C11.8454 13.9054 11.59 14.0811 11.3814 14.2968L15.1219 15.6467C15.3 15.71 15.4875 15.7428 15.6774 15.7428C15.8672 15.7428 16.0547 15.71 16.2328 15.6467L22.8092 13.2796C23.0319 13.1999 23.1795 12.9866 23.1795 12.7499C23.1795 12.5132 23.0319 12.3023 22.8092 12.2203L16.2352 9.84609C16.057 9.78281 15.8695 9.75 15.6797 9.75ZM11.1798 18.5623C11.1798 19.3896 13.1954 20.2497 15.6797 20.2497C18.164 20.2497 20.1796 19.3896 20.1796 18.5623L19.821 15.1546L16.4883 16.3592C16.2281 16.453 15.9539 16.4998 15.6797 16.4998C15.4055 16.4998 15.1289 16.453 14.8711 16.3592L11.5384 15.1546L11.1798 18.5623Z" fill="white"/>
        `;
      case "Health & Fitness":
        return `
        <g transform="translate(-1, 0)">
        <path d="M9.98871 16.5753L14.9297 21.1881C15.1348 21.3796 15.4055 21.4862 15.6871 21.4862C15.9688 21.4862 16.2395 21.3796 16.4446 21.1881L21.3856 16.5753C22.2168 15.8014 22.6871 14.7159 22.6871 13.5811V13.4225C22.6871 11.5112 21.3063 9.88151 19.4223 9.56706C18.1754 9.35924 16.9067 9.76666 16.0153 10.6581L15.6871 10.9862L15.359 10.6581C14.4676 9.76666 13.1989 9.35924 11.952 9.56706C10.068 9.88151 8.68715 11.5112 8.68715 13.4225V13.5811C8.68715 14.7159 9.15746 15.8014 9.98871 16.5753Z" fill="white"/>
        </g>
        `;
        return "";
    }
  };

  const populateTags = (videoData) => {
    const element = document.createElement("div");
    // element.style.width = "35x";
    // element.style.height = "45px";
    element.style["pointer-events"] = "auto";
    element.style.cursor = "pointer";
    element.style.transform = "translate(-50%, -100%)";

    // Create an SVG element for the icon
    const iconSvgElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    iconSvgElement.setAttribute("width", `35`);
    iconSvgElement.setAttribute("height", `45`);
    iconSvgElement.setAttribute("viewBox", `0 0 35 55`);

    iconSvgElement.setAttribute("fill", "none");
    iconSvgElement.style.zIndex = "-1";
    iconSvgElement.innerHTML = `
    <ellipse cx="15" cy="15" rx="15" ry="15" fill="#222222" style="stroke: white; stroke-width: 1px;"/>
    <path d="M15 45L2.00962 22.5L27.9904 22.5L15 45Z" fill="#222222" style="stroke: white; stroke-width: 1px; stroke-dasharray: 26.5 25 27;"/>
      ${getIconSvg("Gaming", selectedAction)}
    `;

    iconSvgElement.onclick = (event) => {
      event.stopPropagation();
      // console.log("Card opened");
      event.preventDefault();

      const isVideoSelected = selectedVideos.some(
        (video) => video.id === videoData.id
      );
      if (isVideoSelected) {
        setSelectedVideos([]);
        setNumOfActiveCards(0);
      } else {
        setSelectedVideos([videoData]);
        setNumOfActiveCards(1);
      }
      // setSelectedVideo(videoData);
    };

    element.addEventListener("mouseenter", handlePinMouseEnter);
    element.addEventListener("mouseleave", handlePinMouseLeave);
    element.appendChild(iconSvgElement);

    const markerPoint = turf.point([videoData.lng, videoData.lat]);
    const polygonCentroid = turf.centroid(activePolygon);
    const isLeft =
      markerPoint.geometry.coordinates[0] <
      polygonCentroid.geometry.coordinates[0];

    // Calculate if the markerPoint is above the centroid of the activePolygon
    const isTop =
      markerPoint.geometry.coordinates[1] >
      polygonCentroid.geometry.coordinates[1];
    const videoCardElement = createVideoCard({
      videoData,
      isLeft,
      isTop,
      position: "absolute",
    });

    if (selectedVideos.some((video) => video.id === videoData.id)) {
      const container = document.createElement("div");
      ReactDOM.render(videoCardElement, container);
      element.appendChild(container);
    }

    return element;
  };

  const createVideoCard = ({ videoData, isLeft }) => {
    const pinClass = isLeft ? "video-pin" : "video-pin left-tail";
    let videoThumbnail = videoData?.thumbnail_url;
    let videoTitle = videoData?.titles?.[0];

    if (selectedAction === "Highlights" || selectedAction === "Playlist") {
      videoThumbnail = videoData?.thumbnail;
      videoTitle = videoData?.title;
    }

    const markerPoint = turf.point([videoData.lng, videoData.lat]);
    const polygonCentroid = turf.centroid(activePolygon);
    const bottom = "50%";

    let right;
    let left;
    let top;

    if (
      markerPoint.geometry.coordinates[0] <
      polygonCentroid.geometry.coordinates[0]
    ) {
      right = "90%";
    } else if (
      markerPoint.geometry.coordinates[0] >
      polygonCentroid.geometry.coordinates[0]
    ) {
      left = "90%";
    }
    const handleClose = (event) => {
      event.stopPropagation();
      setSelectedVideos(
        selectedVideos.filter((video) => video.id !== videoData.id)
      );
      setNumOfActiveCards(numOfActiveCards - 1);
      setIsPinHovered(false);
    };

    const handleClick = (event) => {
      event.stopPropagation();
      // Check if the dropdown is open
      if (isDropdownOpen) {
        // If the dropdown is open, do not navigate
        return;
      }
      // If the dropdown is not open, proceed with the navigation
      if (selectedAction === "Playlist") {
        navigate(`/CONTENT-PLATFORM/playlist/${videoData.id}`);
      } else {
        navigate(`/CONTENT-PLATFORM/video/${videoData.id}`);
      }
    };

    let width = window.innerWidth;
    return (
      <div
        className="disable-hover"
        style={{
          cursor: "pointer",
          position: "absolute",
          bottom: bottom,
          right: right,
          left: left,
          top: top,
        }}
        onClick={handleClick}
      >
        {width <= 1080 || !isFullScreen ? (
          <VideoCardEarthMinimized
            pinClass={pinClass}
            videoThumbnail={videoThumbnail}
            videoTitle={videoTitle}
            videoData={videoData}
            isDropdownOpen={isDropdownOpen}
            onDropdownToggle={(event) => handleDropdownToggle(event)}
            onClose={handleClose}
            onSelectedOptionsChange={handleSelectedOptions}
            selectedOptions={selectedOptions}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        ) : (
          <VideoCardEarth
            pinClass={pinClass}
            videoThumbnail={videoThumbnail}
            videoTitle={videoTitle}
            videoData={videoData}
            isDropdownOpen={isDropdownOpen}
            onDropdownToggle={(event) => handleDropdownToggle(event)}
            onClose={handleClose}
          />
        )}
      </div>
    );
  };

  // A method to handle globe zoom using the slider
  const handleZoomSliderChange = (_, value) => {
    const DEFAULT_ALTITUDE = 2.5000000000000004; // the default altitude at zoom 0

    const zoomBy = 0.5 * value;
    const altitude = DEFAULT_ALTITUDE - zoomBy;

    world.current.pointOfView(
      {
        altitude: altitude,
      },
      400
    );
  };

  return globeView ? (
    <div className="globe-view">
      {/* <div className="globe-buttons">
        <i
          class="fa-solid fa-maximize"
          id={isFullScreen ? "active" : ""}
          onClick={handleFullScreenClick}
        >
          <span className="tooltip">Maximize</span>
        </i>

        <div className="globe-slider">
          <FaPlus className="slider-icon" />
          <Slider
            orientation="vertical"
            track={false}
            aria-label="Zoom"
            defaultValue={0}
            valueLabelDisplay="auto"
            shiftStep={1}
            step={1}
            marks
            min={-3}
            max={3}
            onChange={handleZoomSliderChange}
          />
          <FaMinus className="slider-icon" />
        </div>

        <i
          class="fa-solid fa-rotate"
          id={isSpinning ? "active" : ""}
          onClick={handleEarthRotation}
        >
          <span className="tooltip">Rotate</span>
        </i>
      </div> */}

      {showDropdown && (
        <div className="dropdown-content">
          <p className="close-button" onClick={toggleDropdown}>
            X
          </p>
          <h1>Customize Globe</h1>
          <p>Pick your favorite color for the globe.</p>
          <div className="row">
            <div
              className="color-option"
              onClick={() => handleColorChange("rgba(157, 42, 103, 0.99)")}
            >
              <img
                src="https://iili.io/JEbtlgs.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />
            </div>
            <div
              className="color-option"
              onClick={() => handleColorChange("rgba(190, 31, 31, 0.99)")}
            >
              <img
                src="https://iili.io/JEmWkKu.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />
            </div>
            <div
              className="color-option"
              onClick={() => handleColorChange("rgba(233,187,24,254)")}
            >
              <img
                src="https://iili.io/JEmh2IV.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="color-option"
              onClick={() => handleColorChange("rgba(109,157,49,255)")}
            >
              <img
                src="https://iili.io/JEmhVQS.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />
            </div>
            <div
              className="color-option"
              onClick={() => handleColorChange("rgb(149, 125, 255)")}
            >
              <img
                src="https://iili.io/JEmjZle.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />
            </div>
            <div
              className="color-option"
              onClick={() => handleColorChange("rgba(233,187,24,255)")}
            >
              <img
                src="https://iili.io/JEmwdiP.png"
                style={{ maxWidth: "65px", maxHeight: "65px" }}
              />{" "}
            </div>
          </div>
          <button
            className="original-globe-button"
            onClick={handleOriginalGlobe}
          >
            Original Globe
          </button>
        </div>
      )}

      {/* {videoReference && renderTutorial && (
        <div className="globe-tutorial">
          <GlobeTutorial isFullScreen={isFullScreen} />
        </div>
      )} */}

      <div className={`earth-container ${selectedVideo ? "shift-earth" : ""}`}>
        <Suspense fallback={<div className="loading">Loading Earth...</div>}>
          <Globe
            showAtmosphere={false}
            onGlobeReady={handleGlobeRendered}
            width={earthWidth}
            height={earthHeight}
            ref={world}
            rendererConfig={{ antialias: true, alpha: true }}
            bumpImageUrl={EarthBump}
            // atmosphereColor={gradient}
            polygonsData={countries.features}
            polygonAltitude={(coordinates) =>
              coordinates === hoveredCountry ? 0.02 : 0.01
            }
            polygonCapColor={getPolygonCapColor}
            globeImageUrl={globeImageUrl}
            polygonSideColor={() => `rgba(0, 0, 0, 0)`}
            polygonStrokeColor={(coordinates) =>
              coordinates === hoveredCountry
                ? `rgba(255, 255, 255, 0.8)`
                : `rgba(255, 255, 255, 0.4)`
            }
            polygonLabel={({ properties: coordinates }) => {
              if (!isPinHovered) {
                return `<b>${coordinates.ADMIN} (${coordinates.ISO_A2})</b> <br />`;
              }
              return null;
            }}
            onPolygonHover={(country) => {
              if (!isPinHovered) {
                setHoveredCountry(country);
              }
            }}
            polygonsTransitionDuration={300}
            onPolygonClick={(polygon, event, { lat, lng, altitude }) => {
              if (!isPinHovered) {
                handlePolygonClick(polygon, { lat, lng, altitude });
              }
            }}
            backgroundColor={"#FF000000"}
            htmlElementsData={tagData}
            htmlElement={(video) => populateTags(video)}
            options={options}
            // pointsData={showPoints ? processedData : []} // Correct prop name for heatmap data
            // pointColor={(point) => {
            //   // Calculate the maximum and minimum views to normalize the views
            //   const maxViews = Math.max(
            //     ...latLngData.map((data) => data.views)
            //   );
            //   const minViews = Math.min(
            //     ...latLngData.map((data) => data.views)
            //   );
            //   // Normalize the views of the current point
            //   const normalizedViews =
            //     (point.views - minViews) / (maxViews - minViews);

            //   const lightness = 80 + normalizedViews * 20; //Lightness is variable
            //   return `rgba(0, 162, 255, ${lightness})`;
            // }}
            // pointAltitude={0.001}
            // pointRadius={(point) => point.radius}
          />
        </Suspense>
      </div>

      {/* <div className="top-right-buttons">
        <button
          onClick={handleTrendingClick}
          className={`${
            selectedAction === "Trending" || tourStep === 3
              ? "top-right-buttons-selected"
              : ""
          }`}
        >
          Trending <i className="fa-solid fa-fire"></i>
        </button>
        <button
          onClick={handleHighlightsClick}
          className={`${
            selectedAction === "Highlights" || tourStep === 4
              ? "top-right-buttons-selected"
              : ""
          }`}
        >
          Highlights <i className="fa-solid fa-star"></i>
        </button>
        <div className="playlist-button-container">
          <button
            onClick={() => {
              if (selectedAction === "Playlist") {
                setSelectedAction("");
                setSelectedPlaylistIds([]);

                setIsPlaylistDropdownOpen(isPlaylistDropdownOpen);
                setShowPoints(true);
              } else {
                setSelectedPlaylistIds([]);
                setIsPlaylistDropdownOpen(!isPlaylistDropdownOpen);
                setSelectedAction("Playlist");
                setVideos();
                setShowPoints(false);
              }
            }}
            className={`${
              selectedAction === "Playlist" || tourStep === 5
                ? "top-right-buttons-selected"
                : ""
            }`}
          >
            Playlist <i className="fa-solid fa-photo-film"></i>
          </button>
          {isPlaylistDropdownOpen && (
            <PlaylistDropdown
              playlists={playlistsData}
              selectedPlaylistIds={selectedPlaylistIds}
              setSelectedPlaylistIds={setSelectedPlaylistIds}
              isOpen={isPlaylistDropdownOpen}
              setIsOpen={setIsPlaylistDropdownOpen}
            />
          )}
        </div>
      </div> */}
    </div>
  ) : (
    <>
      <div className="globe-view" style={{ display: "none" }}>
        <div className="globe-buttons">
          <i
            class="fa-solid fa-maximize"
            id={isFullScreen ? "active" : ""}
            onClick={handleFullScreenClick}
          >
            <span className="tooltip">Maximize</span>
          </i>
          <div className="globe-slider">
            <FaPlus className="slider-icon" />
            <Slider
              orientation="vertical"
              track={false}
              aria-label="Zoom"
              defaultValue={0}
              valueLabelDisplay="auto"
              shiftStep={1}
              step={1}
              marks
              min={-3}
              max={3}
              onChange={handleZoomSliderChange}
            />
            <FaMinus className="slider-icon" />
          </div>
          <i
            class="fa-solid fa-rotate"
            id={isSpinning ? "active" : ""}
            onClick={handleEarthRotation}
          >
            <span className="tooltip">Rotate</span>
          </i>
        </div>

        {showDropdown && (
          <div className="dropdown-content">
            <p className="close-button" onClick={toggleDropdown}>
              X
            </p>
            <h1>Customize Globe</h1>
            <p>Pick your favorite color for the globe.</p>
            <div className="row">
              <div
                className="color-option"
                onClick={() => handleColorChange("rgba(157, 42, 103, 0.99)")}
              >
                <img
                  src="https://iili.io/JEbtlgs.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />
              </div>
              <div
                className="color-option"
                onClick={() => handleColorChange("rgba(190, 31, 31, 0.99)")}
              >
                <img
                  src="https://iili.io/JEmWkKu.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />
              </div>
              <div
                className="color-option"
                onClick={() => handleColorChange("rgba(233,187,24,254)")}
              >
                <img
                  src="https://iili.io/JEmh2IV.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="color-option"
                onClick={() => handleColorChange("rgba(109,157,49,255)")}
              >
                <img
                  src="https://iili.io/JEmhVQS.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />
              </div>
              <div
                className="color-option"
                onClick={() => handleColorChange("rgb(149, 125, 255)")}
              >
                <img
                  src="https://iili.io/JEmjZle.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />
              </div>
              <div
                className="color-option"
                onClick={() => handleColorChange("rgba(233,187,24,255)")}
              >
                <img
                  src="https://iili.io/JEmwdiP.png"
                  style={{ maxWidth: "65px", maxHeight: "65px" }}
                />{" "}
              </div>
            </div>
            <button
              className="original-globe-button"
              onClick={handleOriginalGlobe}
            >
              Original Globe
            </button>
          </div>
        )}

        {/* {videoReference && renderTutorial && (
          <div className="globe-tutorial">
            <GlobeTutorial isFullScreen={isFullScreen} />
          </div>
        )} */}

        <div
          className={`earth-container ${selectedVideo ? "shift-earth" : ""}`}
        >
          <Suspense fallback={<div className="loading">Loading Earth...</div>}>
            <Globe
              showAtmosphere={false}
              width={earthWidth}
              height={earthHeight}
              ref={world}
              rendererConfig={{ antialias: true, alpha: true }}
              bumpImageUrl={EarthBump}
              // atmosphereColor={gradient}
              polygonsData={countries.features}
              polygonAltitude={(coordinates) =>
                coordinates === hoveredCountry ? 0.02 : 0.01
              }
              polygonCapColor={getPolygonCapColor}
              globeImageUrl={globeImageUrl}
              polygonSideColor={() => `rgba(0, 0, 0, 0)`}
              polygonStrokeColor={(coordinates) =>
                coordinates === hoveredCountry
                  ? `rgba(255, 255, 255, 0.8)`
                  : `rgba(255, 255, 255, 0.4)`
              }
              polygonLabel={({ properties: coordinates }) => {
                if (!isPinHovered) {
                  return `<b>${coordinates.ADMIN} (${coordinates.ISO_A2})</b> <br />`;
                }
                return null;
              }}
              onPolygonHover={(country) => {
                if (!isPinHovered) {
                  setHoveredCountry(country);
                }
              }}
              polygonsTransitionDuration={300}
              onPolygonClick={(polygon, event, { lat, lng, altitude }) => {
                if (!isPinHovered) {
                  handlePolygonClick(polygon, { lat, lng, altitude });
                }
              }}
              backgroundColor={"#FF000000"}
              htmlElementsData={tagData}
              htmlElement={(video) => populateTags(video)}
              options={options}
              // pointsData={showPoints ? processedData : []} // Correct prop name for heatmap data
              // pointColor={(point) => {
              //   // Calculate the maximum and minimum views to normalize the views
              //   const maxViews = Math.max(
              //     ...latLngData.map((data) => data.views)
              //   );
              //   const minViews = Math.min(
              //     ...latLngData.map((data) => data.views)
              //   );
              //   // Normalize the views of the current point
              //   const normalizedViews =
              //     (point.views - minViews) / (maxViews - minViews);

              //   const lightness = 80 + normalizedViews * 20; //Lightness is variable
              //   return `rgba(0, 162, 255, ${lightness})`;
              // }}
              // pointAltitude={0.001}
              // pointRadius={(point) => point.radius}
            />
          </Suspense>
        </div>

        {/* <div className="top-right-buttons">
          <button
            onClick={handleTrendingClick}
            className={`${
              selectedAction === "Trending" ? "top-right-buttons-selected" : ""
            }`}
          >
            Trending Videos <i className="fa-solid fa-fire"></i>
          </button>
          <button
            onClick={handleHighlightsClick}
            className={`${
              selectedAction === "Highlights"
                ? "top-right-buttons-selected"
                : ""
            }`}
          >
            My Highlights <i className="fa-solid fa-star"></i>
          </button>
          <div className="playlist-button-container">
            <button
              onClick={handlePlaylistClick}
              className={`${
                selectedAction === "Playlist"
                  ? "top-right-buttons-selected"
                  : ""
              }`}
            >
              My Playlist <i className="fa-solid fa-photo-film"></i>
            </button>
            {isPlaylistDropdownOpen && (
              <PlaylistDropdown
                playlists={playlistsData}
                selectedPlaylistIds={selectedPlaylistIds}
                setSelectedPlaylistIds={setSelectedPlaylistIds}
                isOpen={isPlaylistDropdownOpen}
                setIsOpen={setIsPlaylistDropdownOpen}
              />
            )}
          </div>
        </div> */}
      </div>

      <Banner />
    </>
  );
}

export default Earth;
