import React from "react";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./OnboardingPage.scoped.css";
import { steps } from "../../data/onboardingSteps";
import OnboardingNavbar from "../../components/Onboarding/OnboardingNavbar/OnboardingNavbar";
import OnboardingInstructions from "../../components/Onboarding/OnboardingInstructions/OnboardingInstructions";
import OnboardingBody from "../../components/Onboarding/OnboardingBody/OnboardingBody";
import OnboardingCompletion from "../../components/Onboarding/OnboardingCompletion/OnboardingCompletion";

export default function OnboardingPage() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  let onboardingStep = Number(searchParams.get("step")) - 1;
  if (
    isNaN(onboardingStep) ||
    onboardingStep < 0 ||
    onboardingStep > steps.length
  ) {
    onboardingStep = 0;
  }

  const handleGoBack = () => {
    setSearchParams({ step: onboardingStep });
  };

  const handleGoForward = () => {
    setSearchParams({ step: onboardingStep + 2 });
  };

  const handleSkipOnboarding = () => {
    navigate("/CONTENT-PLATFORM/home");
  };

  const handleSubmitOnboarding = () => {
    navigate("/CONTENT-PLATFORM/home");
  };

  return (
    <Box component="main" className="main-container">
      <OnboardingNavbar
        onboardingStep={onboardingStep}
        totalSteps={steps.length}
        handleSkipOnboarding={handleSkipOnboarding}
      />
      <OnboardingInstructions
        onboardingStep={onboardingStep}
        handleGoBack={handleGoBack}
      />
      {onboardingStep < steps.length ? (
        <OnboardingBody
          onboardingStep={onboardingStep}
          steps={steps}
          handleGoForward={handleGoForward}
        />
      ) : (
        <OnboardingCompletion handleSubmitOnboarding={handleSubmitOnboarding} />
      )}
    </Box>
  );
}
