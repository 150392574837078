import React, { useState, useEffect } from "react";
import { Box, Grid2 } from "@mui/material";
import AudioCard from "./AudioCard";

// Static array
const audioDataTemp = [
  {
    id: 1,
    image: "https://i.ytimg.com/vi/UGsvfsrP2fo/hqdefault.jpg", // You can add image URL here
    title: "Audio Title 1",
    description: "This is the description for Audio 1.",
  },
  {
    id: 2,
    image: "https://i.ytimg.com/vi/g2F5RO6vNSs/hqdefault.jpg", // Add another audio's image URL
    title: "Audio Title 2",
    description: "This is the description for Audio 2.",
  },
];

const AudioComponent = ({ audioData = audioDataTemp }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid2 container spacing={8} columns={5}>
        {audioData.map((audio) => (
          <Grid2 item size={1} key={audio.id}>
            <AudioCard
              image={audio.image}
              title={audio.title}
              description={audio.description}
            />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default AudioComponent;
