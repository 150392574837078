import "./ServiceWatchlist.scoped.css";
import { useEffect, useState } from "react";
import serviceData from "../../../data/services-data.json";
import ServiceCards from "../ServiceCards/ServiceCards";

const ServiceWatchlist = () => {
  const [data, setData] = useState([]);
  const filters = ["All", "Movies", "TV Shows"];
  const [filter, setFilter] = useState(filters[0]);

  const handleSetFilter = (value) => () => setFilter(value);

  useEffect(() => {
    setData(serviceData.results);
  }, []);

  //TODO Make request on filter change
  // useEffect(() => {
  // }, [filter])

  return (
    <div className="service-watchlist">
      <h1 className="service-watchlist__title">Watchlist</h1>
      <div className="service-filters">
        {filters.map((option) => (
          <div
            className={option === filter ? "service-filters__active" : ""}
            onClick={handleSetFilter(option)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className="service-watchlist__content">
        <ServiceCards
          serviceItems={data}
          serviceName="netflix"
          cardType="watchServiceCard"
        />
      </div>
    </div>
  );
};

export default ServiceWatchlist;
