import React from "react";
import Box from "@mui/material/Box";
import "./LandingAISuggest.css";

function LandingAISuggest() {
  return (
    <Box className="ai-parent">
      <Box className="ai-box">AI</Box>
      <Box className="suggestion-box">
        <b>Discover and enjoy content</b> Best action movies from India
      </Box>
      <Box className="suggestion-box">
        <b>Upload a photo</b> What does the sign say?
      </Box>
      <Box className="suggestion-box">
        <b>Global Research</b> Show me trending studies from Japan
      </Box>
    </Box>
  );
}

export default LandingAISuggest;
