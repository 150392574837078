import React, { useEffect } from "react";
import "./HomePage.css";
import NavigationMenu from "../../components/NavigationMenu/NavigationMenu";
import { Box } from "@mui/material";
import useQueryParam from "../../hooks/useQueryParam";
import LandingSearch from "../../components/LandingSearch/LandingSearch";
import Video from "../../components/MediaDisplay/VideoTab/VideoTab";
import Audio from "../../components/MediaDisplay/AudioTab/AudioTab";
import Text from "../../components/MediaDisplay/TextTab/TextTab";
import Featured from "../../components/MediaDisplay/FeaturedTab/FeaturedTab";

function HomePage() {
  const { updateQueryParam, getAllParams } = useQueryParam();

  const params = getAllParams();

  useEffect(() => {
    updateQueryParam("tab", "featured");
  }, []);

  const getMediaDisplay = () => {
    if (params.tab === "featured") {
      return <Featured />;
    } else if (params.tab === "video") {
      return <Video />;
    } else if (params.tab === "audio") {
      return <Audio />;
    } else if (params.tab === "text") {
      return <Text />;
    }
  };

  return (
    <Box>
      <LandingSearch />

      <NavigationMenu />

      <Box className="home-videos">{getMediaDisplay()}</Box>
    </Box>
  );
}

export default HomePage;
