import React from "react";
import Comment from "../Comment/Comment";
import { useSelector } from "react-redux";
import "../../../../pages/VideoPage/VideoPage.scoped.css";

const CommentList = ({
  handleNewCommentLike,
  handleCommentReply,
  handleCommentReplySubmit,
}) => {
  const displayComments = useSelector(
    ({ videoComment }) => videoComment.displayComments
  );
  return (
    <>
      {displayComments &&
        displayComments.map((comment, commentIndex) => (
          <>
            <Comment
              comment={comment}
              commentIndex={commentIndex}
              handleNewCommentLike={handleNewCommentLike}
              handleCommentReply={handleCommentReply}
              handleCommentReplySubmit={handleCommentReplySubmit}
            />
          </>
        ))}
    </>
  );
};

export default CommentList;
