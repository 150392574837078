import { useState } from "react";
import { Button } from "@mui/material";

const ReadMore = ({ text, amountOfWords = 18 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const splittedText = text.split(" ");
  const itCanOverflow = splittedText.length > amountOfWords;
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords).join(" ")
    : text;
  const endText = splittedText.slice(amountOfWords).join(" ");

  const buttonProps = {
    onClick: () => setIsExpanded(!isExpanded),
    sx: {
      fontSize: 14,
      minWidth: "auto",
      textTransform: "none",
      color: "common.white",
      padding: "0",
      textDecoration: "underline",
    },
    variant: "text",
  };

  return (
    <>
      <span style={{ fontSize: 14,  color: "var(--mui-palette-common-white)",}}>{beginText}</span>
      {itCanOverflow && !isExpanded && <span style={{color: "var(--mui-palette-common-white)",}}>... </span>}
      {itCanOverflow && isExpanded && (
        <>
          <span style={{ fontSize: 14, color: "var(--mui-palette-common-white)", }}> {endText}</span>
          <div display="block">
            <br />
            <Button {...buttonProps}>Show less</Button>
          </div>
        </>
      )}
      {itCanOverflow && !isExpanded && <Button {...buttonProps}>more</Button>}
    </>
  );
};

export default ReadMore;
