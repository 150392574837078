import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateQueryParam = useCallback(
    (key, value) => {
      const urlSearchParams = new URLSearchParams(location.search);

      if (value) {
        urlSearchParams.set(key, value);
      } else {
        urlSearchParams.delete(key);
      }

      const newUrl =
        key === "query" && !location.pathname.includes("search")
          ? `/CONTENT-PLATFORM/search?${urlSearchParams.toString()}`
          : `${location.pathname}?${urlSearchParams.toString()}`;

      navigate(newUrl, { replace: true });
    },
    [location.pathname, location.search, navigate]
  );

  const getAllParams = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = {};
    urlSearchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [location.search]);

  return { updateQueryParam, getAllParams };
};

export default useQueryParam;
