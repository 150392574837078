import "./Coursera.scoped.css"

const CourseraContent = () => {
    return (
        <div className="coursera-content-container">
            <div className="coursera-about">
                <h2>About</h2>
                <ul>
                    <li>Follow the design process: empathize with users, define pain points, ideate solutions, create wireframes and prototypes, test and iterate on designs</li>
                    <li>Apply foundational UX concepts, like user-centered design, accessibility, and equity-focused design</li>
                    <li>Understand the basics of UX research, like planning research studies, conducting interviews and usability studies, and synthesizing research results</li>
                    <li>Create a professional UX portfolio that includes 3 end-to-end projects: a mobile app, a responsive website, and a cross-platform experience</li>
                </ul>
            </div>
            <div className="coursera-material">
                <div>
                    <p className="coursera-material__title">Professional Certificate - 7 Courses</p>
                    <p>Beginner Level</p>
                </div>
                <div>
                    <p className="coursera-material__title">6 months at 10 hours a week</p>
                    <p>Flexible Schedule</p>
                </div>
                <div className="coursera-material__title">
                    <p>Earn Degree Credit</p>
                </div>
                <div>
                    <p className="coursera-material__title">Shareable Certificate</p>
                    <p>Add to your LinkedIn profile</p>
                </div>
                <button className="view-course">View Course Mate</button>
            </div>
        </div>
    );
}

export default CourseraContent