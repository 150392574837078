import React, { useEffect, useRef, useState } from 'react';

function useVoiceInput() {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState("");
    const recognitionRef = useRef(null);
    const [statusError, setStatusError] = useState(false);

    useEffect(() => {
        setTranscript("");
    }, []);

    useEffect(() => {
        if (!window.webkitSpeechRecognition) {
            console.error("Web speech API is not supported!");
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognitionRef.current = recognition;
        recognition.interimResults = true;
        recognition.lang = "en-US";
        recognition.continuous = false;
        
        if (window.webkitSpeechGrammarList) {
            const grammar = "#JSGF V1.0; grammar punctuation ; public <punc> = . | , | ? | ! | ; | : ;";
            const speechRecognitionList = new window.webkitSpeechGrammarList();
            speechRecognitionList.addFromString(grammar, 1);
            recognition.grammars = speechRecognitionList;
        }

        recognition.onresult = (event) => {
            const text = Array.from(event.results)
                .map(result => result[0].transcript)
                .join("");
            setTranscript(text);
        };

        recognition.onerror = (event) => {
            console.error("Speech Recognition Error: ", event.error);
            setStatusError(true);
        };

        recognition.onend = () => {
            setIsListening(false);
            // setTranscript("");
        };

        return () => {
            recognition.stop();
        };
    }, []);

    const startListening = () => {
        if (recognitionRef.current && !isListening) {
            recognitionRef.current.start();
            setIsListening(true);
        }
    };

    const stopListening = () => {
        if (recognitionRef.current && isListening) {
            recognitionRef.current.stop();
            setIsListening(false);
        }
    };

    return {
        isListening,
        setTranscript,
        transcript,
        statusError,
        startListening,
        stopListening
    };
}

export default useVoiceInput;
