import {
  Select,
  MenuItem,
  TextField,
  FormControl,
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Link,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import "../TextPageTranslationPanel/TextPageTranslationPanel.scoped.css";
import React from "react";
import Grid from "@mui/material/Grid2";
import { ArrowDropDown } from "@mui/icons-material";
import Flag from "react-world-flags";
import { useSelector, useDispatch } from "react-redux";
import {
  setTranslatedLanguage,
  setOriginalLanguage,
} from "../../../../reducers/languageSlice";

import TextPageActionButtonPanel from "../TextPageActionButtonPanel/TextPageActionButtonPanel";
import { useState, useEffect } from "react";

function TextPageTranslationPanel({ setLanguage, pdfUrl, wordUrl }) {
  const [switchLanguage, setSwitchLanguage] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet")); // Get screen size

  useEffect(() => {
    switchLanguage
      ? setLanguage(selectedOriginalLanguage)
      : setLanguage(selectedTranslatedLanguage);
  }, [switchLanguage]);
  // Language list with country codes for flags
  const languages = [
    { code: "EN", name: "English", countryCode: "CA" },
    { code: "FR", name: "French", countryCode: "FR" },
    { code: "ES", name: "Spanish", countryCode: "ES" },
    { code: "DE", name: "German", countryCode: "DE" },
    { code: "JP", name: "Japanese", countryCode: "JP" },
  ];

  const dispatch = useDispatch();

  const selectedTranslatedLanguage = useSelector(
    (state) => state.languages.selectedTranslatedLanguage
  );

  const selectedOriginalLanguage = useSelector(
    (state) => state.languages.selectedOriginalLanguage
  );

  // setting language for comparing
  function setTranslang() {
    setSwitchLanguage((prev) => {
      return !prev;
    });
  }
  // Handle language selection change for both translated and original languages
  const handleLanguageChange = (event, type) => {
    if (type === "translated") {
      dispatch(setTranslatedLanguage(event.target.value));
    } else {
      dispatch(setOriginalLanguage(event.target.value));
    }
  };

  return (
    <Box className="translation-panel-div-main">
      <Box className="translation-panel-sub-div-one">
        <Box className="select-language-div">
          <Grid
            item
            xs={12}
            // sx={{ width: isMobile ? "45%" : "100%" }}
          >
            <Typography
              variant="subtitle2"
              className="translation-panel-typography"
              sx={{ fontSize: isMobile ? "11px" : "12px" }}
              data-testid="translated-language-caption"
            >
              Translated Language
            </Typography>
            <FormControl fullWidth >
              <Select
                id="translated-language-select"
                data-testid="translated-language-select"
                name="translatedLanguage"
                value={selectedTranslatedLanguage}
                onChange={(event) => handleLanguageChange(event, "translated")}
                IconComponent={ArrowDropDown}
                className="translation-panel-select"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      color:"var(--mui-palette-common-white)",
                      backgroundColor: "var(--mui-palette-neutral-600)"
                    },
                  },
                }}
                renderValue={(value) => {
                  const selected = languages.find(
                    (lang) => lang.code === value
                  );
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: "5px",
                        backgroundColor:"var(--mui-palette-neutral-600)"
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Flag
                          code={selected.countryCode}
                          className="translation-panel-flag"
                        />
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: isMobile ? "12px" : "",
                        }}
                      >
                        {selected.name}
                      </Box>
                    </Box>
                  );
                }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    <ListItemIcon>
                      <Flag
                        code={lang.countryCode}
                        className="translation-panel-flag"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: isMobile ? "13px" : "",
                      }}
                    >
                      {lang.name}
                    </ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className="translation-panel-typography"
              data-testid="original-language-caption"
              sx={{ fontSize: isMobile ? "11px" : "12px" }}
            >
              Original Language
            </Typography>
            <FormControl fullWidth>
              <Select
                id="original-language-select"
                data-testid="original-language-select"
                name="originalLanguage"
                value={selectedOriginalLanguage}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      color:"var(--mui-palette-common-white)",
                      backgroundColor: "var(--mui-palette-neutral-600)"
                    },
                  },
                }}
                onChange={(event) => handleLanguageChange(event, "original")}
                IconComponent={ArrowDropDown}
                className="translation-panel-select"
                renderValue={(value) => {
                  const selected = languages.find(
                    (lang) => lang.code === value
                  );
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: "5px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Flag
                          code={selected.countryCode}
                          className="translation-panel-flag"
                        />
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                          fontSize: isMobile ? "13px" : "",
                        }}
                      >
                        {selected.name}
                      </Box>
                    </Box>
                  );
                }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    <ListItemIcon>
                      <Flag
                        code={lang.countryCode}
                        className="translation-panel-flag"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: isMobile ? "13px" : "",
                      }}
                    >
                      {lang.name}
                    </ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Box>
        <Box className="action-button-div">
          <TextPageActionButtonPanel setTransLang={setTranslang} />
        </Box>
      </Box>
    </Box>
  );
}

export default TextPageTranslationPanel;
