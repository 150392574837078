import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import './ServiceCardModal.scoped.css';

const ServiceCardModal = ({ item, serviceName, isLargeRow, position, setPopupData, setPopupPosition }) => {
    const navigate = useNavigate();
    const baseUrl = "https://image.tmdb.org/t/p/original";
    const popupRef = useRef();
    const [modalWidth, setModalWidth] = useState(0);

    useEffect(() => {
        if (popupRef.current) {
            setModalWidth(popupRef.current.offsetWidth);
        }
    }, [popupRef]);

    const positionRight = position.right - modalWidth / 2;

    const handleSlideCardNavigate = (serviceName, id) => () =>{
        if (serviceName === 'amazon-prime') {
            return navigate('/CONTENT-PLATFORM/video/c855825b-1392-4fbf-8c29-9d83d2e62113');
        }
        navigate(`/CONTENT-PLATFORM/services/${serviceName}/${id}`);
    };

    const hidePopup = () => {
        setPopupData(null);
        setPopupPosition(null);
    };

    return (
        <div
            onMouseLeave={hidePopup}
            ref={popupRef}
            className='service-card-modal-container service-card-modal'
            style={{ right: positionRight, top: position.top, width: `${position.cardWidth || 200}px` }}
            onClick={handleSlideCardNavigate(serviceName, item.id)}
        >
            <div className="service-card-modal-image-container">
                <img src={`${baseUrl}${isLargeRow ? item.backdrop_path : item.backdrop_path}`} alt={item.title} className='service-card-modal-image'/>
            </div>
            <div className="service-card-modal-content">
                <div className="service-card-modal-actions-button-container">
                    <div className="service-card-modal-actions-right-container">
                        <i className="fa-solid fa-play service-card-modal-actions-play-button" aria-hidden="true" />
                    </div>
                    <div className="service-card-modal-actions-left-container">
                        <i className="fa-regular fa-thumbs-up service-card-modal-actions-thumbs-up" data-v-abb07517="" aria-hidden="true"/>
                        <i className="fa-regular fa-thumbs-down service-card-modal-actions-thumbs-down" data-v-abb07517="" aria-hidden="true" />
                        <i className="fa-solid fa-add service-card-modal-actions-add" data-v-abb07517="" aria-hidden="true"/>
                    </div>
                </div>
                <div className="service-card-modal-info-container">
                    <h3 className="service-card-modal-title">{item.title ? item.title : "Lorem Ipsum"}</h3>
                    <h6 className="service-card-modal-sub-title">5 Sessions</h6>
                    <p className="service-card-modal-description">{item.overview}</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceCardModal;
