import "./FullScreenButton.scoped.css";
import { setFullScreen, setHovering } from "../../../reducers/videoPlayerSlice";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const FullscreenButton = ({ mouseMoveTimer, showControls, mediaRef }) => {
  const dispatch = useDispatch();

  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const handleFullscreen = async () => {
    const mediaType = mediaRef.current.tagName.toLowerCase(); // Get the tag name (audio or video)

    const media = document.querySelector(
      mediaType === "audio" ? ".audio-player" : ".video-player"
    );
    console.log("document.querySelector", media);

    if (!media) return;

    if (isFullScreen) {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
      dispatch(setFullScreen(false));
    } else {
      if (media.requestFullscreen) {
        await media.requestFullscreen({ navigationUI: "hide" });
      } else if (media.webkitRequestFullscreen) {
        media.webkitRequestFullscreen();
      } else if (media.msRequestFullscreen) {
        media.msRequestFullscreen();
      }
      dispatch(setFullScreen(false));
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`); // Log the pressed key
      const activeElement = document.activeElement;
      const media = mediaRef?.current;

      // Ignore key events if the focus is on an input field (excluding range inputs)
      if (
        activeElement.tagName.toLowerCase() === "input" &&
        activeElement.type !== "range"
      ) {
        return;
      }

      if (!media) return;

      switch (event.key) {
        case "f": // Enter fullscreen
          event.preventDefault(); // Prevent default behavior
          handleFullscreen?.();
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleFullscreen]);

  return (
    <div
      className="tooltip-fullscreen-right"
      onMouseEnter={() => {
        clearTimeout(mouseMoveTimer.current);
        dispatch(setHovering(true));
      }}
      onMouseLeave={() => {
        dispatch(setHovering(false));
        showControls();
      }}
    >
      {isFullScreen ? (
        <ToolTip content={"Exit Fullscreen"}>
          <MuiIcon
            name="FullscreenExit"
            onClick={handleFullscreen}
            style={{ cursor: "pointer" }}
            id="controls-btn-fullscreen"
          />
        </ToolTip>
      ) : (
        <ToolTip content={"Enter Fullscreen"}>
          <MuiIcon
            name="Fullscreen"
            onClick={handleFullscreen}
            style={{ cursor: "pointer" }}
            id="controls-btn-fullscreen"
          />
        </ToolTip>
      )}
    </div>
  );
};

export default FullscreenButton;
