import React, { useState, useEffect } from "react";
import "./AccountPage.scoped.css";
import AccountNavigation from "../../components/AccountNavigation/AccountNavigation";
import AccountLanguageSettings from "../../components/AccountLanguageSettings/AccountLanguageSettings";
import ProfileSettings from "../../components/ProfileSettings/ProfileSettings"
function AccountPage() {
  const [activeTab, setActiveTab] = useState("profiles"); // Default active tab

  useEffect(() => {
    // Set default tab when component mounts
    setActiveTab("profiles");
  }, []);

  return (
    <div className="account-body-container">
      <div className="navigation-container">
        <AccountNavigation activeTab={activeTab} onTabChange={setActiveTab} />{" "}
      </div>

      <div className="profile-content">
        {activeTab === "language" && <AccountLanguageSettings />}
        {activeTab === "profiles" && <ProfileSettings/>}
        {activeTab === "dashboard" && <div>Dashboard Content Here</div>}
      </div>
    </div>
  );
}

export default AccountPage;
