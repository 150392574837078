import React, { useState } from "react";
import "./VideoPlayerTranscript.scoped.css";
import {
    Button,
    Box,
    Typography,
    IconButton,
    useMediaQuery,
    Menu,
    MenuItem,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled, alpha } from "@mui/material/styles";
import FlagIcon from "@mui/icons-material/Flag";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useAlert } from "../../../../context/AlertProvider";
import Transcript from "../../../../components/Transcript/Transcript";
import { downloadFile } from "../../../../utils/fileActions";
import WatchPartyModal from "../../../../components/WatchPartyModal/WatchPartyModal";
import ShareModal from "../../../../components/ShareModal/ShareModal";
import { useTheme } from "@emotion/react";
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';

const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.neutral[600], // Use theme's neutral color
  color: theme.palette.common.white, // Use theme's common white color
  borderRadius: "3rem",
  "&:hover": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.8),
  },
  "&:disabled": {
    backgroundColor: alpha(theme.palette.neutral[600], 0.3),
    color: theme.palette.common.white,
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.neutral[600],
    },
  }));
  
  
  const CustomMenuButton = styled(MenuItem)(({ theme }) => ({
      backgroundColor: theme.palette.neutral[600], 
      color: theme.palette.common.white,
      "&:hover": {
      backgroundColor: alpha(theme.palette.neutral[600], 0.8),
    },
    }));
  
    const CustomMenu = styled(Menu)(({ theme }) => ({
      "ul": {
          backgroundColor: theme.palette.neutral[600],
    },
    }));

const VideoPlayerTranscript = ({
    originalTranscript,
    translatedTranscript,
    fileUrl
}) => {
    const theme = useTheme();
    const [showCompare, setShowCompare] = useState(false);
    const [openWatchParty, setOpenWatchParty] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const { showAlert } = useAlert();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("desktop"));
    const isMobileView = useMediaQuery(theme.breakpoints.down("tablet"));

    const handleDownloadClick = () => {
        downloadFile(fileUrl);
        setAnchorEl(null);
    };

    const handleBookmarkClick = () => {
        showAlert("success", "Media file has been bookmarked successfully!");
        setAnchorEl(null);
    };

    const handleCompareClick = () => {
        setShowCompare(!showCompare);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            component="section"
            bgcolor="var(--mui-palette-neutral-800)"
            borderRadius="16px"
            p="1rem"
        >
            <Box
                data-testid="menu-1"
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
                mb="1rem"
            >
                <Box
                    component="div"
                    display="flex"
                    flexDirection={isMobileView ? "column" : "row"}
                    gap="0.5rem"
                    my="1rem"
                >
                    <CustomButton
                        variant="contained"
                        startIcon={<ChromeReaderModeOutlinedIcon />}
                        onClick={handleCompareClick}
                        disabled={showCompare}
                        data-testid="compareBtn"
                    >
                        Compare
                    </CustomButton>
                    <CustomButton
                        onClick={() => {
                            showAlert("success", "Flagged translations reported!");
                            setAnchorEl(null);
                        }}
                        variant="contained"
                        startIcon={<NearMeOutlinedIcon />}
                    >
                        Report Flagged Translations
                    </CustomButton>
                </Box>
                <Box component="section" display="flex" gap="0.5rem" my="1rem">
                    {isSmallerScreen ? (
                        <>
                            <CustomIconButton onClick={handleMenuOpen}>
                                <MoreVertOutlinedIcon sx={{ color: "neutral.25" }}/>
                            </CustomIconButton>
                            <CustomMenu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <CustomMenuButton onClick={() => setShowCompare(false)}>
                                    <FormatAlignLeftIcon style={{ marginRight: "1rem" }} />
                                    Transcript
                                </CustomMenuButton>
                                <CustomMenuButton
                                    onClick={() => {
                                        setOpenWatchParty(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <GroupsOutlinedIcon style={{ marginRight: "1rem" }} /> Watch
                                    Party
                                </CustomMenuButton>
                                <CustomMenuButton onClick={handleBookmarkClick}>
                                    <BookmarkAddOutlinedIcon style={{ marginRight: "1rem" }} />
                                    Bookmark
                                </CustomMenuButton>
                                <CustomMenuButton onClick={handleDownloadClick}>
                                    <FileDownloadOutlinedIcon style={{ marginRight: "1rem" }} />
                                    Download
                                </CustomMenuButton>
                                <CustomMenuButton
                                    onClick={() => {
                                        setOpenShare(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <ShareOutlinedIcon style={{ marginRight: "1rem" }} />
                                    Share
                                </CustomMenuButton>
                            </CustomMenu>
                        </>
                    ) : (
                        <>
                            <CustomButton
                                onClick={() => setShowCompare(false)}
                                variant="contained"
                                startIcon={<FormatAlignLeftIcon />}
                                disabled={!showCompare}
                            >
                                Transcript
                            </CustomButton>
                            <CustomButton
                                variant="contained"
                                startIcon={<GroupsOutlinedIcon />}
                                onClick={() => {
                                    setOpenWatchParty(true);
                                    setAnchorEl(null);
                                }}
                            >
                                Watch Party
                            </CustomButton>

                            <CustomButton
                                variant="contained"
                                startIcon={<BookmarkAddOutlinedIcon />}
                                onClick={handleBookmarkClick}
                            >
                                Bookmark
                            </CustomButton>
                            <CustomButton
                                variant="contained"
                                startIcon={<FileDownloadOutlinedIcon />}
                                onClick={handleDownloadClick}
                            >
                                Download
                            </CustomButton>
                            <CustomButton
                                variant="contained"
                                startIcon={<ShareOutlinedIcon />}
                                onClick={() => setOpenShare(true)}
                            >
                                Share
                            </CustomButton>
                        </>
                    )}
                    <WatchPartyModal
                        isOpen={openWatchParty}
                        onClose={() => setOpenWatchParty(false)}
                    />
                    <ShareModal isOpen={openShare} onClose={() => setOpenShare(false)} />
                </Box>
            </Box>

            {/* Content Display When Not Comparing */}
            {!showCompare && (
                <Box m={2}>
                    <Box mb={2} className="transcript-panel">
                        <Transcript transcriptJson={translatedTranscript} />
                    </Box>

                    <Box display="flex">
                        <CustomIconButton>
                            <BookmarkIcon sx={{ color: "neutral.25" }}/>
                        </CustomIconButton>
                        <CustomIconButton>
                            <FlagIcon sx={{ color: "neutral.25" }}/>
                        </CustomIconButton>
                    </Box>
                </Box>
            )}

            {/* Conditional Compare Section */}
            {showCompare && (
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box flex={1} mr={2} className="transcript-panel">
                            <Typography variant="body1" component="div">
                                <Transcript transcriptJson={originalTranscript} />
                            </Typography>
                        </Box>
                        <Box flex={1} ml={2} className="transcript-panel">
                            <Typography variant="body1" component="div">
                                <Transcript transcriptJson={translatedTranscript} />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default VideoPlayerTranscript;
