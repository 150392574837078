import React, { useRef, useState, useEffect } from "react";
import "./Dialogue.scoped.css";
import { useDispatch } from "react-redux";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import CheckIcon from "@mui/icons-material/Check";
import { setCurrentTime } from "../../reducers/videoPlayerSlice.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Dialogue = ({
  transcript,
  handleflagging,
  showFlagAndTime,
  listItemHeight,
  handleSubmitSuggestionText,
  setCurrentItemPosition,
  index,
  setAutoScroll,
  setShowResumeScrolling,
  activeItemIndex,
  isAnyTranscriptflagged,
  transcriptList,
  LIST_ITEM_HEIGHT,
  setIsSnackbarOpen,
  setSnackMessage,
}) => {
  const listItem = useRef();
  const [showSuggestionTextbox, setShowSuggestionTextbox] = useState(false);
  const [suggestionText, setSuggestionText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (transcript.flagged) {
      setShowSuggestionTextbox(true);
    } else {
      setShowSuggestionTextbox(false);
    }
  }, [transcript.flagged]);

  const handleTranscriptClick = () => {
    if (!isAnyTranscriptflagged) {
      setAutoScroll(false);
      dispatch(setCurrentTime(transcript.startTime));
      setCurrentItemPosition(index);
      if (index == 0) {
        setAutoScroll(true);
        setShowResumeScrolling(false);
      }
    } else {
      setSnackMessage("Please complete the open suggestion");
      setIsSnackbarOpen(true);
    }
  };

  return (
    <Box
      className={`transcript-list-item`}
      ref={listItem}
      style={{ minHeight: listItemHeight }}
    >
      {showFlagAndTime && (
        <>
          {transcript.flagged ? (
            <FlagIcon
              onClick={() => {
                handleflagging(transcript.timestamp);
                setCurrentItemPosition(activeItemIndex);
                if (
                  transcriptList.current.scrollTop ==
                  activeItemIndex * LIST_ITEM_HEIGHT
                ) {
                  setShowResumeScrolling(false);
                  setAutoScroll(true);
                }
              }}
            />
          ) : (
            <FlagOutlinedIcon
              data-testid="FlagOutlinedIcon"
              onClick={() => {
                if (!isAnyTranscriptflagged) {
                  handleflagging(transcript.timestamp);
                  setShowResumeScrolling(true);
                } else {
                  setSnackMessage("Please complete the open suggestion");
                  setIsSnackbarOpen(true);
                }
              }}
            />
          )}
          <Typography variant="body1" className="transcript-time">
            {transcript.timestamp}
          </Typography>
        </>
      )}
      <Box>
        <Box
          className="transcript-content"
          onClick={() => {
            handleTranscriptClick();
          }}
        >
          <Typography variant="h3">{`${transcript.speaker} :`}</Typography>
          <Typography variant="body1">{transcript.text}</Typography>
        </Box>
        {showSuggestionTextbox && (
          <Box className="suggestion-wrapper">
            <Typography variant="h3">Suggested Translation :</Typography>
            <Box className="suggestion-content">
              <input
                type="text"
                name="suggestion_textbox"
                placeholder="Type here"
                onChange={(e) => setSuggestionText(e.target.value)}
                value={suggestionText}
              />
              <Button
                variant="contained"
                onClick={() => {
                  setSuggestionText("");
                  if (
                    transcriptList.current.scrollTop ==
                    activeItemIndex * LIST_ITEM_HEIGHT
                  ) {
                    handleflagging(transcript.timestamp);
                    setShowResumeScrolling(false);
                    setAutoScroll(true);
                  } else {
                    handleflagging(transcript.timestamp);
                    setCurrentItemPosition(activeItemIndex);
                  }
                }}
              >
                <CloseOutlinedIcon />
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (suggestionText) {
                    handleSubmitSuggestionText(
                      suggestionText,
                      transcript.timestamp
                    );
                    if (
                      transcriptList.current.scrollTop ==
                      activeItemIndex * LIST_ITEM_HEIGHT
                    ) {
                      handleflagging(transcript.timestamp);
                      setShowResumeScrolling(false);
                      setAutoScroll(true);
                    } else {
                      handleflagging(transcript.timestamp);
                      setCurrentItemPosition(activeItemIndex);
                    }
                  } else {
                    setSnackMessage("cannot leave suggestion empty ");
                    setIsSnackbarOpen(true);
                  }
                }}
              >
                <CheckIcon />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dialogue;
