import React from "react";
import { IconButton, Snackbar, Alert } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import "./IconSet.css";

const IconSet = ({ showIcons }) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [isHeartFilled, setIsHeartFilled] = React.useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
    setSnackbarMessage(
      isHeartFilled
        ? "Content removed from the favorite"
        : "Content added to the favorite"
    );
    setSnackbarOpen(true);
  };

  const handleAddClick = () => {
    setSnackbarMessage("Add icon clicked");
    setSnackbarOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className={`icons-container ${showIcons ? "show" : "hide"}`}>
      {showIcons && (
        <>
          <IconButton className="icon-button" onClick={handleHeartClick}>
            {isHeartFilled ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
          <IconButton className="icon-button" onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        className="snackbar"
      >
        <Alert onClose={handleClose} severity="info" className="alert">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default IconSet;
