import React, { useState, useRef, useEffect } from "react";
import { createKeyframes, createExpandDropdown, useDidMountEffect, createCollapseDropdown } from "../../Utils.jsx";
import "./Dropdown.scoped.css";

const Dropdown = ({
    list = [],
    type,
    handleDropDownOpen,
    handleSelection,
    isOpen,
    anchorRef,
    search,
    height,
    mainElement,
    direction,
    offsetX,
    offsetY,
}) => {
    const dropdownRef = useRef(null);
    const maxHeight = "max-" + (height - 100);
    const [searchQuery, setSearchQuery] = useState("");
    const filteredSelections = list.filter((selected) =>
        selected.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleDropdownAction = (select) => () => {
    handleSelection(select);
    handleDropDownOpen(false)();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !(anchorRef?.current && anchorRef.current.contains(event.target))
      ) {
        handleDropDownOpen(false)();
      }
    };

    // Adding the event listener
    document.addEventListener("mousedown", handleClickOutside);

        // Cleanup function to remove the event listener
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [handleDropDownOpen, anchorRef]);

    useEffect(() => {
        const dropdownElement = dropdownRef.current;
        const maxHeightOpen = height || 400;

        createExpandDropdown(maxHeightOpen, true);
        createCollapseDropdown(maxHeightOpen, true, 8);

        if (isOpen) {
            dropdownElement.style.animation = "expandDropdownDynamic 0.5s forwards";
        } else {
            dropdownElement.style.animation = "collapseDropdownDynamic 0.0s forwards";
        }
    }, [isOpen, height]);

  useDidMountEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (!isOpen) {
      dropdownElement.style.animation = "collapseDropdownDynamic 0.5s forwards";
    }
  }, [isOpen]);

    return (
        <div className={"dropdown " + direction} ref={dropdownRef} style={{ right: offsetX ? `${offsetX}px` : "", top: offsetY ? `${offsetY}px` : "" }}>
            <div className={"dropdown-title" + (type === "Language" ? " language-dropdown-title" : "")}>
                <span>{type}</span>
                <i
                    className="fa-solid fa-xmark"
                    onClick={handleDropDownOpen(false)}
                />
            </div>
            {search && (
                <>
                    <div className="dropdown-searchbar">
                        <i className="fa-solid fa-magnifying-glass" />
                        <input
                            type="text"
                            placeholder={"Search your " + type}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="dropdown-searchline" />
                </>
            )}
            <div className={"dropdown-items " + maxHeight}>
                {filteredSelections.map((selected, index) => (
                    <div
                        key={type + index} // Changed to use index directly
                        className="dropdown-item"
                        onClick={handleDropdownAction(selected)}
                    >
                        <span>{selected.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Dropdown;
