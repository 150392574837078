import React from "react";
import ToolTip from "../../ToolTip/ToolTip";
import MuiIcon from "../../../utils/MuiIcon";
import "./RewindForwardButton.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTime } from "../../../reducers/videoPlayerSlice";

const RewindForwardButtons = ({ mediaRef }) => {
  const currentTime = useSelector(({ videoPlayer }) => videoPlayer.currentTime);
  const duration = useSelector(({ videoPlayer }) => videoPlayer.duration);

  const dispatch = useDispatch();

  function handleRewind() {
    const newTime = Math.max(0, currentTime - 10);
    dispatch(setCurrentTime(newTime));
    mediaRef.current.currentTime = newTime;
  }

  function handleForward() {
    const newTime = Math.min(duration, currentTime + 10);
    dispatch(setCurrentTime(newTime));
    mediaRef.current.currentTime = newTime;
  }

  return (
    <>
      <div className="rewind-btn-control" onClick={handleRewind}>
        <ToolTip content={"Rewind 10 Seconds"}>
          <MuiIcon name="Replay10" />
        </ToolTip>
      </div>
      <div className="forward-btn-control" onClick={handleForward}>
        <ToolTip content={"Forward 10 Seconds"}>
          <MuiIcon name="Forward10" />
        </ToolTip>
      </div>
    </>
  );
};
export default RewindForwardButtons;
