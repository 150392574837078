function formatViewCount(viewCount) {
  console.log('viewCount', viewCount)
  if(!viewCount) return "";
  
  if (viewCount < 1000) {
    return viewCount.toString(); // Return the original number if less than 1000
  } else if (viewCount < 1000000) {
    return (viewCount / 1000).toFixed(1) + 'k'; // Convert to 'k' notation for thousands
  } else {
    return (viewCount / 1000000).toFixed(1) + 'M'; // Convert to 'M' notation for millions
  }
}

export default formatViewCount;