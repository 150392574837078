import "./HistorySideBar.scoped.css";
import Search from "../../Search/Search";
import { useState, useRef } from "react";
import { searchSidebarItems } from "../../../utils/serach-sidebar";
import useQueryParam from "../../../hooks/useQueryParam";
import { Box, Stack, Paper, Typography } from "@mui/material";

const HistorySidebar = () => {
  const [searchTerm, setSearchTerm] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef(null);
  const { updateQueryParam } = useQueryParam();

  const handleSearchSubmit = (searchValue) => {
    setSearchTerm(searchValue);
    updateQueryParam("search", searchValue);
  };

  return (
    <Box className="history-sidebar">
        <Search
          searchRef={searchRef}
          placeholder="Search watch history"
          value={searchTerm}
          setValue={setSearchTerm}
          onSubmit={handleSearchSubmit}
          setIsSearching={setIsSearching}
        />
        {searchSidebarItems.map(({ name, icon, handler }) => (
          <Stack className="search-sidebar" key={name}>
            <Paper className="search-sidebar__items" onClick={handler} sx={{ backgroundColor: 'black', padding: 2 }}>
              <Box component="i" className={`fa-solid ${icon}`} />
              <Typography variant="body1" >{name}</Typography>
            </Paper>
          </Stack>
        ))}
    </Box>
  );
};

export default HistorySidebar;
