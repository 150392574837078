import "./ServiceDetailsPage.scoped.css";
import Nav from "../../components/Service/ServiceNav/ServiceNav.jsx";
import { useEffect, useMemo, useState } from "react";
import Image from "../../components/Service/ServiceBanner/img/breakingBad.png";
import { mockData as courseraData } from "../../data/service-data-coursera.js";
import { useParams } from "react-router-dom";
import useQueryParam from "../../hooks/useQueryParam.jsx";
import Netflix from "../../components/Service/ServicesDetails/Netflix/Netflix.jsx";
import Coursera from "../../components/Service/ServicesDetails/Coursera/Coursera.jsx";

const mockData = {
  bannerImg: Image,
  title: "Breaking Bad",
  description:
    "A chemistry teacher diagnosed with inoperable lung cancer turns to manufacturing and selling methamphetamine with a former student in order to secure his family's future",
  seasons: [
    { name: "Season 1", value: 1 },
    { name: "Season 2", value: 2 },
    { name: "Season 3", value: 3 },
    { name: "Season 4", value: 4 },
    { name: "Season 5", value: 5 },
  ],
};

const ServiceDetailsPage = () => {
  const [data, setData] = useState([]);
  const { serviceName, videoId } = useParams();
  const categories = useMemo(
    () =>
      serviceName === "netflix"
        ? ["All", "Movies", "TV Shows", "Watchlist"]
        : ["Home", "My Learning", "Categories"],
    [serviceName]
  );
  const { getAllParams } = useQueryParam();
  const selectedCategory = useMemo(
    () => getAllParams().category || categories[0],
    [categories, getAllParams]
  );

  useEffect(() => {
    switch (serviceName) {
      case "netflix":
        setData(mockData);
        break;
      case "coursera":
        setData(courseraData.find(({ id }) => id === videoId));
        break;
      default:
        setData(mockData);
        break;
    }
  }, [serviceName, videoId]);

  return (
    <div className="service-details">
      <Nav
        serviceName={serviceName}
        categories={categories}
        selectedCategory={selectedCategory}
      />
      {serviceName === "netflix" && <Netflix data={data} />}
      {serviceName === "coursera" && <Coursera data={data} />}
    </div>
  );
};

export default ServiceDetailsPage;
