import { useDispatch, useSelector } from "react-redux";
import { userProfileUpdate } from "../api/profile";
import { setUserInfo } from "../reducers/userSlice";
import { logout } from "../reducers/authSlice";
import { useNavigate } from "react-router-dom";

export default function useUpdateUserProfile() {
  const dispatch = useDispatch();
  const userPref = useSelector(({ userPref }) => userPref);
  const navigate = useNavigate();

  const updateUserProfile = async () => {
    console.log("update user profile");
    try {
      const data = { ...userPref, has_finished_onboarding: true };
      delete data.onboardingStage;
      delete data.interests;
      delete data.formData;
      !data.preferred_language && delete data.preferred_language;
      !data.dob && delete data.dob;
      !data.gender && delete data.gender;
      !data.country && delete data.country && delete data.city;
      const res = await userProfileUpdate(data);
      if (res?.status === 200 && res?.data?.user) {
        console.log("setUserInfo", res);
        dispatch(setUserInfo(res?.data?.user));
      }
    } catch (err) {
      let statusCode = err?.response?.status;
      if (statusCode === 401) {
        dispatch(logout());
        navigate("/CONTENT-PLATFORM/auth/login");
      } else if (statusCode === 500) {
        // alert('network error')
      } else {
      }
    }
  };

  return updateUserProfile;
}
