import { Navigate } from "react-router";
import HomePage from "../pages/HomePage/HomePage";
import PlaylistsPage from "../pages/PlaylistsPage/PlaylistsPage";
import POCPage from "../pages/POC/POCPage";
import SearchResultPage from "../pages/SearchResultPage/SearchResultPage";
import ServiceDetailsPage from "../pages/ServiceItemDetailsPage/ServiceItemDetailsPage";
import ServiceMaterials from "../pages/ServiceMaterials/ServiceMaterials";
import ServicePage from "../pages/ServicePage/ServicePage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import VideoPage from "../pages/VideoPage/VideoPage";
import UploadVideoPage from "../pages/UploadTranslationPages/UploadVideoPage/UploadVideoPage";
import AuthPage from "../pages/AuthPage/AuthPage";
import ForgetPassword from "../pages/ForgetPasswordPage/ForgetPasswordPage";
import ResetPassword from "../pages/ResetPasswordPage/ResetPasswordPage";
import HistoryPage from "../pages/HistroyPage/HistoryPage";
import UploadAudioPage from "../pages/UploadTranslationPages/AudioPage/AudioPage";
import { TRANSLATE_URL } from "../constant/constantUrls";
import UploadImagePage from "../pages/UploadTranslationPages/ImagePage/ImagePage";
import UploadTextPage from "../pages/UploadTranslationPages/UploadTextPage/UploadTextPage";
import AudioPage from "../pages/AudioPage/AudioPage";
import WatchPartyPage from "../pages/WatchPartyPage/WatchPartyPage";
import AccountPage from "../pages/AccountPage/AccountPage";
import SettingsPageNavigation from "../pages/SettingsNavigationPage/SettingsNavigationPage";
import TextContainer from "../components/TextContainer/TextContainer";
import OnboardingPage from "../pages/OnboardingPage/OnboardingPage";

const CONSTANT_URL_PART = "/CONTENT-PLATFORM";
const HOME_URL = `${CONSTANT_URL_PART}/home`;
const AUTH_URL = `${CONSTANT_URL_PART}/auth`;

const redirectHomeElement = <Navigate to={HOME_URL} />;

export const navItems = [
  {
    path: CONSTANT_URL_PART,
    element: <POCPage />,
    isPrivateRoute: true,
    hasLayoutParent: false,
  },
  {
    path: HOME_URL,
    element: <HomePage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/video`,
    element: <VideoPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/video/:videoId`,
    element: <VideoPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/watch-party/:videoId`,
    element: <WatchPartyPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/audio/:audioId`,
    element: <AudioPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/:textType/:textId`,
    element: <UploadTextPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/playlist`,
    element: <PlaylistsPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/search`,
    element: <SearchResultPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/history`,
    element: <HistoryPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/playlist/:playlistId`,
    element: <PlaylistsPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/services/:serviceName`,
    element: <ServicePage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/services/:serviceName/materials/:videoId`,
    element: <ServiceMaterials />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/services/:serviceName/:videoId`,
    element: <ServiceDetailsPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/settings`,
    element: <SettingsPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${TRANSLATE_URL}/audio/:audioId`,
    element: <UploadAudioPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${TRANSLATE_URL}/video/:videoId`,
    element: <UploadVideoPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${TRANSLATE_URL}/image/:imageId`,
    element: <UploadImagePage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: `${TRANSLATE_URL}/:textType/:textId`,
    element: <UploadTextPage />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
  {
    path: "*",
    element: redirectHomeElement,
    isPrivateRoute: false,
    hasLayoutParent: false,
  },
  {
    path: `${CONSTANT_URL_PART}/account`,
    element: <AccountPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/account`,
    element: <AccountPage />,
    isPrivateRoute: false,
    hasLayoutParent: true,
  },
  {
    path: `${CONSTANT_URL_PART}/text/:textID`,
    element: <TextContainer />,
    isPrivateRoute: true,
    hasLayoutParent: true,
  },
];

export const authItems = [
  {
    path: `${AUTH_URL}/login`,
    element: <AuthPage activeTab="login" />,
    redirectElement: redirectHomeElement,
  },
  {
    path: `${AUTH_URL}/signup`,
    element: <AuthPage activeTab="signup" />,
    redirectElement: redirectHomeElement,
  },
  {
    path: `${AUTH_URL}/onboarding`,
    element: <OnboardingPage />,
    redirectElement: redirectHomeElement,
  },
  {
    path: `${AUTH_URL}/forget-password`,
    element: <ForgetPassword />,
    redirectElement: redirectHomeElement,
  },
  {
    path: `${AUTH_URL}/reset-password`,
    element: <ResetPassword />,
    redirectElement: redirectHomeElement,
  },
];
