import { configureStore, combineReducers } from "@reduxjs/toolkit";
import regionReducer from "./reducers/regionSlice";
import authReducer from "./reducers/authSlice";
import userPrefReducer from "./reducers/userPrefReducer";
import airisSidebarReducer from "./reducers/airisSidebarSlice";
import airisChatHistoryReducer from "./reducers/airisChatHistorySlice";
import userSlice from "./reducers/userSlice";
import snackbarSlice from "./reducers/snackbarSlice";
import globeBannerSlice from "./reducers/globeBannerSlice";
import dashVideoObjectSlice from "./reducers/dashVideoObjectSlice";
import notificationSlice from "./reducers/notificationSlice";
import videoCommentSlice from "./reducers/videoCommentSlice";
import recordingReducer from "./reducers/recordingSlice";
import languageReducer from "./reducers/languageSlice";
import videoPlayerSlice from "./reducers/videoPlayerSlice";
import audioObjectSlice from "./reducers/audioObjectSlice";
import textObjectSlice from "./reducers/textObjectSlice";
const combinedReducer = combineReducers({
  auth: authReducer,
  region: regionReducer,
  userPref: userPrefReducer,
  airisSidebar: airisSidebarReducer,
  airisChatHistory: airisChatHistoryReducer,
  userInfo: userSlice,
  toast: snackbarSlice,
  globeBanner: globeBannerSlice,
  dashVideoObject: dashVideoObjectSlice,
  notification: notificationSlice,
  videoComment: videoCommentSlice,
  recording: recordingReducer,
  languages: languageReducer,
  videoPlayer: videoPlayerSlice,
  audioObject: audioObjectSlice,
  textObject: textObjectSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
