import CourseraLogo from "../imgs/coursera-logo.png";
import CourseraBannerImage from "../imgs/coursera-image.png";
import "./ServiceBanner.scoped.css"
import { useNavigate, useParams} from "react-router-dom";
import ServiceTitle from "./ServiceTitle";
import CourseraButton from "../ServicesDetails/Coursera/CourseraButton";

const CourseraBanner = ({title, description, icon, company, image, action, enrolled  }) => {
    const navigate = useNavigate()
    const {videoId, serviceName} = useParams()

    const handleAction = () => navigate(`/CONTENT-PLATFORM/services/${serviceName}/materials/${videoId}`)

    return (
        <div className="coursera__slider-item">
            <div className="coursera__slider-item__description">
                <img src={CourseraLogo} alt="course logo"/>
                <ServiceTitle icon={icon} company={company}>{title}</ServiceTitle>
                <p>
                    {description}
                </p>
                {action && (
                    <CourseraButton action={handleAction} className={enrolled ? 'course-resume' : 'course-enroll'}>
                        {enrolled ? 'Resume course' : 'Enroll'}
                    </CourseraButton>
                )}
            </div>
            <div className="coursera__slider-item__image" style={{ backgroundImage: `url(${image || CourseraBannerImage})` }} />
        </div>
    )
}

export default CourseraBanner