import { useEffect } from "react";

const useKeyboardControls = (
  mediaRef,
  togglePlayPause = () => {},
  handleMiniPlayer = () => {},
  handleTimeUpdate = () => {}
) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`);
      const activeElement = document.activeElement;
      const media = mediaRef.current;

      // Ignore key events if the focus is on an input (excluding range inputs)
      if (
        activeElement.tagName.toLowerCase() === "input" &&
        activeElement.type !== "range"
      ) {
        return;
      }

      if (!media) return;

      switch (event.key) {
        case "k": // Toggle play/pause
        case " ":
          event.preventDefault();
          togglePlayPause();
          break;

        case "i": // Toggle mini player
          event.preventDefault();
          handleMiniPlayer();
          break;

        case "j": // Rewind by 10 seconds
          event.preventDefault();
          handleTimeUpdate(-10);
          break;

        case "l": // Fast forward by 10 seconds
          event.preventDefault();
          handleTimeUpdate(10);
          break;

        case "ArrowRight": // Skip forward by 5 seconds
          event.preventDefault();
          handleTimeUpdate(5);
          break;

        case "ArrowLeft": // Rewind by 5 seconds
          event.preventDefault();
          handleTimeUpdate(-5);
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [mediaRef, togglePlayPause, handleMiniPlayer, handleTimeUpdate]);
};

export default useKeyboardControls;
