import React, { useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TranslationPanel from "../../../components/TranslationPanel/TranslationPanel";
import TranslationButton from "../../../components/TranslationPanel/TranslationButton";
import Summary from "../../../components/Summary/Summary";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ActionButtonsPanel from "../../../components/ActionButtonsPanel/ActionButtonsPanel";

import { useTheme } from "@emotion/react";

const ImagePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [image, setImage] = useState(null); // Default to null or loading image
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  useEffect(() => {
    // Simulate fetching the original and translated images from an API
    const fetchImages = async () => {
      const originalImage =
        process.env.PUBLIC_URL + `/assets/ImagePage/Original.png`;

      const translatedImage =
        process.env.PUBLIC_URL + `/assets/ImagePage/Translated.png`;

      // Simulate async API call delay
      setTimeout(() => {
        setImage({
          original: originalImage,
          translated: translatedImage,
          current: translatedImage, // Initially show the translated image
        });
      }, 1000); // Mock delay
    };

    fetchImages();
  }, []);

  const handleTranslation = () => {
    if (image) {
      setImage({
        ...image,
        current:
          image.current === image.translated
            ? image.original
            : image.translated,
      });
    }
  };

  if (!image) {
    return <div>Loading...</div>; // Show a loader until the images are loaded
  }

  return (
    <Box padding="1rem var(--mui-spacing-8)">
      <Box component="section">
        <Button
          variant="text"
          className="back-btn"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
          onClick={() => navigate("/")}
          sx={{ color: "var(--mui-palette-neutral-25)", textTransform: "none" }}
        >
          Back
        </Button>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isSmallerScreen ? "column" : "row"}
        paddingTop="var(--mui-spacing-4)"
      >
        <Box display="flex" alignItems="start" justifyContent="center" flex="1">
          <img src={image.current} alt="uploaded image" />
        </Box>

        <Box
          maxWidth={isMobile ? "100%" : "350px"}
          m={isSmallerScreen ? "2rem 0" : "0 0 0 2rem"}
        >
          <TranslationPanel fileUrl={image.translated}>
            <TranslationButton
              label="Show Translation"
              handleClick={handleTranslation}
            />
          </TranslationPanel>
          {!isMobile && (
            <ActionButtonsPanel showRate={true} fileUrl={image.translated} />
          )}
          <Box width="100%" m={isMobile && "1rem 0"}>
            <Summary
              summary={`Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`}
              title="Image Description"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImagePage;
