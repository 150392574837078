import {
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LanguageIcon from "@mui/icons-material/Language";
import GoogleIcon from "@mui/icons-material/Google";

import "./NewSignUp.scoped.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { checkUserExists, userSignup } from "../../api/auth";
import { setToast } from "../../reducers/snackbarSlice";
import { useDispatch } from "react-redux";

const NewSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [language, setLanguage] = useState("EN");
  const navigate = useNavigate();
  const [signupFieldError, setsignupFieldError] = useState();
  const dispatch = useDispatch();

  const signUpSchema = z.object({
    fullName: z.string().regex(/\b[A-Z][a-z]+ [A-Z][a-z]+\b/, {
      message: "Please enter a valid full name.",
    }),
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().refine(
      (value) => {
        if (value.length < 12) return false; // length
        if (!/[a-z]/.test(value)) return false; // at least one lowercase
        if (!/[A-Z]/.test(value)) return false; // at least one uppercase
        return true;
      },
      {
        message:
          "Your password must contain at-least 12 characters, one upper case and one lower case letter",
      }
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: zodResolver(signUpSchema),
    mode: "onBlur",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "EN" ? "FR" : "EN"));
  };

  const submitSignupForm = async () => {
    setIsLoading(true);
    let userExists = await checkIfUserExists();
    if (userExists) {
      dispatch(
        setToast({
          showSnackBar: true,
          message:
            "You already have an account with us. Please Login to continue.",
          type: "red",
        })
      );
    } else {
      let signupSuccess = await sendSignUpForm();
      if (signupSuccess) {
        // navigate to the next page for post signup steps (onboarding)
        // navigate("/");
      }
    }

    setIsLoading(false);
  };

  const checkIfUserExists = async () => {
    try {
      const data = getValues();
      const formData = {
        username: data?.username,
        email: data?.email,
      };
      let res = await checkUserExists(formData);
      if (res?.status === 200 && res?.data?.exists) {
        // User exists, show error, log kept to check backend response
        // console.log("checkExists", res);
        return true;
      }
    } catch (err) {
      let errObj = err?.response?.data;
      if (
        err?.response?.status === 404 &&
        errObj &&
        "exists" in errObj &&
        !errObj?.exists
      ) {
        // User does not exist, continue, log kept to check backend response
        // console.log("checkExists err", err);
      }
    }
    return false;
  };

  const sendSignUpForm = async () => {
    try {
      const data = getValues();
      const password2 = getValues("password");
      const formData = {
        ...data,
        password2: password2,
        has_agreed_to_terms: true,
        has_agreed_to_privacy_policy: true,
      };
      let res = await userSignup(formData);
      if (res?.status === 201) {
        return true;
      }
    } catch (err) {
      let statusCode = err?.response?.status;
      if (statusCode && statusCode === 400) {
        setsignupFieldError(err?.response?.data);
      } else {
        dispatch(
          setToast({
            showSnackBar: true,
            message: "Some error ocurred. Please try again later",
            type: "red",
          })
        );
      }
    }
    return false;
  };

  return (
    <Box className="signup">
      <img
        className="logo-img"
        src={process.env.PUBLIC_URL + "/assets/vosyn-logo-dark.svg"}
        alt="company's logo"
      />

      <Typography className="tagline" variant="h6">
        Join{" "}
        <Typography variant="h6" component="span" className="highlight">
          VosynVerse
        </Typography>{" "}
        and Unlock Seamless Translation Across All Media
      </Typography>

      <Box className="signup-box">
        <Box component="form" onSubmit={handleSubmit(submitSignupForm)}>
          <FormControl
            variant="outlined"
            fullWidth
            className="formfield-control"
            error={Boolean(errors.fullName || signupFieldError?.error)}
          >
            <FormLabel className="formfield-label" htmlFor="signup-fullname">
              Full Name
            </FormLabel>
            <OutlinedInput
              {...register("fullName")}
              id="signup-fullname"
              aria-describedby="signup-fullname-helper-text"
              className={`formfield ${errors.fullName ? "input-error" : ""}`}
              type="text"
              placeholder="John Doe"
            />
            {errors.fullName && (
              <FormHelperText
                id="signup-fullname-helper-text"
                className="formfield-error-msg"
              >
                {errors.fullName?.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            variant="outlined"
            fullWidth
            className="formfield-control"
            error={Boolean(errors.email || signupFieldError?.error)}
          >
            <FormLabel className="formfield-label" htmlFor="signup-email">
              Enter email address
            </FormLabel>
            <OutlinedInput
              {...register("email")}
              id="signup-email"
              aria-describedby="signup-email-helper-text"
              className={`formfield ${errors.email ? "input-error" : ""}`}
              type="email"
              placeholder="username@example.com"
            />
            {errors.email && (
              <FormHelperText
                id="signup-email-helper-text"
                className="formfield-error-msg"
              >
                {errors.email?.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            variant="outlined"
            fullWidth
            className="formfield-control"
            error={Boolean(errors.password || signupFieldError?.error)}
          >
            <FormLabel className="formfield-label" htmlFor="signup-password">
              Password
            </FormLabel>
            <OutlinedInput
              {...register("password")}
              id="signup-password"
              aria-describedby="signup-password-helper-text"
              className={`formfield ${errors.password ? "input-error" : ""}`}
              type={showPassword ? "text" : "password"}
              slotProps={{
                input: {
                  endAdornment: (
                    <IconButton
                      className="visibility-icon-box"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityIcon className="visibility-icon" />
                      ) : (
                        <VisibilityOffIcon className="visibility-icon" />
                      )}
                    </IconButton>
                  ),
                },
              }}
            />
            {errors.password && (
              <FormHelperText
                id="signup-password-helper-text"
                className="formfield-error-msg"
              >
                {errors.password?.message}
              </FormHelperText>
            )}
          </FormControl>

          <Button
            type="submit"
            fullWidth
            disabled={isLoading}
            className={`formbtn signupbtn ${isLoading ? "buttonLoading" : ""}`}
            variant="contained"
          >
            Sign Up
          </Button>
        </Box>

        <Box className="formlink-box ">
          <Typography className="signup-text">
            <Typography>
              Already have an account?{" "}
              <Link className="existing-usr" to={"/login"}>
                Log In
              </Link>
            </Typography>
          </Typography>
        </Box>
        <Box className="divider">
          <Box component="span" className="line" />
          <Typography className="text">Or</Typography>
          <Box component="span" className="line" />
        </Box>

        <Button
          className="formbtn google-loginbtn"
          variant="contained"
          startIcon={<GoogleIcon />}
        >
          Continue with Google
        </Button>

        <Button className="formbtn other-optionsbtn">See other options</Button>

        <Box className="language-selector-box" onClick={toggleLanguage}>
          <LanguageIcon />
          <Typography>{language}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewSignUp;
