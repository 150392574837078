import React, { useRef, useState } from "react";
import ToolTip from "../../ToolTip/ToolTip";
import { Menu, MenuItem, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setHovering,
  setPlaybackSpeed,
} from "../../../reducers/videoPlayerSlice";
import "./PlaybackSpeed.scoped.css";
import { useEventListener } from "../../../Utils";

const PlaybackSpeed = ({ mediaRef, mouseMoveTimer, showControls }) => {
  const dispatch = useDispatch();
  const playbackSpeedContainerRef = useRef(null);

  const [showPlaybackSpeedMenu, setShowPlaybackSpeedMenu] = useState(false);
  const isFullScreen = useSelector(
    ({ videoPlayer }) => videoPlayer.isFullScreen
  );
  const playbackSpeed = useSelector(
    ({ videoPlayer }) => videoPlayer.playbackSpeed
  );

  const playbackSpeedRef = useRef(null);

  const handleSpeedChange = (speedValue) => {
    mediaRef.current.playbackRate = speedValue;
  };

  const togglePlaybackSpeedMenu = () => {
    setShowPlaybackSpeedMenu((prev) => !prev);
  };

  const handlePlaybackSpeedButtonClick = (e) => {
    if (
      showPlaybackSpeedMenu &&
      playbackSpeedContainerRef.current &&
      !playbackSpeedContainerRef.current.contains(e.target)
    ) {
      setShowPlaybackSpeedMenu(false);
    }
  };

  const handleMenuClose = () => {
    setShowPlaybackSpeedMenu(false);
  };

  const speedOptions = [
    { label: "1x", value: "1" },
    { label: "1.25x", value: "1.25" },
    { label: "1.5x", value: "1.5" },
    { label: "2x", value: "2" },
  ];

  const iconButtonStyle = {
    backgroundColor: "lightgrey",
    padding: "8px",
    borderRadius: "50%",
    pointerEvents: "none",
    cursor: "default",
  };

  const menuPaperProps = {
    sx: {
      backgroundColor: "darkgrey",
      borderRadius: "40px",
      padding: "10px",
      width: "60px",
      zIndex: 9999,
      marginTop: "-40px",
    },
  };

  useEventListener("click", handlePlaybackSpeedButtonClick);

  return (
    <div
      className="playback-speed-btn-container"
      ref={playbackSpeedContainerRef}
      onMouseEnter={() => {
        clearTimeout(mouseMoveTimer.current);
        dispatch(setHovering(true));
      }}
      onMouseLeave={() => {
        dispatch(setHovering(false));
        showControls();
      }}
    >
      <ToolTip content="Playback Speed">
        <div
          onClick={togglePlaybackSpeedMenu}
          style={{ cursor: "pointer" }}
          ref={playbackSpeedRef}
        >
          {/* Display current playback speed as text using Typography with fixed size and centered */}
          <Typography
            variant="body2"
            sx={{
              width: "34.22px",
              height: "20.01px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {`${playbackSpeed}x`}
          </Typography>
        </div>
      </ToolTip>

      {/* Playback Speed Menu */}
      <Menu
        anchorEl={playbackSpeedRef.current}
        container={isFullScreen ? playbackSpeedRef.current : document.body}
        open={showPlaybackSpeedMenu}
        onClose={handleMenuClose}
        PaperProps={menuPaperProps}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        disableScrollLock
      >
        {speedOptions.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              dispatch(setPlaybackSpeed(option.value));
              handleSpeedChange(option.value);
              handleMenuClose();
            }}
            selected={playbackSpeed === option.value}
            sx={{ justifyContent: "center" }}
          >
            {option.label}
          </MenuItem>
        ))}
        <MenuItem disableGutters sx={{ justifyContent: "center" }}>
          <IconButton sx={iconButtonStyle} onClick={handleMenuClose}>
            {/* Remove the icon here if not needed */}
            {/* <MuiIcon name="TimesOneMobiledata" /> */}
          </IconButton>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PlaybackSpeed;
