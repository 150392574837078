import { useEffect, useState, useMemo, memo } from "react";
import {
  animate,
  motion,
  useMotionValue,
  useTransform,
  useAnimate,
} from "framer-motion";
import { interpolate } from "flubber";

import {
  EASING_SINE_INOUT,
  topLayerPathStrings,
  shuffleArray,
  generateTopTransformations,
  getTopTransformationProperties,
} from "./airis-blob-utils";
import useFirstRender from "./useFirstRender";
import "./AirisBlob.scoped.css";

const AirisBlobTop = ({
  size,
  roughness,
  animationState,
  initialAnimationState,
  animationEnabled,
}) => {
  // For SVG path morphing animation
  const [pathIndex, setPathIndex] = useState(1);
  const animationProgress = useMotionValue(0);
  // eslint-disable-next-line
  const [pathArray, setPathArray] = useState(shuffleArray(topLayerPathStrings));

  // For regular transformations animation
  const [layerScope, animateLayer] = useAnimate();
  const isFirstRender = useFirstRender();
  const [duringStateTransition, setDuringStateTransition] = useState(
    initialAnimationState !== -1 && initialAnimationState !== animationState
  );
  const [transformations, setTransformations] = useState(
    generateTopTransformations(animationState)
  );

  // Generate and store interpolation functions for SVG path morphing
  const pathInterpolationFunctions = useMemo(() => {
    let pathInterpolations = [];
    for (let i = 0; i < pathArray.length - 1; i++) {
      pathInterpolations.push(
        interpolate(pathArray[i], pathArray[i + 1], {
          maxSegmentLength: roughness,
        })
      );
    }

    return pathInterpolations;
  }, [pathArray, roughness]);

  // Get current SVG path from interpolation functions
  const path = useTransform(
    animationProgress,
    pathArray.map((_, idx) => idx),
    pathArray,
    {
      mixer: (a, b) => {
        const idx = pathArray.indexOf(a);
        return pathInterpolationFunctions[idx];
      },
    }
  );

  // Re-generate transformations if animation state has changed
  useEffect(() => {
    // Do not re-generate transformations on mount, only during state changes
    if (!isFirstRender) {
      setTransformations(generateTopTransformations(animationState));
      setDuringStateTransition(true);
    }
    // eslint-disable-next-line
  }, [animationState]);

  // Regular layer transformations (e.g., skew, scale)
  useEffect(() => {
    let animation = undefined;
    if (animationEnabled || duringStateTransition) {
      animation = animateLayer(layerScope.current, transformations, {
        ...getTopTransformationProperties(
          animationState,
          duringStateTransition,
          animationEnabled
        ),
        onComplete: () => {
          // After transform animation completes, generate a new set of transforms to animate to
          setTransformations(generateTopTransformations(animationState));
          setDuringStateTransition(false);
        },
      });
    }

    return () => {
      if (animation !== undefined) {
        animation.stop();
      }
    };
    // eslint-disable-next-line
  }, [
    animationEnabled,
    transformations,
    duringStateTransition,
    layerScope,
    animateLayer,
  ]);

  // SVG path morphing animation
  useEffect(() => {
    let animation = undefined;
    if (animationEnabled && animationState <= 1) {
      animation = animate(animationProgress, pathIndex, {
        duration: animationState === 1 ? 2 : 3,
        ease: EASING_SINE_INOUT,
        onComplete: () => {
          // Upon finishing morphing to one shape, update target animationProgress value to morph to next shape
          if (pathIndex === pathArray.length - 1) {
            animationProgress.set(0);
            setPathIndex(1);
          } else {
            setPathIndex(pathIndex + 1);
          }
        },
      });
    }

    return () => {
      if (animation !== undefined) {
        animation.stop();
      }
    };
    // eslint-disable-next-line
  }, [animationState, animationEnabled, pathIndex, pathArray]);

  return (
    <div
      className={`${
        size === -1 ? "va-blob-unsized-layer-wrapper" : "va-blob-layer-wrapper"
      }`}
    >
      <svg
        width={size === -1 ? "100%" : size}
        height={size === -1 ? "100%" : size}
        viewBox="0 0 144 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Motion component for regular transformations */}
        <motion.g
          ref={layerScope}
          initial={generateTopTransformations(
            initialAnimationState === -1
              ? animationState
              : initialAnimationState
          )}
          style={
            animationEnabled || duringStateTransition
              ? undefined
              : generateTopTransformations(animationState)
          }
        >
          {/* Motion component for SVG path morphing animation */}
          <motion.path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d={path}
            fill={
              animationState === 2
                ? "url(#paint0_radial_347_5_state2)"
                : animationState === 1
                  ? "url(#paint0_radial_347_5_state1)"
                  : "url(#paint0_radial_347_5)"
            }
            initial={{ rotate: Math.random() * 360 }}
          />
        </motion.g>
        <defs>
          <radialGradient
            id="paint0_radial_347_5"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(72 72) rotate(90) scale(46.5883 47.5522)"
          >
            <stop offset="0.7" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#45e8ff" />
          </radialGradient>
          <radialGradient
            id="paint0_radial_347_5_state1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(72 72) rotate(90) scale(46.5883 47.5522)"
          >
            <stop offset="0.6" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#45e8ff" />
          </radialGradient>
          <radialGradient
            id="paint0_radial_347_5_state2"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(72 72) rotate(90) scale(46.5883 47.5522)"
          >
            <stop offset="0.6" stop-color="#222222" stop-opacity="0" />
            <stop offset="1" stop-color="#e81601" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export default memo(AirisBlobTop);
