import React from "react";
import { useAlert } from "../../context/AlertProvider";
import "./Alert.scoped.css";

const getAlertType = (type) => {
  switch (type) {
    case "success":
      return "alert-success";
    case "warning":
      return "alert-warning";
    case "error":
      return "alert-error";
    default:
      return "alert-info";
  }
};

const Alert = () => {
  const { alert } = useAlert();

  if (!alert) return null;

  const alertClass = getAlertType(alert.type);

  return (
    <div className={`alert ${alertClass}`}>
      <i className="fas fa-exclamation-circle"></i>
      {alert.message}
    </div>
  );
};

export default Alert;
