import "./Overlay.scoped.css";
import ButtonsTour from "./ButtonsTour/ButtonsTour";
import React, { useEffect, useRef } from "react";
import AirisTour from "./AirisTour/AirisTour";
import GlobeTour from "./GlobeTour/GlobeTour";
import WelcomeModal from "./WelcomeModal/WelcomeModal";
import ProfileSetupBox from "./ProfileSetup/ProfileSetupBox/ProfileSetupBox";
import ProfileSetupYN from "./ProfileSetup/ProfileSetupYN/PofileSetupYN";
import ProfileLocation from "./ProfileSetup/ProfileLocation/ProfileLocation";
import ProfileLanguage from "./ProfileSetup/ProfileLanguage/ProfileLanguage";
import ProfileBirthDay from "./ProfileSetup/ProfileBirthDay/ProfileBirthDay";
import { useDispatch, useSelector } from "react-redux";
import { incrementStage, skipToStage } from "../../reducers/userPrefReducer";
import TopicsButtons from "./ProfileSetup/ProfileTopics/TopicsButtons";
import { setOnboardingCompleted } from "../../reducers/userSlice";
import TourFinalPage from "./TourFinalPage/TourFinalPage";
import useUpdateUserProfile from "../../hooks/useUpdateUserProfile";
import useProfileCompletion from "../../hooks/useProfileCompletion";

export const StateContext = React.createContext();

const Overlay = () => {
  const tourStep = useSelector(({ userPref }) => userPref.onboardingStage);
  const dispatch = useDispatch();
  const updateUserProfile = useUpdateUserProfile();
  const [profileCompletion, numberOfCompleted] = useProfileCompletion();
  const initialCompleted = useRef();
  const profileSetupTitles = [
    "What is your preferred language?",
    "What's your location?",
    "Your birthday and gender?",
    "Lastly, choose the topics that you like!",
  ];

  const profileSetupTexts = [
    "Experience worldwide content in your language.",
    "Discover what's happening in your area.",
    "This helps to provide you with a more inclusive and tailored experience.",
    "We will be recommending your chosen topic more.",
  ];

  useEffect(() => {
    if (tourStep >= 11) {
      if (tourStep === 11) initialCompleted.current = numberOfCompleted;
      updateUserProfile();
      if (localStorage.getItem("showProfileSetupAt") !== null)
        localStorage.removeItem("showProfileSetupAt");
      dispatch(setOnboardingCompleted(true));
    }
  }, [tourStep]);

  // for disabling scroll in onboarding tour pages
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    if (tourStep > -1 && tourStep < 16) {
      document.body.style.overflow = "hidden";
      document.addEventListener("scroll", handleScroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("scroll", handleScroll);
    };
  }, [tourStep]);

  useEffect(() => {
    // For enabling esc key press during tour to skip the tour
    const escKeyHandler = (e) => {
      console.log("KEY PRESSED:", e.key);
      if (e.key === "Escape") {
        e.preventDefault();
        dispatch(skipToStage(16));
      }
    };

    if (tourStep > 0 && tourStep < 10) {
      document.addEventListener("keydown", escKeyHandler);
    }

    return () => {
      document.removeEventListener("keydown", escKeyHandler);
    };
  });

  // Form components go here in order
  const profileSetupForms = [
    <ProfileLanguage />,
    <ProfileLocation />,
    <ProfileBirthDay />,
    <TopicsButtons />,
  ];

  const incrementStep = () => {
    dispatch(incrementStage());
  };

  const getComponent = () => {
    if (tourStep === 0) return <WelcomeModal onClick={incrementStep} />;
    else if (tourStep === 1) return <GlobeTour />;
    else if (tourStep >= 2 && tourStep <= 5) return <ButtonsTour />;
    else if (tourStep >= 6 && tourStep <= 9) return <AirisTour />;
    else if (tourStep === 10) return <TourFinalPage />;
    else if (tourStep === 11)
      return (
        <div className="profile-setup-yn">
          <ProfileSetupYN
            profileCompletion={profileCompletion}
            numberOfCompleted={numberOfCompleted}
          />
        </div>
      );
    else if (tourStep >= 12) {
      const profileStep = tourStep - 12;
      return (
        <div className="profile-setup-box">
          <ProfileSetupBox
            formTitle={profileSetupTitles[profileStep]}
            formText={profileSetupTexts[profileStep]}
            profileStep={profileStep}
            showBackButton={initialCompleted.current < profileStep + 1}
          >
            {profileSetupForms[profileStep]}
          </ProfileSetupBox>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className="overlay"
        style={{
          opacity: tourStep > 0 && tourStep < 10 && 0,
        }}
      />
      {getComponent()}
    </>
  );
};

export default Overlay;
