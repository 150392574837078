import axios from "axios";
const get_all_my_playlists = `${process.env.REACT_APP_PROD_URL}/interactions/playlist/`;
const create_playlist = `${process.env.REACT_APP_PROD_URL}/interactions/playlist/`;
const add_content_to_playlist = `${process.env.REACT_APP_PROD_URL}/interactions/playlistcontent/`;
const remove_content_from_playlist = `${process.env.REACT_APP_PROD_URL}/interactions/playlistcontent/`;
const get_contents_in_playlist = `${process.env.REACT_APP_PROD_URL}/interactions/playlistcontent/get-all-content/`;
const get_playlists_of_content = `${process.env.REACT_APP_PROD_URL}/interactions/playlistcontent/check-content-playlist/`;
const get_playlist_details = `${process.env.REACT_APP_PROD_URL}/interactions/playlist/`;

export const getAllMyPlaylists = async (page) => {
    try{
        const accessToken = localStorage.getItem("token");
        console.log('token', accessToken)
        let res = await axios.get(`${get_all_my_playlists}/?page=${page}`, {  headers: {
            Authorization: `Token ${accessToken}`
          }})
        console.log('response',res)
        return res
    }catch(err) {
        throw(err)
    }
}

export const createPlaylist = async (data) => {
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.post(create_playlist, data, {  headers: {
            Authorization: `Token ${accessToken}`
          }})
        return res
    }catch(err) {
        throw(err)
    }
}


export const addContentToPlaylist = async (data) => {
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.post(add_content_to_playlist, data, {  headers: {
            Authorization: `Token ${accessToken}`
          }})
        return res
    }catch(err) {
        throw(err)
    }
}

export const removeFromPlaylist = async (playListId, contentId) => {
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.delete(`${remove_content_from_playlist}/${playListId}/${contentId}`, {  headers: {
            Authorization: `Token ${accessToken}`
          }})
        return res
    }catch(err) {
        throw(err)
    }
}

export const getContentsInPlaylist = async (data) => {
    let queryParam=''
    if(data) {
        queryParam = '?';
        for(let item in data) {
            queryParam=queryParam+`${item}=${data[item]}&`
        }
        queryParam=queryParam.slice(0, -1)
    }
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.get(get_contents_in_playlist+queryParam ,{   headers: {
            Authorization: `Token ${accessToken}`
          }})
          console.log(res)
        return res
    }catch(err) {
        throw(err)
    }
}

export const checkPlaylistsOfVideo = async (content_id) => {
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.get(get_playlists_of_content, { params: {content_id},   headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json', // explicity set content type to json
          }})
        return res
    }catch(err) {
        throw(err)
    }
}

export const getSinglePlayliseDetails = async (playlist_id) => {
    try{
        const accessToken = localStorage.getItem("token");
        let res = await axios.get(get_playlist_details+playlist_id, { headers: {
            Authorization: `Token ${accessToken}`
          }})
        return res
    }catch(err) {
        throw(err)
    }
}
