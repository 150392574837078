export const useScrollToBottom = (chatHistory, messagesEndRef) => {
  const scrollToBottom = () => {
    if (chatHistory.length) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView(
          { behavior: "smooth" },
          { block: "end" }
        );
      }, 50);
    }
  };
  const scrollToTop = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView(
        { behavior: "smooth" },
        { block: "start" }
      );
    }, 50);
  };

  return { scrollToBottom, scrollToTop };
};
