import { useState, useEffect } from "react";
import "./AirisVideoCard.scoped.css";
import { FaCirclePlay } from "react-icons/fa6";

const AirisVideoCard = ({ label, divStyle, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);

  const scale = [1, 0.9, 0.8];
  const hideDelay = 1200;

  useEffect(() => {
    // Function to show and hide the card
    const showHideCard = () => {
      setIsVisible(true); // Show the card

      // Hide the card after a delay
      const hideTimeout = setTimeout(
        () => {
          if (index !== 3) setIsVisible(false);
        },
        hideDelay * (index + 1)
      );

      const scaleUpTimeout1 = setTimeout(() => {
        if (index !== 1) setScaleFactor(scale[index - 2]);
      }, hideDelay * 2);

      const scaleUpTimeout2 = setTimeout(() => {
        if (index === 3) setScaleFactor(scale[index - 3]);
      }, hideDelay * 3);

      // Return cleanup function to clear the hide timeout
      return () => {
        clearTimeout(hideTimeout);
        clearTimeout(scaleUpTimeout1);
        clearTimeout(scaleUpTimeout2);
      };
    };

    // Show the card after an initial delay
    const timeout = setTimeout(showHideCard, 400); // Initial delay for showing the card

    // Cleanup function to clear the initial timeout
    return () => clearTimeout(timeout);
  }, [index]);

  // Update scale factor when index changes
  useEffect(() => {
    setScaleFactor(scale[index - 1]);
  }, [index]);

  // Repeat the cycle
  useEffect(() => {
    const interval = setInterval(() => {
      const timeout = setTimeout(() => {
        setIsVisible(true);
        setScaleFactor(scale[index - 1]);
      }, 400);

      const hideTimeout = setTimeout(
        () => {
          if (index !== 3) setIsVisible(false);
        },
        (index + 1) * hideDelay
      );

      const scaleUpTimeout1 = setTimeout(() => {
        if (index !== 1) setScaleFactor(scale[index - 2]);
      }, hideDelay * 2);

      const scaleUpTimeout2 = setTimeout(() => {
        if (index === 3) setScaleFactor(scale[index - 3]);
      }, hideDelay * 3);

      return () => {
        clearTimeout(timeout);
        clearTimeout(hideTimeout);
        clearTimeout(scaleUpTimeout1);
        clearTimeout(scaleUpTimeout2);
      };
    }, 5600); // Repeat the cycle after 4 seconds

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="parent-container">
      <div
        className={`outer-big-div ${isVisible ? "visible" : ""}`}
        style={{
          top: `${index * -20}px`, // Adjust the top position as needed
          transform: `scale(${scaleFactor}) translateY(${
            isVisible ? 0 : index * 28
          }px)`, // Apply scale factor and downward transformation
        }}
      >
        <div className="inner-big-div" style={divStyle}>
          <FaCirclePlay
            style={{
              width: 72.08,
              height: 72.08,
              margin: "auto",
              color: "black",
            }}
          />
        </div>
        <div className="card-text">
          <p>{label}</p>
        </div>
      </div>
    </div>
  );
};

export default AirisVideoCard;
