import React, { useState } from "react";
import "./WatchPartyModal.scoped.css";
import {
  Modal,
  Icon,
  Box,
  Typography,
  Grid2,
  Button,
  TextField,
  ThemeProvider,
} from "@mui/material";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export const socialMediaPlatforms = [
  {
    name: "WhatsApp",
    iconClass: WhatsAppIcon,
    watchPartyUrl: "https://wa.me/?text=",
  },
  {
    name: "Facebook",
    iconClass: FacebookIcon,
    watchPartyUrl: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    name: "Instagram",
    iconClass: InstagramIcon,
    watchPartyUrl: "https://www.instagram.com/?url=",
  },
  {
    name: "Telegram",
    iconClass: TelegramIcon,
    watchPartyUrl: "https://telegram.me/share/url",
  },
  {
    name: "Email",
    iconClass: EmailIcon,
    watchPartyUrl: "mailto:?&subject=VosynVerse%20Video&body=",
  },
];

const modalTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
  },
};

/**
 * WatchPartyModal component for inviting friends to a watch party.
 *
 * @component
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {Function} props.onClose - Function to call when closing the modal.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const WatchPartyModal = ({ isOpen, onClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Modal
        className="watch-party-modal"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="watch-party-invite-friends"
      >
        <Box className="watch-party-modal-content">
          <Grid2 className="watch-party-close">
            <Icon
              onClick={onClose}
              component={CloseIcon}
              aria-label="Close"
              aria-hidden="true"
            ></Icon>
          </Grid2>
          <Box className="invite-friends-box">
            <Typography
              id="watch-party-invite-friends"
              variant="h2"
              className="watch-party-modal-title"
            >
              Invite your friends!
            </Typography>
            <Box className="invite-friends">
              <GroupAddIcon className="add-icon" />
            </Box>
          </Box>

          <Box className="social-media-icons">
            {socialMediaPlatforms.map((platform) => (
              <Icon
                className="social-media-icon"
                fontSize="inherit"
                key={platform.name}
                component={platform.iconClass}
                aria-label={platform.name}
                onClick={() =>
                  window.open(
                    platform.watchPartyUrl + window.location.href,
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              />
            ))}
          </Box>

          <Box className="watch-party-modal-input-copybtn">
            <TextField
              fullWidth
              size="small"
              value={window.location.href}
              readOnly
              className="copy-link-textfield"
            />
            <Box className="watch-party-modal-copyLinkBtn-box">
              <Button
                className="watchParty-copyLinkBtn"
                aria-label="Copy Link"
                onClick={handleCopyClick}
              >
                {copied ? <CheckIcon /> : null}
                {copied ? "Copied!" : "Copy Link"}
              </Button>
            </Box>
          </Box>

          <Box className="watchParty-maybelater">
            <Button
              className="button-maybelater"
              onClick={onClose}
              aria-label="Maybe Later"
            >
              Maybe Later
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default WatchPartyModal;
