import "./ServiceContent.scoped.css";
import { useEffect, useState } from "react";
import serviceData from "../../../data/services-data.json";
import ServiceCards from "../ServiceCards/ServiceCards";

const ServiceContent = ({ selectedSection }) => {
  const [episodesData, setEpisodesData] = useState([]);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const detailsContentInfo =
    "Violence, frightening scenes, substance use, alcohol use, foul language, sexual content";
  const baseUrl = "https://image.tmdb.org/t/p/original";

  //TODO Temporary function for format time according to mock data. Remove when real API will be implimented
  const tempDateFormatting = (inputDate) => {
    if (!inputDate) {
      return "March 8, 2024";
    }
    const [year, month, day] = inputDate?.split("-");
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[parseInt(month, 10) - 1];
    return `${monthName} ${parseInt(day, 10)}, ${year}`;
  };

  useEffect(() => {
    const tempData = serviceData.results.map((card) => ({
      thumbnail_url: `${baseUrl}/${card.backdrop_path}`,
      released_date: tempDateFormatting(card.release_date),
      description: card.overview,
      titles: [card.title],
      id: "a2e8cf81-9b5c-4391-baf9-47cec8609149",
    }));
    setEpisodesData(tempData);
    setRecommendationsData(serviceData.results);
  }, [episodesData.overview]);

  return (
    <div className="service-content">
      {selectedSection === "Episodes" && (
        <>
          <ServiceCards
            serviceItems={episodesData}
            serviceName="netflix"
            cardType="episodesServiceCard"
          />
        </>
      )}
      {selectedSection === "Recommended" && (
        <>
          <h3 className="service-content__recommendations-title">
            Based on what you are watching
          </h3>
          <div className="service-content__recommendations">
            <ServiceCards
              serviceItems={recommendationsData}
              serviceName="netflix"
              cardType="recommenedServiceCard"
            />
          </div>
        </>
      )}
      {selectedSection === "Details" && (
        <div className="service-content__details">
          <h3>More info</h3>
          <p>Content advisory</p>
          <span>{detailsContentInfo}</span>
        </div>
      )}
    </div>
  );
};

export default ServiceContent;
