import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Rating,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

//Styling for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  textAlign: "center",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ReportFlagModal = ({ open, handleClose, onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  const handleDone = () => {
    const reportFlagData = { rating, feedback };
    onSubmit(reportFlagData);
    handleClose();
  };

  /* Removing keyboard shortcuts for the video in the background during
  input of feedback in the modal */
  const handleKeyDown = (e) => {
    if (
      e.key === " " ||
      e.key === "ArrowRight" ||
      e.key === "ArrowLeft" ||
      e.key === "i" ||
      e.key === "f" ||
      e.key === "j" ||
      e.key === "l" ||
      e.key === "k" ||
      e.key === "m"
    ) {
      e.stopPropagation();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableScrollLock
      aria-labelledby="report-flag-modal"
      aria-describedby="report-flag-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="report-flag-modal" variant="h6" component="h2">
          Report Submitted Successfully!
        </Typography>
        <Typography id="report-flag-modal-description" sx={{ mt: 2 }}>
          How were the translations? Did you want to provide any feedback so we
          can improve for future translations?
        </Typography>
        <Rating
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          label="Input Feedback"
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{ mt: 2 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography variant="body2">
            See <Link>Flagged</Link> translations to review & provide feedback.
          </Typography>
          <Button onClick={handleDone} variant="contained" color="primary">
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReportFlagModal;
